import TargetingManager from 'targetingManager';
import AdsDataSource from 'datasource/adsDataSource';
import Utils from 'common/utils';
import Constants from 'common/constants';
import {
    jobTypedAdUnitValidatorWithEventsV1,
    landingAdUnitValidatorV1,
    amazonLanderAuValidation,
} from './adUnitDataValidators';
import { IdStore, MemoryStore } from 'dataStore/index';
import memoryStoreKeys from 'common/memoryStoreKeys';

import {
    fireAdImpressionEvent,
    fireSlotFailedEvent,
    fireSlotImpVEvent,
} from 'eventManager/eventHelpers/adEvents';
import EventManager from 'eventManager/eventManager';
import {
    getSkipToOfferAuId,
    getTabOverAuId,
} from 'helpers/featureValueHelpers/featureValueHelpers';
import { AbTestHelper } from 'helpers/index';

const _skipToTabInValidAuCb = abTestSlot => {
    if (abTestSlot) {
        IdStore.storeIdForKey(abTestSlot, 'control');
    } else {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.AB_TEST_23, 'control');
    }

    IdStore.sync();
};

export const fetchSkipToTabAdUnitV5 = () => {
    return AdsDataSource.fetchAdsForAdUnitFbs(
        TargetingManager.fetchTargetingParams(),
        getSkipToOfferAuId(),
        landingAdUnitValidatorV1,
    )
        .then(adUnitData => {
            if (Utils.getObjVal(adUnitData, ['isValid'])) {
                MemoryStore.setItem(memoryStoreKeys.SKIP_TO_TAB_AD_UNIT_DATA, adUnitData);

                return adUnitData;
            } else {
                _skipToTabInValidAuCb();
            }
        })
        .catch(err => {
            _skipToTabInValidAuCb();
        });
};

export const fetchSkipToTabAdUnitV6 = () => {
    return AdsDataSource.fetchAdsForAdUnitFbs(
        TargetingManager.fetchTargetingParams(),
        getTabOverAuId(),
        landingAdUnitValidatorV1,
    )
        .then(adUnitData => {
            if (Utils.getObjVal(adUnitData, ['isValid'])) {
                MemoryStore.setItem(memoryStoreKeys.LANDING_TAB_OVER_AD_UNIT_DATA, adUnitData);

                return adUnitData;
            }
        })
        .catch(err => {
            console.log('au call fail for Tab over', err);
        });
};

export const fetchSkipToTabAdUnit = () =>
    AdsDataSource.fetchAdsForAdUnitFbs(
        TargetingManager.fetchTargetingParams(),
        Utils.isTestEnv()
            ? Constants.AD_UNIT_ID.SKIP_TO_NEW_TAB_AU.STAG
            : Constants.AD_UNIT_ID.SKIP_TO_NEW_TAB_AU.PROD,
        jobTypedAdUnitValidatorWithEventsV1,
    )
        .then(adUnitData => {
            if (Utils.getObjVal(adUnitData, ['isValid'])) {
                MemoryStore.setItem(memoryStoreKeys.SKIP_TO_TAB_AD_UNIT_DATA, adUnitData);

                return adUnitData;
            } else {
                _skipToTabInValidAuCb();
            }
        })
        .catch(err => {
            _skipToTabInValidAuCb();
        });

const _fireFailedAuEvents = (failedAus, skipFiringSlotImp = false) => {
    failedAus.forEach(failedAuData => {
        if (!failedAuData.ad) return;

        fireAdImpressionEvent(failedAuData.ad, Constants.EVENT_TYPE.LANDING, {}, 'lp_imp', true);

        if (!skipFiringSlotImp) {
            fireSlotImpVEvent(
                failedAuData.ad,
                failedAuData.ad.selectedJob || {},
                Constants.EVENT_TYPE.LANDING,
                {},
                'lp_imp',
                true,
            );
        }
        fireSlotFailedEvent(
            failedAuData.ad,
            failedAuData.error,
            Constants.EVENT_TYPE.LANDING,
            {},
            'lp_imp',
            true,
        );
    });
};

export const fetchAmazonBrandedAU = auId =>
    AdsDataSource.fetchAdsForAdUnitFbs(
        TargetingManager.fetchTargetingParams(),
        auId,
        amazonLanderAuValidation,
    )
        .then(adUnitData => {
            const { failedAus = [] } = adUnitData;

            if (Utils.getObjVal(adUnitData, ['isValid'])) {
                fireAdImpressionEvent(adUnitData.ad, Constants.EVENT_TYPE.LANDING);
                fireSlotImpVEvent(
                    adUnitData.ad,
                    adUnitData.ad.jobresult,
                    Constants.EVENT_TYPE.LANDING,
                );
            }

            if (failedAus && failedAus.length) {
                failedAus.forEach(auData => {
                    if (auData.ad && auData.ad.jobresult) {
                        auData.ad.selectedJob = auData.ad.jobresult;
                    }
                });
                _fireFailedAuEvents(failedAus, true);
            }

            return adUnitData;
        })
        .catch(e => {
            console.log(e);
            EventManager.sendEvent(
                Constants.EVENT.AD_FAILED,
                { error: 'au call Failed' },
                Constants.EVENT_TYPE.LANDING,
            );
        });

export const fetchSkipToTabAdUnitV4 = (
    adUnitId,
    abTestSlot,
    amazonLanderAuValidationCheck = false,
) =>
    AdsDataSource.fetchAdsForAdUnitFbs(
        TargetingManager.fetchTargetingParams(),
        adUnitId,
        amazonLanderAuValidationCheck ? amazonLanderAuValidation : landingAdUnitValidatorV1,
        {},
        AbTestHelper.amazonLanderStaticAdId() ? 0 : 2,
    )
        .then(adUnitData => {
            if (Utils.getObjVal(adUnitData, ['isValid'])) {
                if (amazonLanderAuValidationCheck && adUnitData.ad && adUnitData.ad.jobresult) {
                    adUnitData.selectedJob = adUnitData.ad.jobresult;
                }
                MemoryStore.setItem(memoryStoreKeys.SKIP_TO_TAB_AD_UNIT_DATA, adUnitData);

                if (adUnitData && adUnitData.failedAus && adUnitData.failedAus.length) {
                    _fireFailedAuEvents(adUnitData.failedAus, amazonLanderAuValidationCheck);
                }

                return adUnitData;
            } else if (
                amazonLanderAuValidationCheck &&
                adUnitData.ad &&
                adUnitData.ad.customAdInfo &&
                adUnitData.ad.customAdInfo.link &&
                AbTestHelper.amazonLanderStaticAdId()
            ) {
                if (amazonLanderAuValidationCheck && adUnitData.ad && adUnitData.ad.customAdInfo) {
                    adUnitData.selectedJob = adUnitData.ad.customAdInfo;
                }
                MemoryStore.setItem(memoryStoreKeys.SKIP_TO_TAB_AD_UNIT_DATA, adUnitData);

                return adUnitData;
            } else {
                abTestSlot && _skipToTabInValidAuCb(abTestSlot);
                const { failedAus = [] } = adUnitData;

                fireSlotFailedEvent(
                    {},
                    'au call failed',
                    Constants.EVENT_TYPE.LANDING,
                    {},
                    'lp_imp',
                    true,
                );

                if (
                    amazonLanderAuValidationCheck &&
                    failedAus &&
                    failedAus.length &&
                    !AbTestHelper.amazonLanderStaticAdId()
                ) {
                    failedAus.forEach(auData => {
                        if (auData.ad && auData.ad.jobresult) {
                            auData.ad.selectedJob = auData.ad.jobresult;
                        }
                    });
                }

                if (
                    adUnitData &&
                    adUnitData.failedAus &&
                    adUnitData.failedAus.length &&
                    !AbTestHelper.amazonLanderStaticAdId()
                ) {
                    _fireFailedAuEvents(adUnitData.failedAus, amazonLanderAuValidationCheck);
                }
            }
        })
        .catch(err => {
            abTestSlot && _skipToTabInValidAuCb(abTestSlot);
            fireSlotFailedEvent({}, 'au call failed', Constants.EVENT_TYPE.LANDING, {}, 'lp_imp');
        });

export const fetchSkipToTabAdUnitV7 = (adUnitId, abTestSlot) =>
    AdsDataSource.fetchAdsForAdUnitFbs(
        TargetingManager.fetchTargetingParams(),
        adUnitId,
        landingAdUnitValidatorV1,
        {},
        0,
    )
        .then(adUnitData => {
            if (AbTestHelper.dynamicTaboverLander() && adUnitData.ad && adUnitData.ad.jobresult) {
                if (adUnitData.ad && adUnitData.ad.jobresult) {
                    adUnitData.selectedJob = adUnitData.ad.jobresult;
                }
                MemoryStore.setItem(memoryStoreKeys.SKIP_TO_TAB_AD_UNIT_DATA, adUnitData);

                if (adUnitData && adUnitData.failedAus && adUnitData.failedAus.length) {
                    _fireFailedAuEvents(adUnitData.failedAus, false);
                }

                return adUnitData;
            } else if (
                adUnitData.ad &&
                adUnitData.ad.customAdInfo &&
                adUnitData.ad.customAdInfo.link &&
                AbTestHelper.staticTaboverLander()
            ) {
                MemoryStore.setItem(memoryStoreKeys.SKIP_TO_TAB_AD_UNIT_DATA, adUnitData);

                return adUnitData;
            } else {
                abTestSlot && _skipToTabInValidAuCb(abTestSlot);

                if (AbTestHelper.staticTaboverLander()) {
                    fireSlotFailedEvent(
                        {},
                        'au call failed',
                        Constants.EVENT_TYPE.LANDING,
                        {},
                        'lp_imp',
                        true,
                    );
                }

                if (
                    AbTestHelper.dynamicTaboverLander() &&
                    adUnitData.ad &&
                    !adUnitData.ad.jobresult
                ) {
                    fireSlotFailedEvent(
                        {},
                        'no jobs',
                        Constants.EVENT_TYPE.LANDING,
                        {},
                        'lp_imp',
                        true,
                    );
                } else if (AbTestHelper.dynamicTaboverLander() && adUnitData.ad) {
                    fireSlotFailedEvent(
                        {},
                        'au call failed',
                        Constants.EVENT_TYPE.LANDING,
                        {},
                        'lp_imp',
                        true,
                    );
                }
            }
        })
        .catch(err => {
            abTestSlot && _skipToTabInValidAuCb(abTestSlot);
            fireSlotFailedEvent({}, 'au call failed', Constants.EVENT_TYPE.LANDING, {}, 'lp_imp');
        });
