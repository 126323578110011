import { fetchSessionData } from 'datasource';
import Utils from 'common/utils';
import Constants from 'common/constants';
import { IdStore } from 'dataStore';
import EventManager from 'eventManager/eventManager';
import { ErrLogger } from 'errLogger';
import { ConfigProvider, FeatureMap } from 'providers';
import { CommonHelper, AbTestHelper } from 'helpers';
import { fireAntiSpamPixelEvent } from 'eventManager/eventHelpers/landingEvents';

const getSession = count => {
    fetchSessionData()
        .then(sessionData => {
            console.log('SESSION_DATA: ', JSON.stringify(sessionData));
            // Store the session value
            AppCommon.storeSessionResp(sessionData);

            // Dispatch session fetched
            Utils.dispatchEvent(Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED);
            CommonHelper.antiSpamPixel(fireAntiSpamPixelEvent);
        })
        .catch(sessionErr => {
            console.log('ERR: Failed fetching session -', sessionErr);
            ErrLogger.log(sessionErr);

            if (count < 4) {
                setTimeout(() => getSession(count + 1), 500 * (count + 1));
            } else {
                Utils.dispatchEvent(Constants.INTERNAL_EVENTS.ON_SESSION_FETCH_FAILED);

                EventManager.sendEvent(
                    'session_retry_Failed',
                    { responsedata: `${sessionErr}` },
                    Constants.EVENT_TYPE.LANDING,
                );
            }

            try {
                EventManager.sendEvent(
                    'session_failed',
                    { responsedata: `${sessionErr}` },
                    Constants.EVENT_TYPE.LANDING,
                );
            } catch (e) {
                // Do Nothing
            }
        });
};

export default class AppCommon {
    /**
     * This contains methods that need to be called after an app has been mounted;
     */
    static async fireOnAppMounted() {
        if (window.isLh) return;
        getSession(0);
    }

    /**
     * This contains methods that need to be called when an app has been rendered;
     * This will be called every time the app renders
     */
    static fireOnAppRendered() {
        EventManager.initialize();
    }

    static storeSessionResp(resp) {
        if (Utils.isNull(resp)) {
            console.log('Could not store session-response since it is null');

            return;
        }

        let sessionId = resp.id;

        if (false === Utils.isEmptyStr(sessionId)) {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.SESSION_ID, sessionId);
        }

        if (false === Utils.isNull(resp.deviceId) && false === Utils.isEmptyStr(resp.deviceId)) {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.DEVICE_ID, resp.deviceId);
        }

        let geoObj = resp.geo;

        if (false === Utils.isEmptyObj(geoObj)) {
            /*
             * geo ->
             * city: "mumbai"
             * cityLatLong: "19.075984,72.877656"
             * country: "IN"
             * deviceId: "9bd5b5aa-87e7-4131-9949-4662b98d5784"
             * region: "mh"
             * userIP: "122.15.120.178"
             */

            // Extract the geo data
            let city = geoObj['city'];

            if (
                false === Utils.isEmptyStr(city) &&
                Utils.isEmptyStr(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_CITY))
            ) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_CITY, city);
            }

            let country = geoObj['country'];

            if (
                false === Utils.isEmptyStr(country) &&
                Utils.isEmptyStr(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_COUNTRY))
            ) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_COUNTRY, country);
            }

            let region = geoObj['region'];

            if (
                false === Utils.isEmptyStr(region) &&
                Utils.isEmptyStr(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_REGION))
            ) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_REGION, region);
            }
            let zipFrmUrl = Utils.filterInvalidUrlInput(
                Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.ZIP),
            );
            let isValidZip = zipFrmUrl && Utils.isValidNumber(zipFrmUrl) && zipFrmUrl.length === 5;
            let zipCode = isValidZip ? zipFrmUrl : geoObj['zip'];

            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE_FED, zipCode);

            if (
                false === Utils.isEmptyStr(zipCode) &&
                Utils.isEmptyStr(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE))
            ) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE, zipCode);
            }

            let userIP = geoObj['userIP'];

            if (
                false === Utils.isEmptyStr(userIP) &&
                Utils.isEmptyStr(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_IP))
            ) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_IP, userIP);
            }
        } else {
            try {
                EventManager.sendEvent(
                    'nozip',
                    { responsedata: JSON.stringify(resp) },
                    Constants.EVENT_TYPE.LANDING,
                );
            } catch (e) {
                // Do Nothing
            }
        }
    }

    static sessionStoreUpdate() {
        const WfhAmazonTest = ConfigProvider.Features.getFeature(FeatureMap.WfhAmazon);
        const WfhAmazon = WfhAmazonTest ? WfhAmazonTest.value === 'wfh-amazon' : false;

        if (WfhAmazon) {
            Utils.insertUrlParam('jt', 'amazon');
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL, 'amazon');
        }

        const BaristaStarbucksKwTest = ConfigProvider.Features.getFeature(
            FeatureMap.BaristaStarbucksKw,
        );

        let keyword = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LANDING_KEYWORD) || '';

        const BaristaStarbucksKw = BaristaStarbucksKwTest
            ? BaristaStarbucksKwTest.value === 'barista-for-starbucks-kw' &&
              keyword.toLowerCase() !== AbTestHelper.getStarbucksKwToExclude()
            : false;

        if (BaristaStarbucksKw) {
            Utils.replaceUrlParam('kw', 'barista');
            Utils.insertUrlParam('jt', 'starbucks');
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL, 'starbucks');
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UTM_TERM, 'barista');
        }

        const OnlineJobsToWorkFromHomeForGoogle = ConfigProvider.Features.getFeature(
            FeatureMap.OnlineJobsToWorkFromHomeForGoogle,
        );

        const isOnlineJobsToWorkFromHomeForGoogle = OnlineJobsToWorkFromHomeForGoogle
            ? OnlineJobsToWorkFromHomeForGoogle.value === 'force_feed_kw'
            : false;

        const kwToReplaceWith = ConfigProvider.Features.getFeature(
            FeatureMap.ForceFeedToUpdateWith,
        );

        if (isOnlineJobsToWorkFromHomeForGoogle && kwToReplaceWith) {
            Utils.replaceUrlParam('kw', decodeURIComponent(kwToReplaceWith.value));
            IdStore.storeIdForKey(
                Constants.ID_STORE_KEYS.UTM_TERM,
                decodeURIComponent(kwToReplaceWith.value),
            );
        }

        if (AbTestHelper.isAmericanAirlinesMultiLogo()) {
            Utils.replaceUrlParam('kw', 'airport');
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UTM_TERM, 'airport');
        }
    }
}
