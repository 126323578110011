import React, { useState } from 'react';
import { Dialog, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LABELS } from 'strings';
import RouterPrefixHandler from 'common/routePrefixHandler';
import EmailRegistrationForm from '../regCTAButtonContainer/regCTAEmailSubForm';
import { AntSwitch } from './antSwitch';

const formLayout = {
    xs: 12,
    sm: 8,
    md: 6,
    lg: 4,
    xl: 3,
};

const gridItemProps = {
    item: true,
    style: {
        width: '100%',
    },
    ...formLayout,
};

const EmailPopupToggleContainer = props => {
    const [showDialog, setShowDialog] = useState(false);
    const [emailSend, setEmailSend] = useState(false);
    const classes = useStyles();

    const handleToggle = event => {
        !emailSend && setShowDialog(state => !state);
    };

    return (
        <>
            <hr className={classes.horizontalLine} />
            <Grid {...gridItemProps} className={classes.container}>
                <div className={classes.textContainer} item>
                    <img
                        alt={'icon'}
                        className={`${classes.logo}`}
                        src={RouterPrefixHandler.getImagePath('/images/email-toggle.svg')}
                    />
                    <span className={classes.text}>{LABELS.EMAIL_TOGGLE_TEXT}</span>
                </div>
                <AntSwitch
                    checked={emailSend || showDialog}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    item
                    onChange={handleToggle}
                />
            </Grid>
            {showDialog && (
                <Dialog
                    classes={{
                        paper: classes.popOverDialog,
                        scrollPaper: classes.customScrollPaper,
                    }}
                    open={true}>
                    <EmailRegistrationForm
                        emailSendSuccess={() => setEmailSend(true)}
                        handleClose={() => setShowDialog(false)}
                        open={showDialog}
                    />
                </Dialog>
            )}
        </>
    );
};

const useStyles = makeStyles(theme => ({
    horizontalLine: {
        margin: '0px 15px',
        width: '-webkit-fill-available',
        maxWidth: 420,
        border: 'solid 0.5px #dbdee2',
    },
    container: {
        display: 'block',
        alignItems: 'center',
        padding: '8px 15px',
        margin: '10px 0px',
        maxWidth: '450px',
    },
    textContainer: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    logo: {
        width: 23,
        marginRight: 10,
    },
    text: {
        fontSize: 16,
        fontWeight: 500,
        color: '#294964',
    },
    popOverDialog: {
        borderRadius: 10,
        top: 36,
        margin: 16,
    },
    customScrollPaper: {
        alignItems: 'flex-start',
    },
}));

export default EmailPopupToggleContainer;
