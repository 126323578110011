export const KeyMap = {
    // ---- Dev Features --- //
    DEV_FEATURE_SHOULD_VALIDATE_IFRAME_HTML: 'DEV_FEATURE_SHOULD_VALIDATE_IFRAME_HTML',
    // ----- Data Source Features  -------- //
    KwImageSrc: 'KwImageSrc',
    RgBenefitBadgeText: 'RgBenefitBadgeText',
    SERPListingsConfig: 'SERPListingsConfig',
    SERPNativeAdConfig: 'SERPNativeAdConfig',
    SERPPreListingsConfig: 'SERPPreListingsConfig',
    JTSERPListingConfig: 'JTSERPListingConfig',
    BenefitList: 'BenefitList',
    LanderTitle: 'LanderTitle',
    CTAStyles: 'CTAStyles',
    CTAText: 'CTAText',
    AmazonBrandFlowAuSource: 'AmazonBrandFlowAuSource',
    NEW_FALLBACK_LOGIC: 'NEW_FALLBACK_LOGIC',
    // ----- Generic Features ------ //
    HideZipPage: 'HideZipPage',
    SkipReg2Page: 'SkipReg2Page',
    ShowSkipConfirmInterstitial: 'ShowSkipConfirmInterstitial',
    HideJobCategoryQuestion: 'HideJobCategoryQuestion',
    ShowJobCategoryTest: 'ShowJobCategoryTest',
    JobTypeAsKeywordIfEmpty: 'JobTypeAsKeywordIfEmpty',
    REG_PAGES_H1_CTA_TEXT: 'REG_PAGES_H1_CTA_TEXT',
    HideDOBField: 'HideDOBField',
    PROFANITY_EXCLUSION_WORDS: 'PROFANITY_EXCLUSION_WORDS',
    SkipToNewTabTestData: 'SkipToNewTabTestData',
    // ------ AbTest Features ------------//
    SkipToNewTabTestV4: 'SkipToNewTabTestV4',
    BLMailSuffixes: 'BLMailSuffixes',
    EduQuestionTest: 'EduQuestionTest',
    ListingsAsLandingFlow: 'ListingsAsLandingFlow',
    UpdatableABTChannel1: 'UpdatableABTChannel1',
    UpdatableABTChannel2: 'UpdatableABTChannel2',
    UpdatableABTChannelForTalrooAPIKwTest: 'UpdatableABTChannelForTalrooAPIKwTest',
    KwBenefitsLander: 'KwBenefitsLander',
    DxmlExtFlow: 'DxmlExtFlow',
    AmazonBrandFlowV2: 'AmazonBrandFlowV2',
    KwJtConfigIntegration: 'KwJtConfigIntegration',
    LeaveBehindTestV2: 'LeaveBehindTestV2',
    ShowStaticJL: 'ShowStaticJL',
    ShowTipBelowSERP: 'ShowTipBelowSERP',
    ListingEmailAlerts: 'ListingEmailAlerts',
    EmailPlacementTest: 'EmailPlacementTest',
    KwAsListingsLander: 'KwAsListingsLander',
    SummerJobsLogo: 'SummerJobsLogo',
    SubjectWellSkipInBlue: 'SubjectWellSkipInBlue',
    CustomisedColorForSkip: 'CustomisedColorForSkip',
    EnableJtKwMappingForJobCategorySurvey: 'EnableJtKwMappingForJobCategorySurvey',
    ZipAndEmailLanding: 'ZipAndEmailLanding',
    UpwardZipInEmail: 'UpwardZipInEmail',
    DisableExitInterstitial: 'DisableExitInterstitial',
    SkipToOffersNewTab: 'SkipToOffersNewTab',
    PrePopFlowV2: 'PrePopFlowV2',
    PrePopFlowV3: 'PrePopFlowV3',
    PrePopFlow: 'PrePopFlow',
    RepeatUserListingsBg: 'RepeatUserListingsBg',
    RgBenefitStrip: 'RgBenefitStrip',
    JobsZipOptimized: 'JobsZipOptimized',
    KwdDropdownLander: 'KwdDropdownLander',
    RgStaticKwImage: 'RgStaticKwImage',
    OfferPathHeaderDesignTest: 'OfferPathHeaderDesignTest',
    OfferPathNewUITest: 'OfferPathNewUITest',
    GenderOptimizeTest: 'GenderOptimizeTest',
    FulfillmentPageDesignTest: 'FulfillmentPageDesignTest',
    PIITest: 'PIITest',
    PIITestForPPR: 'PIITestForPPR',
    EduQuestion: 'EduQuestion',
    DriverOfferPathTargeting: 'DriverOfferPathTargeting',
    MultipleOfferPath: 'MultipleOfferPath',
    MultiBrandKWLogo: 'MultiBrandKWLogo',
    ListingsJobDedup: 'ListingsJobDedup',
    SMSRedirectTest: 'SMSRedirectTest',
    PhoneNoDeepValidation: 'PhoneNoDeepValidation',
    FbBgFlow: 'FbBgFlow',
    FbGoogleEmailInterstitial: 'FbGoogleEmailInterstitial',
    SkipToOfferPathSources: 'SkipToOfferPathSources',
    SkipToOfferPathChannel: 'SkipToOfferPathChannel',
    OldUserSkipToOfferPath: 'OldUserSkipToOfferPath',
    GoogleDisplaySkipToOfferPath: 'GoogleDisplaySkipToOfferPath',
    TaboolaLander: 'TaboolaLander',
    OldUserJobcaseOffer: 'OldUserJobcaseOffer',
    NotifyZipToOffers: 'NotifyZipToOffers',
    InterstitialBetweenOfferPath: 'Interstitial Between Offer Path',
    Back2ListingsMidPathRedirection: 'back2ListingsMidPathRedirection',
    AllInboxEmailLander: 'AllInboxEmailLander',
    RegThree: 'RegThree',
    GoogleDisplayKw: 'GoogleDisplayKw',
    RgKwNewFlow: 'RgKwNewFlow',
    WfhAmazon: 'WfhAmazon',
    MarketingPartners: 'MarketingPartners',
    TCPAMarketingPartners: 'TCPAMarketingPartners',
    TCPMarketingPartnersExtra: 'TCPAMarketingPartnersExtra',
    BaristaStarbucksKw: 'BaristaStarbucksKw',
    ListingsInBg: 'ListingsInBg',
    ListingsInBgHybridSurveyFlow: 'ListingsInBgHybridSurveyFlow',
    ListingsInBgHybridOffersFlow: 'ListingsInBgHybridOffersFlow',
    ListingsInBgMidpathOffers: 'ListingsInBgMidpathOffers',
    ListingsInBgPrelanderKw: 'ListingsInBgPrelanderKw',
    ListingsBgXmlTest: 'ListingsBgXmlTest',
    GoogleProgressBarTest: 'GoogleProgressBarTest',
    ListingsInBgWithZipUpwardOpt: 'ListingsInBgWithZipUpwardOpt',
    AIBEMJobCategoryTest: 'AIBEMJobCategoryTest',
    NoSkipToOffersTest: 'NoSkipToOffersTest',
    ListingsBgXmlStaticImageTest: 'ListingsBgXmlStaticImageTest',
    ListingsBgXmlTestMobileImage: 'ListingsBgXmlTestMobileImage',
    ListingsBgXmlTestTabletImage: 'ListingsBgXmlTestTabletImage',
    ListingsBgXmlTestWebImage: 'ListingsBgXmlTestWebImage',
    PushNotifier: 'PushNotifier',
    AIBEMLander: 'AIBEMLander',
    SurveyQueVaraition: 'SurveyQueVaraition',
    JobcaseCoregUI: 'JobcaseCoregUI',
    RgKeyword: 'RgKeyword',
    XMLPartTimeBG: 'XMLPartTimeBG',
    RepeatUser: 'RepeatUser',
    FBSkipToOffers: 'FBSkipToOffers',
    KWSelectorRGLandingPageTest: 'KWSelectorRGLandingPageTest',
    GoogleKWSelectorLandingPage: 'GoogleKWSelectorLandingPage',
    GoogleKWSelectorLanderMapping: 'GoogleKWSelectorLanderMapping',
    Reg2Optimize: 'Reg2Optimize',
    PrePopKWSelectorLanderTest: 'PrePopKWSelectorLanderTest',
    UpwardEmailAndRegPageOptimization: 'UpwardEmailAndRegPageOptimization',
    AllInboxSMSLander: 'AllInboxSMSLander',
    SMSLanderJTMapping: 'SMSLanderJTMapping',
    RGDropdownLander: 'RGDropdownLander',
    XMLListingBgSkipWithoutZip: 'XMLListingBgSkipWithoutZip',
    EduQuestionVar: 'EduQuestionVar',
    AWKSMultiBrandLander: 'AWKSMultiBrandLander',
    AWKSMultiBrandLanderLogos: 'AWKSMultiBrandLanderLogos',
    SeniorJobsFacebook: 'SeniorJobsFacebook',
    SeniorJobsFacebookWithoutZip: 'SeniorJobsFacebookWithoutZip',
    MultiBrandLander: 'MultiBrandLander',
    MultiBrandLanderLogos: 'MultiBrandLanderLogos',
    MultiBrandKwOptions: 'MultiBrandKwOptions',
    UpwardEmailOptInTest: 'UpwardEmailOptInTest',
    ListingsInBGWithWhiteBGTest: 'ListingsInBGWithWhiteBGTest',
    EventsToTrack: 'EventsToTrack',
    SnapchatMultiBrand: 'SnapchatMultiBrand',
    SnapchatMultiBrandLogoList: 'SnapchatMultiBrandLogoList',
    ArrowForResultsGeneration: 'ArrowForResultsGeneration',
    OnlineJobsToWorkFromHomeForGoogle: 'OnlineJobsToWorkFromHomeForGoogle',
    ForceFeedToUpdateWith: 'ForceFeedToUpdateWith',
    PushOptInPlacementTest: 'PushOptInPlacementTest',
    PushOptInPlacement: 'PushOptInPlacement',
    StarbucksKwToExclude: 'StarbucksKwToExclude',
    AmericanAirlinesRG: 'AmericanAirlinesRG',
    OvalCta: 'OvalCta',
    AmericanAirlinesRgOptions: 'AmericanAirlinesRgOptions',
    AmericanAirlinesRgIcons: 'AmericanAirlinesRgIcons',
    FbclidReplacement: 'FbclidReplacement',
    EDUPartners: 'EDUPartners',
    FulfilmentPageListings: 'FulfilmentPageListings',
    FulfilmentPageOverlayListings: 'FulfilmentPageOverlayListings',
    HideReg1Skip: 'HideReg1Skip',
    FulfillmentPageNewDesign: 'FulfillmentPageNewDesign',
    SkipBlack: 'SkipBlack',
    ListingsBgRegThreeV1: 'ListingsBg-RegThree-V1',
    ListingsBgRegThreeV2: 'ListingsBg-RegThree-V2',
    Reg2OptMobile: 'Reg2OptMobile',
    Reg2GreyedCTA: 'Reg2GreyedCTA',
    GoogleDisplayLander: 'GoogleDisplayLander',
    UpwardBottomAlignedInterstitial: 'UpwardBottomAlignedInterstitial',
    ExclusionZipTest: 'ExclusionZipTest',
    JobCategoryQuestionNeuvooCreative: 'JobCategoryQuestionNeuvooCreative',
    SkipControlFlow: 'SkipControlFlow',
    AmericanAirlinesMultiLogo: 'AmericanAirlinesMultiLogo',
    GroceryStoreVariant: 'GroceryStoreVariant',
    FulfillmentDesignPhaseTwo: 'FulfillmentDesignPhaseTwo',
    ProfileCTALander: 'ProfileCTALander',
    SkipToOfferNewTabReg2: 'SkipToOfferNewTabReg2',
    SkipToOfferNeTabPGMTCAndBing: 'SkipToOfferNeTabPGMTCAndBing',
    PrePopUpdatedLandingPage: 'PrePopUpdatedLandingPage',
    PrePopUpdatedReg2: 'PrePopUpdatedReg2',
    PremiumJobProgressBar: 'PremiumJobProgressBar',
    ChangeCtaForUpward: 'ChangeCtaForUpward',
    RemoveLogoFromReg2: 'RemoveLogoFromReg2',
    EnableSms2Click: 'EnableSms2Click',
    GovernmentLogo: 'GovernmentLogo',
    GovernmentLogoAwks: 'GovernmentLogoAwks',
    UpwardGovernment: 'UpwardGovernment',
    SurveyPathHeader: 'SurveyPathHeader',
    TalrooAPIKwTest: 'TalrooAPIKwTest',
    RegisteredUserSkipReg1: 'RegisteredUserSkipReg1',
    AmazonBrandFlow: 'AmazonBrandFlow',
    AmazonBrandFlowJobSelection: 'AmazonBrandFlowJobSelection',
    FedExBrandFlow: 'FedExBrandFlow',
    SkipRegPages: 'SkipRegPages',
    SkipPositioningTest: 'SkipPositioningTest',
    RgJtKwTest: 'RgJtKwTest',
    FacebookHalloweenJobs: 'FacebookHalloweenJobs',
    DisableBackButtonBehaviourListingsPage: 'DisableBackButtonBehaviourListingsPage',
    UpwardRevampTest: 'UpwardRevampTest',
    CtaRegTick: 'CtaRegTick',
    WarmUpQuestionTest: 'WarmUpQuestionTest',
    WarmUpTestAnswers: 'WarmUpTestAnswers',
    XmlPopUp: 'XmlPopUp',
    ListingsBGDesktopModelChange: 'ListingsBGDesktopModelChange',
    EmailOnLanderTest: 'EmailOnLanderTest',
    MotiveInteractiveTest: 'MotiveInteractiveTest',
    MotiveInteractiveTestOS: 'MotiveInteractiveTestOS',
    NeuvooWidgetTest: 'NeuvooWidgetTest',
    PrePopReg1AndReg2CollapsedFields: 'PrePopReg1AndReg2CollapsedFields',
    PuzzSurveyVariation: 'PuzzSurveyVariation',
    RestrictNotificationConfig: 'RestrictNotificationConfig',
    TaboolaAmazonLander: 'TaboolaAmazonLander',
    GoogleSSOLogin: 'GoogleSSOLogin',
    DxmlExtNewLander: 'DxmlExtNewLander',
    SourceListForTCPARegisteredUsers: 'SourceListForTCPARegisteredUsers',
    AmazonBrandedInterstitial: 'AmazonBrandedInterstitial',
    HideCheckBoxOptSkipLink: 'HideCheckBoxOptSkipLink',
    APIRepeatUserFlow: 'APIRepeatUserFlow',
    MJCEmailOptin: 'MJCEmailOptin',
    BrandLanderTest: 'BrandLanderTest',
    BrandLanderAdUnitId: 'BrandLanderAdUnitId',
    BrandCompanyNameForUpwardApi: 'BrandCompanyNameForUpwardApi',
    BrandCID: 'BrandCID',
    BrandLanderUI: 'BrandLanderUI',
    BrandLanderV2: 'BrandLanderV2',
    BrandLanderV2UI: 'BrandLanderV2UI',
    SkipLanderWhenFailed: 'SkipLanderWhenFailed',
    BrandCIDForUpwardApi: 'BrandCIDForUpwardApi',
    NeuvooWidgetCPCFloor: 'NeuvooWidgetCPCFloor',
    GenericKeywordLander: 'GenericKeywordLander',
    GenericKeywordMap: 'GenericKeywordMap',
    NeuvooWidgetAdUnit: 'NeuvooWidgetAdUnit',
    DisplayJobsLogoAwks39: 'DisplayJobsLogoAwks39',
    GovernmentLogoAwks39: 'GovernmentLogoAwks39',
    AirportLogoAwks39: 'AirportLogoAwks39',
    HardRedirectOnInit: 'HardRedirectOnInit',
    HardRedirectOnInitLink: 'HardRedirectOnInitLink',
    GoogleSSOClientId: 'GoogleSSOClientId',
    SetS2oFlag: 'SetS2oFlag',
    OlduserTCPAoptin: 'OlduserTCPAoptin',
    SERPNativeAd: 'SERPNativeAd',
    SERPNativeIframeSrc: 'SERPNativeIframeSrc',
    RemoveLogoRegScreen: 'RemoveLogoRegScreen',
    LoaderInterstitialTest: 'LoaderInterstitialTest',
    UpwardRemoveGenderField: 'UpwardRemoveGenderField',
    MergedListingsSources: 'MergedListingsSources',
    ListingsBGRemoveGenderField: 'ListingsBGRemoveGenderField',
    LeaveBehindDxmlExtInterstitial: 'LeaveBehindDxmlExtInterstitial',
    JobTitleLogoList: 'JobTitleLogoList',
    SkipOnRegHeader: 'SkipOnRegHeader',
    ControlFlowRemoveGenderField: 'ControlFlowRemoveGenderField',
    RemoveExitInterstitial: 'RemoveExitInterstitial',
    EmailPreferencePage: 'EmailPreferencePage',
    CTAVariationBlue: 'CTAVariationBlue',
    SkipOnUpwardPageOptimization: 'SkipOnUpwardPageOptimization',
    VerizonLander: 'VerizonLander',
    SkipUserReg: 'SkipUserReg',
    WarmUpWithoutZip: 'WarmUpWithoutZip',
    RegThreeUIV2: 'RegThreeUIV2',
    JobAlertSurveyPath: 'JobAlertSurveyPath',
    TaboolaKeywordSpecificLanderTrucker: 'TaboolaKeywordSpecificLanderTrucker',
    TaboolaKeywordSpecificLanderProduct: 'TaboolaKeywordSpecificLanderProduct',
    TaboolaKeywordSpecificLanderAmazon: 'TaboolaKeywordSpecificLanderAmazon',
    GoogleOnlineJobsLander: 'GoogleOnlineJobsLander',
    WarmUpHeaderTitle: 'WarmUpHeaderTitle',
    WarmUpDisplayHourlyPay: 'WarmUpDisplayHourlyPay',
    DynamicMultiBrandKWLogo: 'DynamicMultiBrandKWLogo',
    LightGradientBg: 'LightGradientBg',
    GoogleSeniorJobsLander: 'GoogleSeniorJobsLander',
    WarmUpWithinWeek: 'WarmUpWithinWeek',
    PhoneNoPlacementTest: 'PhoneNoPlacementTest',
    TaboverMultibrandLogos: 'TaboverMultibrandLogos',
    DisregardJobType: 'DisregardJobType',
    DisableBackButtonListings: 'DisableBackButtonListings',
    AggressiveRegPgHeading: 'AggressiveRegPgHeading',
    AggressiveHeadersList: 'AggressiveHeadersList',
    ListingsBgStaticImages: 'ListingsBgStaticImages',
    ListingsBgStaticImageTest: 'ListingsBgStaticImageTest',
    Reg3BrandedLander: 'Reg3BrandedLander',
    QueryParamChangeAdvertisers: 'QueryParamChangeAdvertisers',
    GREY_BUTTON_TCPA_UNCHECKED: 'GREY_BUTTON_TCPA_UNCHECKED',
    EmploymentStatusTest: 'EmploymentStatusTest',
    SkipButtonTest: 'SkipButtonTest',
    TalrooAdRelated: 'TalrooAdRelated',
    customQuestionsListMJC: 'customQuestionsListMJC',
    CONFIRMATION_PAGE_MJC: 'ConfirmationPageMJC',
    MJC_DOB_HEADER: 'MJC_DOB_HEADER',
    TALROO_WIDGET: 'TALROO_WIDGET',
    MJC_SERP_REVAMP: 'MJC_SERP_REVAMP',
    FORCE_FEED_UTM_TERMS: 'FORCE_FEED_UTM_TERMS',
    MJC_SP_CHANGE: 'MJC_SP_CHANGE',
    GenericTerms: 'GenericTerms',
    JTFallbackValue: 'JTFallbackValue',
    PDMO2_COPY_CHANGE: 'PDMO2_COPY_CHANGE',
    SubjectwellCreativeTest: 'SubjectwellCreativeTest',
    JobAlertSurveyPathSkipMappingLanding: 'JobAlertSurveyPathSkipMappingLanding',
    ProgressBarTest: 'ProgressBarTest',
    RemoveRegTopStripScreen: 'RemoveRegTopStripScreen',
    SwapZipFieldAddress: 'SwapZipFieldAddress',
    REPEAT_USER_V3: 'REPEAT_USER_V3',
    REPEAT_USER_TCPA_TEXT: 'REPEAT_USER_TCPA_TEXT',
    JOB_ALERT_TITLE: 'JOB_ALERT_TITLE',
    JOB_ALERT_CTA1: 'JOB_ALERT_CTA1',
    JOB_ALERT_CTA2: 'JOB_ALERT_CTA2',
    REMOVE_TOP_STRIP: 'REMOVE_TOP_STRIP',
    HIDE_SKIP_FOR_REG_PAGES: 'HIDE_SKIP_FOR_REG_PAGES',
    ADDICTED_AFFILIATE_29: 'ADDICTED_AFFILIATE_29',
    SubjectwellCreativeTestV2: 'SubjectwellCreativeTestV2',
    Generic_Multibrand_Lander: 'Generic_Multibrand_Lander',
    Branded_Reg_Path_CTA_BG_Colour: 'Branded_Reg_Path_CTA_BG_Colour',
    MJC_CUSTOM_QUESTION_CONFIG: 'MJC_CUSTOM_QUESTION_CONFIG',
    PreferencePopupTest: 'PreferencePopupTest',
    ICON_ABOVE_HEADER: 'ICON_ABOVE_HEADER',
    MJC_SERP_NEW: 'MJC_SERP_NEW',
    DarkSerpTest: 'DarkSerpTest',
    MJC_LANDING_SKIP_TEXT_CHANGE: 'MJC_LANDING_SKIP_TEXT_CHANGE',
    MJC_NEW_CTA_TEST_PDMO: 'MJC_NEW_CTA_TEST_PDMO',
    ZIP_LANDER_NEW: 'ZIP_LANDER_NEW',
    SkipToNewTabTestV4AuCallNewStrategy: 'SkipToNewTabTestV4AuCallNewStrategy',
    SkipToNewTabTestV4NewStrategyBrandName: 'SkipToNewTabTestV4NewStrategyBrandName',
    MJC_SKIP_TEST: 'MJC_SKIP_TEST',
    SemiPrepopReg1: 'SemiPrepopReg1',
    SHOW_JT_LOGO: 'SHOW_JT_LOGO',
    NEW_DXML_STATIC_PUB: 'NEW_DXML_STATIC_PUB',
    MJC_WARM_UP_QUESTION: 'MJC_WARM_UP_QUESTION',
    WARM_UP_QUESTION_BG_COLOR: 'WARM_UP_QUESTION_BG_COLOR',
    EMAIL_SUBMIT_PAGE: 'EMAIL_SUBMIT_PAGE',
    EMAIL_SUBMIT_TEST_HEADER: 'EMAIL_SUBMIT_TEST_HEADER',
    WARMUP_QUESTION_DROPDOWN: 'WARMUP_QUESTION_DROPDOWN',
    REG_H1_QUESTION_TEXT: 'REG_H1_QUESTION_TEXT',
    MJC_SEASONAL_JOBS_LANDING_PAGE: 'MJC_SEASONAL_JOBS_LANDING_PAGE',
    INTERSTITIAL_AD_UNIT: 'INTERSTITIAL_AD_UNIT',
    BACK_BUTTON_INSTERSTITIAL_DISPLAY: 'BACK_BUTTON_INSTERSTITIAL_DISPLAY',
    BACK_BTN_INTERSTITIAL_TEXT: 'BACK_BTN_INTERSTITIAL_TEXT',
    BackButtonInterstitialListings: 'BackButtonInterstitialListings',
    SURVEY_INSTERSTITIAL_DISPLAY: 'SURVEY_INSTERSTITIAL_DISPLAY',
    TCPA_PAGE_NEW_USER: 'TCPA_PAGE_NEW_USER',
    JOBCATEGORY_QN: 'JOBCATEGORY_QN',
    JOBCATEGORY_QN_TITLE: 'JOBCATEGORY_QN_TITLE',
    XML_FULL_REG_FLOW: 'XML_FULL_REG_FLOW',
    SKIP_BTN_COLOR: 'SKIP_BTN_COLOR',
    XML_LANDER_TITLE: 'XML_LANDER_TITLE',
    showHPText: 'showHPText',
    FallBackListings: 'FallBackListings',
    BRANDED_LANDER: 'BRANDED_LANDER',
    BRANDED_FLOW_REG_PAGES_VALUES: 'BRANDED_FLOW_REG_PAGES_VALUES',
    BRANDED_FLOW_SKIP_HIDE: 'BRANDED_FLOW_SKIP_HIDE',
    NON_XML_HEADER: 'NON_XML_HEADER',
    BACK_BTN_INTERSTITIAL_BTN_TEXT: 'BACK_BTN_INTERSTITIAL_BTN_TEXT',
    SKIP_TO_OFFER_AD_ID: 'SKIP_TO_OFFER_AD_ID',
    SHOW_AD_UNIT_URL: 'SHOW_AD_UNIT_URL',
    BRANDED_ZIP_PAGE: 'BRANDED_ZIP_PAGE',
    AD_DESCRIPTION_TOGGLE: 'AD_DESCRIPTION_TOGGLE',
    REPEAT_USER: 'REPEAT_USER',
    COMPLIANCE_TEXT_COLOR: 'COMPLIANCE_TEXT_COLOR',
    LEAVE_BEHIND_CTA_CLICK: 'LEAVE_BEHIND_CTA_CLICK',
    BACK_BUTTON_INSTERSTITIAL_PULSE_CTA: 'BACK_BUTTON_INSTERSTITIAL_PULSE_CTA',
    skipToOfferNewTabReg3: 'skipToOfferNewTabReg3',
    skipToOfferNewTabReg1: 'skipToOfferNewTabReg1',
    ENABLE_H1_TEXT: 'ENABLE_H1_TEXT',
    enableH2Text: 'enableH2Text',
    questionTitle: 'questionTitle',
    ShowKwIntentSupport: 'ShowKwIntentSupport',
    MJC_REG_LEADS: 'MJC_REG_LEADS',
    EnableSurveyQueOnReg: 'EnableSurveyQueOnReg',
    MJC_META_TAGS: 'MJC_META_TAGS',
    MJC_LISTING_CTA: 'MJC_LISTING_CTA',
    MJC_SEARCH_TERM: 'MJC_SEARCH_TERM',
    showDOBonReg2: 'showDOBonReg2',
    showDOBLabel: 'showDOBLabel',
    WarmUpLanderQuestionTitle: 'WarmUpLanderQuestionTitle',
    BACK_BUTTON_2_OFFER: 'BACK_BUTTON_2_OFFER',
    EduUpperSkip: 'EduUpperSkip',
    EduLowerSkip: 'EduLowerSkip',
    EduSkipColor: 'EduSkipColor',
    EduSkipText: 'EduSkipText',
    OVERRIDE_SERP_AU: 'OVERRIDE_SERP_AU',
    PopularKeywordsLander: 'PopularKeywordsLander',
    PopularKeywordsCTAs: 'PopularKeywordsCTAs',
    KeywordsOptions: 'KeywordsOptions',
    PopularKeywordTitle: 'PopularKeywordTitle',
    BRANDED_LANDER_OFFER_FULL_IMG: 'BRANDED_LANDER_OFFER_FULL_IMG',
    BRANDED_LANDER_OFFER_HALF_IMG: 'BRANDED_LANDER_OFFER_HALF_IMG',
    BRANDED_LANDER_SURVEY_FULL_IMG: 'BRANDED_LANDER_SURVEY_FULL_IMG',
    BRANDED_LANDER_SURVEY_HALF_IMG: 'BRANDED_LANDER_SURVEY_HALF_IMG',
    BRANDED_LANDER_LISTINGS: 'BRANDED_LANDER_LISTINGS',
    DisclosurePositionTest: 'DisclosurePositionTest',
    BRANDED_LANDER_REG0: 'BRANDED_LANDER_REG0',
    BRANDED_KEYWORDS: 'BRANDED_KEYWORDS',
    BRANDED_QUESTION_TITLE: 'BRANDED_QUESTION_TITLE',
    BRANDED_BENEFITS_KEYWORDS: 'BRANDED_BENEFITS_KEYWORDS',
    UpdatableABTChannel3: 'UpdatableABTChannel3',
    SHOW_TAB_OVER_LANDING: 'SHOW_TAB_OVER_LANDING',
    LANDING_TAB_OVER_AD_ID: 'LANDING_TAB_OVER_AD_ID',
    SemiPrePopSkipColor: 'SemiPrePopSkipColor',
    SemiPrePopSkipText: 'SemiPrePopSkipText',
    OFFERS_PROGRESS_BAR_TITLE: 'OFFERS_PROGRESS_BAR_TITLE',
    AMAZON_LANDER_STATIC_AD_CALL: 'AMAZON_LANDER_STATIC_AD_CALL',
    STATIC_DYNAMIC_TABOVER_LANDER: 'STATIC_DYNAMIC_TABOVER_LANDER',
    STATIC_DYNAMIC_TABOVER_DATA: 'STATIC_DYNAMIC_TABOVER_DATA',
    SPONSORED_MARKETPLACE_VARIANT: 'SPONSORED_MARKETPLACE_VARIANT',
    SPONSORED_MARKETPLACE_CONFIG: 'SPONSORED_MARKETPLACE_CONFIG',
    INSTERSTITIAL_DISPLAY: 'INSTERSTITIAL_DISPLAY',
    ROUTING_INSTERSTITIAL_DISPLAY: 'ROUTING_INSTERSTITIAL_DISPLAY',
    SHOULD_FIRE_SMS_TRIGGER: 'SHOULD_FIRE_SMS_TRIGGER',
    SHOW_TABOVER_ON_REG: 'SHOW_TABOVER_ON_REG',
    SHOULD_FIRE_NOC_SMS: 'SHOULD_FIRE_NOC_SMS',
    Sponsored_SERP_CTA: 'Sponsored_SERP_CTA',
    Sponsored_CTA_Text: 'Sponsored_CTA_Text',
    Sponsored_CTA_Background: 'Sponsored_CTA_Background',
    SHOW_SKIP_ON_LANDER: 'SHOW_SKIP_ON_LANDER',
};
