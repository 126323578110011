import {
    getFeatureValueJsonByCurrentScope,
    getFeatureValuesByCurrentScope,
    getFeatureValues,
    getFeatureValueJson,
    getFeatureMapValuesByCurrentScope,
    getFeatureMapValue,
    getFeatureValueJsonV2,
} from './getFeatureValues';
import { FeatureMap, ConfigProvider } from 'providers';
import Utils from 'common/utils';
import { MacrosHelper } from 'helpers';
import Constants from 'common/constants';

// ------- Helpers ------- //
const _filterInvalidSERPListings = listings =>
    listings.filter(item => {
        if (
            !Utils.isNull(item) &&
            !Utils.isNull(item.id) &&
            !Utils.isNull(item.position) &&
            !Utils.isNull(item.page)
        ) {
            return true;
        } else {
            console.error('Config: Invalid SERP listing configured', item);

            return false;
        }
    });

const _filterInvalidJTSERPListingsConf = listings =>
    listings.filter(item => {
        if (!Utils.isNull(item) && !Utils.isNull(item.id) && !Utils.isNull(item.position)) {
            return true;
        } else {
            console.error('Config: Invalid JT SERP listing configured', item);

            return false;
        }
    });

// ------- Public Exports --------- //
/**
 * @name getSERPListingsConfig
 * @description Gets Configuration for SERP MarketPlace Config
 * @returns Array of valid Listing
 */
export const getSERPListingsConfig = () =>
    _filterInvalidSERPListings(getFeatureValueJsonByCurrentScope(FeatureMap.SERPListingsConfig));

export const getSurveyQueOnRegLeads = () =>
    Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.MJC_REG_LEADS));

export const getSERPNativeAdListingsConfig = () =>
    _filterInvalidSERPListings(getFeatureValueJsonByCurrentScope(FeatureMap.SERPNativeAdConfig));

export const getSERPPreListingsConfig = () =>
    _filterInvalidSERPListings(getFeatureValueJsonByCurrentScope(FeatureMap.SERPPreListingsConfig));

export const getFulfilmentPageListingsAuId = () =>
    getFeatureValuesByCurrentScope(FeatureMap.FulfilmentPageListings)[0];

export const getFallBackPageListingsAuId = () =>
    getFeatureValuesByCurrentScope(FeatureMap.FallBackListings)[0];
export const getSkipToOfferAuId = () =>
    getFeatureValuesByCurrentScope(FeatureMap.SKIP_TO_OFFER_AD_ID)[0];
export const getTabOverAuId = () =>
    getFeatureValuesByCurrentScope(FeatureMap.LANDING_TAB_OVER_AD_ID)[0];
export const getOverrideSerpAuId = () =>
    getFeatureValuesByCurrentScope(FeatureMap.OVERRIDE_SERP_AU)[0];

export const getOffersProgressBarTitle = () =>
    getFeatureMapValue(FeatureMap.OFFERS_PROGRESS_BAR_TITLE);

export const getBackButtonInterstitialAuId = () =>
    getFeatureValuesByCurrentScope(FeatureMap.BackButtonInterstitialListings)[0];

export const getOverlayJobProviderAuId = () =>
    getFeatureValuesByCurrentScope(FeatureMap.FulfilmentPageOverlayListings)[0];

export const getFulfilmentMergedSourceAuIds = () =>
    getFeatureValuesByCurrentScope(FeatureMap.MergedListingsSources) || [];

export const getBackButtonInterstitialBtnText = () => {
    const ctasJson = getFeatureValuesByCurrentScope(FeatureMap.BACK_BTN_INTERSTITIAL_BTN_TEXT);
    let ctasJsonObject = {};

    if (ctasJson && !Utils.isEmptyStr(ctasJson)) {
        ctasJsonObject = Utils.jsonSafeParse(ctasJson);
    }

    return ctasJsonObject;
};

export const getJTSERPListingsConfig = () =>
    _filterInvalidJTSERPListingsConf(
        getFeatureValueJsonByCurrentScope(FeatureMap.JTSERPListingConfig) || [],
    );

export const getKwImageSrc = () => getFeatureValues(FeatureMap.KwImageSrc)[0];

export const getBenefitList = () => getFeatureValueJson(FeatureMap.BenefitList)[0];

export const getLanderTitle = () => getFeatureValues(FeatureMap.LanderTitle)[0];

export const getCTAStyles = () => getFeatureValueJson(FeatureMap.CTAStyles)[0];

export const getCTAText = () => getFeatureValues(FeatureMap.CTAText)[0];

export const getSponsoredCTAText = () => getFeatureMapValue(FeatureMap.Sponsored_CTA_Text);

export const getSponsoredCTABackground = () =>
    getFeatureMapValue(FeatureMap.Sponsored_CTA_Background);

export const getListingCTAText = () => getFeatureMapValue(FeatureMap.MJC_LISTING_CTA);

export const getJTFallbackValue = () => getFeatureValues(FeatureMap.JTFallbackValue)[0];

export const getUpdatableABTChannel1 = () => getFeatureValues(FeatureMap.UpdatableABTChannel1)[0];

export const getUpdatableABTChannel2 = () => getFeatureValues(FeatureMap.UpdatableABTChannel2)[0];

export const getUpdatableABTChannel3 = () => getFeatureValues(FeatureMap.UpdatableABTChannel3)[0];

export const showDOBonReg2 = () => {
    const value = getFeatureMapValue(FeatureMap.showDOBonReg2);

    if (value && !Utils.isEmptyStr(value)) {
        if (value === '1') return true;

        return false;
    }

    return false;
};

export const showDOBLabel = () => {
    const value = getFeatureMapValue(FeatureMap.showDOBLabel);

    if (value && !Utils.isEmptyStr(value)) {
        if (value === '1') return true;

        return false;
    }

    return false;
};

export const getSearchTerm = () => {
    const searchTerm = getFeatureMapValue(FeatureMap.MJC_SEARCH_TERM);

    if (searchTerm && !Utils.isEmptyStr(searchTerm)) {
        return searchTerm;
    }

    return null;
};

export const getLeaveBehindRegPage = () => {
    const regValue = getFeatureMapValue(FeatureMap.LEAVE_BEHIND_CTA_CLICK);

    if (regValue && !Utils.isEmptyStr(regValue)) {
        return regValue;
    }

    return false;
};

export const getQuestionTitle = () => {
    const questionTitle = getFeatureMapValue(FeatureMap.questionTitle);

    if (questionTitle && !Utils.isEmptyStr(questionTitle)) {
        return questionTitle;
    }

    return false;
};

export const getUpdatableABTChannelForTalrooAPIKwTest = () =>
    getFeatureValues(FeatureMap.UpdatableABTChannelForTalrooAPIKwTest)[0];

export const getAmazonBrandFlowAuSourceV2 = () =>
    getFeatureValues(FeatureMap.AmazonBrandFlowAuSource)[0];

export const getCustomConfigQuestions = () => {
    return Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.MJC_CUSTOM_QUESTION_CONFIG));
};

export const getskipTextColor = () => {
    return Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.SHOW_SKIP_ON_LANDER));
};

export const getHardRedirectOnInitLink = () =>
    getFeatureValues(FeatureMap.HardRedirectOnInitLink)[0];

export const getSERPNativeIframeSrc = () => {
    const featureValue = getFeatureMapValuesByCurrentScope(FeatureMap.SERPNativeIframeSrc);
    let decodedValue;

    try {
        decodedValue = decodeURIComponent(featureValue);
        const urlParams = Utils.getAllUrlParams(decodedValue);
        let replaceCRParams = MacrosHelper.replaceMacrosWithValues();
        const maxMacros = MacrosHelper.initializeMaxMacros(urlParams);
        replaceCRParams = {
            ...replaceCRParams,
            ...maxMacros,
        };
        const iframeSrc = Utils.matchAndReplaceParams(
            decodedValue,
            replaceCRParams,
            Constants.CR_MAX_UNIFIED_REGEX,
        );

        return iframeSrc;
    } catch (e) {
        console.error('Error decoding native ad Listings src');
    }

    return '';
};

export const getLeadBlackListMailSuffixes = () => {
    return getFeatureValues(FeatureMap.BLMailSuffixes) || [];
};

export const getQueryParamChangeAdvertisers = () =>
    getFeatureValues(FeatureMap.QueryParamChangeAdvertisers) || [];

export const fvGetGenericTerms = () => getFeatureValues(FeatureMap.GenericTerms) || [];

export const getForceFeedUtmTerms = () => {
    const forceFeedUtmTerms = getFeatureMapValue(FeatureMap.FORCE_FEED_UTM_TERMS);
    let decodedForceFeedUtmTerms = {};

    if (forceFeedUtmTerms && !Utils.isEmptyStr(forceFeedUtmTerms)) {
        try {
            decodedForceFeedUtmTerms = JSON.parse(forceFeedUtmTerms);
        } catch (e) {
            console.log('[debugging]: error parsing forceFeedUtmTerms');
        }
    }

    return decodedForceFeedUtmTerms;
};

export const getRegTCPAText = () => getFeatureMapValue(FeatureMap.REPEAT_USER_TCPA_TEXT);
export const getJobAlertTitle = () => getFeatureMapValue(FeatureMap.JOB_ALERT_TITLE);
export const getJobAlertCta1 = () => getFeatureMapValue(FeatureMap.JOB_ALERT_CTA1);
export const getJobAlertCta2 = () => getFeatureMapValue(FeatureMap.JOB_ALERT_CTA2);
export const getSkipText = () => getFeatureMapValue(FeatureMap.MJC_SKIP_TEST);
export const complianceTextColor = () => getFeatureMapValue(FeatureMap.COMPLIANCE_TEXT_COLOR);
export const brandedRegPathCtaBgColour = () =>
    getFeatureMapValue(FeatureMap.Branded_Reg_Path_CTA_BG_Colour);
export const xmlLanderSkipColorChange = () => getFeatureMapValue(FeatureMap.SKIP_BTN_COLOR);
export const warmUpQuestionColor = () => getFeatureMapValue(FeatureMap.WARM_UP_QUESTION_BG_COLOR);
export const getXmlLanderTitle = () => getFeatureMapValue(FeatureMap.XML_LANDER_TITLE);
export const shouldFireSmsTrigger = () => getFeatureMapValue(FeatureMap.SHOULD_FIRE_SMS_TRIGGER);
export const shouldFireNocSms = () => getFeatureMapValue(FeatureMap.SHOULD_FIRE_NOC_SMS);
export const getJobAlertLandingSkipPages = () => {
    const jobAlertSkipMappingLanding = getFeatureMapValue(
        FeatureMap.JobAlertSurveyPathSkipMappingLanding,
    );
    let decodedJobAlertSkipMappingForLanding = {};

    if (jobAlertSkipMappingLanding && !Utils.isEmptyStr(jobAlertSkipMappingLanding)) {
        decodedJobAlertSkipMappingForLanding = Utils.jsonSafeParse(jobAlertSkipMappingLanding);
    }

    return decodedJobAlertSkipMappingForLanding;
};

export const getH1CtaText = () => {
    const h1CtaText = getFeatureMapValue(FeatureMap.REG_PAGES_H1_CTA_TEXT);
    let decodeH1AndCTAText = {};

    if (h1CtaText && !Utils.isEmptyStr(h1CtaText)) {
        decodeH1AndCTAText = Utils.jsonSafeParse(h1CtaText);
    }

    return decodeH1AndCTAText;
};

export const getHeaderQuestionText = () => {
    const headerQuestionText = getFeatureMapValue(FeatureMap.REG_H1_QUESTION_TEXT);
    let decodeHeaderAndQuestionText = {};

    if (headerQuestionText && !Utils.isEmptyStr(headerQuestionText)) {
        decodeHeaderAndQuestionText = Utils.jsonSafeParse(headerQuestionText);
    }

    return decodeHeaderAndQuestionText;
};

export const getEmailTestHeader = () => {
    const h1Text = getFeatureMapValue(FeatureMap.EMAIL_SUBMIT_TEST_HEADER);

    if (h1Text && !Utils.isEmptyStr(h1Text)) {
        return h1Text;
    }
};

export const getJobCategroyTitle = () => {
    const title = getFeatureMapValue(FeatureMap.JOBCATEGORY_QN_TITLE);

    if (title && !Utils.isEmptyStr(title)) {
        return title;
    }
};

export const removeRegTopStripScreen = () => {
    const RemoveRegTopStripScreen = getFeatureMapValue(FeatureMap.RemoveRegTopStripScreen);
    let RemoveRegTopStripScreenObject = {};

    if (RemoveRegTopStripScreen && !Utils.isEmptyStr(RemoveRegTopStripScreen)) {
        RemoveRegTopStripScreenObject = Utils.jsonSafeParse(RemoveRegTopStripScreen);
    }

    return RemoveRegTopStripScreenObject;
};

export const brandedFlowValues = () => {
    const brandedFlowValuesScreen = getFeatureMapValue(FeatureMap.BRANDED_FLOW_REG_PAGES_VALUES);
    let brandedFlowValuesScreenObject = {};

    if (brandedFlowValuesScreen && !Utils.isEmptyStr(brandedFlowValuesScreen)) {
        brandedFlowValuesScreenObject = Utils.jsonSafeParse(brandedFlowValuesScreen);
    }

    return brandedFlowValuesScreenObject;
};

export const removeSkipFromRegPage = () => {
    const removeSkipFromRegPageFeatureMap = ConfigProvider.Features.getFeature(
        FeatureMap.HIDE_SKIP_FOR_REG_PAGES,
    );

    const removeSkipFromRegPageFeature =
        removeSkipFromRegPageFeatureMap && removeSkipFromRegPageFeatureMap.value
            ? removeSkipFromRegPageFeatureMap.value.split(',')
            : [];

    let h = window.location.hash;

    if (Utils.isValidNumber(h && h.substring(1))) {
        h = h.substring(1);
    }

    return removeSkipFromRegPageFeature.includes(h);
};

export const getProfanityExcludedTerms = () =>
    getFeatureValues(FeatureMap.PROFANITY_EXCLUSION_WORDS);

export const getH1AndH2Text = () => {
    const h1h2Text = getFeatureMapValue(FeatureMap.BACK_BTN_INTERSTITIAL_TEXT);
    let h1andh2Text = {};

    if (h1h2Text && !Utils.isEmptyStr(h1h2Text)) {
        h1andh2Text = Utils.jsonSafeParse(h1h2Text);
    }

    return h1andh2Text;
};

export const backButtonInterstitialDisplay = () =>
    getFeatureValues(FeatureMap.BACK_BUTTON_INSTERSTITIAL_DISPLAY) || [];

export const getRouterInterstitialDisplay = () =>
    getFeatureValues(FeatureMap.ROUTING_INSTERSTITIAL_DISPLAY) || [];

export const intertitialDisplay = () => getFeatureValues(FeatureMap.INSTERSTITIAL_DISPLAY) || [];

export const getMultiBrandKwIcons = () =>
    Utils.jsonSafeParse(
        decodeURIComponent(
            ConfigProvider.Features.getFeature(FeatureMap.MultiBrandLanderLogos) &&
                ConfigProvider.Features.getFeature(FeatureMap.MultiBrandLanderLogos).value,
        ),
    );

export const getMultiLogosForTabover = () =>
    Utils.jsonSafeParse(
        decodeURIComponent(
            ConfigProvider.Features.getFeature(FeatureMap.TaboverMultibrandLogos) &&
                ConfigProvider.Features.getFeature(FeatureMap.TaboverMultibrandLogos).value,
        ),
    );

export const getSkipToNewTabV4BrandName = () =>
    Utils.jsonSafeParse(
        decodeURIComponent(
            getFeatureMapValuesByCurrentScope(FeatureMap.SkipToNewTabTestV4NewStrategyBrandName),
        ),
    );

export const getNonXMLHeader = () => {
    const headerQuestionText = getFeatureMapValue(FeatureMap.NON_XML_HEADER);
    let decodeHeaderAndQuestionText = {};

    if (headerQuestionText && !Utils.isEmptyStr(headerQuestionText)) {
        decodeHeaderAndQuestionText = Utils.jsonSafeParse(headerQuestionText);
    }

    return decodeHeaderAndQuestionText;
};
export const getSkipToNewTabV4Data = () => {
    const feature = getFeatureValueJsonV2(FeatureMap.SkipToNewTabTestData);

    if (feature && feature[0]) {
        const featureValue = feature && feature[0];

        return {
            ...featureValue,
            h1: MacrosHelper.replaceMacroParamsStringMemoized(featureValue.h1),
            h2: MacrosHelper.replaceMacroParamsStringMemoized(featureValue.h2),
            keywords: MacrosHelper.replaceMacroParamsStringMemoized(featureValue.keywords),
            chips: (featureValue.chips || []).map(MacrosHelper.replaceMacroParamsStringMemoized),
            cta: MacrosHelper.replaceMacroParamsStringMemoized(featureValue.cta),
            secondaryCTA: MacrosHelper.replaceMacroParamsStringMemoized(featureValue.secondaryCTA),
            bg: featureValue.bg,
        };
    }

    return null;
};

export const getStaticDynamicTaboverData = () => {
    const feature = getFeatureValueJsonV2(FeatureMap.STATIC_DYNAMIC_TABOVER_DATA);

    if (feature && feature[0]) {
        const featureValue = feature && feature[0];

        return {
            ...featureValue,
            h1: MacrosHelper.replaceMacroParamsString(featureValue.h1),
            cta: MacrosHelper.replaceMacroParamsString(featureValue.cta),
            dynamicQuestionTitle: MacrosHelper.replaceMacroParamsString(
                featureValue.dynamicQuestionTitle,
            ),
            staticHeading: MacrosHelper.replaceMacroParamsString(featureValue.staticHeading),
            staticSubHeading: MacrosHelper.replaceMacroParamsString(featureValue.staticSubHeading),
        };
    }

    return null;
};
export const getSponsoredMarketplaceVariant = () =>
    getFeatureValuesByCurrentScope(FeatureMap.SPONSORED_MARKETPLACE_VARIANT)[0];

export const getSponsoredMarketplaceConfig = () =>
    getFeatureValueJsonV2(FeatureMap.SPONSORED_MARKETPLACE_CONFIG)[0];

export const getShowTaboverOnReg = () => getFeatureValues(FeatureMap.SHOW_TABOVER_ON_REG) || [];
