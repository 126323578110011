import Utils from 'common/utils';
import Constants from 'common/constants';
import {
    fireSlotImpVEvent,
    fireAdImpressionEvent,
    fireSlotFailedEvent,
} from 'eventManager/eventHelpers/index';
import { FeatureValueHelper } from 'helpers';

export const isAdUnitJobListItemDupeV1 = (jobItem, ad) => {
    return !!Utils.getCookie(`${jobItem.title}-${jobItem.company}-${ad.advertiserName}`);
};

export const setAdUnitJobListItemDupeV1 = (jobItem, ad) => {
    Utils.setCookie(`${jobItem.title}-${jobItem.company}-${ad.advertiserName}`, '1', 60 * 24 * 7);
};

export const deDupeAdUnitJobListV1 = (jobsInfo = {}) => {
    const { jobs = [], advertiser = {} } = jobsInfo;

    return jobs.filter(j => !isAdUnitJobListItemDupeV1(j, advertiser.name));
};

export const isAdUnitDupeV1 = (adUnitData = {}) => {
    const { ad = {} } = adUnitData;

    return !!Utils.getCookie(`${ad.advertiserName}-click`);
};

export const setAdUnitImpV1 = (adUnitData = {}) => {
    const { ad = {} } = adUnitData;
    Utils.setCookie(`${ad.advertiserName}-click`, '1', 60);
};

export const adUnitValidatorV1 = (adUnitData, options = {}) => {
    const { eventType, extraData } = options.event || {};
    const shouldFireEvents = !!eventType;
    const filterOptions = options.filter || {};
    const eventQueueKey = options.eventQueueKey;

    const { ad = {}, jobsInfo = {} } = adUnitData;
    const { customAdInfo = {} } = ad;
    // Fire Impression
    shouldFireEvents && fireAdImpressionEvent(ad, eventType, extraData, eventQueueKey);

    if (!isAdUnitDupeV1(adUnitData)) {
        if (filterOptions.type && filterOptions.type !== customAdInfo.type) {
            // Fire Slot Failed if type mismatch
            shouldFireEvents &&
                fireSlotFailedEvent(ad, 'no-jobs', eventType, extraData, eventQueueKey);
            console.log('AdUnit: Au Fail Mismatch');

            return [false, {}];
        }

        if (customAdInfo.type === Constants.AD_UNIT.CUSTOM_AD_INFO.TYPE.JOB) {
            // Dedupe job type adunits
            const { jobsData = {} } = jobsInfo;
            const jobs = jobsData.jobs || [];

            const nonDupeJob = jobs.find(j => !isAdUnitJobListItemDupeV1(j, ad));

            if (nonDupeJob) {
                // Fire job slot imp and mark imp

                setAdUnitJobListItemDupeV1(nonDupeJob, ad);
                shouldFireEvents &&
                    fireSlotImpVEvent(ad, nonDupeJob, eventType, extraData, eventQueueKey);

                console.log('AdUnit: Au Job Success');

                return [true, { selectedJob: nonDupeJob }];
            } else {
                // Fire job slot failure
                const reason = jobs.length > 0 ? 'dupe-logic' : 'no-jobs';
                shouldFireEvents &&
                    fireSlotFailedEvent(ad, reason, eventType, extraData, eventQueueKey);
                console.log('AdUnit: Au Job Failure', reason);

                return [false, { error: reason }];
            }
        } else {
            console.log('AdUnit: Au Success');
            shouldFireEvents && fireSlotImpVEvent(ad, {}, eventType, extraData, eventQueueKey);

            return [true, {}];
        }
    }

    console.log('AdUnit: Au failure');
    shouldFireEvents && fireSlotFailedEvent(ad, 'dupe-logic', eventType, extraData);

    return [false, {}];
};

export const jobTypedAdUnitValidatorWithEventsV1 = adUnitData =>
    adUnitValidatorV1(adUnitData, {
        event: {
            eventType: Constants.EVENT_TYPE.LANDING,
        },
        filter: {
            type: Constants.AD_UNIT.CUSTOM_AD_INFO.TYPE.JOB,
        },
    });

export const landingAdUnitValidatorV1 = adUnitData =>
    adUnitValidatorV1(adUnitData, {
        filter: {},
    });

export const amazonLanderAuValidation = adUnitData => {
    console.log('[AdUnit] AdUnit Validating Amaazon Au', adUnitData);
    const companyName = FeatureValueHelper.getSkipToNewTabV4BrandName();
    const company = Utils.getObjVal(adUnitData, ['ad', 'jobresult', 'company']) || '';

    return [
        companyName &&
            companyName.some(cname => company.toLowerCase().includes(cname.toLowerCase())),
        { error: `${companyName ? companyName : 'amazon'} employer selection logic` },
    ];
};
