import React, { useState, useEffect, useRef } from 'react';
import Utils from 'common/utils';
import Constants from 'common/constants';
import {
    Button,
    Grid,
    Typography,
    FormControlLabel,
    FormControl,
    Checkbox,
    InputAdornment,
    FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import flavors from 'flavors.macro';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import RouterPrefixHandler from 'common/routePrefixHandler';
import { IdStore } from 'dataStore';
import UserDataStore from 'components/landing/userDataStore';
import UserDataValidator from 'common/userDataValidator';
import EventManager from 'eventManager/eventManager';
import { ValidatorFormHOC } from 'hocs/validatorFormHOC';
import ReactHtmlParser from 'react-html-parser';
import { ConfigProvider, FeatureMap } from 'providers';
import MarketingPartnersPopOver from 'components/common/marketingPartnersPopOver/marketingPartnersPopOver';
import UserDataSource from 'datasource/userDetailsDataSource';
import { isLeadBlackListMail } from 'helpers/form/formHelpers';
import { FormHelpers } from 'helpers';

flavors();

const formLayout = {
    xs: 12,
};

const gridItemProps = {
    item: true,
    style: {
        width: '100%',
    },
    ...formLayout,
};

const EmailRegistration = props => {
    const classes = useStyles();
    const [checked, setChecked] = useState(true);
    const [success, setSucess] = useState(true);
    const [clicked, setClicked] = useState(false);
    const [email, setEmail] = useState(() => {
        let initEmail = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EMAIL) || '';
        initEmail = Utils.filterInvalidUrlInput(email);

        const oldUserDetails = UserDataStore.fetchUserDetailsFromStore();

        if (false === Utils.isNull(oldUserDetails) && false === Utils.isEmptyObj(oldUserDetails)) {
            let oldDetails = oldUserDetails;
            const isEmailResetted = Boolean(
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.EMAIL_RESETTED),
            );

            if (
                (isEmailResetted || Utils.isEmptyStr(email)) &&
                !Utils.isEmptyStr(oldDetails.email) &&
                !Utils.isNull(oldDetails.email) &&
                oldDetails.email.length > 0
            ) {
                initEmail = oldDetails.email;
            }
        }

        return initEmail;
    });

    const emailRef = useRef();

    useEffect(() => {
        ValidatorForm.addValidationRule('isValidEmail', value => value);
        ValidatorForm.addValidationRule('hasProfanity', value => {
            return Utils.isNull(FormHelpers.hasProfanity(value));
        });

        EventManager.sendEvent(
            Constants.EVENT.EMAIL_INTERSTITIAL_SHOW,
            {},
            Constants.EVENT_TYPE.GRAITIFICATION,
        );
    }, []);

    const textFieldProps = (success, val, isTouched = true) => ({
        onChange: handleInputChange,
        InputProps: {
            disableUnderline: true,
            spellCheck: false,
            endAdornment: success && val && isTouched && (
                <InputAdornment position="end">
                    <LazyLoadComponent threshold="50">
                        <picture>
                            <source
                                srcSet={RouterPrefixHandler.getImagePath('images/checked.webp')}
                                type="image/webp"
                            />
                            <img
                                alt={`icon`}
                                className={classes.checkedImg}
                                src={RouterPrefixHandler.getImagePath('images/checked.png')}
                            />
                        </picture>
                    </LazyLoadComponent>
                </InputAdornment>
            ),
        },
    });

    const browserType = Utils.browserType();

    const validateEmail = event => {
        const { value } = event.target;
        checkEmail(value);
    };

    const checkEmail = value => {
        const validEmailSyntax = Utils.isNull(UserDataValidator.isValidEmail(value));

        if (!validEmailSyntax) {
            setSucess(false);
            emailRef.current.validate(false);
        } else {
            setSucess(true);
            emailRef.current.makeValid();
        }
    };

    const handleInputChange = event => {
        const { name, value } = event.target;

        if (name === 'email') {
            setEmail(value);
        }
        checkEmail(value);
    };

    const handleCheckboxChange = () => {
        setChecked(!checked);
    };

    const handleSubmit = e => {
        e.preventDefault();
        checkEmail(email);
        console.log('submitted');
    };

    const getMjcEmailOptinText = () => {
        const mjcEmailOptin = ConfigProvider.Features.getFeature(FeatureMap.MJCEmailOptin);
        const mjcEmailOptinText = mjcEmailOptin && mjcEmailOptin.value ? mjcEmailOptin.value : '';

        return mjcEmailOptinText
            ? ReactHtmlParser(decodeURIComponent(mjcEmailOptinText))
            : ReactHtmlParser(`I want to receive email updates for new job
                                                    listings and career opportunities from MyJobsCorner,&nbsp;
                                                    <a
                                                        href="https://www.everyjobforme.com/"
                                                        rel="noopener noreferrer"
                                                        style="color: inherit"
                                                        target="_blank">EveryJobForMe.com</a>, NewJobConnections and our`);
    };

    const handleClick = async event => {
        setClicked(true);
        checkEmail(email);
        const validEmailSyntax = Utils.isNull(UserDataValidator.isValidEmail(email));
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.USER_EMAIL, email);
        const isEmailVarified = await UserDataSource.validateEmail(email);

        if (validEmailSyntax && checked) {
            if (isLeadBlackListMail(email)) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.IS_EMAIL_BLACK_LISTED, true);
            }

            const eventData = {
                email: email,
                optxt: `${Constants.EMAIL_TCPA}`,
            };
            const eventData2 = {
                email: email,
                optxt: `${Constants.EMAIL_TCPA}`,
                cat: 'email_org',
                evs: isEmailVarified,
            };
            EventManager.sendEvent(
                Constants.EVENT.EMAIL_SUB_LISTINGS,
                eventData,
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
            EventManager.sendEvent(Constants.EVENT.HNP_LEAD, eventData2, Constants.EVENT_TYPE.DATA);

            if (checked) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.EMAIL_SUB, 'true');
            }
            props.setCurrentForm('phone');
        }
    };

    return (
        <div>
            <Grid>
                <Grid className={`padding-pop-resp ${classes.root}`} container direction="column">
                    <Grid {...gridItemProps}>
                        <Typography className={classes.header}>
                            What email address should a hiring manager contact you?
                        </Typography>
                    </Grid>
                    <ValidatorFormHOC
                        className={`padding-responsive-wrapper ${classes.v2inputContainer}`}
                        onError={errors => console.log(errors)}
                        onSubmit={handleSubmit}>
                        <Grid {...gridItemProps}>
                            <TextValidator
                                className={`input-with-icon emailInput ${
                                    success ? '' : classes.errorInputBox
                                } ${
                                    ['safari', 'samsungbrowser'].includes(browserType)
                                        ? classes.v2Error
                                        : ''
                                }`}
                                errorMessages={['This field is required', 'Invalid email']}
                                id="email"
                                name="email"
                                placeholder={`name@domain.com`}
                                type="email"
                                validators={['required', 'isValidEmail']}
                                value={email}
                                {...textFieldProps(success, email)}
                                InputProps={{
                                    disableUnderline: true,
                                    spellCheck: false,
                                    endAdornment: success && email && (
                                        <InputAdornment position="end">
                                            <LazyLoadComponent threshold="50">
                                                <picture>
                                                    <source
                                                        srcSet={RouterPrefixHandler.getImagePath(
                                                            'images/checked.webp',
                                                        )}
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        alt={`icon`}
                                                        className={classes.checkedImg}
                                                        src={RouterPrefixHandler.getImagePath(
                                                            'images/checked.png',
                                                        )}
                                                    />
                                                </picture>
                                            </LazyLoadComponent>
                                        </InputAdornment>
                                    ),
                                }}
                                onBlur={validateEmail}
                                onChange={handleInputChange}
                                ref={emailRef}
                            />
                        </Grid>
                        <Grid {...gridItemProps}>
                            <div
                                className={`disclaimer-with-error-msg ${classes.checkBoxContainer}`}
                                style={{
                                    border: '1px solid',
                                    borderColor: 'transparent',
                                    background: 'transparent',
                                    marginBottom: 0,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                checkedIcon={
                                                    <span
                                                        className={`${classes.icon} ${classes.checkedIcon}`}
                                                    />
                                                }
                                                className={classes.checkbox}
                                                color="primary"
                                                icon={<span className={classes.icon} />}
                                                inputProps={{
                                                    color: 'red',
                                                }}
                                                name="opt_in_email"
                                                onChange={handleCheckboxChange}
                                                value={checked}
                                            />
                                        }
                                        label={
                                            <div className={classes.disclaimerWrapperNew}>
                                                <input id="leadid_tcpa_disclosure" type="hidden" />
                                                <input
                                                    id="leadid_token"
                                                    name="universal_leadid"
                                                    type="hidden"
                                                />
                                                <label htmlFor="leadid_tcpa_disclosure">
                                                    <Typography
                                                        className={`disclaimer-wrapper ${classes.disclaimer}`}
                                                        variant="h3">
                                                        {getMjcEmailOptinText()}
                                                        &nbsp;
                                                        <MarketingPartnersPopOver />
                                                    </Typography>
                                                </label>
                                            </div>
                                        }
                                    />
                                </FormControl>
                            </div>
                        </Grid>
                        {checked ? null : clicked ? (
                            <Grid {...gridItemProps}>
                                <FormHelperText
                                    error
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                    }}>
                                    Please tick checkbox to proceed
                                </FormHelperText>
                            </Grid>
                        ) : null}
                        <Grid {...gridItemProps}>
                            <div className={classes.submitContainer}>
                                <Button
                                    className={classes.v2button}
                                    color="primary"
                                    endIcon={
                                        <picture>
                                            <source
                                                srcSet={RouterPrefixHandler.getImagePath(
                                                    'images/left-arrow.webp',
                                                )}
                                                type="image/webp"
                                            />
                                            <img
                                                alt={`icon`}
                                                className={classes.btnIcon}
                                                src={RouterPrefixHandler.getImagePath(
                                                    'images/left-arrow.png',
                                                )}
                                            />
                                        </picture>
                                    }
                                    id="submitBtn"
                                    name="confirm"
                                    onClick={handleClick}
                                    style={{
                                        fontSize: 22,
                                    }}
                                    type="submit">
                                    {'Continue'}
                                </Button>
                            </div>
                        </Grid>
                    </ValidatorFormHOC>
                </Grid>
            </Grid>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        overflowX: 'hidden',
        alignItems: 'center',
        background: '#f8f8f8',
    },
    header: {
        color: '#313131',
        fontSize: '1.2em',
        fontWeight: '600',
        textAlign: 'center',
        lineHeight: 'normal',
    },
    errorInputBox: {
        border: 'solid 1px #e63737',
        borderRadius: '2px',
    },
    v2inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10px',
        width: '100%',
    },
    checkedImg: {
        maxWidth: '23px',
        maxHeight: '17px',
        width: 'auto',
        height: 'auto',
    },
    disclaimer: {
        lineHeight: 1.2,
        fontSize: '12px',
        marginTop: '3px',
        textAlign: 'left',
    },
    disclaimerWrapperNew: {
        marginBottom: 10,
        textAlign: 'center',
    },
    box: {
        maxWidth: '18px',
        maxHeight: '18px',
        paddingRight: 10,
    },
    btnIcon: {
        transition: 'transform 0.3s ease-out',
        position: 'relative',
        top: '1px',
        left: '15px',
    },
    checkBoxContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 6,
    },
    checkbox: {
        color: '#e1e1e1',
        width: 16,
        height: 16,
        marginTop: -7,
        alignSelf: 'flex-start',
    },
    v2button: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'break-word',
        fontSize: 22,
    },
    submitContainer: {
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    icon: {
        width: '10px',
        height: '10px',
        padding: '2.5px',
        fill: 'white',
        borderRadius: '2px',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#fff',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        padding: '4px',
        '&:before': {
            display: 'block',
            width: 10,
            height: 14,
            padding: 4,
            backgroundImage:
                "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='15' viewBox='0 0 20 18'%3E%3Cpath fill='%2355ae00' fill-rule='evenodd' stroke='%2355ae00' stroke-width='.972' d='M14.057 1c.32.304.623.624.943.944-2.712 2.698-5.408 5.414-8.12 8.112-.304.32-.624.624-.944.944-.303-.32-.623-.624-.926-.944C3.662 8.723 2.33 7.39 1 6.042c.303-.303.623-.624.943-.927L5.936 9.11C8.65 6.413 11.344 3.698 14.056 1z'/%3E%3C/svg%3E%0A\")",
            backgroundRepeat: 'no-repeat',
            content: '""',
            margin: 0,
        },
        'input:hover ~ &': {
            backgroundColor: '#fff',
        },
    },
    v2Error: {
        position: 'relative',
        '& #email-helper-text': {
            position: 'absolute',
            bottom: '-10px',
            left: '50%',
            transform: 'translate(-50%, 0)',
        },
    },
    '@global': {
        '.MuiFormHelperText-root.Mui-error': {
            width: 'auto',
            margin: '0 auto',
            color: '#e10000',
            textAlign: 'center',
            display: 'inline-flex',
            padding: '0 9px',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#f6ddd9',
            marginTop: '4px',
        },
        '.MuiRadio-colorSecondary.Mui-checked': {
            color: theme.palette.primary.main,
            fontSize: 18,
        },
        '.MuiAvatar-img': {
            objectFit: 'contain',
            backgroundColor: 'transparent',
        },
        '.MuiFormControlLabel-label': {
            fontSize: 18,
        },
    },
}));

export default EmailRegistration;
