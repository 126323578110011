import React, { Component } from 'react';
import LandingPage from 'components/landing';
import analyticsManager from 'eventManager/analyticsManager';
import CommonAppWrapper from './commonAppWrapper';
import Constants from 'common/constants';
import EventManager from 'eventManager/eventManager';
import { IdStore } from 'dataStore';
import Utils from 'common/utils';
import JobSearchDataSource from 'datasource/jobsSearchDataSource';
import UserDataStore from 'components/landing/userDataStore';
import { withRouter } from 'react-router-dom';
import RoutePrefixHandler from 'common/routePrefixHandler';
import AbManager from '../abManager/abTest';
import { AbTestHelper } from 'helpers/index';
import { fetchSkipToTabAdUnit, fetchSkipToTabAdUnitV5 } from 'helpers/adUnits/adUnitFetchHelpers';
import { updateFormDataWithJbDetails } from 'helpers/form/formHelpers';
import { getDefaultJobSourcePayload } from 'helpers/jobs/jobSourceHelpers';
import { getConcatAddressField } from 'eventManager/eventHelpers/eventDataHelpers';
import { getEkw } from 'common/scopeUtils';
import ReactHtmlParser from 'react-html-parser';
import { shouldFireSmsTrigger } from 'helpers/featureValueHelpers/featureValueHelpers';

const InterstitialAdsVariation2 = React.lazy(() =>
    import('components/landing/forms/exploreJobs/interstitialAdsVariation2'),
);

class LandingApp extends Component {
    constructor(props) {
        super(props);
        this.setState({
            showInterstitialAds: false,
        });
        this.goToListings = false;
        this.popState = this.onPopState.bind(this);
        !AbTestHelper.disableBackButtonListings() &&
            window.addEventListener('popstate', this.popState);
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.CURRENT_APP, Constants.EVENT_TYPE.LANDING);
        const abt6 = AbManager.getAbt6();

        switch (abt6) {
            case 'rg_nonbillable_skip_listings':
                this.props.history.push({
                    pathname: RoutePrefixHandler.generateActualPathname(
                        Constants.PAGE_ENDPOINTS.GRATIFICATION,
                    ),
                    search: `${window.location.search}&lrdr=rg_non_bl_listings`,
                });
                break;
            case 'rg_nonbillable_skip_offers':
                this.props.history.push({
                    pathname: RoutePrefixHandler.generateActualPathname(
                        Constants.PAGE_ENDPOINTS.ADS,
                    ),
                    search: `${window.location.search}&lrdr=rg_non_bl_offers`,
                });
                break;
            default:
                break;
        }

        if (AbTestHelper.listingsAsLandingFlow()) {
            this.props.history.push({
                pathname: RoutePrefixHandler.generateActualPathname(
                    Constants.PAGE_ENDPOINTS.GRATIFICATION,
                ),
            });
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.FIRE_LANDING_IMP_ON_GRAT, true);
        }
    }

    onPopState(e) {
        let hash = window.location.hash;

        if (hash) {
            hash = hash.replace('#', '');
        }

        if (AbTestHelper.backBtn2Offer()) {
            this.props.history.push({
                pathname: RoutePrefixHandler.generateActualPathname(Constants.PAGE_ENDPOINTS.ADS),
                search: `${window.location.search}&lrdr=landing${parseInt(hash) + 1}`,
            });
        } else if (
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.BACK_INTERSTITIAL) === '1' &&
            Utils.enableBack2Listings(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE))
        ) {
            this.props.history.push({
                pathname: RoutePrefixHandler.generateActualPathname(
                    Constants.PAGE_ENDPOINTS.GRATIFICATION,
                ),
                search: `${window.location.search}&lrdr=landing${hash}`,
            });
        }
    }

    async componentWillMount() {
        this.setState({
            showInterstitialAds: false,
        });
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.popState);
        window.removeEventListener(
            Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED,
            this.sessionCallback,
            false,
        );
        window.removeEventListener(
            Constants.INTERNAL_EVENTS.ON_SESSION_FETCH_FAILED,
            this.sessionFailureCallback,
            false,
        );
    }

    async componentDidMount() {
        analyticsManager.pageView('Landing', window.location.pathname + window.location.search);
        this.sessionCallback = this.sessionInitializedEventHandler.bind(this);
        window.addEventListener(Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED, this.sessionCallback);
        window.addEventListener(
            Constants.INTERNAL_EVENTS.ON_SESSION_FETCH_FAILED,
            this.sessionFailureCallback,
        );
    }

    fireLandingImp2 = () => {
        if (
            AbTestHelper.shouldFireLpImp2() &&
            !(
                AbTestHelper.brandedOfferFedExTest() ||
                AbTestHelper.brandedOfferAmazonTest() ||
                AbTestHelper.brandedOfferTest() ||
                AbTestHelper.skipToNewTabV3()
            )
        ) {
            EventManager.sendEvent(
                Constants.EVENT.LANDING_PAGE_SPECIAL_IMP,
                {},
                Constants.EVENT_TYPE.LANDING,
            );
        }
    };

    fireLandingImp = () => {
        if (!Utils.getValForKeyFromCurrentUrl('s2nt')) {
            let formData = {};

            let details = JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_DETAILS));

            const IsRegistered = IdStore.fetchIdForKey(
                Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT,
            );
            const performanceTime = Utils.getPagePerformanceTiming();

            formData = updateFormDataWithJbDetails(details, formData);

            EventManager.sendEvent(
                Constants.EVENT.LANDING_PAGE_IMP,
                { ...formData, container_load_time: performanceTime },
                Constants.EVENT_TYPE.LANDING,
            );

            if (
                (Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_MEDIUM) === 'sms2click' ||
                    Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_MEDIUM_G) ===
                        'sms2click') &&
                shouldFireSmsTrigger() === '1'
            ) {
                const userData = Utils.fetchUserDetailsFromUrl();
                const eventData = { ...formData, ...userData };
                EventManager.sendEvent(
                    Constants.EVENT.HNP_LEAD,
                    { ...eventData, cat: 'sms_trigger' },
                    Constants.EVENT_TYPE.DATA,
                );
            }
            const userStatus = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS_CURRENT);

            if (
                IsRegistered !== 0 &&
                AbTestHelper.repeatUserTest() &&
                userStatus === 'reg_same_device'
            ) {
                const userDetails = UserDataStore.fetchUserDetailsFromStore();
                const sid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SURVEY_ID);
                let jt =
                    IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL) ||
                    IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL) ||
                    Constants.DEFAULT_KW;

                const commonEventData = {
                    first_name: userDetails.first_name,
                    last_name: userDetails.last_name,
                    email: userDetails.email,
                    gender: userDetails.gender,
                    address: getConcatAddressField(userDetails.address),
                    phone_number: userDetails.phone_number,
                    optxt:
                        sid === Constants.SID_SMS_COMPLIANCE
                            ? Constants.TCPA_OPTIN_TEXT_SMS
                            : `${
                                  Constants.TCPA_OPTIN_TEXT +
                                  (Utils.isLiveSurvey()
                                      ? ReactHtmlParser(Constants.TCPA_EXTRA_PARTNERS)
                                      : '')
                              }Marketing Partners ${Constants.TCPA_OPTIN_TEXT_CONT}`,
                    evs: userDetails.evs,
                    job_type: jt,
                };
                EventManager.sendEvent(
                    Constants.EVENT.HNP_LEAD,
                    { ...formData, ...commonEventData, cat: 'user_repeat', ekw: getEkw() },
                    Constants.EVENT_TYPE.DATA,
                );
            }

            if (AbTestHelper.skipToNewTabV2()) {
                fetchSkipToTabAdUnit().finally(this.fireLandingImp2);
            } else if (AbTestHelper.ShowAdUnitUrlTest()) {
                fetchSkipToTabAdUnitV5().finally(this.fireLandingImp2);
            } else {
                this.fireLandingImp2();
            }
        }
    };

    sessionFailureCallback = () => {
        if (!Utils.getValForKeyFromCurrentUrl('s2nt')) {
            this.fireLandingImp();
        }
        window.removeEventListener(
            Constants.INTERNAL_EVENTS.ON_SESSION_FETCH_FAILED,
            this.sessionFailureCallback,
            false,
        );
    };

    sessionInitializedEventHandler() {
        if (!Utils.getValForKeyFromCurrentUrl('s2nt')) {
            this.fireLandingImp();
        }

        window.removeEventListener(
            Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED,
            this.sessionCallback,
            false,
        );
        this.updateJobsDataForInterstitial();
    }

    render() {
        const { showInterstitialAds, jobs } = this.state;

        return (
            <div
                className={`app-wrapper ${
                    (AbTestHelper.SeasonalJobLandingv1() || AbTestHelper.SeasonalJobLandingv2()) &&
                    'app-wrapper-seasonal'
                } ${AbTestHelper.BrandedLanderTestv1() && 'app-wrapper-branded'}`}>
                {jobs && showInterstitialAds && (
                    <InterstitialAdsVariation2
                        handleClose={this.hideInterstitial.bind(this)}
                        jobs={jobs}
                        open={showInterstitialAds}
                    />
                )}
                <LandingPage {...this.props} />
            </div>
        );
    }

    hideInterstitial() {
        this.setState({
            showInterstitialAds: false,
        });
        this.goToListings = true;
    }

    updateJobsDataForInterstitial() {
        const oldUserDetails = UserDataStore.fetchUserDetailsFromStore();
        let zip =
            Utils.getValForKeyFromCurrentUrl('z') ||
            (oldUserDetails && oldUserDetails.zip) ||
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE);
        const term = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM);

        if (zip) {
            const jobSourceQuery = JobSearchDataSource.createJobsQueryForTerm({
                term,
                pageNume: 1,
                pageSize: 4,
                location: zip,
            });

            this.updateJobsDataForQuery(
                getDefaultJobSourcePayload(jobSourceQuery, { target: 'count' }),
            );
        }
    }

    updateJobsDataForQuery(jobsQuery) {
        JobSearchDataSource.fetchJobsDataForQueryMultiSource(jobsQuery)
            .then(jobsData => {
                console.log('JOBS: got jobs data - ', jobsData);

                let { totalCount: totalJobs } = jobsData;

                if (Utils.isNull(totalJobs)) {
                    totalJobs = 0;
                }
                let { jobs = [] } = jobsData;

                this.setState({
                    jobs,
                });
            })
            .catch(e => {
                console.log('JOBS: ERR: could not fetch the data - ', e);
            });
    }
}
export default CommonAppWrapper(withRouter(LandingApp), {
    showSubFooter: true,
    lndScrollCls: 'sv-scroll-header',
});
