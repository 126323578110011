export default {
    SINGLE_JOB_AD_AU: 'SINGLE_JOB_AD_AU',
    SKIP_TO_TAB_AD_UNIT_DATA: 'SKIP_TO_TAB_AD_UNIT_DATA',
    JOB_AD_META_DATA: 'JOB_AD_META_DATA',
    JOB_AD_AU: 'JOB_AD_AU',
    JOB_AD_SOURCE_IDS: 'JOB_AD_SOURCE_IDS',
    JT_JOB_AD_INFO_DATA: 'JT_JOB_AD_INFO_DATA',
    JOB_AD_SOURCES_UUID_MAP: 'JOB_AD_SOURCES_UUID_MAP',
    INITIAL_VIEW_PORT_HEIGHT: 'INITIAL_VIEW_PORT_HEIGHT',
    JOB_OVERLAY_INT_AU: 'JOB_OVERLAY_INT_AU',
    EXIT_INIT_AU: 'EXIT_INIT_AU',
    BACK_BUTTON_INIT_AU: 'BACK_BUTTON_INIT_AU',
    DEFAULT_JOB_SOURCE: 'DEFAULT_JOB_SOURCE',
    JOB_AD_SOURCE_FALLBACK: 'JOB_AD_SOURCE_FALLBACK',
    LANDING_TAB_OVER_AD_UNIT_DATA: 'LANDING_TAB_OVER_AD_UNIT_DATA',
    SPONSORED_MARKETPLACE_AD_UNIT_DATA: 'SPONSORED_MARKETPLACE_AD_UNIT_DATA',
};
