import Constants from 'common/constants';

export const LABELS = {
    WELCOME_EXCLAIM: 'Welcome!',
    WELCOME_BACK: 'Welcome Back!',
    MESSAGE_SENT_THANKS: 'Thank you for your message. It has been sent.',
    HP_DURATION: '/hr',
    PER_HOUR_DURATION: ' per hour',
    HOURS_DURATION: '/ hour',
    LOOKING_FOR_JOBS: jt => `Looking for ${jt} Jobs?`,
    JOB_OFFERING_PAY: hourlyPay => `Here's one offering ${hourlyPay}`,
    HP_KW_AVAILABLE: (hp = Constants.DEFAULT_PAY_RANGE, kw) => `${hp} ${kw} Jobs Available`,
    KW_JOBS_AVAILABLE: (kw = Constants.DEFAULT_KW) => `${kw} Jobs Available`,
    KW_JOBS_STARTING_AT_RANGE: (kw = Constants.DEFAULT_KW, hp = Constants.DEFAULT_PAY_RANGE) =>
        `${kw} Jobs starting at ${hp}/hr.`,
    EARN_PAY_RANGE: (hp = Constants.DEFAULT_PAY_RANGE) => `Earn ${hp}`,
    WHAT_KIND_OF_JOB: `What kind of a job are you <br /> looking for?`,
    NOT_INTERESTED_JOBS: `Not interested in the above jobs?`,
    STARTING_AT_PAY: hpShort => `Starting at <br /> ${hpShort}/hr`,
    VIEW_ALL_JOBS: `View All Jobs &gt;`,
    GOOGLE_KW_LIST: ['Full Time Jobs', 'Part Time Jobs', 'Immediate Hiring', 'Work From Home Job'],
    VERIFY_INFO: 'Verify your information to create your job profile',
    VERIFY_COMPLETE_PROFILE: 'Verify your information to complete your job profile',
    COMPLETE_JOB_PROFILE: 'Complete your job profile to view jobs',
    ENTER_NAME_EMAIL_CONTINUE: 'Enter your name and email to continue',
    ENTER_DETAILS_ACCESS_JD: 'Enter your details to access latest job listings',
    HOW_CONTACT_USER: 'How can we contact you?',
    ALMOST_THERE_EXCLAIM: 'Almost There!',
    FINAL_STEP_EXCLAIM: 'Final Step!',
    FINAL_STEP_COLON: 'Final Step:',
    FINAL_STEP_CONTACT: 'Final Step: How can we contact you?',
    NEED_MORE_INFO: 'We need a bit more information to show you more job opportunities',
    RELEVANT_JOBS: 'Help us show you relevant job opportunities',
    RELEVANT_JOBS_V2: 'Make it easier for potential employers to find you',
    CONFIRM_AGE_ADDRESS: 'Confirm your Age & Address',
    CONFIRM_PH_NO_AGE: 'Confirm your Phone Number and Age',
    CONFIRM_PH_NO_AGE_V2: 'Confirm your age & phone number to continue',
    CONFIRM_ZIP_AND_EMAIL_CONTINUE: 'Confirm your zip and email to continue',
    LAST_STEP_COMPLETE: 'Last Step! Complete your registration',
    HELP_EMP_CONTACT: 'Help employers contact you',
    HELP_HM_EMP_CONTACT: 'How can you be contacted',
    COMPLETE_PROFILE: 'Complete your profile to view jobs',
    COMPLETE_PROFILE_LISTINGS: 'Complete your profile to search job listings',
    LOADING_ELLIPSIS: 'Loading...',
    COUNT_LOCATION_JOBS: (totalJobs, location) =>
        `We found over ${totalJobs} new jobs near ${location}`,
    LOCATION_JOBS: location => `We found high paying new jobs near ${location}`,
    FOUND_JOBS_PAYING_HP: hp => `We found jobs paying <b>${hp}</b>`,
    GOOGLE_JOBS_PAYING_HP: hp => `Jobs starting at <b>${hp}/hr</b>`,
    KW_JOBS: (kw = Constants.DEFAULT_KW) => `${kw} Jobs`,
    UPDATED_LANDING_KW_JOBS: (kw = Constants.DEFAULT_KW) => `Submit your ${kw} application`,
    UPDATED_LANDING_KW_JOBS_LISTINGS_BG: (kw = Constants.DEFAULT_KW) =>
        `Verify your application for ${kw}`,
    VIEW_JOBS_HIRING: jt => `View <b>${jt}</b> Jobs Hiring Immediately`,
    SENIOR_JOBS: 'Senior Jobs',
    SKIP_CONFIRMATION: 'Are you sure you want to skip this step?',
    SKIP_DISCOURAGE: 'Creating a job profile can help you find better job matches',
    GOOGLE_RE_CAPTCHA_DISCLOSURE:
        'All company names are the registered trademarks of their original owners. The use of any trade name or trademark does not imply any association with the third-party company. This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" color="inherit">Privacy Policy</a> and <a href="https://policies.google.com/terms" color="inherit">Terms of Service</a> apply.',
    GOOGLE_RE_CAPTCHA_DISCLOSURE_CLONE:
        'This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" color="inherit">Privacy Policy</a> and <a href="https://policies.google.com/terms" color="inherit">Terms of Service</a> apply.',
    GET_NOTIFIED_EXCLAIM: 'Get notified when new jobs appear in your area!',
    CREATE_JOB_ALERT: 'Create Job Alert',
    GET_SIMILAR_JOBS_EMAIL: 'Get similar jobs sent to your email',
    KNOW_WHEN_TOP_EMP_HIRING: 'Know when top employers are hiring',
    WHAT_EMAIL_HM_QUESTION: 'What email address should a hiring manager contact you?',
    MARKETING_PARTNERS: 'Marketing Partners',
    JOB_UPDATES_QUESTION: location =>
        `Would you like to receive emails when we find new jobs in ${location}?`,
    JOB_UPDATES_QUESTION_NEAR_YOU: `Would you like to receive emails when we find new jobs near you?`,
    CHECK_AFFIRM: 'Please tick checkbox to proceed',
    NO_THANKS_SEP: 'No, thanks',
    CLICK_ON_FIELD: 'Click on fields to make changes',
    EMAIL_TOGGLE_TEXT: 'Get new jobs by email',
    SURVEY_PATH_HEADER_TEXT: 'Complete your profile and get job matches in just <b>30 seconds</b>!',
    WARMUP_WEEK_TITLE: 'Are you available to start working within the week?',
    WARMUP_WEEK_YES_ZIP_TITLE: 'Great! Confirm your location to proceed:',
    WARMUP_WEEK_NO_ZIP_TITLE:
        'No problem, You can start when ready. Confirm your location to proceed:',
    POSITION_AVAILABLE: 'Position Available',
    HP_UPTO: hourlyPay => `Hourly pay rate: Up to ${hourlyPay}`,
    AMAZON_BRAND_FLOW: {
        TITLE: 'AMAZON IS HIRING!',
        DESCRIPTION: hourlyPay => `Hourly pay rate: Starting at ${hourlyPay}`,
        LISTS: [
            'Signing Bonus might be available in your area',
            'Easy Application',
            'Competitive Pay',
            'Paid Time Off',
            'Health Medical Plan',
        ],
        BUTTON_LABEL: 'View Amazon Jobs',
    },
    FED_EX_BRAND_FLOW: {
        TITLE: 'FEDEX IS HIRING!',
        DESCRIPTION: hourlyPay => `Hourly pay rate: Up to ${hourlyPay}`,
        LISTS: [
            'Hiring Bonus',
            'Easy Application',
            'Competitive Pay',
            'Paid Time Off',
            'Health Medical Plan',
        ],
        BUTTON_LABEL: 'View FedEx Jobs',
    },
    defaultTitle: {
        0: 'Welcome!',
        1: 'Enter Your Name and Email To Continue',
        2: 'We need a bit more information to show you more job opportunities',
        3: 'Confirm your Phone Number and Age',
    },
    WarmUpTestAnswers: ['Immediately', 'Within 30 days', 'After 30 days', "I'm just browsing"],
    WarmUpTestAnswersV1: ['Right Now', 'Within a day', 'In 2-6 Months', 'In 6+ Months'],
    WarmUpTestAnswersV2: ['Immediately', 'Within 30 days', 'Just Looking'],
    WIN_CASH: 'WIN CASH FOR ANSWERING FUN QUESTIONS!',
    SIGN_UP_AND_CHECK:
        ' Sign up, then check your inbox (or spam folder) for your first email from SURVEYS AND QUIZZES',
    JOB_ALERT_TITLE: `Be the first to see new jobs in your area! Sign up for text alerts`,
    EDU_OPTIN_TCPA: (eduPartners, phoneNumber) =>
        `${'By selecting "Yes", "Maybe" or "Maybe later"  and clicking “Agree and Continue”'}, I agree to be contacted by phone or received daily SMS text message (message and data rates may apply) by ${
            eduPartners ? eduPartners.join(', ') : ''
        } at ${phoneNumber} (including my wireless) regarding educational opportunities. I acknowledge that these calls or sms messages may be generated using an autodialer and may also contain pre-recorded messages. I understand my consent is not required as a condition of purchasing any goods or services. To receive this information without providing consent,  `,
    EDU_OPTIN_TITLE: 'Interested in continuing your education?',
    EDU_OPTIN_TITLE_V2: 'Educational opportunities',
    EDU_OPTIN_SUBTITLE: 'Are you interested in educational opportunities?',
    POSTED_AGO: (pastHour, formattedDate) =>
        `Posted about ${pastHour} hours ago - ${formattedDate}`,
    SKIP_TEXT: 'skip >',
};
