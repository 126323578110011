import React, { useState, useEffect, useRef } from 'react';
import Utils from 'common/utils';
import Constants from 'common/constants';
import {
    Grid,
    Typography,
    FormControlLabel,
    FormControl,
    Checkbox,
    FormHelperText,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import RoutePrefixHandler from 'common/routePrefixHandler';
import { IdStore } from 'dataStore';
import UserDataStore from 'components/landing/userDataStore';
import UserDataValidator from 'common/userDataValidator';
import EventManager from 'eventManager/eventManager';
import { LABELS, CTA_LABELS, FIELD_LABELS } from 'strings';
import cn from 'classnames';
import flavors from 'flavors.macro';
import { PrimaryCTA } from '../buttons/primaryCta';
import InputEndAdornment from 'components/landing/forms/common/inputEndAdornment';
import * as FlavorConstants from 'flavors/layout-theme/constants.js';
import ReactHtmlParser from 'react-html-parser';
import { fireHnpEmailHnpImp } from 'eventManager/eventHelpers/hnpEvents';
import UserDataSource from 'datasource/userDetailsDataSource';
import { ConfigProvider, FeatureMap } from 'providers';
import MarketingPartnersPopOver from '../marketingPartnersPopOver/marketingPartnersPopOver';
import { ValidatorFormHOC } from 'hocs/validatorFormHOC';
import { fireHnpEmailOnlyHnpLead } from 'helpers/form/formHelpers';

flavors();
const formLayout = {
    xs: 12,
};

const gridItemProps = {
    item: true,
    style: {
        width: '100%',
    },
    ...formLayout,
};

const renderLogo = ({ classes }) => {
    return (
        <img
            alt={'icon'}
            className={classes.logo}
            src={RoutePrefixHandler.getImagePath('/images/email-sub.svg')}
        />
    );
};

const renderTitle = ({ classes }) => {
    return (
        <Grid {...gridItemProps}>
            <Typography className={cn(classes.header)}>
                {LABELS.KNOW_WHEN_TOP_EMP_HIRING}
            </Typography>
        </Grid>
    );
};

const renderSubTitle = ({ classes }) => {
    const city = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_CITY);
    const state = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_REGION);
    const location = `<b>${city}, ${state}</b>`;

    return (
        <Grid {...gridItemProps}>
            <Typography className={classes.subTitle}>
                {Utils.isEmptyStr(city) || Utils.isEmptyStr(state)
                    ? LABELS.JOB_UPDATES_QUESTION_NEAR_YOU
                    : ReactHtmlParser(LABELS.JOB_UPDATES_QUESTION(location))}
            </Typography>
        </Grid>
    );
};

const getMjcEmailOptinText = () => {
    const mjcEmailOptin = ConfigProvider.Features.getFeature(FeatureMap.MJCEmailOptin);
    const mjcEmailOptinText = mjcEmailOptin && mjcEmailOptin.value ? mjcEmailOptin.value : '';

    return mjcEmailOptinText
        ? ReactHtmlParser(decodeURIComponent(mjcEmailOptinText))
        : ReactHtmlParser(`I want to receive email updates for new job
                                                    listings and career opportunities from MyJobsCorner,&nbsp;
                                                    <a
                                                        href="https://www.everyjobforme.com/"
                                                        rel="noopener noreferrer"
                                                        style="color: inherit"
                                                        target="_blank">EveryJobForMe.com</a>, NewJobConnections and our`);
};

const renderTCPAOptIn = ({ classes, handleCheckboxChange, checked, clicked }) => {
    return (
        <>
            <Grid {...gridItemProps}>
                <div
                    className={`disclaimer-with-error-msg ${classes.checkBoxContainer}`}
                    style={{
                        border: '1px solid',
                        borderColor: 'transparent',
                        background: 'transparent',
                        marginBottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                    <FormControl component="fieldset">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    checkedIcon={
                                        <span
                                            className={`${classes.icon} ${classes.checkedIcon}`}
                                        />
                                    }
                                    className={classes.checkbox}
                                    color="primary"
                                    icon={<span className={classes.icon} />}
                                    inputProps={{
                                        color: 'red',
                                    }}
                                    name="opt_in_email"
                                    onChange={handleCheckboxChange}
                                    value={checked}
                                />
                            }
                            label={
                                <div className={classes.disclaimerWrapperNew}>
                                    <input id="leadid_tcpa_disclosure" type="hidden" />
                                    <input
                                        id="leadid_token"
                                        name="universal_leadid"
                                        type="hidden"
                                    />
                                    <label htmlFor="leadid_tcpa_disclosure">
                                        <Typography
                                            className={`disclaimer-wrapper ${classes.disclaimer}`}
                                            variant="h3">
                                            {getMjcEmailOptinText()}
                                            &nbsp;
                                            <MarketingPartnersPopOver />
                                        </Typography>
                                    </label>
                                </div>
                            }
                        />
                    </FormControl>
                </div>
            </Grid>
        </>
    );
};

const renderFooter = ({ classes, handleCrossClick }) => {
    return (
        <div className={classes.footerContainer}>
            <input id="leadid_tcpa_disclosure" type="hidden" />
            <input id="leadid_token" name="universal_leadid" type="hidden" />
            <label htmlFor="leadid_tcpa_disclosure">
                <div className={classes.noThanksText} onClick={handleCrossClick}>
                    {LABELS.NO_THANKS_SEP}
                </div>
                <div className={classes.emailCTAFootDisclosure}>
                    By clicking ‘Submit’, you agree to the{' '}
                    <a
                        className={classes.linkText}
                        href="https://myjobscorner.com/privacy-policy/"
                        rel="noreferrer"
                        target="_blank">
                        Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                        className={classes.linkText}
                        href="https://myjobscorner.com/terms-of-use/"
                        rel="noreferrer"
                        target="_blank">
                        Terms & Conditions
                    </a>{' '}
                    of {FlavorConstants.TEXT_CONTENTS.FOOTER_DISCLOSURE}
                    to receive job alerts by email. You can unsubscribe at any time.
                </div>
            </label>
        </div>
    );
};

const EmailRegistration = props => {
    const { handleClose, emailSendSuccess } = props;
    const classes = useStyles();
    const [checked, setChecked] = useState(true);
    const [success, setSucess] = useState(true);
    const [clicked, setClicked] = useState(false);
    const [inValidCount, setInValidCount] = useState(0);

    const [email, setEmail] = useState(() => {
        let initEmail = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EMAIL) || '';
        initEmail = Utils.filterInvalidUrlInput(initEmail);

        const oldUserDetails = UserDataStore.fetchUserDetailsFromStore();

        if (!initEmail && !Utils.isNull(oldUserDetails) && !Utils.isEmptyObj(oldUserDetails)) {
            let oldDetails = oldUserDetails;
            const isEmailResetted = Boolean(
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.EMAIL_RESETTED),
            );

            if (
                (isEmailResetted || Utils.isEmptyStr(email)) &&
                !Utils.isEmptyStr(oldDetails.email) &&
                !Utils.isNull(oldDetails.email) &&
                oldDetails.email.length > 0
            ) {
                initEmail = oldDetails.email;
            }
        }

        return initEmail;
    });

    const emailRef = useRef();

    useEffect(() => {
        ValidatorForm.addValidationRule('isValidEmailEmailSub', value => value);
        fireHnpEmailHnpImp(Constants.EVENT_TYPE.GRAITIFICATION);
        EventManager.sendEvent(
            Constants.EVENT.EMAIL_SUB_INT_IMP,
            null,
            Constants.EVENT_TYPE.GRAITIFICATION,
        );

        return () => ValidatorForm.removeValidationRule('isValidEmailEmailSub');
    }, []);

    const browserType = Utils.browserType();

    const scrollPageUP = e => {
        const field = document.getElementById('email');
        field && field.scrollIntoView && field.scrollIntoView(true);
    };

    const validateEmail = async event => {
        const { value } = event.target;
        const { VERIFIED, RETRY } = Constants.EMAIL_VERIFICATION;
        const res = await UserDataSource.validateEmail(value);
        checkEmail(value);

        if (inValidCount < 4 && res !== VERIFIED && res !== RETRY) {
            setInValidCount(inValidCount + 1);
            EventManager.sendEvent(
                Constants.EVENT.EMAIL_VERIFICATION_API_FAILED,
                { evs: res, email: value },
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
        }
    };

    const checkEmail = value => {
        const validEmailSyntax = Utils.isNull(UserDataValidator.isValidEmail(value));

        if (!validEmailSyntax) {
            setSucess(false);
            emailRef.current.validate(false);
        } else {
            setSucess(true);
            emailRef.current.makeValid();
        }
    };

    const handleInputChange = event => {
        const { value } = event.target;
        checkEmail(value);
        setEmail(value);
    };

    const handleCheckboxChange = () => {
        setChecked(!checked);
    };

    const handleCrossClick = e => {
        EventManager.sendEvent(
            Constants.EVENT.EMAIL_SUB_INT_CANCEL,
            null,
            Constants.EVENT_TYPE.GRAITIFICATION,
        );
        handleClose();
    };

    const handleSubmit = async e => {
        e.preventDefault();
        checkEmail(email);
        setClicked(true);
        checkEmail(email);
        const validEmailSyntax = Utils.isNull(UserDataValidator.isValidEmail(email));

        if (validEmailSyntax && checked) {
            UserDataStore.storeUserDataInCache({ email });
            const eventData = {
                email: email,
                optxt: `${Constants.TCPA_EMAIL_V2}${FlavorConstants.TEXT_CONTENTS.FOOTER_DISCLOSURE} and ${LABELS.MARKETING_PARTNERS}`,
                evs: await UserDataSource.validateEmail(email),
            };

            if (checked) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.EMAIL_SUB, 'true');
            }
            fireHnpEmailOnlyHnpLead(eventData, Constants.EVENT_TYPE.GRAITIFICATION);
            EventManager.sendEvent(
                Constants.EVENT.EMAIL_SUB_INT_SUBMIT,
                null,
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
            emailSendSuccess && emailSendSuccess();
            handleClose();
        }
    };
    const [deviceWidth] = Utils.getDeviceInfo();

    return (
        <Grid className={`${classes.container} sv-scroll-header`}>
            <Close className={classes.crossIcon} onClick={handleCrossClick} />
            <Grid className={cn(classes.root)} container direction="column">
                <Grid className={classes.containerTop} container direction="column">
                    {renderLogo({ classes })}
                    {renderTitle({ classes })}
                    {renderSubTitle({ classes })}
                </Grid>
                <ValidatorFormHOC
                    className={classes.emailAlertsCTAFormContainer}
                    onError={errors => console.log(errors)}
                    onSubmit={handleSubmit}>
                    <Grid {...gridItemProps}>
                        <TextValidator
                            InputProps={{
                                disableUnderline: true,
                                spellCheck: false,
                                endAdornment: success && email && <InputEndAdornment />,
                            }}
                            className={`input-with-icon emailInput ${
                                success ? '' : classes.errorInputBox
                            } ${
                                ['safari', 'samsungbrowser'].includes(browserType)
                                    ? classes.v2Error
                                    : ''
                            }`}
                            errorMessages={[
                                FIELD_LABELS.REQUIRED_FIELD,
                                FIELD_LABELS.INVALID_EMAIL,
                            ]}
                            id="email"
                            name="email"
                            onBlur={validateEmail}
                            onChange={handleInputChange}
                            onFocus={deviceWidth === 'sm' ? scrollPageUP : null}
                            placeholder={FIELD_LABELS.CONFIRM_EMAIL}
                            ref={emailRef}
                            type="email"
                            validators={['required', 'isValidEmailEmailSub']}
                            value={email}
                        />
                    </Grid>
                    <div className={classes.ctaContainer}>
                        {!checked && clicked ? (
                            <Grid {...gridItemProps}>
                                <FormHelperText
                                    error
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                    }}>
                                    {LABELS.CHECK_AFFIRM}
                                </FormHelperText>
                            </Grid>
                        ) : null}
                        <PrimaryCTA
                            endIconVariant={'padded'}
                            onClick={() => {
                                setClicked(true);
                            }}>
                            {CTA_LABELS.SUBMIT}
                        </PrimaryCTA>
                    </div>
                    {renderTCPAOptIn({ classes, handleCheckboxChange, checked, clicked })}
                    {renderFooter({ classes, handleCrossClick })}
                </ValidatorFormHOC>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
    },
    crossIcon: {
        position: 'absolute',
        right: '12px',
        top: '12px',
        width: '20px',
        height: '20px',
        cursor: 'pointer',
        color: 'rgb(95, 95, 95)',
    },
    root: {
        display: 'flex',
        width: '100%',
        overflowX: 'hidden',
        background: '#f8f8f8',
    },
    logo: {
        alignSelf: 'flex-start',
    },
    header: {
        marginTop: 14,
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: 1.3,
        color: '#294964',
        textTransform: 'uppercase',
    },
    containerTop: {
        padding: '24px 24px 16px 24px',
        background: 'rgba(41, 73, 100, 0.07)',
    },
    subTitle: {
        marginTop: 6,
        fontSize: '16px',
        lineHeight: 1.38,
        color: 'rgba(41, 73, 100, 0.8)',
    },
    emailAlertsCTAHeader: {
        marginTop: 14,
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: 1.3,
        color: '#294964',
        textTransform: 'uppercase',
    },
    emailAlertsCTAFormContainer: {
        width: 'auto',
        padding: 20,
    },
    noThanksText: {
        marginTop: 16,
        fontSize: '16px',
        lineHeight: 1.38,
        color: 'rgba(41, 73, 100, 0.6)',
        textAlign: 'center',
        cursor: 'pointer',
    },
    emailCTAFootDisclosure: {
        marginTop: 16,
        fontSize: '10px',
        lineHeight: 1.3,
        textAlign: 'center',
        color: 'rgba(124, 143, 159, 0.8)',
    },
    linkText: {
        color: 'rgba(124, 143, 159, 0.8)',
    },
    errorInputBox: {
        border: 'solid 1px #e63737',
        borderRadius: '2px',
    },
    v2inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10px',
        width: '100%',
    },
    checkedImg: {
        maxWidth: '23px',
        maxHeight: '17px',
        width: 'auto',
        height: 'auto',
    },
    disclaimer: {
        lineHeight: 1.2,
        fontSize: '12px',
        marginTop: '3px',
        textAlign: 'left',
    },
    disclaimerWrapperNew: {
        marginBottom: 10,
        textAlign: 'center',
    },
    box: {
        maxWidth: '18px',
        maxHeight: '18px',
        paddingRight: 10,
    },
    btnIcon: {
        transition: 'transform 0.3s ease-out',
        position: 'relative',
        top: '1px',
        left: '15px',
    },
    checkBoxContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 6,
    },
    checkbox: {
        color: '#e1e1e1',
        width: 22,
        height: 22,
        padding: '4px 9px 0 0',
        alignSelf: 'flex-start',
    },
    v2button: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'break-word',
        fontSize: 22,
    },
    submitContainer: {
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    icon: {
        width: '22px',
        height: '22px',
        fill: 'white',
        borderRadius: '2px',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    ctaContainer: {
        marginTop: 12,
    },
    checkedIcon: {
        backgroundColor: '#fff',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        padding: '0',
        '&:before': {
            display: 'block',
            width: 22,
            height: 22,
            padding: 0,
            backgroundImage: `url(${RoutePrefixHandler.getImagePath('images/checked.png')})`,
            backgroundSize: '80%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            content: '""',
            margin: 0,
        },
        'input:hover ~ &': {
            backgroundColor: '#fff',
        },
    },
    v2Error: {
        position: 'relative',
        '& #email-helper-text': {
            position: 'absolute',
            bottom: '-10px',
            left: '50%',
            transform: 'translate(-50%, 0)',
        },
    },

    '@global': {
        '.MuiFormHelperText-root.Mui-error': {
            width: 'auto',
            margin: '0 auto',
            color: '#e10000',
            textAlign: 'center',
            display: 'inline-flex',
            padding: '0 9px',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#f6ddd9',
            marginTop: '4px',
        },
        '.MuiRadio-colorSecondary.Mui-checked': {
            color: theme.palette.primary.main,
            fontSize: 18,
        },
        '.MuiAvatar-img': {
            objectFit: 'contain',
            backgroundColor: 'transparent',
        },
        '.MuiFormControlLabel-label': {
            fontSize: 18,
        },
    },
}));

export default EmailRegistration;
