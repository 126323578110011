import React from 'react';
import { Button } from '@material-ui/core';

import { LazyLoadComponent } from 'react-lazy-load-image-component';
import RouterPrefixHandler from 'common/routePrefixHandler';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import flavors from 'flavors.macro';

flavors();

const useStyles = makeStyles(theme => ({
    btnV2: {
        overflow: 'hidden',
        whiteSpace: 'break-word',
    },
    btnIcon: {
        transition: 'transform 0.3s ease-out',
        position: 'relative',
        top: '2px',
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        width: '100%',
        margin: 'auto',
        paddingTop: 10,
    },
    btnContainerLeft: {
        justifyContent: 'flex-start',
        paddingLeft: 80,
    },
    endIconPadded: {
        marginLeft: 8,
    },
    endIconLeftTextVariant: {
        position: 'absolute',
        right: 44,
    },
    fontSizeMd: {
        fontSize: 24,
    },
    fontSizeSm: {
        fontSize: 20,
    },
    buttonSizeSm: {
        height: 48,
        paddingTop: 5,
    },
    buttonSizeMd: {
        height: 56,
        paddingTop: 5,
    },
    buttonBorderRadiusSm: {
        borderRadius: 4,
    },
    buttonBorderRadiusMd: {
        borderRadius: 8,
    },
    endIconSm: {
        height: 11,
        top: -1,
    },
    endIconSpacedMd: {
        top: '1px',
        height: '15px',
        left: '12px',
    },
}));

export const PrimaryCTA = (props = {}) => {
    const classes = useStyles();

    return (
        <Button
            className={classNames(classes.btnContainer, {
                [classes.buttonSizeSm]: props.buttonSizeVariant === 'sm',
                [classes.buttonSizeMd]: props.buttonSizeVariant === 'md',
                [classes.buttonBorderRadiusSm]: props.buttonBorderRadiusVariant === 'sm',
                [classes.buttonBorderRadiusMd]: props.buttonBorderRadiusVariant === 'md',
                [classes.fontSizeMd]: props.fontVariant === 'md',
                [classes.fontSizeSm]: props.fontVariant === 'sm',
            })}
            classes={{
                label: classNames({
                    [classes.btnContainerLeft]: props.textVariant === 'left-text',
                }),
                endIcon: classNames({
                    [classes.endIconLeftTextVariant]: props.textVariant === 'left-text',
                }),
            }}
            color="primary"
            endIcon={
                <LazyLoadComponent threshold="50">
                    <picture
                        className={classNames(props.endIconClassName, {
                            [classes.endIconPadded]: props.endIconVariant === 'padded',
                        })}>
                        <source
                            srcSet={RouterPrefixHandler.getImagePath('images/left-arrow.webp')}
                            type="image/webp"
                        />
                        <img
                            alt={`icon`}
                            className={classNames(classes.btnIcon, {
                                [classes.endIconSm]: props.buttonSizeVariant === 'sm',
                                [classes.endIconSpacedMd]: props.buttonSpaced === 'md',
                            })}
                            src={RouterPrefixHandler.getImagePath('images/left-arrow.png')}
                        />
                    </picture>
                </LazyLoadComponent>
            }
            type="submit"
            {...props}
        />
    );
};
