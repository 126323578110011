const JT_KW_MAPPING = (window.CNXBucket &&
    window.CNXBucket.JT_KW_MAPPING &&
    (Object.keys(window.CNXBucket.JT_KW_MAPPING).length > 0
        ? window.CNXBucket.JT_KW_MAPPING
        : null)) || {
    amazon: ['Warehouse', 'Delivery Driver', 'Work from home', 'Other'],
    costco: ['Cashier', 'Warehouse', 'Customer Service', 'Other'],
    'home depot': ['Warehouse', 'Customer service', 'Cashier', 'Delivery Driver', 'Other'],
    walmart: ['Warehouse', 'Work At Home', 'Driver', 'Other'],
    fedex: ['Warehouse', 'Customer Service', 'Delivery Driver', 'Other'],
    target: ['Stocker', 'Customer Service', 'Merchandise', 'Other'],
    walgreens: ['Customer Service', 'Stocker', 'Sales', 'Other'],
    apple: ['Sales', 'Customer service', 'Team lead'],
    tjmaxx: ['Customer service', 'Cashier', 'Stocker', 'Other'],
    goodwill: ['Cashier', 'Warehouse', 'Customer Service', 'Other'],
    'coca cola': ['Sales', 'Warehouse', 'Delivery Driver', 'Other'],
    'dollar general': ['Cashier', 'Customer Service', 'Warehouse', 'Other'],
    'foot locker': ['Sales', 'Stocker', 'Cashier', 'Other'],
    'immediate hiring': [
        'Customer Service',
        'Stocker',
        'Delivery Driver',
        'Warehouse',
        'Seasonal',
        'Other',
    ],
    'full time jobs': ['Customer Service', 'Delivery Driver', 'Warehouse', 'Other'],
    pepsi: ['Sales', 'Warehouse', 'Delivery Driver', 'Other'],
    'frito lay': ['Delivery Driver', 'Stocker', 'Warehouse', 'Sales', 'Store Manager', 'Other'],
    'sams club': ['Stocker', 'Customer Service', 'Warehouse', 'Other'],
    usps: ['Customer Service', 'Delivery Driver', 'Package Handler', 'Other'],
    ups: ['Warehouse', 'Customer service', 'Delivery Driver', 'Stocker', 'Other'],
    marriott: [
        'Customer service',
        'Warehouse',
        'Front Desk',
        'Receptionist',
        'Housekeeping',
        'Security',
        'Other',
    ],
    macys: ['Cashier', 'Warehouse', 'Sales', 'Other'],
    mac: ['Cashier', 'Stocker', 'Sales', 'Other'],
    publix: ['Warehouse', 'Customer service', 'Cashier', 'Other'],
    dmv: ['Customer service', 'Clerk', 'Operations specialist'],
    subway: ['Team Member', 'Cashier', 'Manager', 'Server', 'Other'],
    'pizza hut': ['Team Member', 'Delivery driver', 'Cashier', 'Manager', 'Other'],
    kfc: ['Team Member', 'Manager', 'Server', 'Cashier', 'Other'],
    lowes: ['Cashier', 'Customer Service', 'Warehouse', 'Other'],
    kroger: ['Cashier', 'Customer Service', 'Warehouse', 'Other'],
    nike: ['Sales', 'Delivery Driver', 'Warehouse', 'Other'],
    aldi: ['Cashier', 'Customer Service', 'Warehouse', 'Other'],
    mcdonalds: ['Team Member', 'Cashier', 'Shift Manager', 'Other'],
    'dollar tree': ['Cashier', 'Customer Service', 'Warehouse', 'Other'],
    uber: ['Driver', 'Customer Service', 'Manager', 'Other'],
    'american airlines': ['Security', 'Driver', 'Package Handler', 'Other'],
    starbucks: [
        'Barista',
        'Store Manager',
        'Cashier',
        'Team Member',
        'Cashier',
        'Manager',
        'Other',
    ],
    'kelly services': ['Material Handler', 'Assembly worker', 'Customer Service', 'Other'],
    ford: [
        'Data Entry Clerk',
        'Customer Service',
        'Warehouse',
        'Call Center',
        'Sales',
        'Production Supervisor',
        'Other',
    ],
    boa: ['Security', 'Customer service', 'Sales', 'other'],
    'taco bell': ['Team Member', 'Cashier', 'Manager', 'Supervisor', 'Other'],
    'waste management': ['Driver', 'Collector', 'Laborer', 'Sorter', 'Other'],
    'chick fil a': ['Team Member', 'Cashier', 'Manager'],
    macy: ['Cashier', 'Warehouse', 'Sales', 'Other'],
    hilton: [
        'Front Desk',
        'Guest Service',
        'Housekeeper',
        'Customer service',
        'Cleaning',
        'Receptionist',
        'Other',
    ],
    wendys: ['Team Member', 'Cashier', 'Manager'],
    popeyes: ['Team Member', 'Cashier', 'Manager'],
    chipotle: ['Team Member', 'Cashier', 'Manager'],
    ihop: ['Team Member', 'Cashier', 'Manager'],
    'dunkin donuts': ['Team Member', 'Cashier', 'Manager'],
    'panera bread': ['Team Member', 'Cashier', 'Manager'],
    'little caesars': ['Team Member', 'Cashier', 'Manager'],
    "buc ee's": ['Team Member', 'Cashier', 'Manager'],
    'food locker': ['Sales', 'Stocker', 'Cashier', 'Other'],
    'dairy queen': ['Team Member', 'Cashier', 'Manager'],
    'at&t': ['Customer service', 'Sales', 'Technician', 'Other'],
    'food lion': ['Team Member', 'Cashier', 'Manager'],
    'baskin robbin': ['Team Member', 'Cashier', 'Manager'],
    'burger king': ['Team Member', 'Cashier', 'Shift Manager', 'Other'],
    'union pacific railroad': ['Train Crew', 'Logistics', 'Mechanic', 'Other'],
    fema: ['Customer Service', 'Emergency team', 'Construction', 'Other'],
    cvs: ['Retail', 'Cashier', 'Customer Service', 'Other'],
    'cvs health': ['Customer Service', 'Stocker', 'Sales', 'Other'],
    'hobby lobby': ['Customer Service', 'Warehouse', 'Sales', 'Other'],
    tsa: ['Security', 'Mission Support', 'Law Enforcement', 'Other'],
    'five below': ['Stocker', 'Customer Service', 'Warehouse', 'Other'],
    petsmart: ['Sales', 'Stocker', 'Cashier', 'Other'],
    'whole foods': ['Stocker', 'Customer Service', 'Warehouse', 'Other'],
    tesla: ['Warehouse', 'Data Entry', 'Customer Service', 'Other'],
    delta: ['Security', 'Driver', 'Package Handler', 'Other'],
    'family dollar': ['Cashier', 'Customer Service', 'Warehouse', 'Other'],
    kohl: ['Cashier', 'Stocker', 'Customer Service', 'Other'],
};
export default JT_KW_MAPPING;

export const GoogleAllowedKeywordsJT = ['full time jobs', 'part time jobs', 'work from home'];

export const GoogleJtKwMappings = {
    'full time jobs': ['Warehouse', 'Sales', 'Customer Service', 'Delivery Driver', 'Other'],
    'part time jobs': ['Delivery Driver', 'Cashier', 'Warehouse', 'Other'],
    'work from home': ['Customer Service', 'Data Entry', 'Sales', 'Other'],
};

export const AbiemJtKwMappings = {
    fema: ['Customer Service', 'Assistant', 'Clean Up Crew', 'Other'],
    fedex: ['Warehouse', 'Delivery Driver', 'Customer Service', 'Other'],
    costco: ['Customer Service', 'Warehouse', 'Delivery Driver', 'Other'],
    nike: ['Warehouse', 'Customer Service', 'Cashier', 'Other'],
    amazon: ['Delivery Driver', 'Warehouse', 'Online Jobs', 'Other'],
    'kelly services': ['Customer Service', 'Warehouse', 'Assistant', 'Other'],
    'hobby lobby': ['Retail', 'trucking', 'warehouse', 'Other'],
    'coca cola': ['warehouse', 'Delivery Driver', 'Retail', 'Other'],
};

export const WarmupQuestion = [
    'Immediately',
    'Within 30 days',
    'After 30 days',
    "I'm just browsing",
];

export const GoogleRGLanderKwMappings = {
    'online jobs': ['Customer Service', 'Work from home', 'Data Entry', 'Other'],
    'part time jobs': ['Delivery Driver', 'Customer Service', 'Work from home', 'Other'],
    'entry level jobs': ['Delivery Driver', 'Customer Service', 'Work from home', 'Other'],
    'full time jobs': ['Delivery Driver', 'Customer Service', 'Work from home', 'Other'],
    'immediate hiring': ['Delivery Driver', 'Customer Service', 'Work from home', 'Other'],
};

export const JobCategoriesJTMapping = {
    costco: ['Cashier', 'Stocker', 'Logistics', 'Pharmacy', 'Membership Assistant', 'Food Service'],
    walgreens: [
        'Customer Service',
        'Pharmacy',
        'Entry Level',
        'Store Manager',
        'Cashier',
        'Part Time',
    ],
    target: [
        'Warehouse',
        'Stocker',
        'Cashier',
        'Store Manager',
        'Sales',
        'Pharmacy',
        'Store Attendant',
    ],
    walmart: [
        'Sales',
        'Retail',
        'Customer Service',
        'Logistics',
        'Warehouse',
        'Stocker',
        'Cleaner',
    ],
    goodwill: [
        'Stocker',
        'Retail',
        'Driver',
        'Sales',
        'Store Clerk',
        'Cashier',
        'Janitor',
        'Forklift Operator',
    ],
    usps: [
        'Mail Handler',
        'Delivery Driver',
        'Postal Worker',
        'Customer Service',
        'Mail Processor',
        'Rural Carrier',
        'Package Handler',
    ],
    marriott: ['Front Desk', 'Housekeeper', 'Laundry Attendant', 'Cashier', 'Dishwasher'],
    'sams club': ['Stocker', 'Store Manager', 'Cashier', 'Packager', 'Sales', 'Retail'],
    amazon: [
        'Warehouse',
        'Package Handler',
        'Delivery Driver',
        'Packer',
        'Customer Service',
        'Office',
    ],
    starbucks: ['Barista', 'Retail', 'Store Manager', 'Shift Supervisor', 'Office'],
    'dollar general': ['Sales', 'Truck Driver', 'Store Manager', 'Customer Service', 'Warehouse'],
    'coca cola': [
        'Warehouse',
        'Truck Driver',
        'Technician',
        'Manufacturing',
        'Supervisor',
        'Part Time',
    ],
    pepsi: ['Sales', 'Warehouse', 'Delivery Driver', 'Material Handler', 'Packer', 'Store Manager'],
    'home depot': [
        'Cashier',
        'Store Support',
        'Customer Service',
        'Warehouse',
        'Maintenance',
        'Driver',
    ],
    'dollar tree': ['Warehouse', 'Driver', 'Sales', 'Store Manager', 'Cashier'],
    fedex: [
        'Warehouse',
        'Package Handler',
        'Customer Service',
        'Delivery Driver',
        'Technician',
        'Sorter',
    ],
    'frito lay': ['Driver', 'Sales', 'Material Handler', 'Part-Time', 'Warehouse'],
};

export const DynamicJobsJobProviderLogoMapping = {
    amazon: 'amazon',
    burgerking: 'burger king',
    burger: 'burger king',
    fedex: 'fedex',
    starbucks: 'starbucks',
    target: 'target',
    ups: 'ups',
    walmart: 'walmart',
};

export const jtDefaultKWMapping = {
    fedex: 'Customer Service',
    costco: 'Stocker',
    target: 'Stocker',
    amazon: 'Customer Service',
};
