import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import { AbTestHelper, FeatureValueHelper } from 'helpers/index';
import Constants from 'common/constants';
import { fetchSkipToTabAdUnitV4, fetchSkipToTabAdUnitV7 } from 'helpers/adUnits/adUnitFetchHelpers';
import { fetchSessionData } from 'datasource';

import flavors, { getFlavor } from 'flavors.macro';

flavors();

export const postConfigInitRedirection = () => {
    const hardRedirectLink = FeatureValueHelper.getHardRedirectOnInitLink();

    if (AbTestHelper.hardRedirectOnInit() && !Utils.isEmptyStr(hardRedirectLink)) {
        console.log(
            '[Debug]: Redirecting to Site',
            hardRedirectLink,
            AbTestHelper.hardRedirectOnInit(),
        );
        window.location = hardRedirectLink;

        return true;
    }

    return false;
};

export const postConfigInitProcessor = async () => {
    const { jobType } = Utils.getJobType();
    const keyword = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM);
    const forceFeedUtmTerms = FeatureValueHelper.getForceFeedUtmTerms();

    if (keyword && Object.keys(forceFeedUtmTerms).length > 0) {
        let forceFeedUtmTermsLowerCaseKey = {};
        let kwsToForceFeed = Object.keys(forceFeedUtmTerms);
        kwsToForceFeed.forEach(kw => {
            forceFeedUtmTermsLowerCaseKey[kw.toLowerCase()] = forceFeedUtmTerms[kw].toLowerCase();
        });

        if (Object.keys(forceFeedUtmTermsLowerCaseKey).includes(keyword.toLowerCase())) {
            IdStore.storeIdForKey(
                Constants.ID_STORE_KEYS.UTM_TERM,
                forceFeedUtmTermsLowerCaseKey[keyword.toLowerCase()],
            );
        }
    }

    if (AbTestHelper.upwardJtAsUtmTerm()) {
        if (!keyword && jobType) IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UTM_TERM, jobType);
        else if (!keyword && !jobType)
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UTM_TERM, Constants.DEFAULT_KW);
    }

    if (AbTestHelper.disregardJobType()) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL, Constants.DEFAULT_KW);
    }

    if (AbTestHelper.skipToNewTabV4()) {
        const featureData = FeatureValueHelper.getSkipToNewTabV4Data();
        const skipToNewTabV4AuNewStrategy = AbTestHelper.skipToNewTabV4AuNewStrategy();

        if (featureData && featureData.auId) {
            const sessionData = await fetchSessionData();
            const city = Utils.getObjVal(sessionData, ['geo', 'city']);
            const country = Utils.getObjVal(sessionData, ['geo', 'country']);
            const region = Utils.getObjVal(sessionData, ['geo', 'region']);
            const zipCodeSession = Utils.getObjVal(sessionData, ['geo', 'zip']);

            if (city) IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_CITY, city);

            if (country) IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_COUNTRY, country);

            if (region) IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_REGION, region);

            let zipFrmUrl = Utils.filterInvalidUrlInput(
                Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.ZIP),
            );
            let isValidZip = zipFrmUrl && Utils.isValidNumber(zipFrmUrl) && zipFrmUrl.length === 5;
            let zipCode = isValidZip ? zipFrmUrl : zipCodeSession;

            zipCode && IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE_FED, zipCode);
            !Utils.isEmptyStr(zipCode) &&
                Utils.isEmptyStr(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE)) &&
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE, zipCode);

            let flavorName = getFlavor('layout-theme');

            if (false === Utils.isEmptyStr(flavorName)) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.FLAVOR_NAME, flavorName);
            }

            await fetchSkipToTabAdUnitV4(
                featureData.auId,
                featureData.abtChannel,
                skipToNewTabV4AuNewStrategy,
            );
        }
    }

    if (AbTestHelper.staticDynamicTaboverLander()) {
        const featureData = FeatureValueHelper.getStaticDynamicTaboverData();

        if (featureData && featureData.auId) {
            const sessionData = await fetchSessionData();
            const city = Utils.getObjVal(sessionData, ['geo', 'city']);
            const country = Utils.getObjVal(sessionData, ['geo', 'country']);
            const region = Utils.getObjVal(sessionData, ['geo', 'region']);
            const zipCodeSession = Utils.getObjVal(sessionData, ['geo', 'zip']);

            if (city) IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_CITY, city);

            if (country) IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_COUNTRY, country);

            if (region) IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_REGION, region);

            let zipFrmUrl = Utils.filterInvalidUrlInput(
                Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.ZIP),
            );
            let isValidZip = zipFrmUrl && Utils.isValidNumber(zipFrmUrl) && zipFrmUrl.length === 5;
            let zipCode = isValidZip ? zipFrmUrl : zipCodeSession;

            zipCode && IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE_FED, zipCode);
            !Utils.isEmptyStr(zipCode) &&
                Utils.isEmptyStr(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE)) &&
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE, zipCode);

            let flavorName = getFlavor('layout-theme');

            if (false === Utils.isEmptyStr(flavorName)) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.FLAVOR_NAME, flavorName);
            }

            await fetchSkipToTabAdUnitV7(featureData.auId, featureData.abtChannel);
        }
    }
};
