export const CTA_LABELS = {
    CONTINUE: 'Continue',
    CONFIRM: 'Confirm',
    GET_STARTED: 'Get Started',
    VIEW_JOBS: 'View Jobs',
    SKIP_TO_JOBS: 'Skip to Jobs',
    VERIFY: 'Verify',
    YES: 'Yes',
    NO: 'No',
    VIEW: 'View',
    FIND_JOBS: 'Find Jobs',
    SAVE: 'Save',
    SUBMIT: 'Submit',
    YES_INTERESTED: 'Yes, I’m Interested',
    NO_THANKS: 'No, thanks',
    AGREE_AND_CONT: 'Agree & Continue',
    CTA1: 'Continue',
    CTA2: 'View Appointments',
};
