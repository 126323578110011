import Utils from './utils';

class CrossFrameComUtils {
    stream = [];

    init(iframe) {
        window.addEventListener('message', this.receiveMessage.bind(this));
        this.stream = [];
        this.frame = iframe;
    }

    destroy() {
        window.addEventListener('message', this.receiveMessage);
        this.stream = [];
    }

    receiveMessage(evt) {
        if (evt && evt.data && evt.data.id === 'il_cd_c_event' && evt.data.action) {
            console.log('[IFrameCom] Message Received', evt.data);
            this.stream.push(evt.data);
        }
    }

    popMessage(action) {
        const messages = this.stream.filter(message => message.action === action);
        this.stream = this.stream.filter(message => message.action !== action);

        return messages;
    }

    async sendMessageAwaitResponse(messageAction, messagePayload, receiveAction) {
        if (this.frame && this.frame.contentWindow) {
            for (let i = 0; i < 6; i++) {
                if (i % 2 === 0) {
                    this.sendMessage(messageAction, messagePayload);
                }
                await Utils.sleepPromise(1000);
                const response = this.popMessage(receiveAction || messageAction);
                console.log(
                    '[IFrameCom] Awaiting response',
                    receiveAction || messageAction,
                    response,
                    i,
                );

                if (response && response.length) {
                    return response;
                } else {
                    fetch(
                        `https://api.cnxdserv.com/e/?evt_id=failed_to_fetch&event_type=cross_domain&ext1=${
                            receiveAction || messageAction
                        }&ext2=${response}`,
                    );
                }
            }
        }
    }

    async pingIframe(messageAction, messagePayload, receiveAction) {
        if (this.frame && this.frame.contentWindow) {
            let response = [],
                i = 0;

            while (response.length === 0) {
                if (i % 2 === 0) {
                    this.sendMessage(messageAction, messagePayload);
                }
                await Utils.sleepPromise(1000);
                response = this.popMessage(receiveAction || messageAction);
                console.log(
                    '[IFrameCom] Awaiting for Ping response',
                    receiveAction || messageAction,
                    response,
                    i,
                );
                i++;
            }

            return response;
        }
    }

    sendMessage(messageAction, messagePayload) {
        console.log('[IFrameCom] : Posting Message', messageAction, messagePayload);

        if (this.frame && this.frame.contentWindow) {
            this.frame.contentWindow.postMessage(
                { id: 'il_cd_p_event', action: messageAction, payload: messagePayload },
                '*',
            );
        }
    }
}

export default new CrossFrameComUtils();
