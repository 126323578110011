import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, Link } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { dispatchAdClkEvent, dispatchAdImpEvents } from './sponsoredMarketplaceHandler';
import { useStyles } from './styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { FeatureValueHelper, MacrosHelper } from 'helpers';
import { MemoryStore, IdStore } from 'dataStore/index';
import memoryStoreKeys from 'common/memoryStoreKeys';
import Constants from 'common/constants';
import Utils from 'common/utils';

const SponsoredMarketplaceContainer = () => {
    const classes = useStyles();
    const [ads, setAds] = useState([]);

    useEffect(() => {
        let jobs = MemoryStore.getItem(memoryStoreKeys.SPONSORED_MARKETPLACE_AD_UNIT_DATA);

        if (jobs && jobs.length > 0) {
            jobs.sort((a, b) => {
                return a.spotNo - b.spotNo;
            });

            setAds(jobs);
        }
    }, [ads]);

    if (ads && ads.length)
        ads.forEach((ad, index) => {
            if (ad.type === 'Static') {
                if (
                    !Utils.getCookie(
                        `ad-imp-dedupe-${ad.job.creativeTitle}-${ad.job.dealId}-${ad.job.uuid}`,
                    )
                ) {
                    dispatchAdImpEvents(ad, index);
                    Utils.setCookie(
                        `ad-imp-dedupe-${ad.job.creativeTitle}-${ad.job.dealId}-${ad.job.uuid}`,
                        '1',
                        1440,
                    );
                }
            } else if (ad.type === 'Dynamic') {
                if (
                    !Utils.getCookie(
                        `ad-imp-dedupe-${ad.job.jobresult.title}-${ad.job.jobresult.company}-${ad.job.uuid}`,
                    )
                ) {
                    dispatchAdImpEvents(ad, index);
                    Utils.setCookie(
                        `ad-imp-dedupe-${ad.job.jobresult.title}-${ad.job.jobresult.company}-${ad.job.uuid}`,
                        '1',
                        1440,
                    );
                }
            }
        });

    const adVariant = FeatureValueHelper.getSponsoredMarketplaceVariant();

    const uuid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UUID);

    // Get Redirection Url
    const getRedirectionLink = ad => {
        if (ad.type === 'Static')
            return MacrosHelper.replaceMacroParamsStringMemoized(ad.job.customAdInfo.link, uuid);

        if (ad.type === 'Dynamic') return ad.job.jobresult.url;

        return '';
    };

    // Get Job Title
    const getJobTitle = ad => {
        if (ad.type === 'Static')
            return MacrosHelper.replaceMacroParamsStringMemoized(ad.job.creativeTitle, uuid);

        if (ad.type === 'Dynamic') return ad.job.jobresult.title;

        return '';
    };

    // Get Company Name
    const getCompany = ad => {
        if (ad.type === 'Static') return '';

        if (ad.type === 'Dynamic') return ad.job.jobresult.company;

        return '';
    };

    // Get Description
    const getDescription = ad => {
        if (ad.type === 'Static')
            return MacrosHelper.replaceMacroParamsStringMemoized(ad.job.creativeDescription1, uuid);

        if (ad.type === 'Dynamic')
            return MacrosHelper.replaceMacroParamsStringMemoized(ad.job.creativeDescription1, uuid);

        return '';
    };

    //
    const handleClick = event => {
        event.currentTarget.remove();
        let parentNode;

        if (adVariant === 'v1') {
            parentNode = document.getElementsByClassName('contentWrapperV1');
        } else if (adVariant === 'v2') {
            parentNode = document.getElementsByClassName('contentWrapperV2');
        }

        if (!parentNode[0].hasChildNodes()) {
            parentNode[0].parentNode.removeChild(parentNode[0]);
        }
    };

    return (
        <Grid className={classes.root}>
            {adVariant === 'v1' && ads && ads.length > 0 && (
                <Grid className={'container contentWrapperV1'}>
                    {ads.map((job, index) => (
                        <Link
                            className={classes.wrapperV1}
                            href={getRedirectionLink(job)}
                            key={index}
                            onClick={event => {
                                dispatchAdClkEvent(job, index);
                                handleClick(event);
                            }}
                            target="_blank">
                            <Typography className={classes.titleV1}>
                                {ReactHtmlParser(getJobTitle(job))}
                            </Typography>
                            {job.type === 'Dynamic' && (
                                <Typography className={classes.companyV1}>
                                    {ReactHtmlParser(getCompany(job))}
                                </Typography>
                            )}
                            <Typography className={classes.descriptionV1}>
                                {ReactHtmlParser(getDescription(job))}
                            </Typography>
                        </Link>
                    ))}
                </Grid>
            )}

            {adVariant === 'v2' && ads && ads.length > 0 && (
                <Grid className={'container'}>
                    <Grid className={`contentWrapperV2 ${classes.contentWrapperV2}`}>
                        {ads.map((job, index) => (
                            <Link
                                className={classes.wrapperV2}
                                href={getRedirectionLink(job)}
                                key={index}
                                onClick={event => {
                                    dispatchAdClkEvent(job, index);
                                    handleClick(event);
                                }}
                                target="_blank">
                                <Typography className={classes.titleV2}>
                                    {ReactHtmlParser(getJobTitle(job))}
                                </Typography>
                                <Button className={classes.ctaButtonV2}>
                                    <ArrowForwardIosIcon />
                                </Button>
                            </Link>
                        ))}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
export default SponsoredMarketplaceContainer;
