import React, { Component } from 'react';
import 'styles/err_page.css';
import TopBar from 'components/common/topBar';
import RoutePrefixHandler from 'common/routePrefixHandler';
import Constants from 'common/constants';
import EventManager from 'eventManager/eventManager';

export default class ErrorPage extends Component {
    render() {
        const route = (window.location.pathname || '').toLowerCase();
        const endPointEventTypeMap = {
            [Constants.PAGE_ENDPOINTS.LANDING]: Constants.EVENT_TYPE.LANDING,
            [Constants.PAGE_ENDPOINTS.SURVEY]: Constants.EVENT_TYPE.SURVEY,
            [Constants.PAGE_ENDPOINTS.ADS]: Constants.EVENT_TYPE.ADS,
            [Constants.PAGE_ENDPOINTS.GRATIFICATION]: Constants.EVENT_TYPE.GRAITIFICATION,
        };
        const eventType = endPointEventTypeMap[route];
        EventManager.sendEvent(Constants.EVENT.ERROR_PG_IMP, {}, eventType);

        return (
            <div className="err-page-wrapper">
                <TopBar />
                <div className="err-page-content-wrapper">
                    <div className="err-page-img-wrapper">
                        <img
                            alt="err-banner"
                            src={RoutePrefixHandler.getImagePath('images/err-banner.svg')}
                        />
                    </div>
                    <div className="err-page-heading">Oops!</div>
                    <div className="err-page-content">Something went wrong!</div>
                </div>
            </div>
        );
    }
}
