import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const AntSwitch = withStyles(theme => ({
    root: {
        width: 42,
        height: 20,
        padding: 0,
        display: 'flex',
        overflow: 'inherit',
        float: 'right',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.common.white,
        '&$checked': {
            transform: 'translateX(20px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 18,
        height: 18,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 20 / 2,
        opacity: 1,
        backgroundColor: '#98a8b6',
    },
    checked: {},
}))(Switch);
