export const FIELD_LABELS = {
    ZIP: 'Zip Code',
    ENTER_ZIP: 'Enter Zip Code',
    KEYWORD: 'Keyword',
    JOB_TITLE: 'Job Title',
    STATE: 'State',
    CITY: 'City',
    LAST_NAME: 'Last Name',
    FIRST_NAME: 'First Name',
    SELECT_JOB_POSITION_HYPHENED: '-- Select Job Position --',
    CONFIRM_EMAIL: 'Confirm Your Email Address',
    REQUIRED_FIELD: 'This field is required',
    INVALID_EMAIL: 'Invalid email',
    SELECT_ONE_PLACE_HOLDER: '-Select One-',
};
