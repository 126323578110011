import Constants from 'common/constants';
import { NetSpendExclusionZipList } from 'common/netSpendExclusionZips';
import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import { ConfigProvider, FeatureMap } from 'providers';
import {
    getFeatureMapValue,
    getFeatureValues,
    getFeatureValuesByCurrentScope,
} from '../featureValueHelpers/getFeatureValues';

import { MacrosHelper } from 'helpers';
import { parseValueToArray, getCurrentRoute } from '../common';

const matchFeature = (feature, value) => {
    let match = false;

    if (feature && !Utils.isEmptyStr(feature.value)) {
        parseValueToArray(feature.value).some(val => {
            if (decodeURIComponent(val) === value) {
                match = true;

                return true;
            }

            return false;
        });
    }

    return match;
};

const matchFeatureByKey = (featureKey, value, scope) => {
    const feature = ConfigProvider.Features.getFeature(featureKey, scope);

    return matchFeature(feature, value);
};

export const matchFeatureByCurrentScope = (featureKey, value, scope = getCurrentRoute()) => {
    return matchFeatureByKey(featureKey, value, scope);
};

export const isDXMLlChannel = () => {
    const channel = (IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM) || '').toLowerCase();

    return channel === 'dxml';
};

export const isDXMLMedium = () => {
    const channel = (IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM) || '').toLowerCase();

    return channel === 'dxml' || channel === 'dxml_api' || dxmlExtFlow();
};

export const customisedColorForSkip = () => {
    return ConfigProvider.Features.getFeature(FeatureMap.CustomisedColorForSkip, 'global');
};

const skipToOfferPathSource = () => {
    const skipToOfferPathSources = ConfigProvider.Features.getFeature(
        FeatureMap.SkipToOfferPathSources,
    );
    const fbAwksModel = ConfigProvider.Features.getFeature(FeatureMap.FBSkipToOffers);

    const source = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE);
    const src = !Utils.isNull(source) && !Utils.isEmptyStr(source) ? source.toLowerCase() : '';

    return matchFeature(skipToOfferPathSources, src) || matchFeature(fbAwksModel, '1');
};

const skipToOfferPathChannel = () => {
    const skipToOfferPathChannel = ConfigProvider.Features.getFeature(
        FeatureMap.SkipToOfferPathChannel,
    );

    const channel = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM) || '';

    return matchFeature(skipToOfferPathChannel, channel);
};

const googleDisplaySkipToOffer = () => {
    const googleDisplaySkipToOfferPath = ConfigProvider.Features.getFeature(
        FeatureMap.GoogleDisplaySkipToOfferPath,
    );

    if (
        googleDisplaySkipToOfferPath &&
        googleDisplaySkipToOfferPath.value &&
        googleDisplaySkipToOfferPath.value === 'skip-to-offers'
    ) {
        return true;
    }

    return false;
};

const fbTrafficSkipToOffer = () =>
    skipToOfferPathSource() || skipToOfferPathChannel() || googleDisplaySkipToOffer();

const oldUsersSkipToOffer = () => {
    if (skipToOfferPathChannel()) {
        return false;
    }

    const oldUserSkipToOfferPath = ConfigProvider.Features.getFeature(
        FeatureMap.OldUserSkipToOfferPath,
    );

    if (
        oldUserSkipToOfferPath &&
        oldUserSkipToOfferPath.value &&
        oldUserSkipToOfferPath.value === 'old-user-skip-to-offers'
    ) {
        return true;
    }

    return false;
};

const skipToOffer = () => fbTrafficSkipToOffer() || oldUsersSkipToOffer();

const oldUserJobcaseOffer = () => {
    if (fbTrafficSkipToOffer()) {
        return false;
    }

    const oldUserJobcaseOfferFeature = ConfigProvider.Features.getFeature(
        FeatureMap.OldUserJobcaseOffer,
    );

    const channel = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM) || '';

    if (isDXMLMedium() || channel === 'dxml_static') {
        return false;
    }

    return matchFeature(oldUserJobcaseOfferFeature, 'repeat-user-jobcase-offer');
};

const enableNotifyZipToOffers = () => {
    const enabledSources = ConfigProvider.Features.getFeature(FeatureMap.NotifyZipToOffers);
    const source = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE);
    const src = !Utils.isNull(source) && !Utils.isEmptyStr(source) ? source.toLowerCase() : '';

    return matchFeature(enabledSources, src);
};

const enableInterstitialOfferPath = () => {
    const interstitialOfferPathFeature = ConfigProvider.Features.getFeature(
        FeatureMap.InterstitialBetweenOfferPath,
    );

    return (
        matchFeature(interstitialOfferPathFeature, 'Interstitial-v1') ||
        matchFeature(interstitialOfferPathFeature, 'Interstitial-v2') ||
        matchFeature(interstitialOfferPathFeature, 'Interstitial-v3')
    );
};

const enableBack2ListingsMidPathOffer = () => {
    const enableListingsToMidPathOffer = ConfigProvider.Features.getFeature(
        FeatureMap.Back2ListingsMidPathRedirection,
    );

    return matchFeature(enableListingsToMidPathOffer, 'redirect-to-offers');
};

const fbGoogleEmailInterstitial = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.FbGoogleEmailInterstitial);

    if (matchFeature(feature, 'fb-email-interstitial')) {
        return true;
    }

    if (googleDisplaySkipToOffer()) {
        return matchFeature(feature, 'google-email-interstitial');
    }

    return false;
};

const multiBrandLogo = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.MultiBrandKWLogo);

    return (
        matchFeature(feature, 'multi-brand-logo') ||
        matchFeature(feature, 'multi-brand-logo-full-time-jobs') ||
        isAmericanAirlinesMultiLogo() ||
        isMultiJobLogos() ||
        multiBrandLander() // Add multiBrandLander abTest
    );
};

const isMultiJobLogos = () => {
    try {
        let multiJobTitleLogoList = multiJobTitleLogos();
        let logoList =
            multiJobTitleLogoList &&
            JSON.parse(multiJobTitleLogoList)['logos'].map(logo => logo.toLowerCase());

        return logoList && logoList.length;
    } catch (error) {
        console.log('Error while fetching logo :', error);

        return false;
    }
};

export const multiJobTitleLogos = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.JobTitleLogoList);

    return feature && !Utils.isEmptyStr(feature.value) && decodeURIComponent(feature.value);
};

const listingsInBgWithZipInEmail = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.ListingsInBg);

    return matchFeature(feature, 'listings_in_bg_with_zipInEmail') || profileCTALander();
};

const noSkipToOffersListingsInBg = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.NoSkipToOffersTest);

    return matchFeature(feature, 'no-skip-to-offers');
};

const listingsInBgWithZip = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.ListingsInBg);
    const restrictListingsInBgWithZip = profileCTALander();

    return (
        (matchFeature(feature, 'listings_in_bg_with_zip') || noSkipToOffersListingsInBg()) &&
        !restrictListingsInBgWithZip
    );
};

const listingsInBgHybridFlow = () => {
    const scope = getCurrentRoute();
    const surveyFeature = ConfigProvider.Features.getFeature(
        FeatureMap.ListingsInBgHybridSurveyFlow,
        scope,
    );
    const offersFeature = ConfigProvider.Features.getFeature(
        FeatureMap.ListingsInBgHybridOffersFlow,
        scope,
    );

    return (
        matchFeature(surveyFeature, 'listings_in_bg_hybrid_flow') ||
        matchFeature(offersFeature, 'listings_in_bg_hybrid_flow')
    );
};

const listingsInBgMidpathOffers = () => {
    const scope = getCurrentRoute();
    const feature = ConfigProvider.Features.getFeature(FeatureMap.ListingsInBgMidpathOffers, scope);

    return matchFeature(feature, 'listings_in_bg_midpath_offers');
};

const listingsInBgLandingFlow = () =>
    xmlFullRegFlowTest() || listingsInBgWithZipInEmail() || listingsInBgWithZip();

const showListingsBgXMLTestV2 = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.ListingsBgXmlStaticImageTest);

    return matchFeature(feature, 'true');
};

export const Reg3BrandedLander = () => matchFeatureByKey(FeatureMap.Reg3BrandedLander, 'variant');

export const Reg3BrandedLanderAA = () =>
    matchFeatureByKey(FeatureMap.Reg3BrandedLander, 'variant-1');

export const BrandedLanderTestv1 = () => matchFeatureByKey(FeatureMap.BRANDED_LANDER, 'v1');
export const BrandedLanderTestv2 = () => matchFeatureByKey(FeatureMap.BRANDED_LANDER, 'v2');
export const BrandedWarmUpLanderTest = () =>
    matchFeatureByKey(FeatureMap.BRANDED_LANDER_REG0, 'v3');
export const BrandedSurveyLanderTest = () =>
    matchFeatureByKey(FeatureMap.BRANDED_LANDER_REG0, 'v2');
export const BrandedBenefitsLanderTest = () =>
    matchFeatureByKey(FeatureMap.BRANDED_LANDER_REG0, 'v1');
export const BrandedReg0LanderTest = () =>
    BrandedWarmUpLanderTest() || BrandedSurveyLanderTest() || BrandedBenefitsLanderTest();

export const BrandedLanderTest = () => BrandedLanderTestv1() || BrandedLanderTestv2();

export const BrandedLanderSurveyTest = () =>
    BrandedLanderSurveyTestV1() || BrandedLanderSurveyTestV2();
export const BrandedLanderSurveyTestV1 = () =>
    matchFeatureByKey(FeatureMap.BRANDED_LANDER_SURVEY_FULL_IMG, '1');
export const BrandedLanderSurveyTestV2 = () =>
    matchFeatureByKey(FeatureMap.BRANDED_LANDER_SURVEY_HALF_IMG, '1');

export const BrandedLanderOfferTest = () =>
    BrandedLanderOfferTestV1() || BrandedLanderOfferTestV2();
export const BrandedLanderOfferTestV1 = () =>
    matchFeatureByKey(FeatureMap.BRANDED_LANDER_OFFER_FULL_IMG, '1');
export const BrandedLanderOfferTestV2 = () =>
    matchFeatureByKey(FeatureMap.BRANDED_LANDER_OFFER_HALF_IMG, '1');

export const BrandedLanderListingsTest = () =>
    matchFeatureByKey(FeatureMap.BRANDED_LANDER_LISTINGS, '1');

export const Reticular_Branded_JTPreference = () => matchFeatureByKey(FeatureMap.SHOW_JT_LOGO, '1');

export const eduUpperSkip = () => matchFeatureByKey(FeatureMap.EduUpperSkip, '1');
export const eduLowerSkip = () => matchFeatureByKey(FeatureMap.EduLowerSkip, '1');
export const eduSkipColor = () => {
    return ConfigProvider.Features.getFeature(FeatureMap.EduSkipColor, 'global');
};
export const eduSkipText = () => {
    return ConfigProvider.Features.getFeature(FeatureMap.EduSkipText, 'global');
};

export const staticImgListingsBgTest = () =>
    matchFeatureByKey(FeatureMap.ListingsBgStaticImageTest, 'variant');

export const getStaticListingsBgImgs = () => {
    const featureVal = getFeatureMapValue(FeatureMap.ListingsBgStaticImages, 'global');
    const staticBgImgs = Utils.jsonSafeParse(decodeURIComponent(featureVal));

    const staticMobileImg = (staticBgImgs && staticBgImgs['mobile']) || '';
    const staticTabletImg = (staticBgImgs && staticBgImgs['tablet']) || '';
    const staticPCImg = (staticBgImgs && staticBgImgs['pc']) || '';

    const staticBgObj = {
        mobile: staticMobileImg,
        tablet: staticTabletImg,
        web: staticPCImg,
    };

    return staticBgObj;
};

const xmlListingsInBgWithoutZip = () => {
    return matchFeatureByKey(FeatureMap.XMLListingBgSkipWithoutZip, '1');
};

const listingsInBgFlow = () =>
    xmlFullRegFlowTest() ||
    listingsInBgLandingFlow() ||
    listingsInBgHybridFlow() ||
    listingsInBgMidpathOffers() ||
    showListingsBgXMLTestV2() ||
    xmlListingsInBgWithoutZip() ||
    emailPreferencePageTest();

const listingsInBgPrelanderKw = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.ListingsInBgPrelanderKw);

    return matchFeature(feature, 'listings_in_bg_prelander_kw');
};

export const emailPreferencePageTest = () => {
    return window.location.pathname === Constants.PAGE_ENDPOINTS.EMAIL_PREFERENCE;
};

const googleProgressBarTest = () => {
    if (googleDisplaySkipToOffer() || rgKeywordTest()) {
        return false;
    }

    const feature = ConfigProvider.Features.getFeature(FeatureMap.GoogleProgressBarTest);

    if (feature && feature.value && feature.value === 'googleProgressBar') {
        return true;
    }

    return false;
};

const listingsInBgWithZipUpwardOpt = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.ListingsInBgWithZipUpwardOpt);

    return matchFeature(feature, 'reg2-optin');
};

const adWorksExternalNotificationPushEnabled = () => {
    const isExternalFeatureEnabled = ConfigProvider.Features.getFeature(FeatureMap.PushNotifier);
    let lrdrFromUrl = Utils.getValForKeyFromCurrentUrl('lrdr');

    return (
        matchFeature(isExternalFeatureEnabled, 'external') &&
        !Utils.isNull(lrdrFromUrl) &&
        !Utils.isEmptyStr(lrdrFromUrl) &&
        lrdrFromUrl.includes('landing')
    );
};

const reg2Optimize = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.Reg2Optimize);

    return matchFeature(feature, 'Reg2-Opt');
};

const reg2OptMobile = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.Reg2OptMobile);

    return matchFeature(feature, 'Reg2-Opt-Mobile');
};

const reg2GreyCta = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.Reg2GreyedCTA);

    return matchFeature(feature, 'Reg2-Greyed-CTA');
};

const surveyQueVaraition = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.SurveyQueVaraition);

    return matchFeature(feature, 'SurveyQueAge');
};

const puzzSurveyVariation1 = () => matchFeatureByKey(FeatureMap.PuzzSurveyVariation, 'puzzVar1');
const puzzSurveyVariation2 = () => matchFeatureByKey(FeatureMap.PuzzSurveyVariation, 'puzzVar2');
const puzzSurveyVariation3 = () => matchFeatureByKey(FeatureMap.PuzzSurveyVariation, 'puzzVar3');
const puzzSurveyVariation4 = () => matchFeatureByKey(FeatureMap.PuzzSurveyVariation, 'puzzVar4');

const CtaRegTick = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.CtaRegTick);

    return matchFeature(feature, 'circle-tick');
};

const jobcaseCoregUI = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.JobcaseCoregUI);

    return matchFeature(feature, 'jobcasecoreg-variation');
};

const repeatUser = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.RepeatUser);

    return matchFeature(feature, 'repeatuser-variation');
};

const aibemLander = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.AIBEMLander);

    return matchFeature(feature, 'aibem-lander');
};

const rgKeywordTest = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.RgKeyword);

    return (
        matchFeature(feature, 'rg_kw_flow') ||
        (matchFeature(feature, 'rg_kw_google_flow') && googleDisplaySkipToOffer())
    );
};

const xmlPartTimeBg = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.XMLPartTimeBG);

    return matchFeature(feature, 'white-bg');
};

const kwSelectorRGLandingPageTest = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.KWSelectorRGLandingPageTest);

    return matchFeature(feature, 'kw-selector-rg-landing');
};

const prePopKwSelectorLanderTest = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.PrePopKWSelectorLanderTest);

    return matchFeature(feature, 'pre-pop-kw-selector');
};

const googleKWSelectorLandingPage = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.GoogleKWSelectorLandingPage);

    return matchFeature(feature, 'google_search');
};

const googleKWSelectorLanderMapping = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.GoogleKWSelectorLanderMapping);

    return feature && !Utils.isEmptyStr(feature.value) && decodeURIComponent(feature.value);
};

const upwardEmailAndRegPageOptimization = () => {
    const feature = ConfigProvider.Features.getFeature(
        FeatureMap.UpwardEmailAndRegPageOptimization,
    );

    return matchFeature(feature, 'page_optimization');
};

const allInboxSMSLander = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.AllInboxSMSLander);

    return matchFeature(feature, '1');
};

const isRGDropdownLander = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.RGDropdownLander);

    return matchFeature(feature, 'dropdown_view');
};
const eduQuestionVar1 = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.EduQuestionVar);

    return matchFeature(feature, 'edu-que-var1');
};

const eduQuestionVar2 = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.EduQuestionVar);

    return matchFeature(feature, 'edu-que-var2');
};

const isAWKSMultiBrandLander = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.AWKSMultiBrandLander);

    return matchFeature(feature, 'multi_brand_logo');
};

const getAWKSMultiBrandLanderLogos = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.AWKSMultiBrandLanderLogos);

    return feature && !Utils.isEmptyStr(feature.value) && decodeURIComponent(feature.value);
};

const isSeniorJobsFacebookTest = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.SeniorJobsFacebook);

    return matchFeature(feature, 'senior_jobs_facebook');
};

const isSeniorJobsFacebookTestWithoutZip = () =>
    matchFeatureByKey(FeatureMap.SeniorJobsFacebookWithoutZip, '1');

const isSeniorJobsFacebook = () =>
    isSeniorJobsFacebookTest() || isSeniorJobsFacebookTestWithoutZip();

const multiBrandLander = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.MultiBrandLander);

    return matchFeature(feature, '1');
};

const upwardEmailOptinTest = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.UpwardEmailOptInTest);

    return matchFeature(feature, 'no-email-optin-upward');
};

const isListingsInBGWithWhiteBGTest = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.ListingsInBGWithWhiteBGTest);

    return matchFeature(feature, 'white_bg');
};

const isSnapchatMultiBrandLander = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.SnapchatMultiBrand);

    return matchFeature(feature, 'multi_brand_logo');
};

const getSnapchatMultiBrandLogos = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.SnapchatMultiBrandLogoList);

    return feature && !Utils.isEmptyStr(feature.value) && decodeURIComponent(feature.value);
};

const showArrowForResultsGeneration = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.ArrowForResultsGeneration);

    return matchFeature(feature, 'show_arrow');
};

const showOvalCTA = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.OvalCta);

    return matchFeature(feature, 'oval-cta-variant');
};

export const governmentLogoValue = () => {
    const logo = getFeatureValues(FeatureMap.GovernmentLogo);

    return logo[0] || '';
};

const showGovtLogosUpward = () => matchFeatureByKey(FeatureMap.UpwardGovernment, 'variant');

const showGovtLogosAwks = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.GovernmentLogoAwks);

    return matchFeature(feature, 'government');
};

export const displayGovtAirportLogosAwks39 = () =>
    matchFeatureByKey(FeatureMap.DisplayJobsLogoAwks39, 'display_jobs_logo_awks_39');

export const hideJobAlertOnSurveyPath = () => {
    const listingsBgReg2Skipped =
        skipReg2Page() ||
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LISTING_BG_REG2_SKIPPED) ||
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_ALERT_SURVEY_PATH_LANDING_SKIP);
    const zip2OfferNewUser = skipToOfferPathSource();

    const showOnSkipLbgReg2 = jobAlertOnSurveyPath() && listingsBgReg2Skipped;
    const showOnZip2Offer = jobAlertOnSurveyPath() && zip2OfferNewUser;
    const isJobAlertHidden = !(showOnSkipLbgReg2 || showOnZip2Offer);

    return isJobAlertHidden;
};

export const jobAlertOnSurveyPath = () =>
    matchFeatureByKey(FeatureMap.JobAlertSurveyPath, 'variant');

const showGovtLogoAwks39 = () => {
    const governmentLogo = getFeatureValues(FeatureMap.GovernmentLogoAwks39);

    return governmentLogo[0] || '';
};

const showAirportLogoAwks39 = () => {
    const airportLogo = getFeatureValues(FeatureMap.AirportLogoAwks39);

    return airportLogo[0] || '';
};

export const warmUpHeaderTitle = () => {
    const warmUpHeaderTitle = getFeatureValues(FeatureMap.WarmUpHeaderTitle);

    return warmUpHeaderTitle[0] || '';
};

export const warmUpDisplayHourlyPay = () =>
    matchFeatureByKey(FeatureMap.WarmUpDisplayHourlyPay, '1');

const pushOptInPlacementTest = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.PushOptInPlacementTest);

    return matchFeature(feature, 'push-optin-placement');
};

const getPushOptInPlacementValue = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.PushOptInPlacement);

    return feature && !Utils.isEmptyStr(feature.value) && decodeURIComponent(feature.value);
};

const rgStaticKwImages = () =>
    matchFeature(ConfigProvider.Features.getFeature(FeatureMap.RgStaticKwImage), '1');

const getStarbucksKwToExclude = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.StarbucksKwToExclude);

    return feature && !Utils.isEmptyStr(feature.value) && decodeURIComponent(feature.value);
};

const showAmericanAirlineRg = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.AmericanAirlinesRG);

    return matchFeature(feature, 'american-airlines-rg');
};

const exploreJobsZipOptimized = () => matchFeatureByKey(FeatureMap.JobsZipOptimized, '1');
export const backBtnInterstitialPulseCta = () =>
    matchFeatureByKey(FeatureMap.BACK_BUTTON_INSTERSTITIAL_PULSE_CTA, '1');

const kwDropDowLander = () =>
    matchFeatureByKey(FeatureMap.KwdDropdownLander, '1') && !exploreJobsZipOptimized();

const repeatUserListingBg = () => matchFeatureByKey(FeatureMap.RepeatUserListingsBg, '1');

const rgBenefitStrip = () => matchFeatureByKey(FeatureMap.RgBenefitStrip, '1');

const prePopFlowV2 = () => matchFeatureByKey(FeatureMap.PrePopFlowV2, '1');

export const prePopFlowV3 = () => matchFeatureByKey(FeatureMap.PrePopFlowV3, '1');

const regThreeTest = () => {
    const restrictRegThreeTest = netSpendZipExclusionRestrictions();

    return matchFeatureByKey(FeatureMap.RegThree, 'new-reg3-screen') && !restrictRegThreeTest;
};

const regThreeFlowV1 = () => matchFeatureByKey(FeatureMap.RegThree, 'reg-three-flow-v1');

const regThreeFlowV2 = () => {
    const restrictRegThreeFlow = netSpendZipExclusionRestrictions();

    return matchFeatureByKey(FeatureMap.RegThree, 'reg-three-flow-v2') && !restrictRegThreeFlow;
};

const listingsBgRegThreeFlowV1 = () =>
    matchFeatureByKey(FeatureMap.ListingsBgRegThreeV1, 'listings-bg-reg-three-flow-v1');

const listingsBgRegThreeFlowV2 = () => {
    const restrictListingsBgRegThreeFlowV2 = netSpendZipExclusionRestrictions();

    return (
        matchFeatureByKey(FeatureMap.ListingsBgRegThreeV2, 'listings-bg-reg-three-flow-v2') &&
        !restrictListingsBgRegThreeFlowV2
    );
};

const listingBgRegThreeTest = () => listingsBgRegThreeFlowV1() || listingsBgRegThreeFlowV2();

const hideReg1Skip = () => matchFeatureByKey(FeatureMap.HideReg1Skip, '1');

export const ProgressBarTestV1 = () => matchFeatureByKey(FeatureMap.ProgressBarTest, '1');

export const ProgressBarTestV2 = () => matchFeatureByKey(FeatureMap.ProgressBarTest, '2');

export const ProgressBarTestV3 = () => matchFeatureByKey(FeatureMap.ProgressBarTest, '3');

export const ProgressBarTest = () =>
    ProgressBarTestV1() || ProgressBarTestV2() || ProgressBarTestV3();

const skipButtonTest = () => matchFeatureByKey(FeatureMap.SkipButtonTest, '1');

const subjectwellCreativeTest = () => matchFeatureByKey(FeatureMap.SubjectwellCreativeTest, '1');

const regThreeFlow = () => regThreeFlowV2() || regThreeFlowV1();

const disableExitInterstitial = () => matchFeatureByKey(FeatureMap.DisableExitInterstitial, '1');

const removeExitInterstitial = () => matchFeatureByKey(FeatureMap.RemoveExitInterstitial, '1');

const skipToOffersNewTabV1 = () =>
    matchFeatureByKey(FeatureMap.SkipToOffersNewTab, 's2nt-v1') && !hideReg1Skip();

const hideZipPage = () => matchFeatureByKey(FeatureMap.HideZipPage, '1');

const upwardZipInEmail = () => matchFeatureByKey(FeatureMap.UpwardZipInEmail, '1');

export const subjectWellSkipInBlue = () => matchFeatureByKey(FeatureMap.SubjectWellSkipInBlue, '1');

const skipReg2Page = () => {
    const restrictSkipReg2 = netSpendZipExclusionRestrictions();

    return matchFeatureByKey(FeatureMap.SkipReg2Page, '1') && !restrictSkipReg2;
};

const LpOptimizationTestUpdate = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.SeniorJobsFacebook);

    return matchFeature(feature, 'senior_jobs_notify');
};

const isFulfillmentPageNewDesign = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.FulfillmentPageNewDesign);

    return matchFeature(feature, 'fulfillment_page_new_design');
};

const makeSkipBlack = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.SkipBlack);

    return matchFeature(feature, 'skip_black');
};

const zipAndEmailLandingTest = () => matchFeatureByKey(FeatureMap.ZipAndEmailLanding, '1');

const showSkipConfirmInterstitial = () =>
    matchFeatureByKey(FeatureMap.ShowSkipConfirmInterstitial, '1');

export const enableJtKwMappingForJobCategorySurvey = () =>
    matchFeatureByKey(FeatureMap.EnableJtKwMappingForJobCategorySurvey, '1');

export const summerJobsLogoTest = () => matchFeatureByKey(FeatureMap.SummerJobsLogo, '1');

export const disclosurePositionTest = () =>
    matchFeatureByKey(FeatureMap.DisclosurePositionTest, '1');

const isGoogleDisplayLander = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.GoogleDisplayLander);

    return (
        matchFeature(feature, 'variant_one') ||
        matchFeature(feature, 'variant_two') ||
        matchFeature(feature, 'variant_three')
    );
};

const getGoogleDisplayLander = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.GoogleDisplayLander);

    return feature && !Utils.isEmptyStr(feature.value) && decodeURIComponent(feature.value);
};

export const kwAsListingsLanderV1 = () => matchFeatureByKey(FeatureMap.KwAsListingsLander, 'v1');

export const kwAsListingLanderV2 = () => matchFeatureByKey(FeatureMap.KwAsListingsLander, 'v2');

export const kwAsListingsLander = () => kwAsListingsLanderV1() || kwAsListingLanderV2();

const netSpendZipExclusion = () => {
    const zipCodeFed = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE);

    return zipCodeFed && zipCodeFed !== 'null' && !NetSpendExclusionZipList.includes(zipCodeFed);
};

export const netSpendZipExclusionTest = () =>
    matchFeatureByKey(FeatureMap.ExclusionZipTest, 'exclusion-zip-test');

export const netSpendZipExclusionBoth = () =>
    netSpendZipExclusionTest() || matchFeatureByKey(FeatureMap.ExclusionZipTest, 'control');

export const netSpendExclusionAndZipTest = () =>
    netSpendZipExclusionTest() && netSpendZipExclusion();

export const netSpendZipExclusionRestrictions = () => {
    return netSpendZipExclusionTest() ? netSpendExclusionAndZipTest() : false;
};

export const enableSurveyQueOnRegV1 = () => matchFeatureByKey(FeatureMap.EnableSurveyQueOnReg, '1');
export const enableSurveyQueOnRegV2 = () => matchFeatureByKey(FeatureMap.EnableSurveyQueOnReg, '2');

export const isUpwardBottomAlignedInterstitial = () =>
    matchFeatureByKey(FeatureMap.UpwardBottomAlignedInterstitial, 'bottom-aligned');

export const upwardRemoveGenderFieldTest = () =>
    matchFeatureByKey(FeatureMap.UpwardRemoveGenderField, 'removing_gender');

export const listingsBGRemoveGenderTest = () =>
    matchFeatureByKey(FeatureMap.ListingsBGRemoveGenderField, '1');

export const controlFlowRemoveGenderTest = () =>
    matchFeatureByKey(FeatureMap.ControlFlowRemoveGenderField, '1');

export const preferencePopupTest = () => matchFeatureByKey(FeatureMap.PreferencePopupTest, '1');
export const subjectwellCreativeTestV2Variant1 = () =>
    matchFeatureByKey(FeatureMap.SubjectwellCreativeTestV2, '1');

export const subjectwellCreativeTestV2Variant2 = () =>
    matchFeatureByKey(FeatureMap.SubjectwellCreativeTestV2, '2');

export const employmentStatusTest = () => matchFeatureByKey(FeatureMap.EmploymentStatusTest, '1');

export const googleKwSelectorTest = () =>
    matchFeatureByKey(FeatureMap.GoogleKWSelectorLandingPage, 'google_kw_select');

const noJobCategoryNeuvooCreativeTest = () =>
    matchFeatureByKey(
        FeatureMap.JobCategoryQuestionNeuvooCreative,
        'no-job-category-neuvoo-creative',
    );
const skipControlFlowTest = () =>
    matchFeatureByKey(FeatureMap.SkipControlFlow, 'skip-control-flow') &&
    !matchFeatureByKey(FeatureMap.ListingsInBg, 'listings_in_bg_with_zipInEmail');
export const isAmericanAirlinesMultiLogo = () =>
    matchFeatureByKey(FeatureMap.AmericanAirlinesMultiLogo, 'american-airlines-multi-logo');
export const isRgGrocery = () =>
    matchFeatureByKey(FeatureMap.GroceryStoreVariant, 'grocery-store-variant');
export const isFulfillmentDesignPhaseTwo = () =>
    matchFeatureByKey(FeatureMap.FulfillmentDesignPhaseTwo, 'phase-two');

export const profileCTALander = () => matchFeatureByKey(FeatureMap.ProfileCTALander, '1');
export const isSkipToOfferNewTabReg2 = () =>
    matchFeatureByKey(FeatureMap.SkipToOfferNewTabReg2, 'skip-to-offer-new-tab-reg2');
export const isSkipToOfferNeTabPGMTCAndBing = () =>
    matchFeatureByKey(FeatureMap.SkipToOfferNeTabPGMTCAndBing, 'skip-to-offers-new-tab');

export const emailAlertsCTA = () => matchFeatureByKey(FeatureMap.ListingEmailAlerts, '1');

const prePopUpdatedLandingPageTest = () =>
    matchFeatureByKey(FeatureMap.PrePopUpdatedLandingPage, 'landing-page-updated');

export const prePopUpdatedReg2Test = () => matchFeatureByKey(FeatureMap.PrePopUpdatedReg2, '1');

export const isShowPremiumJobProgressBar = () =>
    matchFeatureByKey(FeatureMap.PremiumJobProgressBar, 'show-progress-bar') &&
    Utils.checkMobileDevice();

export const changeCtaForUpward = () =>
    matchFeatureByKey(FeatureMap.ChangeCtaForUpward, 'change-cta');
export const isRemoveLogoFromReg2 = () =>
    matchFeatureByKey(FeatureMap.RemoveLogoFromReg2, 'remove-logo') && Utils.checkMobileDevice();

export const hideJobCategoryQuestion = () =>
    matchFeatureByKey(FeatureMap.HideJobCategoryQuestion, '1');

export const showJobCategoryTest = () => matchFeatureByKey(FeatureMap.ShowJobCategoryTest, '1');

export const upwardJtAsUtmTerm = () => matchFeatureByKey(FeatureMap.HideJobCategoryQuestion, '1');

export const showKwIntentSupport = () => matchFeatureByKey(FeatureMap.ShowKwIntentSupport, '1');

const surveyPathHeaderTest = () =>
    matchFeatureByKey(FeatureMap.SurveyPathHeader, 'show-survey-header');
export const aibFieldPlacementTestControl = () =>
    matchFeatureByKey(FeatureMap.EmailPlacementTest, 'control');
export const aibFieldPlacementTestVariant = () =>
    matchFeatureByKey(FeatureMap.EmailPlacementTest, 'variant');
export const aibFieldPlacementTest = () =>
    aibFieldPlacementTestControl() || aibFieldPlacementTestVariant();

const isRegisteredUserSkipReg1Test = () =>
    matchFeatureByKey(FeatureMap.RegisteredUserSkipReg1, 'variant');

export const setS2oFlagTest = () => matchFeatureByKey(FeatureMap.SetS2oFlag, 'set_s2o_flag');

export const olduserTCPAoptinTest = () =>
    matchFeatureByKey(FeatureMap.OlduserTCPAoptin, 'old_user_tcpa_optin');

export const brandedOfferAmazonTestVariant1 = () =>
    matchFeatureByKey(FeatureMap.AmazonBrandFlow, 'variant-1');
export const brandedOfferAmazonTestVariant2 = () =>
    matchFeatureByKey(FeatureMap.AmazonBrandFlow, 'variant-2');
export const brandedOfferAmazonTestJobSelectionV2 = () =>
    matchFeatureByKey(FeatureMap.AmazonBrandFlowJobSelection, 'v2');
export const brandedOfferAmazonControlFlow = () =>
    matchFeatureByKey(FeatureMap.AmazonBrandFlow, 'control');
const brandedOfferAmazonTest = () =>
    brandedOfferAmazonTestVariant1() || brandedOfferAmazonTestVariant2();

const brandedOfferFedExTestVariant1 = () =>
    matchFeatureByKey(FeatureMap.FedExBrandFlow, 'variant-1');
export const brandedOfferFedExTestVariant2 = () =>
    matchFeatureByKey(FeatureMap.FedExBrandFlow, 'variant-2');
export const brandedOfferFedExControlFlow = () =>
    matchFeatureByKey(FeatureMap.FedExBrandFlow, 'control');
const brandedOfferFedExTest = () =>
    brandedOfferFedExTestVariant1() || brandedOfferFedExTestVariant2();

const brandedOfferVariant1 = () => matchFeatureByKey(FeatureMap.BrandLanderTest, 'variant-1');
export const brandedOfferVariant2 = () =>
    matchFeatureByKey(FeatureMap.BrandLanderTest, 'variant-2');
export const brandedOfferControl = () => matchFeatureByKey(FeatureMap.BrandLanderTest, 'control');

const brandedOfferTest = () => brandedOfferVariant1() || brandedOfferVariant2();
const brandedLanderSkipWhenFailed = () => matchFeatureByKey(FeatureMap.SkipLanderWhenFailed, '1');

const newBrandedLanderTest = () => matchFeatureByKey(FeatureMap.BrandLanderV2, '1');

const skipRegPages = () => matchFeatureByKey(FeatureMap.SkipRegPages, '1');

const LoaderInterstitialTest = () => matchFeatureByKey(FeatureMap.LoaderInterstitialTest, '1');

export const skipToNewTabV3 = () =>
    matchFeatureByKey(FeatureMap.LeaveBehindDxmlExtInterstitial, 'variant');

export const skipToNewTabV3Control = () =>
    matchFeatureByKey(FeatureMap.LeaveBehindDxmlExtInterstitial, 'control');
export const isSkipPositioningTest = () =>
    matchFeatureByKey(FeatureMap.SkipPositioningTest, 'increase-skip-size');

export const shouldFireLpImp2 = () =>
    skipToNewTabV2Test() ||
    skipToNewTabV2Control() ||
    brandedOfferAmazonControlFlow() ||
    brandedOfferFedExControlFlow() ||
    amazonBrandedLanderV2Control() ||
    brandedOfferControl();

export const skipToNewTabV2Control = () =>
    matchFeatureByKey(FeatureMap.LeaveBehindTestV2, 'control');
export const skipToNewTabV2Test = () => matchFeatureByKey(FeatureMap.LeaveBehindTestV2, 'variant');
export const skipToNewTabV2 = () =>
    matchFeatureByKey(FeatureMap.LeaveBehindTestV2, 'variant') &&
    !Utils.getValForKeyFromCurrentUrl('s2nt');

export const RgJtKwTestVariant1 = () => matchFeatureByKey(FeatureMap.RgJtKwTest, 'variant-1');
export const RgJtKwTestVariant2 = () => matchFeatureByKey(FeatureMap.RgJtKwTest, 'variant-2');

const RgJtKwTest = () => RgJtKwTestVariant1() || RgJtKwTestVariant2();
export const showStaticJL = () => matchFeatureByCurrentScope(FeatureMap.ShowStaticJL, '1');
export const showNativeJL = () => matchFeatureByCurrentScope(FeatureMap.SERPNativeAd, '1');
export const ShowTipBelowSERP = () => matchFeatureByKey(FeatureMap.ShowTipBelowSERP, '1');

const facebookHalloweenJobsTest = () =>
    matchFeatureByKey(FeatureMap.FacebookHalloweenJobs, 'variant');

const disableBackButtonBehaviour = () =>
    matchFeatureByCurrentScope(FeatureMap.DisableBackButtonBehaviourListingsPage, '1');
export const dxmlExtFlow = () => {
    const channel = (IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM) || '').toLowerCase();

    return channel === 'dxml_ext';
};

export const kwJtConfigIntegration = () => matchFeatureByKey(FeatureMap.KwJtConfigIntegration, '1');

const upwardRevampTest = () => matchFeatureByKey(FeatureMap.UpwardRevampTest, '1');

export const amazonBandedLanderV2 = () =>
    matchFeatureByKey(FeatureMap.AmazonBrandFlowV2, 'variant');

export const amazonBrandedLanderV2Control = () =>
    matchFeatureByKey(FeatureMap.AmazonBrandFlowV2, 'control');

export const CTAVariationBlue = () => matchFeatureByKey(FeatureMap.CTAVariationBlue, '1');

export const phoneNoPlacementTest = () => matchFeatureByKey(FeatureMap.PhoneNoPlacementTest, '1');

const warmUpQuestionTest = () => matchFeatureByKey(FeatureMap.WarmUpQuestionTest, 'variant');

export const getReg1Headers = () => {
    const aggrHeaderList = getFeatureValues(FeatureMap.AggressiveHeadersList);
    const headersObjList = Utils.jsonSafeParse(aggrHeaderList);

    const reg1Heading = MacrosHelper.replaceMacroParamsStringMemoized(
        Utils.getObjVal(headersObjList, [0, 'heading1']) || '',
    );
    const reg1SubHeading = MacrosHelper.replaceMacroParamsStringMemoized(
        Utils.getObjVal(headersObjList, [0, 'subheading1']) || '',
    );
    const reg1Cta = MacrosHelper.replaceMacroParamsStringMemoized(
        Utils.getObjVal(headersObjList, [0, 'cta']) || '',
    );
    const reg1HeaderObj = { heading: reg1Heading, subheading: reg1SubHeading, cta: reg1Cta };

    return reg1HeaderObj;
};

export const getReg2Headers = () => {
    const aggrHeaderList = getFeatureValues(FeatureMap.AggressiveHeadersList);
    const headersObjList = Utils.jsonSafeParse(aggrHeaderList);

    const reg2Heading = MacrosHelper.replaceMacroParamsStringMemoized(
        Utils.getObjVal(headersObjList, [1, 'heading2']) || '',
    );
    const reg2SubHeading = MacrosHelper.replaceMacroParamsStringMemoized(
        Utils.getObjVal(headersObjList, [1, 'subheading2']) || '',
    );
    const reg2Cta = MacrosHelper.replaceMacroParamsStringMemoized(
        Utils.getObjVal(headersObjList, [1, 'cta']) || '',
    );
    const reg2HeaderObj = { heading: reg2Heading, subheading: reg2SubHeading, cta: reg2Cta };

    return reg2HeaderObj;
};

export const getCustomQuestionsList = () => {
    const customQuestions = [];
    const customQuestionFeature = ConfigProvider.Features.getFeature(
        FeatureMap.customQuestionsListMJC,
    );

    const customQuestionsList =
        customQuestionFeature && customQuestionFeature.value
            ? customQuestionFeature.value.split(',')
            : [];
    customQuestions.push(...customQuestionsList);

    return customQuestions;
};

export const getReg3Headers = () => {
    const aggrHeaderList = getFeatureValues(FeatureMap.AggressiveHeadersList);
    const headersObjList = Utils.jsonSafeParse(aggrHeaderList);

    const reg3Heading = MacrosHelper.replaceMacroParamsStringMemoized(
        Utils.getObjVal(headersObjList, [2, 'heading3']) || '',
    );
    const reg3SubHeading = MacrosHelper.replaceMacroParamsStringMemoized(
        Utils.getObjVal(headersObjList, [2, 'subheading3']) || '',
    );
    const reg3Cta = MacrosHelper.replaceMacroParamsStringMemoized(
        Utils.getObjVal(headersObjList, [2, 'cta']) || '',
    );
    const reg3HeaderObj = { heading: reg3Heading, subheading: reg3SubHeading, cta: reg3Cta };

    return reg3HeaderObj;
};

export const aggressiveRegPgHeading = () =>
    matchFeatureByKey(FeatureMap.AggressiveRegPgHeading, 'variant') || aggressiveRegPgHeadingV2();

export const aggressiveRegPgHeadingV2 = () =>
    matchFeatureByKey(FeatureMap.AggressiveRegPgHeading, 'variant-v2');

export const warmUpWithoutZip = () => matchFeatureByKey(FeatureMap.WarmUpWithoutZip, '1');

export const kwBenefitsLander = () => matchFeatureByKey(FeatureMap.KwBenefitsLander, '1');

export const XmlPopUpTestVariant1 = () => matchFeatureByKey(FeatureMap.XmlPopUp, 'variant-1');
export const XmlPopUpTestVariant2 = () => matchFeatureByKey(FeatureMap.XmlPopUp, 'variant-2');
const xmlPopUpTest = () => XmlPopUpTestVariant1() || XmlPopUpTestVariant2();
const listingsBGModelChangeDesktop = () =>
    matchFeatureByKey(FeatureMap.ListingsBGDesktopModelChange, 'variant') &&
    [Constants.PAGE_ENDPOINTS.LANDING, Constants.PAGE_ENDPOINTS.SURVEY].includes(
        window.location.pathname,
    );

const taboolaAmazonLanderTest = () => matchFeatureByKey(FeatureMap.TaboolaAmazonLander, 'variant');

export const getGoogleSSOClientId = () => {
    const clientId = getFeatureValues(FeatureMap.GoogleSSOClientId);

    return clientId[0] || '';
};

const GoogleSSOLoginTest = () => {
    const googleDisplaySkipToOfferPath = ConfigProvider.Features.getFeature(
        FeatureMap.GoogleDisplaySkipToOfferPath,
    );

    const client_id = getGoogleSSOClientId();

    if (
        googleDisplaySkipToOfferPath &&
        googleDisplaySkipToOfferPath.value &&
        googleDisplaySkipToOfferPath.value === 'control' &&
        !Utils.isEmptyStr(client_id) &&
        matchFeatureByKey(FeatureMap.GoogleSSOLogin, 'variant')
    ) {
        return true;
    }

    return false;
};

const emailOnLanderTest = () => matchFeatureByKey(FeatureMap.EmailOnLanderTest, 'variant');
const motiveInteractiveTest = () => matchFeatureByKey(FeatureMap.MotiveInteractiveTest, '1');
const neuvooWidgetTest = () => matchFeatureByKey(FeatureMap.NeuvooWidgetTest, '1');

export const motiveInteractiveTestIos = () =>
    matchFeatureByKey(FeatureMap.MotiveInteractiveTestOS, 'ios');
export const motiveInteractiveTestAndroid = () =>
    matchFeatureByKey(FeatureMap.MotiveInteractiveTestOS, 'android');

export const prePropCollapse = () =>
    matchFeatureByKey(FeatureMap.PrePopReg1AndReg2CollapsedFields, 'pre_pop');

export const getRestrictNotificationConfig = () => {
    const restrictNotificationConfig = ConfigProvider.Features.getFeature(
        FeatureMap.RestrictNotificationConfig,
    );

    if (
        restrictNotificationConfig &&
        restrictNotificationConfig.value &&
        restrictNotificationConfig.type === 'json'
    ) {
        return JSON.parse(decodeURIComponent(restrictNotificationConfig.value));
    }

    return false;
};
export const fireHnpCatUserLead = () => {
    const source = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE);
    const sourceListRegisteredUserFeature = ConfigProvider.Features.getFeature(
        FeatureMap.SourceListForTCPARegisteredUsers,
    );
    const featureValue =
        sourceListRegisteredUserFeature && sourceListRegisteredUserFeature.value
            ? sourceListRegisteredUserFeature.value
            : '';

    const includedSourceListForRegisteredUser = featureValue && featureValue.split(',');
    const isRegistered = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GTM_IS_REGISTERED) === '1';

    if (!isRegistered || includedSourceListForRegisteredUser.includes(source)) {
        return true;
    }

    return false;
};
export const dxmlNewLanderTest = () => matchFeatureByKey(FeatureMap.DxmlExtNewLander, 'variant');
export const dxmlNewLanderTestV2 = () =>
    matchFeatureByKey(FeatureMap.DxmlExtNewLander, 'variant-2');

export const dxmlNewLander = () => dxmlNewLanderTest() || dxmlNewLanderTestV2();

export const amazonLanderInterstitial = () =>
    matchFeatureByKey(FeatureMap.AmazonBrandedInterstitial, 'variant');

export const genericKeywordLander = () =>
    matchFeatureByKey(FeatureMap.GenericKeywordLander, 'generic_kw_lander');

export const getGenericKeywordMap = () => {
    const genericKeywordMap = ConfigProvider.Features.getFeature(FeatureMap.GenericKeywordMap);

    if (genericKeywordMap && genericKeywordMap.value && genericKeywordMap.type === 'json') {
        return JSON.parse(decodeURIComponent(genericKeywordMap.value));
    }

    return false;
};

export const repeatUserAPIFlow = () => repeatUserAPIFlowV1() || repeatUserAPIFlowV2();

export const repeatUserAPIFlowV1 = () =>
    matchFeatureByKey(FeatureMap.APIRepeatUserFlow, 'variant-v1');
export const repeatUserAPIFlowV2 = () =>
    matchFeatureByKey(FeatureMap.APIRepeatUserFlow, 'variant-v2');

export const hideCheckBoxOptSkipLink = () =>
    matchFeatureByKey(FeatureMap.HideCheckBoxOptSkipLink, '1');

export const newSERPTest = () => getFeatureValuesByCurrentScope(FeatureMap.MJC_SERP_NEW)[0] === '1';

export const darkSERPTestV1 = () =>
    getFeatureValuesByCurrentScope(FeatureMap.DarkSerpTest)[0] === '1';

export const darkSERPTestV2 = () =>
    getFeatureValuesByCurrentScope(FeatureMap.DarkSerpTest)[0] === '2';

export const darkSERPTest = () => darkSERPTestV1() || darkSERPTestV2();

export const semiPrepopReg1Test = () => matchFeatureByKey(FeatureMap.SemiPrepopReg1, '1');

export const hardRedirectOnInit = () => matchFeatureByKey(FeatureMap.HardRedirectOnInit, '1');

export const hideLogo = hashIndex => {
    const featureValue = getFeatureValuesByCurrentScope(FeatureMap.RemoveLogoRegScreen);

    if (!iconAboveHeader() && featureValue && featureValue.length > 0) {
        const hideLogoFeatureValue = JSON.parse(featureValue[0]);

        if (
            hideLogoFeatureValue &&
            hideLogoFeatureValue.hide_logo &&
            hideLogoFeatureValue.hide_logo === '1'
        ) {
            if (hideLogoFeatureValue && hideLogoFeatureValue.reg_screens) {
                const regIndexesToHide = hideLogoFeatureValue.reg_screens.split(',');

                return regIndexesToHide.includes(hashIndex.toString());
            }
        } else {
            return false;
        }
    }

    return false;
};

export const skipOnRegHeader = () => matchFeatureByKey(FeatureMap.SkipOnRegHeader, '1');

export const fbClidReplacement = () => matchFeatureByKey(FeatureMap.FbclidReplacement, '1');

export const skipOnUpwardOptimizedPage = () =>
    matchFeatureByKey(FeatureMap.SkipOnUpwardPageOptimization, '1');

const verizonLander = () => matchFeatureByCurrentScope(FeatureMap.VerizonLander, '1');

export const regThreeUIV2 = () => matchFeatureByKey(FeatureMap.RegThreeUIV2, '1');

export const warmUpWeekTest = () => matchFeatureByKey(FeatureMap.WarmUpWithinWeek, 'variant');

export const taboolaKeywordSpecificLanderTrucker = () =>
    matchFeatureByKey(FeatureMap.TaboolaKeywordSpecificLanderTrucker, '1');
export const taboolaKeywordSpecificLanderProduct = () =>
    matchFeatureByKey(FeatureMap.TaboolaKeywordSpecificLanderProduct, '1');
export const taboolaKeywordSpecificLanderAmazon = () =>
    matchFeatureByKey(FeatureMap.TaboolaKeywordSpecificLanderAmazon, '1');
export const taboolaKeywordSpecificLander = () =>
    taboolaKeywordSpecificLanderTrucker() ||
    taboolaKeywordSpecificLanderProduct() ||
    taboolaKeywordSpecificLanderAmazon();
export const googleSeniorJobsLander = () =>
    matchFeatureByKey(FeatureMap.GoogleSeniorJobsLander, '1');
export const googleOnlineJobsLander = () =>
    matchFeatureByKey(FeatureMap.GoogleOnlineJobsLander, '1');

export const dynamicMultiBrandLogo = () =>
    matchFeatureByKey(FeatureMap.DynamicMultiBrandKWLogo, 'DynamicMultiBrandKWLogo');
export const listingsAsLandingFlow = () => matchFeatureByKey(FeatureMap.ListingsAsLandingFlow, '1');

export const eduQuestionTest = () => eduQuestionTestV1() || eduQuestionTestV2();

export const eduQuestionTestV1 = () => matchFeatureByKey(FeatureMap.EduQuestionTest, 'v1');
export const eduQuestionTestV2 = () => matchFeatureByKey(FeatureMap.EduQuestionTest, 'v2');

export const disregardJobType = () => matchFeatureByKey(FeatureMap.DisregardJobType, '1');

export const eduSurveyQuestionUXV3 = () =>
    matchFeatureByKey(FeatureMap.EduQuestion, 'dropdown-button-hybrid');

export const eduSurveyQuestionUXV2 = () =>
    matchFeatureByKey(FeatureMap.EduQuestion, 'dropdown-test-v2');

export const eduOptinSelectorV1 = () => matchFeatureByKey(FeatureMap.EduQuestion, 'selector-v1');

export const eduOptinCheckboxV1 = () => matchFeatureByKey(FeatureMap.EduQuestion, 'checkbox-v1');

export const eduOptinDropDownSingleClickV1 = () =>
    matchFeatureByKey(FeatureMap.EduQuestion, 'dropdown-single-click-v1');

export const eduSurveyQuestionTest = () =>
    eduSurveyQuestionUXV2() ||
    eduSurveyQuestionUXV3() ||
    eduOptinSelectorV1() ||
    eduOptinCheckboxV1() ||
    eduOptinDropDownSingleClickV1();

export const disableBackButtonListings = () =>
    matchFeatureByKey(FeatureMap.DisableBackButtonListings, '1');

export const greyTcpaUncheckedButton = () =>
    matchFeatureByKey(FeatureMap.GREY_BUTTON_TCPA_UNCHECKED, '1');

export const skipUserRegEvents = () => matchFeatureByKey(FeatureMap.SkipUserReg, '1');

export const enableSms2Click = () => matchFeatureByCurrentScope(FeatureMap.EnableSms2Click, '1');

export const talrooAPIKwTest = () => matchFeatureByKey(FeatureMap.TalrooAPIKwTest, '1');

export const talrooAdRelatedSearchesTest = () =>
    matchFeatureByKey(FeatureMap.TalrooAdRelated, 'search');
export const talrooAdRelatedPositionsTest = () =>
    matchFeatureByKey(FeatureMap.TalrooAdRelated, 'position');
export const talrooAdRelatedTest = () =>
    talrooAdRelatedSearchesTest() || talrooAdRelatedPositionsTest();
export const talrooWidgetTest = () => matchFeatureByKey(FeatureMap.TALROO_WIDGET, '1');
export const serpRevampV1 = () => matchFeatureByKey(FeatureMap.MJC_SERP_REVAMP, 'v1');

export const serpRevampV2 = () => matchFeatureByKey(FeatureMap.MJC_SERP_REVAMP, 'v2');

export const serpRevamp = () => serpRevampV1() || serpRevampV2();

export const ConfirmationPageTest = () => matchFeatureByKey(FeatureMap.CONFIRMATION_PAGE_MJC, '1');

export const ConfirmationPageTestV2 = () =>
    matchFeatureByKey(FeatureMap.CONFIRMATION_PAGE_MJC, '2');

export const ConfirmationPageTestV3 = () =>
    matchFeatureByKey(FeatureMap.CONFIRMATION_PAGE_MJC, '3');

export const brandedKeySelectorv2 = () =>
    matchFeatureByKey(FeatureMap.ListingsInBgPrelanderKw, 'variant-2');

export const brandedKeySelectorv3 = () =>
    matchFeatureByKey(FeatureMap.ListingsInBgPrelanderKw, 'variant-3');

export const brandedKeySelector = () => brandedKeySelectorv2() || brandedKeySelectorv3();

export const dobHeaderReg = () => matchFeatureByKey(FeatureMap.MJC_DOB_HEADER, 'variant');

export const spChangeQuestionUi = () => matchFeatureByKey(FeatureMap.MJC_SP_CHANGE, 'v2');

const regPageTextChangesPDMO2 = () => matchFeatureByKey(FeatureMap.PDMO2_COPY_CHANGE, '0');

const regPageTextChangesPDMO2Variant = () => matchFeatureByKey(FeatureMap.PDMO2_COPY_CHANGE, '1');

export const repeatUserv3 = () => matchFeatureByKey(FeatureMap.REPEAT_USER_V3, '1');
export const removeRegTopStrip = () => matchFeatureByKey(FeatureMap.REMOVE_TOP_STRIP, '1');
export const addictedAffiliateTest = () => matchFeatureByKey(FeatureMap.ADDICTED_AFFILIATE_29, '1');

export const PrePopFlowTestV4 = () => matchFeatureByKey(FeatureMap.PrePopFlow, 'v4');

export const PrePopFlowTestV5 = () => matchFeatureByKey(FeatureMap.PrePopFlow, 'v5');

export const prePopFlowTest = () => PrePopFlowTestV4() || PrePopFlowTestV5();

export const SwapZipFieldAddress = () =>
    matchFeatureByKey(FeatureMap.SwapZipFieldAddress, 'variant');

const hideDOBField = () => matchFeatureByKey(FeatureMap.HideDOBField, '1');

export const mulltiBrandLanderV1 = () =>
    matchFeatureByKey(FeatureMap.Generic_Multibrand_Lander, 'v1');
export const mulltiBrandLanderV2 = () =>
    matchFeatureByKey(FeatureMap.Generic_Multibrand_Lander, 'v2');

export const skipToNewTabV4 = () => skipToNewTabV4V1() || skipToNewTabV4V2();

export const skipToNewTabV4V1 = () => matchFeatureByKey(FeatureMap.SkipToNewTabTestV4, 'v1');
export const skipToNewTabV4V2 = () => matchFeatureByKey(FeatureMap.SkipToNewTabTestV4, 'v2');

export const skipToNewTabV4AuNewStrategy = () =>
    matchFeatureByCurrentScope(FeatureMap.SkipToNewTabTestV4AuCallNewStrategy, '1');

export const iconAboveHeader = () => matchFeatureByKey(FeatureMap.ICON_ABOVE_HEADER, '1');

export const dfShouldValidateIframeHTML = () =>
    matchFeatureByKey(FeatureMap.DEV_FEATURE_SHOULD_VALIDATE_IFRAME_HTML, '1');

export const skipTextChange = () => matchFeatureByKey(FeatureMap.MJC_LANDING_SKIP_TEXT_CHANGE, '1');

export const newCtaTestv1 = () => matchFeatureByKey(FeatureMap.MJC_NEW_CTA_TEST_PDMO, 'v1');

export const newCtaTestv2 = () => matchFeatureByKey(FeatureMap.MJC_NEW_CTA_TEST_PDMO, 'v2');
export const zipLanderTestv1 = () => matchFeatureByKey(FeatureMap.ZIP_LANDER_NEW, 'v1');
export const zipLanderTestv2 = () => matchFeatureByKey(FeatureMap.ZIP_LANDER_NEW, 'v2');
export const zipLanderTestv3 = () => matchFeatureByKey(FeatureMap.ZIP_LANDER_NEW, 'v3');

export const emailSubmitPageTestv1 = () => matchFeatureByKey(FeatureMap.EMAIL_SUBMIT_PAGE, 'v1');
export const emailSubmitPageTestv2 = () => matchFeatureByKey(FeatureMap.EMAIL_SUBMIT_PAGE, 'v2');

export const emailSubmitPageTest = () => emailSubmitPageTestv1() || emailSubmitPageTestv2();

export const zipLanderTestv = () => zipLanderTestv1() || zipLanderTestv2() || zipLanderTestv3();
export const NewDxmlStaticPubTest = () => matchFeatureByKey(FeatureMap.NEW_DXML_STATIC_PUB, '1');

export const lightGradientBg = () => matchFeatureByKey(FeatureMap.LightGradientBg, '1');
export const warmupDropdownTestV1 = () =>
    matchFeatureByKey(FeatureMap.WARMUP_QUESTION_DROPDOWN, 'v1');

export const warmupDropdownTestV2 = () =>
    matchFeatureByKey(FeatureMap.WARMUP_QUESTION_DROPDOWN, 'v2');

export const warmupDropdownTest = () => warmupDropdownTestV1() || warmupDropdownTestV2();

export const SeasonalJobLandingv1 = () =>
    matchFeatureByKey(FeatureMap.MJC_SEASONAL_JOBS_LANDING_PAGE, 'v1') || SeasonalJobLandingNewv1();
export const SeasonalJobLandingv2 = () =>
    matchFeatureByKey(FeatureMap.MJC_SEASONAL_JOBS_LANDING_PAGE, 'v2') || SeasonalJobLandingNewv2();
export const SeasonalJobLandingv3 = () =>
    matchFeatureByKey(FeatureMap.MJC_SEASONAL_JOBS_LANDING_PAGE, 'v3');

export const SeasonalJobLandingNewv1 = () =>
    matchFeatureByKey(FeatureMap.MJC_SEASONAL_JOBS_LANDING_PAGE, 'New v1');
export const SeasonalJobLandingNewv2 = () =>
    matchFeatureByKey(FeatureMap.MJC_SEASONAL_JOBS_LANDING_PAGE, 'New v2');

export const newSeasonalLander = () => SeasonalJobLandingNewv1() || SeasonalJobLandingNewv2();

export const SeasonalJobLanding = () =>
    SeasonalJobLandingv1() || SeasonalJobLandingv2() || SeasonalJobLandingv3();
export const ShowWarmupQuestionv1 = () => matchFeatureByKey(FeatureMap.MJC_WARM_UP_QUESTION, 'v1');
export const ShowWarmupQuestionv2 = () => matchFeatureByKey(FeatureMap.MJC_WARM_UP_QUESTION, 'v2');
export const ShowWarmupQuestion = () => ShowWarmupQuestionv1() || ShowWarmupQuestionv2();

export const isNewFallbackFeature = () => matchFeatureByKey(FeatureMap.NEW_FALLBACK_LOGIC, '1');
export const interstitialAdUnitTestv1 = () =>
    matchFeatureByKey(FeatureMap.INTERSTITIAL_AD_UNIT, 'v1');
export const interstitialAdUnitTestv2 = () =>
    matchFeatureByKey(FeatureMap.INTERSTITIAL_AD_UNIT, 'v2');
export const interstitialAdUnitTestv3 = () =>
    matchFeatureByKey(FeatureMap.INTERSTITIAL_AD_UNIT, 'v3');
export const interstitialAdUnitTestv4 = () =>
    matchFeatureByKey(FeatureMap.INTERSTITIAL_AD_UNIT, 'v4');
export const interstitialAdUnitTestv5 = () =>
    matchFeatureByKey(FeatureMap.INTERSTITIAL_AD_UNIT, 'v5');
export const interstitialAdUnitTest = () =>
    interstitialAdUnitTestv1() ||
    interstitialAdUnitTestv2() ||
    interstitialAdUnitTestv3() ||
    interstitialAdUnitTestv4() ||
    interstitialAdUnitTestv5();

export const xmlFullRegFlowTestV1 = () => matchFeatureByKey(FeatureMap.XML_FULL_REG_FLOW, 'v1');
export const xmlFullRegFlowTestV2 = () => matchFeatureByKey(FeatureMap.XML_FULL_REG_FLOW, 'v2');
export const xmlFullRegFlowTest = () => xmlFullRegFlowTestV1() || xmlFullRegFlowTestV2();
export const TcpaPageNewUserTest = () =>
    matchFeatureByKey(FeatureMap.TCPA_PAGE_NEW_USER, 'varaint');

export const showHPText = () => matchFeatureByKey(FeatureMap.showHPText, '1');

export const hideSkipForBrandedFlow = () =>
    matchFeatureByKey(FeatureMap.BRANDED_FLOW_SKIP_HIDE, '1');
export const hideSkipForBrandedFlowV2 = () =>
    matchFeatureByKey(FeatureMap.BRANDED_FLOW_SKIP_HIDE, '2');
export const hideSkipForBrandedFlowHideBoth = () =>
    matchFeatureByKey(FeatureMap.BRANDED_FLOW_SKIP_HIDE, '0');
export const ShowAdUnitUrlTest = () => matchFeatureByKey(FeatureMap.SHOW_AD_UNIT_URL, '1');
export const showZipBrandedFlowv1 = () => matchFeatureByKey(FeatureMap.BRANDED_ZIP_PAGE, 'v1');
export const showZipBrandedFlowv2 = () => matchFeatureByKey(FeatureMap.BRANDED_ZIP_PAGE, 'v2');
export const showZipBrandedFlow = () => showZipBrandedFlowv1() || showZipBrandedFlowv2();

export const showAdDescription = () => matchFeatureByKey(FeatureMap.AD_DESCRIPTION_TOGGLE, '1');

export const repeatUserTest = () => matchFeatureByKey(FeatureMap.REPEAT_USER, '1');
export const isSkipToOfferNewTabReg1 = () =>
    matchFeatureByKey(FeatureMap.skipToOfferNewTabReg1, '1');

export const isSkipToOfferNewTabReg3 = () =>
    matchFeatureByKey(FeatureMap.skipToOfferNewTabReg3, '1');

export const enableH1Text = () => matchFeatureByKey(FeatureMap.ENABLE_H1_TEXT, '1');
export const enableH2Text = () => matchFeatureByKey(FeatureMap.enableH2Text, '1');

export const metaTagTestv1 = () => matchFeatureByKey(FeatureMap.MJC_META_TAGS, 'v1');
export const metaTagTestv2 = () => matchFeatureByKey(FeatureMap.MJC_META_TAGS, 'v2');
export const metaTagTest = () => metaTagTestv1() || metaTagTestv2();
export const backBtn2Offer = () => matchFeatureByKey(FeatureMap.BACK_BUTTON_2_OFFER, '1');

export const ShowTabOverLanding = () => matchFeatureByKey(FeatureMap.SHOW_TAB_OVER_LANDING, '1');

export const popularKeywordsLanderTestv1 = () =>
    matchFeatureByKey(FeatureMap.PopularKeywordsLander, 'v1');
export const popularKeywordsLanderTestv2 = () =>
    matchFeatureByKey(FeatureMap.PopularKeywordsLander, 'v2');
export const popularKeywordsLanderTestv3 = () =>
    matchFeatureByKey(FeatureMap.PopularKeywordsLander, 'v3');

export const popularKeywordsLanderTest = () =>
    popularKeywordsLanderTestv1() || popularKeywordsLanderTestv2() || popularKeywordsLanderTestv3();

export const semiPrePopSkipColor = () => {
    return ConfigProvider.Features.getFeature(FeatureMap.SemiPrePopSkipColor, 'global');
};
export const semiPrePopSkipText = () => {
    return ConfigProvider.Features.getFeature(FeatureMap.SemiPrePopSkipText, 'global');
};

export const amazonLanderStaticAdId = () =>
    matchFeatureByKey(FeatureMap.AMAZON_LANDER_STATIC_AD_CALL, '1');

export const staticTaboverLander = () =>
    matchFeatureByKey(FeatureMap.STATIC_DYNAMIC_TABOVER_LANDER, 'static');
export const dynamicTaboverLander = () =>
    matchFeatureByKey(FeatureMap.STATIC_DYNAMIC_TABOVER_LANDER, 'dynamic');

export const staticDynamicTaboverLander = () => staticTaboverLander() || dynamicTaboverLander();

export const SponsoredSERPCtaV1 = () => matchFeatureByKey(FeatureMap.Sponsored_SERP_CTA, 'v1');
export const SponsoredSERPCtaV2 = () => matchFeatureByKey(FeatureMap.Sponsored_SERP_CTA, 'v2');
export const SponsoredSERPCtaV3 = () => matchFeatureByKey(FeatureMap.Sponsored_SERP_CTA, 'v3');
export const SponsoredSERPCtaTest = () =>
    SponsoredSERPCtaV1() || SponsoredSERPCtaV2() || SponsoredSERPCtaV3();

export {
    // Atomic flags
    hideZipPage,
    hideReg1Skip,
    regThreeTest,
    skipReg2Page,
    showSkipConfirmInterstitial,
    // Feature Flows
    zipAndEmailLandingTest,
    skipToOffersNewTabV1,
    upwardZipInEmail,
    regThreeFlow,
    regThreeFlowV1,
    regThreeFlowV2,
    disableExitInterstitial,
    removeExitInterstitial,
    isSeniorJobsFacebookTestWithoutZip,
    isSeniorJobsFacebook,
    prePopFlowV2,
    rgBenefitStrip,
    exploreJobsZipOptimized,
    repeatUserListingBg,
    kwDropDowLander,
    rgStaticKwImages,
    multiBrandLander,
    xmlListingsInBgWithoutZip,
    adWorksExternalNotificationPushEnabled,
    skipToOfferPathSource,
    skipToOfferPathChannel,
    oldUsersSkipToOffer,
    fbTrafficSkipToOffer,
    skipToOffer,
    oldUserJobcaseOffer,
    enableNotifyZipToOffers,
    fbGoogleEmailInterstitial,
    enableInterstitialOfferPath,
    googleDisplaySkipToOffer,
    enableBack2ListingsMidPathOffer,
    multiBrandLogo,
    listingsInBgWithZipInEmail,
    noSkipToOffersListingsInBg,
    listingsInBgWithZip,
    listingsInBgHybridFlow,
    listingsInBgMidpathOffers,
    listingsInBgLandingFlow,
    listingsInBgFlow,
    listingsInBgPrelanderKw,
    googleProgressBarTest,
    listingsInBgWithZipUpwardOpt,
    showListingsBgXMLTestV2,
    aibemLander,
    surveyQueVaraition,
    jobcaseCoregUI,
    rgKeywordTest,
    xmlPartTimeBg,
    googleKWSelectorLandingPage,
    googleKWSelectorLanderMapping,
    repeatUser,
    kwSelectorRGLandingPageTest,
    reg2Optimize,
    prePopKwSelectorLanderTest,
    upwardEmailAndRegPageOptimization,
    allInboxSMSLander,
    isRGDropdownLander,
    eduQuestionVar1,
    eduQuestionVar2,
    isAWKSMultiBrandLander,
    getAWKSMultiBrandLanderLogos,
    isSeniorJobsFacebookTest,
    upwardEmailOptinTest,
    isListingsInBGWithWhiteBGTest,
    isSnapchatMultiBrandLander,
    getSnapchatMultiBrandLogos,
    showArrowForResultsGeneration,
    pushOptInPlacementTest,
    getPushOptInPlacementValue,
    getStarbucksKwToExclude,
    showAmericanAirlineRg,
    showOvalCTA,
    LpOptimizationTestUpdate,
    isFulfillmentPageNewDesign,
    makeSkipBlack,
    listingsBgRegThreeFlowV1,
    listingsBgRegThreeFlowV2,
    listingBgRegThreeTest,
    reg2OptMobile,
    reg2GreyCta,
    isGoogleDisplayLander,
    getGoogleDisplayLander,
    noJobCategoryNeuvooCreativeTest,
    skipControlFlowTest,
    prePopUpdatedLandingPageTest,
    showGovtLogosUpward,
    showGovtLogosAwks,
    surveyPathHeaderTest,
    isRegisteredUserSkipReg1Test,
    brandedOfferAmazonTest,
    skipRegPages,
    RgJtKwTest,
    brandedOfferFedExTest,
    facebookHalloweenJobsTest,
    disableBackButtonBehaviour,
    upwardRevampTest,
    CtaRegTick,
    warmUpQuestionTest,
    xmlPopUpTest,
    listingsBGModelChangeDesktop,
    taboolaAmazonLanderTest,
    GoogleSSOLoginTest,
    emailOnLanderTest,
    motiveInteractiveTest,
    neuvooWidgetTest,
    puzzSurveyVariation1,
    puzzSurveyVariation2,
    puzzSurveyVariation3,
    puzzSurveyVariation4,
    brandedOfferTest,
    newBrandedLanderTest,
    brandedLanderSkipWhenFailed,
    showGovtLogoAwks39,
    showAirportLogoAwks39,
    LoaderInterstitialTest,
    verizonLander,
    skipButtonTest,
    regPageTextChangesPDMO2,
    subjectwellCreativeTest,
    regPageTextChangesPDMO2Variant,
    hideDOBField,
};
