import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import Constants from 'common/constants';
import { ConfigProvider } from '../index';

export default class FeatureSets {
    constructor(data) {
        this.initialize(data);
    }

    /**
     * Initialize Features in the application, leverage on caching
     */
    async initialize(data) {
        if (Utils.isNull(data)) {
            await ConfigProvider.initialize();
            data = ConfigProvider.data;
        }

        return data.featureSets === null ? false : this.set(data.featureSets);
    }

    /**
     * Set Feature Sets values in cache
     * @param {*} featureSets feature sets list
     *
     */
    set(featureSets) {
        if (!Array.isArray(featureSets)) return false;

        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.FEATURE_SETS, JSON.stringify(featureSets));

        return true;
    }

    /**
     * Get Feature Sets
     * @param {*} featureSets feature sets list
     */
    get() {
        return (
            (IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.FEATURE_SETS) &&
                Utils.jsonSafeParse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.FEATURE_SETS))) ||
            []
        );
    }
}
