import { IdStore, LocalStoreManager } from 'dataStore';
import Constants from 'common/constants';
import Utils from 'common/utils';
import { ErrLogger } from 'errLogger';
import { getFlavor } from 'flavors.macro';
import DateUtils from 'common/DateUtils';
import EventManager from 'eventManager/eventManager';
import { EVENT_CONSTANTS } from 'eventManager/common';
import { StringHelper } from 'helpers';
import { AbTestHelper } from 'helpers/index';

const EVENT_TYPE_MAPPING = {
    '/landing': Constants.EVENT_TYPE.LANDING,
    '/survey': Constants.EVENT_TYPE.SURVEY,
    '/offers': Constants.EVENT_TYPE.ADS,
    '/listings': Constants.EVENT_TYPE.GRAITIFICATION,
};

class TargetingManager {
    fetchUserTargetingParams() {
        /*
         * These are the user targeting params details.
         * user {
         * public id?: string; // This is the user-id
         * public dob?: Date;
         * public geo?: Geo;
         * public age?: number;
         * public gender?: string;
         * }
         */

        // Create the user targeting params
        let userTargetingParams = {};

        // user-id
        const userId = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_ID);

        if (false === Utils.isNull(userId)) {
            userTargetingParams['id'] = userId;
        }

        // dob
        const flavor = getFlavor('layout-theme');
        const userDataStr = LocalStoreManager.getItem(`${flavor}_${Constants.USER.STORE_USER_KEY}`);

        if (false === Utils.isNull(userDataStr)) {
            try {
                const userData = JSON.parse(userDataStr);

                if (false === Utils.isNull(userData) && false === Utils.isNull(userData['dob'])) {
                    // Convert the dob format
                    userTargetingParams['dob'] = DateUtils.convertDateBetweenFormats(
                        userData['dob'],
                        Constants.USER.DOB_USER_INPUT_DATE_FORMAT,
                        Constants.USER.DOB_API_DATE_FORMAT,
                    );
                }
            } catch (e) {
                ErrLogger.logStr('Error parsing user-data in targeting params');
            }
        }

        // Fetch the geo details
        /*
         * Geo format -
         * public lat?: number;
         * public lon?: number;
         * public state?: string;
         * public country?: string;
         * public city?: string
         */
        let geo = {};

        let userDetails =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS) &&
            JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS));
        // city, country, region
        let city = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_CITY);

        if (false === Utils.isNull(city)) {
            geo['city'] = city;
        }

        let country = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_COUNTRY);

        if (false === Utils.isNull(country)) {
            geo['country'] = country;
        }

        let region = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_REGION);

        if (false === Utils.isNull(region)) {
            geo['state'] = region;
            geo['region'] = region;
        }
        let zip = '';

        if (userDetails && userDetails.zip) {
            zip = userDetails.zip;
        } else {
            zip = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE);
        }

        geo['zip'] = zip;

        if (userDetails && userDetails.gender) {
            userTargetingParams['gender'] = userDetails.gender === 'male' ? 1 : 2;
        }

        if (userDetails && userDetails.email) {
            userTargetingParams['email'] = userDetails.email;
        }

        if (false === Utils.isEmptyObj(geo)) {
            userTargetingParams['geo'] = geo;
        }

        return userTargetingParams;
    }

    /**
     * Returns targeting params available as per the params provided.
     * @param {object} params Params to use in creating targeting params object.
     * @param {object} [params.extraParams] Extra params to add in addition to default extras to extras targeting.
     * @param {object} [params.userParams] User data params to add in addition to default user data to user targeting.
     * @param {object} [params.geoParams] Geo/location params to add in addition to default geo data to geo targeting.
     * @param {boolean} [params.addEventExtras] If to add eventExtras(events object) to targeting.
     * @returns {{ extras: object; user: object }} Targeting obj created
     */
    fetchTargetingParams(params) {
        // new parameters can be passed to override stored values if needed
        /*
         * These are the targeting params details.
         * user {
         * public id?: string; // This is the user-id
         * public dob?: Date;
         * public geo?: Geo;
         * public age?: number;
         * public gender?: string;
         * },
         * extras: {
         * // Any key value
         * }
         */
        const extraParams = (params && params.extraParams) || {};
        const userParams = (params && params.userParams) || {};
        const geoParams = (params && params.geoParams) || {};
        const addEventExtras = (params && params.addEventExtras) || false;
        let targetingParams = {};

        targetingParams['user'] = this.fetchUserTargetingParams();
        // Create the user targeting params first
        let userTargetingParams = {};
        let extras = {};

        // user-id
        const userId = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_ID);

        if (false === Utils.isNull(userId)) {
            userTargetingParams['id'] = userId;
            extras[Constants.ID_STORE_KEYS.USER_ID] = userId;
        }

        // dob
        const flavor = getFlavor('layout-theme');
        const userDataStr = LocalStoreManager.getItem(`${flavor}_${Constants.USER.STORE_USER_KEY}`);
        let piiFound = false;
        let isPrePopulated = false;

        if (false === Utils.isNull(userDataStr)) {
            try {
                const userData = JSON.parse(userDataStr);

                if (false === Utils.isNull(userData)) {
                    if (false === Utils.isNull(userData['dob'])) {
                        // Convert the dob format
                        userTargetingParams['dob'] = DateUtils.convertDateBetweenFormats(
                            userData['dob'],
                            Constants.USER.DOB_USER_INPUT_DATE_FORMAT,
                            Constants.USER.DOB_API_DATE_FORMAT,
                        );
                    }

                    if (false === Utils.isNull(userData['evs'])) {
                        extras[Constants.ADS.EXTRAS.EMAIL_VERIFICATION] = userData['evs'];
                    }

                    piiFound =
                        false === Utils.isNull(userData['email']) &&
                        false === Utils.isNull(userData['first_name']) &&
                        false === Utils.isNull(userData['last_name']);
                    isPrePopulated =
                        (userData[Constants.USER_DETAILS_KEY.FIRST_NAME] &&
                            userData[Constants.USER_DETAILS_KEY.LAST_NAME] &&
                            userData[Constants.USER_DETAILS_KEY.EMAIL] &&
                            userData[Constants.USER_DETAILS_KEY.PHONE] &&
                            userData[Constants.USER_DETAILS_KEY.DOB] &&
                            userData[Constants.USER_DETAILS_KEY.ADDRESS]) ||
                        Utils.hasNonEmptyPIIFieldsFromUrl();
                }
            } catch (e) {
                ErrLogger.logStr('Error parsing user-data in targeting params');
            }
        }
        const locale = Utils.getValForKeyFromCurrentUrl('lng');

        if (locale) {
            extras['locale'] = locale;
        }
        extras[Constants.ID_STORE_KEYS.PII_FOUND] = piiFound ? 1 : 0;
        extras[Constants.ID_STORE_KEYS.PRE_POP] = isPrePopulated ? 1 : 0;
        extras[Constants.ID_STORE_KEYS.PHONE_VERIFICATION] = IdStore.fetchIdForKey(
            Constants.ID_STORE_KEYS.PHONE_VERIFICATION,
        );
        extras['userStatus'] = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS);
        extras['userStatusCurrent'] = IdStore.fetchIdForKey(
            Constants.ID_STORE_KEYS.USER_STATUS_CURRENT,
        );

        const isSub = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS_IS_SUBSCRIBED);
        const isReg = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS_IS_REGISTERED);

        if (isSub !== null && isSub !== undefined) extras['is_sub'] = `${isSub}`;

        if (isReg !== null && isSub !== undefined) extras['is_reg'] = `${isReg}`;

        extras['kwcat'] = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.KW_CAT);

        const jobDetails =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_DETAILS) &&
            JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_DETAILS));

        if (jobDetails && jobDetails.trafficCategory) {
            extras['trafficCategory'] = encodeURIComponent(jobDetails.trafficCategory);
        }

        // Fetch the geo details
        /*
         * Geo format -
         * public lat?: number;
         * public lon?: number;
         * public state?: string;
         * public country?: string;
         * public city?: string
         */
        let geo = {};

        let userDetails = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS)
            ? JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS))
            : {};
        // city, country, region
        let city = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_CITY);

        if (false === Utils.isNull(city)) {
            geo['city'] = city;
        }

        let country = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_COUNTRY);

        if (false === Utils.isNull(country)) {
            geo['country'] = country;
        }

        let region = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_REGION);

        if (false === Utils.isNull(region)) {
            geo['state'] = region;
            geo['region'] = region;
        }
        let zip = '';

        if (userDetails && userDetails.zip) {
            zip = userDetails.zip;
        } else {
            zip = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE);
        }

        geo['zip'] = zip;

        if (userDetails) {
            userTargetingParams['gender'] = userDetails.gender === 'male' ? 1 : 2;
        }

        if (userDetails && userDetails.email) {
            userTargetingParams['email'] = userDetails.email;
        }

        if (false === Utils.isEmptyObj(geoParams)) {
            // override/append geo parameters if passed
            geo = {
                ...geo,
                ...geoParams,
            };
        }

        if (false === Utils.isEmptyObj(geo)) {
            userTargetingParams['geo'] = geo;
        }

        if (false === Utils.isEmptyObj(userParams)) {
            // override/append user parameters if passed
            userTargetingParams = {
                ...userTargetingParams,
                ...userParams,
            };
        }

        targetingParams['user'] = userTargetingParams;

        // Extras
        let sid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SURVEY_ID);

        if (false === Utils.isNull(sid)) {
            extras[Constants.ADS.EXTRAS.SURVEY_ID] = sid;
        }

        let surveyPath = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SURVEY_PATH_ID);

        if (false === Utils.isNull(surveyPath)) {
            extras[Constants.ADS.EXTRAS.SURVEY_PATH_ID] = surveyPath;
        }

        let deviceId = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.DEVICE_ID);

        if (false === Utils.isNull(deviceId)) {
            extras[Constants.ADS.EXTRAS.DEVICE_ID] = deviceId;
        }

        let landerId = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LANDER_ID);

        if (false === Utils.isNull(landerId)) {
            extras[Constants.ADS.EXTRAS.LANDER_ID] = landerId;
        }

        if (window.location.pathname === Constants.PAGE_ENDPOINTS.ADS) {
            extras['placement'] = 'regpath';
        }

        // Add AB test params
        extras = {
            ...extras,
            ...this.setABParam(extras),
        };

        const employerTypeVal = IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL);
        const jobType = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);
        const ljt = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LANDING_JT);
        const lkw = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LANDING_KEYWORD);

        if (
            !Utils.isEmptyStr(ljt) ||
            !Utils.isEmptyStr(employerTypeVal) ||
            !Utils.isEmptyStr(jobType)
        ) {
            extras[Constants.ID_STORE_KEYS.JOB_TYPE_VAL] = employerTypeVal || ljt || jobType;
        }

        if (!Utils.isEmptyStr(lkw)) {
            extras[Constants.ID_STORE_KEYS.LANDING_KEYWORD] = lkw;
        }

        if (!Utils.isEmptyStr(ljt)) {
            extras[Constants.ID_STORE_KEYS.LANDING_JT] = ljt;
        }

        let userIP = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUBLIC_IP);

        if (Utils.isNull(userIP)) {
            userIP = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_IP);
        }

        if (false === Utils.isNull(userIP)) {
            extras[Constants.ADS.EXTRAS.USER_IP] = userIP;
        } else {
            extras[Constants.ADS.EXTRAS.USER_IP] = '103.210.201.170';
        }

        let utmSource = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE);

        if (false === Utils.isNull(utmSource)) {
            extras[Constants.ADS.EXTRAS.UTM_SOURCE] = utmSource;
            extras[Constants.ADS.EXTRAS.UTM_SOURCE_G] = utmSource;
        }

        let utmCampaign = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_CAMPAIGN);

        if (false === Utils.isNull(utmCampaign)) {
            extras[Constants.ADS.EXTRAS.UTM_CAMPAIGN] = utmCampaign;
            extras[Constants.ADS.EXTRAS.UTM_CAMPAIGN_G] = utmCampaign;
        }

        let utmMedium = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM);

        if (false === Utils.isNull(utmMedium)) {
            extras[Constants.ADS.EXTRAS.UTM_MEDIUM] = utmMedium;
            extras[Constants.ADS.EXTRAS.UTM_MEDIUM_G] = utmMedium;
        }

        let utmContent = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_CONTENT);

        if (false === Utils.isNull(utmContent)) {
            extras[Constants.URL_ARGS.UTM_CONTENT] = utmContent;
            extras[Constants.URL_ARGS.UTM_CONTENT_G] = utmContent;
        }

        let utmTerm = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM);

        if (false === Utils.isNull(utmTerm)) {
            extras[Constants.ADS.EXTRAS.UTM_TERM] = utmTerm;
        }

        const urlParams = Utils.getCurrentUrlParams();

        if (!Utils.isEmptyObj(urlParams)) {
            Object.entries(urlParams).forEach(([paramKey, paramVal]) => {
                if (!extras[paramKey]) {
                    extras[paramKey] = paramVal;
                }
            });
        }

        const ps = IdStore.fetchIdForKey(Constants.URL_ARGS.PUBLISHER_SUBID);

        if (false === Utils.isNull(ps)) {
            extras.ps = ps;
        }

        const pss = IdStore.fetchIdForKey(Constants.URL_ARGS.PUBLISHER_SUB_SOURCE);

        if (false === Utils.isNull(pss)) {
            extras.pss = pss;
        }

        let prentiusPreping = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PENTIUS_PREPING);

        if (false === Utils.isNull(prentiusPreping)) {
            extras[Constants.ADS.EXTRAS.PENTIUS_PREPING] = prentiusPreping;
        }

        let aarpPreping = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AARP_PREPING);

        if (false === Utils.isNull(aarpPreping)) {
            extras[Constants.ADS.EXTRAS.AARP_PREPING] = aarpPreping;
        }

        let flatironPreping = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.FLATIRON_MEDIA_PREPING);

        if (false === Utils.isNull(flatironPreping)) {
            extras[Constants.ADS.EXTRAS.FLATIRON_MEDIA_PREPING] = flatironPreping;
        }

        let pDomain = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.P_DOMAIN);

        if (pDomain) {
            extras[Constants.ID_STORE_KEYS.P_DOMAIN] = pDomain;
        }

        extras[Constants.ID_STORE_KEYS.DOMAIN] = IdStore.fetchIdForKey(
            Constants.ID_STORE_KEYS.DOMAIN,
        );
        extras[Constants.ID_STORE_KEYS.IS_RDR] = IdStore.fetchIdForKey(
            Constants.ID_STORE_KEYS.IS_RDR,
        );

        extras[Constants.ID_STORE_KEYS.IS_REGISTERED] = IdStore.fetchIdForKey(
            Constants.ID_STORE_KEYS.GTM_IS_REGISTERED,
        );

        if (process.env.REACT_APP_CLIENT_VERSION) {
            extras['code_version'] = process.env.REACT_APP_CLIENT_VERSION;
        }

        extraParams['eventType'] = EVENT_TYPE_MAPPING[window.location.pathname];

        if (false === Utils.isEmptyObj(extraParams)) {
            // override/append extra parameters if passed
            extras = {
                ...extras,
                ...extraParams,
            };
        }

        // add survey responses for targeting
        let surveyData = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SURVEY_DATA_FOR_AD);

        if (surveyData) {
            surveyData = JSON.parse(surveyData);

            if (false === Utils.isEmptyObj(surveyData)) {
                extras = {
                    ...extras,
                    ...surveyData,
                };
            }
        }

        // Add visit count data and events dispatched
        const visitCount = parseInt(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.VISIT_COUNT));
        const registeredVisitCount = parseInt(
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT),
        );

        try {
            const parsedVisitCount = parseInt(visitCount);
            const parsedRegisteredVisitCount = parseInt(registeredVisitCount);

            if (Number.isInteger(parsedVisitCount)) {
                extras.visitCount = parsedVisitCount;
            }

            if (Number.isInteger(parsedRegisteredVisitCount)) {
                extras.registeredVisitCount = parsedRegisteredVisitCount;
            }
        } catch (err) {
            console.log(`ERR while parsing visitCount/registeredVisitCount: ${err}`);
        }

        const CS_ID = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);
        const dispatchedEventsKey = `${CS_ID}_${Constants.ID_STORE_KEYS.DISPATCHED_EVENTS}`;
        const dispatchedEventsData = IdStore.fetchIdForKey(dispatchedEventsKey);

        extras.cs_id = CS_ID;
        extras.terms_and_conditions =
            AbTestHelper.ConfirmationPageTest() ||
            AbTestHelper.ConfirmationPageTestV2() ||
            AbTestHelper.ConfirmationPageTestV3()
                ? 1
                : userDetails['terms-and-conditions']
                ? Number(userDetails['terms-and-conditions'])
                : 0;
        extras.opt_in_email = userDetails['opt_in_email'] ? Number(userDetails['opt_in_email']) : 0;

        if (extras[Constants.ADS.EXTRAS.UTM_SOURCE] && extras.ps) {
            extras.encodedSource = StringHelper.encodeURLSafeBase64(
                `${IdStore.fetchIdForKey(
                    Constants.ID_STORE_KEYS.UTM_SOURCE,
                )}-${IdStore.fetchIdForKey(
                    Constants.ID_STORE_KEYS.UTM_CAMPAIGN,
                )}-${IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUBLISHER_SUBID)}`,
            );
        }

        const pubSourceTypeConfig =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUB_SOURCE_TYPE_CONFIG) &&
            JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUB_SOURCE_TYPE_CONFIG));

        if (pubSourceTypeConfig) {
            let rawEncodedSource = '';

            if (pubSourceTypeConfig.utmSource)
                rawEncodedSource = rawEncodedSource + pubSourceTypeConfig.utmSource;

            if (pubSourceTypeConfig.pubSourceType)
                rawEncodedSource = rawEncodedSource + pubSourceTypeConfig.pubSourceType;

            const base64EncodedEncodedSource = btoa(`${rawEncodedSource.trim()}`);
            extras.encodedSourceV2 = base64EncodedEncodedSource;
        }

        try {
            const parsedEventsData = JSON.parse(dispatchedEventsData);

            if (!Utils.isEmptyObj(parsedEventsData)) {
                for (const eventKey in EVENT_CONSTANTS.EVENTS_TO_TRACK) {
                    extras[eventKey] = parsedEventsData[eventKey] ? '1' : '0';
                }
            }
        } catch (err) {
            console.log(`ERR while parsing dispatched Events data: ${err}`);
        }

        if (false === Utils.isEmptyObj(extras)) {
            const filteredExtras = {};
            Object.keys(extras).forEach(k => {
                if (!StringHelper.isNull(extras[k])) {
                    filteredExtras[k] = extras[k];
                }
            });
            targetingParams['extras'] = filteredExtras;
        }

        // Attach events obj as eventsExtras, if addEventExtras flag true
        if (addEventExtras) {
            const _eventObj = EventManager.createEventObj({
                eventName: 'dummy_evt',
            });
            // Remove dummy event id added
            delete _eventObj.evt_id;
            const eventObj = {};
            Object.keys(_eventObj).forEach(k => {
                if (!StringHelper.isNull(_eventObj[k])) {
                    eventObj[k] = _eventObj[k];
                }
            });

            if (process.env.REACT_APP_CLIENT_VERSION) {
                eventObj['code_version'] = process.env.REACT_APP_CLIENT_VERSION;
            }

            let tfCertUrl =
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REG2_TRUSTED_FORM_CERT_URL) ||
                (document.getElementById('xxTrustedFormToken_0') &&
                    document.getElementById('xxTrustedFormToken_0').value);
            let leadId =
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REG2_LEADID) ||
                (document.getElementById('leadid_token') &&
                    document.getElementById('leadid_token').value);
            let address = '';

            if (userDetails.address) {
                address = userDetails.address.line1;

                if (userDetails.address.line1) {
                    if (userDetails.address.line2) {
                        address = `${userDetails.address.line1} ${userDetails.address.line2}`;
                    } else {
                        address = userDetails.address.line1;
                    }
                }
            }

            let eventData = {
                first_name: userDetails.first_name,
                last_name: userDetails.last_name,
                email: userDetails.email,
                gender: userDetails.gender,
                dob: userDetails.dob,
                address,
                zip: geo.zip,
                city: geo.city,
                region: geo.state,
                country: geo.country,
                phone_number: userDetails.phone_number,
                evs: userDetails.evs,
                job_type: extras[Constants.ID_STORE_KEYS.JOB_TYPE_VAL],
                tf_curl: tfCertUrl,
                leadid: leadId,
            };

            targetingParams.eventExtras = { ...eventObj, ...eventData };
        }

        return targetingParams;
    }

    setABParam(extras) {
        const abTests = [
            Constants.ID_STORE_KEYS.AB_TEST,
            Constants.ID_STORE_KEYS.AB_TEST_1,
            Constants.ID_STORE_KEYS.AB_TEST_2,
            Constants.ID_STORE_KEYS.AB_TEST_3,
            Constants.ID_STORE_KEYS.AB_TEST_4,
            Constants.ID_STORE_KEYS.AB_TEST_5,
            Constants.ID_STORE_KEYS.AB_TEST_6,
            Constants.ID_STORE_KEYS.AB_TEST_7,
            Constants.ID_STORE_KEYS.AB_TEST_8,
            Constants.ID_STORE_KEYS.AB_TEST_9,
            Constants.ID_STORE_KEYS.AB_TEST_10,
            Constants.ID_STORE_KEYS.AB_TEST_11,
            Constants.ID_STORE_KEYS.AB_TEST_12,
            Constants.ID_STORE_KEYS.AB_TEST_13,
            Constants.ID_STORE_KEYS.AB_TEST_14,
            Constants.ID_STORE_KEYS.AB_TEST_15,
            Constants.ID_STORE_KEYS.AB_TEST_16,
            Constants.ID_STORE_KEYS.AB_TEST_17,
            Constants.ID_STORE_KEYS.AB_TEST_18,
            Constants.ID_STORE_KEYS.AB_TEST_19,
            Constants.ID_STORE_KEYS.AB_TEST_20,
            Constants.ID_STORE_KEYS.AB_TEST_21,
            Constants.ID_STORE_KEYS.AB_TEST_22,
            Constants.ID_STORE_KEYS.AB_TEST_23,
            Constants.ID_STORE_KEYS.AB_TEST_24,
            Constants.ID_STORE_KEYS.AB_TEST_25,
            Constants.ID_STORE_KEYS.AB_TEST_26,
            Constants.ID_STORE_KEYS.AB_TEST_27,
            Constants.ID_STORE_KEYS.AB_TEST_28,
            Constants.ID_STORE_KEYS.AB_TEST_29,
            Constants.ID_STORE_KEYS.AB_TEST_30,
            Constants.ID_STORE_KEYS.AB_TEST_31,
            Constants.ID_STORE_KEYS.AB_TEST_32,
            Constants.ID_STORE_KEYS.AB_TEST_33,
            Constants.ID_STORE_KEYS.AB_TEST_34,
            Constants.ID_STORE_KEYS.AB_TEST_35,
            Constants.ID_STORE_KEYS.AB_TEST_36,
            Constants.ID_STORE_KEYS.AB_TEST_37,
            Constants.ID_STORE_KEYS.AB_TEST_38,
            Constants.ID_STORE_KEYS.AB_TEST_39,
            Constants.ID_STORE_KEYS.AB_TEST_40,
            Constants.ID_STORE_KEYS.AB_TEST_41,
            Constants.ID_STORE_KEYS.AB_TEST_42,
            Constants.ID_STORE_KEYS.AB_TEST_43,
            Constants.ID_STORE_KEYS.AB_TEST_44,
            Constants.ID_STORE_KEYS.AB_TEST_45,
            Constants.ID_STORE_KEYS.AB_TEST_46,
            Constants.ID_STORE_KEYS.AB_TEST_47,
            Constants.ID_STORE_KEYS.AB_TEST_48,
            Constants.ID_STORE_KEYS.AB_TEST_49,
            Constants.ID_STORE_KEYS.AB_TEST_50,
        ];

        const newExtras = { ...extras };
        abTests.forEach(abt => {
            const value = IdStore.fetchIdForKey(abt);

            if (false === Utils.isNull(value)) {
                newExtras[abt] = value;
            }
        });

        return newExtras;
    }

    fetchOfferOptions() {
        let svid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SURVEY_ID);
        let offerLength = 5;

        if (svid === '46') {
            offerLength = 3;
        }

        if (svid === '45' || svid === '47' || svid === '49') {
            offerLength = 6;
        }
        let options = {
            offerWall: false,
            offerPath: true,
            // TODO: This is hard-coded for now. Need to fetch this from survey-response
            offerWallAds: 0,
            offerPathAds: offerLength,
            opDedupeList: Utils.getCookieNamesWithValue('op-dedupe'),
            useFallbackV2: AbTestHelper.isNewFallbackFeature(),
        };

        return options;
    }
}

let manager = new TargetingManager();
export default manager;
