import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import RouterPrefixHandler from 'common/routePrefixHandler';
import { IdStore } from 'dataStore';
import EventManager from 'eventManager/eventManager';
import Constants from 'common/constants';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './interstitialAdsCarousel.css';
import Utils from 'common/utils';
import { RecaptchaHelper } from 'helpers';
import memoryStoreKeys from 'common/memoryStoreKeys';
import memoryStore from 'dataStore/memoryStore';

class InterstitialAdsCarousel extends React.Component {
    fireAnalytics(eventName, rest) {
        const eventData = {
            ...rest,
        };
        EventManager.sendEvent(eventName, eventData, Constants.EVENT_TYPE.LANDING_INTERSTITIAL);
    }

    handleClose = () => this.props.handleClose();

    handleClick = (e, jobsData, applyUrl) => {
        const {
            dataset: { index },
        } = e.currentTarget;
        const { title = '', company = '', description = '', bid, in_uuid } = jobsData;
        const adUnitMap = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU);
        const adSourceMeta = memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA) || [];
        let shortDesc = '';

        const auMeta = adSourceMeta.find(i => i.uuid === in_uuid);

        if (description && !Utils.isNull(description)) {
            shortDesc = description.substring(0, 200);
        }

        const evtObj = {
            adt: title,
            adst: company,
            adst2: shortDesc,
            advid: Utils.getObjVal(adUnitMap, [in_uuid, 'advId']),
            advnm: Utils.getObjVal(adUnitMap, [in_uuid, 'advertiserName']),
            cmpnm: Utils.getObjVal(adUnitMap, [in_uuid, 'campaignName']),
            cmpid: Utils.getObjVal(adUnitMap, [in_uuid, 'campaignId']),
            rv_type: Utils.getObjVal(adUnitMap, [in_uuid, 'revType']),
            max_deal_id: Utils.getObjVal(adUnitMap, [in_uuid, 'dealId']),
            spotno: `${index}`,
            uuid: in_uuid,
            ervn: bid,
            ad_unit_id: auMeta && auMeta.adUnitId,
            job_redirect_url: applyUrl,
        };

        this.fireAnalytics(Constants.EVENT.GRATIFICATION_AVAIL, evtObj);
        EventManager.sendEventWhenVisible(
            Constants.EVENT.GRAT_PROG,
            evtObj,
            Constants.EVENT_TYPE.GRAITIFICATION,
        );

        if (Utils.getObjVal(adUnitMap, [in_uuid, 'customAdInfo', 'triggerErvn'])) {
            this.fireAnalytics(Constants.EVENT.REVENUE, {
                ervn: bid || 0,
                adt: title,
                adst: company,
                adst2: shortDesc,
                advid: Utils.getObjVal(adUnitMap, [in_uuid, 'advId']),
                advnm: Utils.getObjVal(adUnitMap, [in_uuid, 'advertiserName']),
                cmpnm: Utils.getObjVal(adUnitMap, [in_uuid, 'campaignName']),
                cmpid: Utils.getObjVal(adUnitMap, [in_uuid, 'campaignId']),
                spotno: `${index}`,
                uuid: in_uuid,
                max_deal_id: Utils.getObjVal(adUnitMap, [in_uuid, 'dealId']),
                ad_unit_id: auMeta && auMeta.adUnitId,
            });
        }

        RecaptchaHelper.genRecaptcha();
        // window.open(href)
    };

    triggerGratImp = () => {
        const { jobs = [] } = this.props;
        const resultAdSources = jobs.reduce((acc, curr) => {
            if (curr && curr.in_uuid) {
                acc[curr.in_uuid] = true;
            }

            return acc;
        }, {});
        const adSourceAus = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU);
        Object.keys(resultAdSources).forEach(in_uuid => {
            const au = adSourceAus[in_uuid];
            const adSourceMeta = memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA) || [];
            const auMeta = adSourceMeta.find(i => i.uuid === in_uuid);
            au &&
                this.fireAnalytics(Constants.EVENT.GRATIFICATION_PAGE_IMP, {
                    advid: au.advId,
                    advnm: au.advertiserName,
                    cmpnm: au.campaignName,
                    cmpid: au.campaignId,
                    uuid: in_uuid,
                    max_deal_id: au.dealId,
                    ad_unit_id: auMeta && auMeta.adUnitId,
                });
        });
    };

    componentDidMount() {
        if (this.props.open === true) {
            this.triggerGratImp();
        }
    }

    displayJobsList(jobs) {
        let jobList =
            jobs &&
            jobs.length > 0 &&
            jobs.map((jobsData, index) => {
                let {
                    url: applyUrl = '',
                    title = '',
                    location = '',
                    logo = RouterPrefixHandler.getLogoPath(
                        `logos/${Utils.getLogoByUtmTerm(
                            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM),
                        )}.png`,
                    ),
                } = jobsData;

                const clickHandler = e => this.handleClick(e, jobsData, applyUrl);

                return (
                    <div className="job-content" key={index}>
                        <div className="job-content-wrapper">
                            <div className="job-description-wrapper">
                                <div className="job-logo-wrapper">
                                    <Avatar src={logo} />
                                </div>
                                <div className="job-description-container">
                                    <div className="job-description">{title}</div>
                                    <div className="job-location">{location}</div>
                                </div>
                            </div>
                        </div>
                        <div className="button-container">
                            <Button
                                className="btn default"
                                data-index={index}
                                href={applyUrl}
                                onClick={clickHandler}
                                rel="noopener noreferrer"
                                target="_blank">
                                Details
                            </Button>
                            <Button
                                className="btn primary"
                                color="primary"
                                data-index={index}
                                endIcon={
                                    <picture>
                                        <source
                                            srcSet={RouterPrefixHandler.getImagePath(
                                                'images/arrow2.webp',
                                            )}
                                            type="image/webp"
                                        />
                                        <img
                                            alt={`icon`}
                                            className={'arrow-icon'}
                                            src={RouterPrefixHandler.getImagePath(
                                                'images/arrow2.png',
                                            )}
                                        />
                                    </picture>
                                }
                                href={applyUrl}
                                onClick={clickHandler}
                                rel="noopener noreferrer"
                                target="_blank">
                                View Job
                            </Button>
                        </div>
                    </div>
                );
            });

        return (
            <div className="jobs-list-wrapper">
                <Carousel showArrows={true} showThumbs={false}>
                    {jobList}
                </Carousel>
            </div>
        );
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.open === true &&
            prevProps.open !== this.props.open &&
            this.props.jobs.length > 0
        ) {
            this.triggerGratImp();
        }
    }

    render() {
        const { jobs = [], open } = this.props;

        // creating template before as resource loading takes time
        return (
            <div>
                <Dialog
                    aria-describedby="alert-dialog-description"
                    aria-labelledby="alert-dialog-title"
                    className={`interstitial-ads-main-wrapper`}
                    onClose={this.handleClose}
                    open={open && jobs.length > 0}>
                    <div className="interstitial-ads-block bounceInDown">
                        <div className={`interstitial-ads-info-block`}>
                            <div className="header">
                                <div className={'trending-btn'}>
                                    <picture>
                                        <source
                                            srcSet={RouterPrefixHandler.getImagePath(
                                                'images/shape2.webp',
                                            )}
                                            type="image/webp"
                                        />
                                        <img
                                            alt={`icon`}
                                            className="trending-icon"
                                            src={RouterPrefixHandler.getImagePath(
                                                'images/shape2.png',
                                            )}
                                        />
                                    </picture>
                                    Featured Jobs
                                </div>
                                <div className="close" onClick={this.handleClose}>
                                    <picture>
                                        <source
                                            srcSet={RouterPrefixHandler.getImagePath(
                                                'images/close2.webp',
                                            )}
                                            type="image/webp"
                                        />
                                        <img
                                            alt={`icon`}
                                            className="close-icon"
                                            src={RouterPrefixHandler.getImagePath(
                                                'images/close2.png',
                                            )}
                                        />
                                    </picture>
                                </div>
                            </div>
                            <DialogContent>
                                <DialogContentText
                                    className="interstitial-ads-sub-info"
                                    component="div"
                                    id="alert-dialog-description">
                                    <div className="interstitial-ads-jobs-conatiner">
                                        {this.displayJobsList(jobs)}
                                    </div>
                                </DialogContentText>
                            </DialogContent>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}

InterstitialAdsCarousel.propTypes = {
    open: PropTypes.bool.isRequired,
};

export default InterstitialAdsCarousel;
