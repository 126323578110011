import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import RouterPrefixHandler from 'common/routePrefixHandler';
import RegCTAButtonContainer from 'components/common/regCTAButtonContainer/index';
import flavors, { getFlavor } from 'flavors.macro';
import Utils from 'common/utils';
import cn from 'classnames';
import { AbTestHelper } from 'helpers/index';
import Constants from 'common/constants';
import { isSkipToNewTabV4 } from 'helpers/form/formHelpers';

flavors();

class TopBar extends React.Component {
    renderRegCTAIcon() {
        return <RegCTAButtonContainer />;
    }

    render() {
        const { classes, className: customCssCls = '' } = this.props;
        const browserSpecificCssCls = ['safari'].includes(Utils.browserType())
            ? classes.safariTopBarFix
            : '';
        const flavor = getFlavor('layout-theme');
        const [, deviceType] = Utils.getDeviceInfo();
        const showIcon =
            AbTestHelper.emailAlertsCTA() && window.location.pathname.includes('listings');
        const hash = window.location.hash;

        return (
            <Grid
                alignItems="center"
                className={cn(
                    `${classes.topBar} ${deviceType} ${classes.landingTp} top-bar resp-top-bar ${
                        AbTestHelper.talrooWidgetTest() &&
                        window.location.pathname === Constants.PAGE_ENDPOINTS.GRATIFICATION &&
                        classes.respTopBarTalroo
                    } ${customCssCls} ${browserSpecificCssCls} ${
                        (AbTestHelper.BrandedLanderTest() || AbTestHelper.showZipBrandedFlow()) &&
                        window.location.pathname === Constants.PAGE_ENDPOINTS.LANDING &&
                        classes.hideTopbar
                    } ${
                        ((AbTestHelper.BrandedLanderSurveyTest() &&
                            window.location.pathname === Constants.PAGE_ENDPOINTS.SURVEY) ||
                            (AbTestHelper.BrandedLanderListingsTest() &&
                                window.location.pathname ===
                                    Constants.PAGE_ENDPOINTS.GRATIFICATION)) &&
                        classes.hideTopbar
                    }`,
                    {
                        [classes.topBarShadowed]:
                            isSkipToNewTabV4() && AbTestHelper.skipToNewTabV4V2(),
                    },
                )}
                container
                direction="row"
                justify="center">
                <div
                    className={cn(classes.wrapper, {
                        [classes.listingsSizing]: showIcon,
                    })}>
                    {this.props.enableFbBgFlow ? (
                        <>
                            <picture>
                                <source
                                    srcSet={RouterPrefixHandler.getImagePath(
                                        `images/myJobsCorner-logo.webp`,
                                    )}
                                    type="image/webp"
                                />
                                <img
                                    alt={`${flavor}_logo`}
                                    className={classes.topBarImg}
                                    src={RouterPrefixHandler.getImagePath(
                                        `images/myJobsCorner-logo.png`,
                                    )}
                                />
                            </picture>
                        </>
                    ) : (
                        <>
                            {AbTestHelper.darkSERPTest() && (
                                <picture
                                    className={`${classes.topBarImgDiv} ${
                                        AbTestHelper.addictedAffiliateTest() &&
                                        !AbTestHelper.repeatUser() &&
                                        window.location.pathname.includes('landing') &&
                                        hash === '#0' &&
                                        classes.topBarPaddingRight
                                    }`}>
                                    <source
                                        srcSet={RouterPrefixHandler.getImagePath(
                                            `images/MJC-logo-V2.webp`,
                                        )}
                                        type="image/webp"
                                    />
                                    <img
                                        alt={`${flavor}_logo`}
                                        className={classes.topBarImg}
                                        height="36"
                                        src={RouterPrefixHandler.getImagePath(
                                            `images/MJC-logo-V2.png`,
                                        )}
                                        width="170"
                                    />
                                </picture>
                            )}
                            {!AbTestHelper.darkSERPTest() && (
                                <picture
                                    className={`${classes.topBarImgDiv} ${
                                        AbTestHelper.addictedAffiliateTest() &&
                                        !AbTestHelper.repeatUser() &&
                                        window.location.pathname.includes('landing') &&
                                        hash === '#0' &&
                                        classes.topBarPaddingRight
                                    }`}>
                                    <source
                                        srcSet={RouterPrefixHandler.getImagePath(
                                            `images/landing-logo.webp`,
                                        )}
                                        type="image/webp"
                                    />
                                    <img
                                        alt={`${flavor}_logo`}
                                        className={classes.topBarImg}
                                        height="36"
                                        src={RouterPrefixHandler.getImagePath(
                                            `images/landing-logo.png`,
                                        )}
                                        width="170"
                                    />
                                </picture>
                            )}
                            {AbTestHelper.addictedAffiliateTest() &&
                                !AbTestHelper.repeatUser() &&
                                window.location.pathname.includes('landing') &&
                                hash === '#0' && (
                                    <picture
                                        className={`${classes.topBarImgDiv} ${classes.topBarPaddingLeft}`}>
                                        <source
                                            srcSet={RouterPrefixHandler.getLogoPath(
                                                `logos/nhr-logo.webp`,
                                            )}
                                            type="image/webp"
                                        />
                                        <img
                                            alt={`${flavor}_logo`}
                                            className={classes.topBarImg}
                                            height="36"
                                            src={RouterPrefixHandler.getLogoPath(
                                                `logos/nhr-logo.png`,
                                            )}
                                            width="170"
                                        />
                                    </picture>
                                )}
                        </>
                    )}
                    {showIcon && this.renderRegCTAIcon()}
                </div>
            </Grid>
        );
    }
}

const styles = theme => ({
    topBar: {
        width: '100%',
        backgroundColor: '#244766',
        maxHeight: '60px',
        padding: '10px',
        height: '100%',
        '@media (max-width: 650px)': {
            backgroundColor: AbTestHelper.googleOnlineJobsLander()
                ? '#f9f9f9 !important'
                : 'inherit',
        },
    },
    landingTp: {
        backgroundColor: AbTestHelper.darkSERPTest()
            ? '#2D3337'
            : AbTestHelper.newSERPTest()
            ? '#fff'
            : 'transparent',
    },
    topBarImg: {
        maxHeight: '36px',
        height: 36,
        width: 'auto',
    },
    safariTopBarFix: {
        minHeight: '60px',
    },
    wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        alignItems: 'center',
    },
    listingsSizing: {
        maxWidth: '1146px',
        [theme.breakpoints.down('1025')]: {
            maxWidth: '730px',
        },
    },
    respTopBarTalroo: {
        position: 'fixed',
        zIndex: 1,
        backgroundColor: 'white',
    },
    topBarPaddingLeft: {
        paddingLeft: 15,
        borderLeft: '1px solid #ced7dc',
        marginTop: '-10px',
    },
    topBarPaddingRight: {
        padding: 15,
        marginTop: '-10px',
    },
    topBarShadowed: {
        boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.07)',
    },
    hideTopbar: {
        display: 'none !important',
    },
});

TopBar.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
};

export default withStyles(styles)(TopBar);
