import Constants from 'common/constants';
import { IdStore } from 'dataStore';
import Utils from 'common/utils';
import JtKwMappings from 'common/jobtypeKeywordMappings';
import { AbTestHelper, StringHelper } from 'helpers/index';

const getValidLogo = logoArr =>
    logoArr.find(logo => {
        return StringHelper.isNull(logo) ? false : logo;
    });

const getSelectedLogo = (term, jobType) => {
    let { isJobTypeAllowed } = Utils.getJobType();
    let kwFromUrl =
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_TERM_G) ||
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_TERM);
    let kwFromStorage = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM);
    const searchTerm = term !== 'none' && !Utils.isNull(term) ? term : jobType;
    const isDisplayJobsLogoByAdvertiser = AbTestHelper.displayGovtAirportLogosAwks39();
    const governmentLogoValue = AbTestHelper.showGovtLogoAwks39();
    const airportLogoValue = AbTestHelper.showAirportLogoAwks39();

    const getJobLogo = () => {
        if (
            isJobTypeAllowed &&
            (!AbTestHelper.RgJtKwTestVariant2() ||
                AbTestHelper.Reg3BrandedLanderAA() ||
                AbTestHelper.BrandedLanderTest() ||
                AbTestHelper.Reticular_Branded_JTPreference())
        ) {
            return jobType;
        } else if (isDisplayJobsLogoByAdvertiser) {
            return governmentLogoValue || airportLogoValue;
        } else {
            return term ? Utils.defaultLogoFromKeyword(searchTerm) : 'job-search';
        }
    };

    if (
        isDisplayJobsLogoByAdvertiser ||
        AbTestHelper.RgJtKwTestVariant2() ||
        AbTestHelper.zipAndEmailLandingTest() ||
        AbTestHelper.skipControlFlowTest() ||
        (AbTestHelper.prePopUpdatedLandingPageTest() &&
            kwFromUrl &&
            !Utils.isJobTypeAllowed(StringHelper.cleanTerm(kwFromUrl))) ||
        (AbTestHelper.prePopUpdatedLandingPageTest() &&
            jobType &&
            !kwFromUrl &&
            !isJobTypeAllowed) ||
        (AbTestHelper.prePopUpdatedLandingPageTest() && !jobType && kwFromUrl) ||
        (AbTestHelper.prePopUpdatedLandingPageTest() &&
            !kwFromStorage &&
            !Object.keys(JtKwMappings).includes(jobType))
    ) {
        return getJobLogo();
    } else {
        return getValidLogo([
            isJobTypeAllowed && jobType,
            IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL),
            Utils.defaultLogoFromKeyword(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM)),
            'job-search',
        ]);
    }
};

export { getSelectedLogo };
