import Constants from 'common/constants';
import memoryStoreKeys from 'common/memoryStoreKeys';
import memoryStore from 'dataStore/memoryStore';
import {
    getFulfilmentMergedSourceAuIds,
    getFulfilmentPageListingsAuId,
    getOverlayJobProviderAuId,
    getFallBackPageListingsAuId,
} from 'helpers/featureValueHelpers/featureValueHelpers';
import AdsDataSource from 'datasource/adsDataSource';
import TargetingManager from 'targetingManager';
import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import JobSearchDataSource from 'datasource/jobsSearchDataSource';
import { FeatureValueHelper } from 'helpers/index';

const stringify = require('@bugsnag/safe-json-stringify');

const uuidv4 = require('uuid/v4');

export const pickAdvertisersDataForJobResults = uuid => {
    const jobSourceMetaData = memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA);

    return jobSourceMetaData.filter(jobMeta => {
        return uuid.includes(jobMeta.uuid);
    });
};

export const wrapAdvertisersWithExtraData = (advertisers = [], extraData) => {
    return advertisers.map(advObj => {
        return { ...advObj, ...extraData };
    });
};

export const getMockAdSource = source => {
    const advMockMap = Constants.ADVERTISERS.DATA;
    const adMockData = advMockMap[source.toUpperCase()];

    return {
        source: source,
        advId: adMockData.ID,
        ad: {
            uuid: adMockData.ID,
            advId: adMockData.ID,
            advertiserName: adMockData.NAME,
            customAdInfo: { adSource: source },
            campaignName: adMockData.CAMPAIGN_NAME,
            campaignId: adMockData.CAMPAIGN_ID,
        },
        meta: {
            uuid: uuidv4().replace(/-/g, ''),
            advid: adMockData.ID,
            adUnitId: `mock-${adMockData.ID}`,
        },
    };
};

export const getDefaultJobSourcePayload = (
    extraQueryData = {},
    extraAdvData = {},
    cacheKey = 'default',
    adSource = 'upward',
) => {
    const key = `${memoryStoreKeys.DEFAULT_JOB_SOURCE}_${cacheKey}`;

    if (!memoryStore.getItem(key, true)) {
        memoryStore.setItem(key, getMockAdSource(adSource), true);
    }

    const defaultAdSourceUnit = memoryStore.getItem(key, true);

    return {
        ...extraQueryData,
        advertisers: [
            {
                ...defaultAdSourceUnit.meta,
                ...extraAdvData,
            },
        ],
    };
};

export const addMetaDataToAdUnit = adUnitData => {
    return {
        ...adUnitData,
        meta: {
            uuid: uuidv4().replace(/-/g, ''),
            advid: Utils.getObjVal(adUnitData, ['ad', 'advId']),
        },
    };
};

export const setupAdSourceForSingleSource = source => {
    const advMockMap = Constants.ADVERTISERS.DATA;
    const adMockData = advMockMap[source.toUpperCase()];

    const urlAdUuid = uuidv4().replace(/-/g, '');
    const advId = adMockData.ID;

    const uuids = memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS) || [];
    memoryStore.setItem(memoryStoreKeys.JOB_AD_SOURCE_FALLBACK, urlAdUuid);
    memoryStore.setItem(memoryStoreKeys.JOB_AD_SOURCE_IDS, [...uuids, urlAdUuid]);
    const JobAdSourceIds = memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP) || {};
    memoryStore.setItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP, {
        ...JobAdSourceIds,
        [urlAdUuid]: advId,
    });
    const jobAdMetaData = memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA) || [];
    const jobAdMetaDataObject = {};
    [...jobAdMetaData, { advid: advId, uuid: urlAdUuid, adUnitId: `mock-${adMockData.ID}` }].map(
        jobData => (jobAdMetaDataObject[jobData.uuid] = jobData),
    );

    memoryStore.setItem(memoryStoreKeys.JOB_AD_META_DATA, Object.values(jobAdMetaDataObject));

    if (adMockData) {
        const jobAdAu = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU) || {};
        memoryStore.setItem(memoryStoreKeys.JOB_AD_AU, {
            ...jobAdAu,
            [urlAdUuid]: {
                advId: adMockData.ID,
                advertiserName: adMockData.NAME,
                customAdInfo: { adSource: source },
                campaignName: adMockData.CAMPAIGN_NAME,
                campaignId: adMockData.CAMPAIGN_ID,
                revType: adMockData.REVENUE_TYPE,
            },
        });
    } else {
        memoryStore.setItem(memoryStoreKeys.JOB_AD_AU, {});
    }
};

export const fetchAdvertiser = async (adUnitId, targetingParamsArg, allowFallback) => {
    try {
        if (!adUnitId) throw new Error('Ad Unit Id not configured for job listings');
        const targetingParams = TargetingManager.fetchTargetingParams();

        const adUnitData = await AdsDataSource.fetchAdsForAdUnit(targetingParams, adUnitId);

        if (adUnitData && adUnitData.ad && !Utils.isEmptyObj(adUnitData.ad)) {
            const { customAdInfo = {}, advId, uuid } = adUnitData.ad;

            const { forwardAdMetaData, ...rest } = customAdInfo;
            const adSource = customAdInfo.adSource;
            console.log('[Jobs][AdUnit]Debug', adUnitId, 'resolved to', adUnitData.ad);
            const bid = Utils.getObjVal(adUnitData, ['ad', 'bid']);

            if (advId) {
                return {
                    source: adSource,
                    advId,
                    ad: adUnitData.ad,
                    meta: {
                        ...(bid ? { bid: bid } : {}),
                        uuid: uuid || uuidv4().replace(/-/g, ''),
                        advid: advId,
                        ...(forwardAdMetaData ? rest : {}),
                        adUnitId: adUnitId,
                    },
                };
            }
        } else if (allowFallback && adUnitData.fallbacks) {
            return adUnitData;
        } else {
            console.error(
                `Ad source could not be determined from ad unit call: ${stringify(adUnitData)}}`,
            );
        }
    } catch (err) {
        console.error(`Error while fetching AdUnit for Fulfilment Listings - `, err);
    }
};

export const setupAdSourcesForJTJobsAdvertisers = async () => {
    try {
        const adSourcesResponses = (
            await Promise.allSettled(
                FeatureValueHelper.getJTSERPListingsConfig().map(async conf => ({
                    ...(await fetchAdvertiser(conf.id)),
                    auId: conf.id,
                    position: conf.position,
                })),
            )
        ).map(item => item.value);
        const adSources = memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS);
        const adSourceAus = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU);
        const adSourceMeta = memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA);
        const advUuidMap = memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP);

        const dupeMap = adSources.reduce((acc, curr) => {
            acc[curr] = true;

            return acc;
        }, {});

        adSourcesResponses.forEach(resp => {
            if (resp && resp.meta && resp.meta.uuid && !dupeMap[resp.meta.uuid]) {
                const { ad, meta, advId } = resp;

                if (ad && ad.dealId) {
                    meta['dealId'] = ad.dealId;
                }

                if (ad && ad.experiment) {
                    meta['experiment'] = ad.experiment;
                }

                dupeMap[meta.uuid] = true;
                adSourceAus[meta.uuid] = ad;
                adSourceMeta.push(meta);
                advUuidMap[meta.uuid] = advId;
            }
        });
        memoryStore.setItem(memoryStoreKeys.JT_JOB_AD_INFO_DATA, adSourcesResponses);
        memoryStore.setItem(memoryStoreKeys.JOB_AD_AU, adSourceAus);
        memoryStore.setItem(memoryStoreKeys.JOB_AD_META_DATA, adSourceMeta);
        memoryStore.setItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP, advUuidMap);
    } catch (e) {
        console.error('[JTJobs]: Error setting up JT Job Advertisers', e);

        return Promise.resolve();
    }
};

export const fetchJTJobs = async (jt, zip) => {
    try {
        const jobsArray = [];
        const jtJobAdInfoData = memoryStore.getItem(memoryStoreKeys.JT_JOB_AD_INFO_DATA);

        const advJobMapping = {};
        jtJobAdInfoData.forEach(conf => {
            const { meta, position } = conf;
            const uuid = meta && meta.uuid;

            if (!uuid) return;

            if (!advJobMapping[uuid]) {
                advJobMapping[uuid] = [position];
            } else {
                advJobMapping[uuid].push(position);
            }
        });

        const jobResultsMap = {};
        let errorMap = {};
        let experiment = '';

        await Promise.allSettled(
            Object.keys(advJobMapping).map(async uuid => {
                const positions = advJobMapping[uuid];
                const query = JobSearchDataSource.createJobsQueryForTerm({
                    term: jt,
                    pageNume: 1,
                    pageSize: positions.length,
                    location: zip,
                });

                const jobsQuery = {
                    ...query,
                    advertisers: pickAdvertisersDataForJobResults([uuid]),
                };

                try {
                    const jobsData = await JobSearchDataSource.fetchJobsDataForQueryMultiSource(
                        jobsQuery,
                    );

                    let jobs = Utils.getObjVal(jobsData, ['jobs']) || [];
                    const erros = Utils.getObjVal(jobsData, ['error']) || {};
                    experiment = Utils.getObjVal(jobsData, ['experiment']) || '';
                    jobs[0] = {
                        ...jobs[0],
                        experiment: Utils.getObjVal(jobsData, ['experiment']) || '',
                    };

                    jobResultsMap[uuid] = jobs;
                    errorMap = { ...errorMap, ...erros };
                } catch (e) {
                    errorMap[uuid] = 'Error Fetching Job';
                }
            }),
        );

        Object.keys(advJobMapping).forEach(uuid => {
            let i = 0;
            const positions = advJobMapping[uuid];
            const jobs = jobResultsMap[uuid] || [];
            positions.forEach(pos => {
                if (jobs[i]) {
                    jobsArray[pos] = { ...jobs[i], cat: 'jt' };
                    i++;
                }
            });
        });

        return [jobsArray, errorMap, experiment];
    } catch (e) {
        console.error('[JTJobs]: Error fetching JT Jobs', e);

        return [];
    }
};

export const setupOverlayJobProvider = async () => {
    const adUnitId = getOverlayJobProviderAuId();
    console.log('[Jobs]: Setting up Overlay Job provider', adUnitId);

    if (adUnitId) {
        const auResp = await fetchAdvertiser(adUnitId);
        console.log('[Jobs]: Setting up Overlay Job ad unit', auResp);

        auResp && auResp.ad && memoryStore.setItem(memoryStoreKeys.JOB_OVERLAY_INT_AU, auResp);
    }
};

export const setupExitInterstitialAuData = async auId => {
    const auResp = await fetchAdvertiser(auId);
    console.log('[Jobs]: Setting up Exit Int Job ad unit', auResp);

    if (auResp && auResp.ad) {
        memoryStore.setItem(memoryStoreKeys.EXIT_INIT_AU, auResp);
    } else {
        const fallBackSource = Constants.ADVERTISERS.TALROO;
        const adMockData = Constants.ADVERTISERS.DATA[fallBackSource.toUpperCase()];
        const mockAu = {
            advId: adMockData.ID,
            ad: {
                advertiserName: adMockData.NAME,
                customAdInfo: { adSource: fallBackSource },
                campaignName: adMockData.CAMPAIGN_NAME,
                campaignId: adMockData.CAMPAIGN_ID,
            },
            meta: {
                uuid: uuidv4().replace(/-/g, ''),
                advid: adMockData.ID,
            },
        };

        console.log('[Jobs]: Setting up fallback Exit Int Job ad unit', mockAu);

        memoryStore.setItem(memoryStoreKeys.EXIT_INIT_AU, mockAu);
    }

    return memoryStore.getItem(memoryStoreKeys.EXIT_INIT_AU);
};

export const setupBackButtonInterstitialAuData = async auId => {
    const auResp = await fetchAdvertiser(auId);
    console.log('[Jobs]: Setting up Exit Int Job ad unit', auResp);

    if (auResp && auResp.ad) {
        memoryStore.setItem(memoryStoreKeys.BACK_BUTTON_INIT_AU, auResp);
    } else {
        const updatableAbtChannel3 = FeatureValueHelper.getUpdatableABTChannel3();

        if (!Utils.isEmptyStr(updatableAbtChannel3)) {
            IdStore.storeIdForKey(updatableAbtChannel3, 'control');
        }
        const fallBackSource = Constants.ADVERTISERS.TALROO;
        const adMockData = Constants.ADVERTISERS.DATA[fallBackSource.toUpperCase()];
        const mockAu = {
            advId: adMockData.ID,
            ad: {
                advertiserName: adMockData.NAME,
                campaignName: adMockData.CAMPAIGN_NAME,
                campaignId: adMockData.CAMPAIGN_ID,
            },
        };

        console.log('[Jobs]: Setting up fallback Exit Int Job ad unit', mockAu);

        memoryStore.setItem(memoryStoreKeys.BACK_BUTTON_INIT_AU, mockAu);
    }

    return memoryStore.getItem(memoryStoreKeys.BACK_BUTTON_INIT_AU);
};

export const selectJobSourceAdvertiser = async () => {
    const overrideSerpAuId = Utils.getValForKeyFromCurrentUrl('overrideSerp');
    const adUnitId = getFulfilmentPageListingsAuId() || '';
    const auIds = overrideSerpAuId ? overrideSerpAuId.split(',') : getFulfilmentMergedSourceAuIds();
    const fallbackAuIds = getFallBackPageListingsAuId();
    const urlAdSource = Utils.getValForKeyFromCurrentUrl('source');
    let jobAdsSource = 'upward';

    if (
        [
            Constants.ADVERTISERS.TALROO,
            Constants.ADVERTISERS.UPWARD,
            Constants.ADVERTISERS.APPCAST,
            Constants.ADVERTISERS.ZIPRECRUITER,
        ].includes(urlAdSource)
    ) {
        await Promise.resolve();
        console.log('[Jobs] Got Source from URL', urlAdSource);
        jobAdsSource = urlAdSource;
        setupAdSourceForSingleSource(urlAdSource);
    } else {
        console.log('[Jobs] Firing AUs', auIds);

        const singleAuResp = await fetchAdvertiser(adUnitId);
        // Set Job Ad Source across other JobResults

        if (singleAuResp && singleAuResp.source) {
            jobAdsSource = singleAuResp.source;
            memoryStore.setItem(memoryStoreKeys.SINGLE_JOB_AD_AU, singleAuResp && singleAuResp.ad);
        } else {
            jobAdsSource = Constants.ADVERTISERS.UPWARD;
        }

        // Set Job Ad Sources for job results
        let adSourcesResponses =
            auIds && auIds.length
                ? (await Promise.allSettled(auIds.map(fetchAdvertiser))).map(item => item.value)
                : [singleAuResp];

        if (!adSourcesResponses || !adSourcesResponses[0]) {
            const fallbackAuResp = await fetchAdvertiser(fallbackAuIds);
            adSourcesResponses[0] = fallbackAuResp;
        }

        const adSourceIds = [];
        const adSourceAus = {};
        const adSourceMeta = [];
        const advUuidMap = {};
        const dupeMap = {};
        adSourcesResponses.forEach(resp => {
            if (resp && resp.meta && resp.meta.uuid && !dupeMap[resp.meta.uuid]) {
                const { ad, meta, advId } = resp;

                if (ad && ad.dealId) {
                    meta['dealId'] = ad.dealId;
                }

                if (ad && ad.experiment) {
                    meta['experiment'] = ad.experiment;
                }

                dupeMap[meta.uuid] = true;
                adSourceIds.push(meta.uuid);
                adSourceAus[meta.uuid] = ad;
                adSourceMeta.push(meta);
                advUuidMap[meta.uuid] = advId;
            }
        });

        if (adSourceIds.length) {
            memoryStore.setItem(memoryStoreKeys.JOB_AD_SOURCE_IDS, adSourceIds);
            const jobAddAU = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU) || {};
            memoryStore.setItem(memoryStoreKeys.JOB_AD_AU, { ...jobAddAU, ...adSourceAus });

            const jobAdMetaData = memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA) || [];
            const jobAdMetaDataObject = {};
            [...jobAdMetaData, ...adSourceMeta].map(
                jobData => (jobAdMetaDataObject[jobData.uuid] = jobData),
            );

            memoryStore.setItem(
                memoryStoreKeys.JOB_AD_META_DATA,
                Object.values(jobAdMetaDataObject),
            );
            const jobAddUuidMap =
                memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP) || {};
            memoryStore.setItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP, {
                ...jobAddUuidMap,
                ...advUuidMap,
            });
        } else {
            // Fallback ad source
            setupAdSourceForSingleSource(Constants.ADVERTISERS.UPWARD);
        }
    }

    console.log(
        '[Jobs] Payload',
        memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS),
        memoryStore.getItem(memoryStoreKeys.JOB_AD_AU),
        memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA),
        memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP),
    );

    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_ADS_SOURCE, jobAdsSource);

    return jobAdsSource;
};
