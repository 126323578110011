import { IdStore } from 'dataStore';
import Constants from 'common/constants';
import * as moment from 'moment';
import Utils from 'common/utils';
import crossFrameComUtils from 'common/crossFrameComUtils';

class Session {
    logVisit = () => {
        const currentDateTime = new Date();

        // check if first visit
        if (null === IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.FIRST_VISIT)) {
            // log first visit
            IdStore.storeIdForKey(
                Constants.ID_STORE_KEYS.FIRST_VISIT,
                moment(currentDateTime).utc().toISOString(),
            );
        }
        // log current visit
        IdStore.storeIdForKey(
            Constants.ID_STORE_KEYS.LAST_VISIT,
            moment(currentDateTime).utc().toISOString(),
        );

        crossFrameComUtils.sendMessage('set_cookie', {
            [Utils.getFlavoredKey(Constants.ID_STORE_KEYS.FIRST_VISIT)]: IdStore.fetchIdForKey(
                Constants.ID_STORE_KEYS.FIRST_VISIT,
            ),
            [Utils.getFlavoredKey(Constants.ID_STORE_KEYS.LAST_VISIT)]: IdStore.fetchIdForKey(
                Constants.ID_STORE_KEYS.LAST_VISIT,
            ),
        });
    };

    timeSinceFirstVisit = () => {
        const firstVisit = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.FIRST_VISIT);

        if (Utils.isEmptyStr(firstVisit)) {
            return 0;
        }
        const currentDateTime = moment(new Date());

        try {
            const duration = moment.duration(currentDateTime.diff(moment(firstVisit)));

            return parseInt(duration.asSeconds());
        } catch {
            return 0;
        }
    };

    timeSinceLastVisit = () => {
        const lastVisit = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LAST_VISIT);
        const currentDateTime = moment(new Date());

        try {
            const duration = moment.duration(currentDateTime.diff(moment(lastVisit)));

            return parseInt(duration.asSeconds());
        } catch {
            return 0;
        }
    };

    trackVisitCount = () => {
        try {
            const isRegistered = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_ID)
                ? true
                : false;
            const visitCount = parseInt(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.VISIT_COUNT));
            const registeredCount = parseInt(
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT),
            );

            if (visitCount || visitCount === 0) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.VISIT_COUNT, visitCount + 1);

                if (isRegistered) {
                    IdStore.storeIdForKey(
                        Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT,
                        registeredCount + 1,
                    );
                } else {
                    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT, 0);
                }
            } else {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.VISIT_COUNT, 0);
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT, 0);
            }
        } catch (err) {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.VISIT_COUNT, 0);
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT, 0);
        }
        crossFrameComUtils.sendMessage('set_cookie', {
            [Utils.getFlavoredKey(Constants.ID_STORE_KEYS.VISIT_COUNT)]: IdStore.fetchIdForKey(
                Constants.ID_STORE_KEYS.VISIT_COUNT,
                0,
            ),
            [Utils.getFlavoredKey(
                Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT,
            )]: IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT, 0),
        });
    };
}

export const sessionHelper = new Session();
