import Utils from 'common/utils';
import EventManager from 'eventManager/eventManager';
import * as Constants from 'common/constants';
import { RecaptchaHelper, FeatureValueHelper, MacrosHelper } from 'helpers';
import AdsDataSource from 'datasource/adsDataSource';
import TargetingManager from 'targetingManager';
import { MemoryStore, IdStore } from 'dataStore/index';
import memoryStoreKeys from 'common/memoryStoreKeys';

export const getAdsForSponsoredMarketplace = async () => {
    try {
        const adConfig = FeatureValueHelper.getSponsoredMarketplaceConfig();

        // Get Targeting Params
        const targetingParams = TargetingManager.fetchTargetingParams();

        // Get Static And Dynamic Ads
        let mixAds = [];

        if (adConfig && adConfig.length) {
            await Promise.all(
                adConfig.map(async adConfigData => {
                    if (adConfigData.adType.toLowerCase() === 'static') {
                        // AU API CALL

                        try {
                            const adUnitData = await AdsDataSource.fetchAdsForAdUnit(
                                targetingParams,
                                adConfigData.adUnitId,
                                { sendRawError: true },
                            );

                            // Validating Data
                            if (adUnitData && adUnitData.ad && !Utils.isNull(adUnitData)) {
                                // Dedupe and empty Check
                                if (
                                    !Utils.getCookie(
                                        `${replaceMacro(
                                            adUnitData.ad.creativeTitle,
                                        )}-${replaceMacro(adUnitData.ad.dealId)}-${replaceMacro(
                                            adUnitData.ad.advertiserName,
                                        )}`,
                                    ) &&
                                    !Utils.isEmptyStr(adUnitData.ad.creativeTitle) &&
                                    !Utils.isEmptyStr(adUnitData.ad.customAdInfo.link)
                                ) {
                                    // Creating Object for job
                                    const ad = {
                                        job: adUnitData.ad,
                                        adUnitId: adConfigData.adUnitId,
                                        spotNo: adConfigData.spotNo,
                                        type: adConfigData.adType,
                                    };

                                    // Pushing fetched jobs in array
                                    mixAds.push(ad);

                                    MemoryStore.setItem(
                                        memoryStoreKeys.SPONSORED_MARKETPLACE_AD_UNIT_DATA,
                                        mixAds,
                                    );
                                } else if (
                                    Utils.isEmptyStr(adUnitData.ad.creativeTitle) ||
                                    Utils.isEmptyStr(adUnitData.ad.customAdInfo.link)
                                ) {
                                    // Get error text message
                                    const getErrorText = () => {
                                        if (Utils.isEmptyStr(adUnitData.ad.creativeTitle))
                                            return 'Title Text is not present';

                                        if (Utils.isEmptyStr(adUnitData.ad.customAdInfo.link))
                                            return 'Redirection Link is not present';

                                        return '';
                                    };

                                    // Send slot_failed event if title or link is not present
                                    EventManager.sendEvent(
                                        Constants.EVENT.AD_FAILED,
                                        {
                                            error: getErrorText(),
                                            ad_unit_id: adConfigData.adUnitId,
                                            cat: 'sponsored_marketplace',
                                        },
                                        Constants.EVENT_TYPE.GRAITIFICATION,
                                    );
                                } else {
                                    // Console if Dedupe is true
                                    console.error(
                                        '[Err] Sponsored Marketplace Static Jobs Dedupe.',
                                        adUnitData,
                                    );
                                }
                            }
                        } catch (error) {
                            EventManager.sendEvent(
                                Constants.EVENT.AD_FAILED,

                                {
                                    error: error.error,
                                    ad_unit_id: adConfigData.adUnitId,
                                    cat: 'sponsored_marketplace',
                                },
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                        }
                    } else if (adConfigData.adType === 'Dynamic') {
                        try {
                            // AU Api Call to fetch adunit data
                            const adUnitData = await AdsDataSource.fetchAdsForAdUnit(
                                targetingParams,
                                adConfigData.adUnitId,
                            );

                            // Validating data check
                            if (adUnitData && adUnitData.ad && !Utils.isEmptyObj(adUnitData.ad)) {
                                console.log(adUnitData);

                                // Get JobResult
                                const jobDetails = adUnitData.ad.jobresult;

                                try {
                                    if (jobDetails) {
                                        // Filter Dedupe
                                        const filteredJob = Utils.getCookie(
                                            `${jobDetails.title}-${jobDetails.company}-${jobDetails.advnm}`,
                                        );

                                        // Validating jobs data
                                        if (
                                            Utils.isNull(filteredJob) &&
                                            jobDetails &&
                                            jobDetails.title &&
                                            jobDetails.url
                                        ) {
                                            const ad = {
                                                job: {
                                                    ...adUnitData.ad,
                                                },
                                                adUnitId: adConfigData.adUnitId,
                                                spotNo: adConfigData.spotNo,
                                                type: adConfigData.adType,
                                            };

                                            mixAds.push(ad);
                                            MemoryStore.setItem(
                                                memoryStoreKeys.SPONSORED_MARKETPLACE_AD_UNIT_DATA,
                                                mixAds,
                                            );
                                        } else if (
                                            Utils.isEmptyStr(jobDetails.title) ||
                                            Utils.isEmptyStr(jobDetails.url)
                                        ) {
                                            // Sending event if title and url is not present

                                            const getErrorText = () => {
                                                if (Utils.isEmptyStr(jobDetails.title))
                                                    return 'Title Text is not present';

                                                if (Utils.isEmptyStr(jobDetails.url))
                                                    return 'Redirection Link is not present';

                                                return '';
                                            };

                                            EventManager.sendEvent(
                                                Constants.EVENT.ADS_IMP,
                                                {
                                                    ad_unit_id: adConfigData.adUnitId,
                                                    cat: 'sponsored_marketplace',
                                                    ervn: adUnitData.bid,
                                                    rv_type: adUnitData.revType,
                                                    crid: adUnitData.creativeId,
                                                    cmpnm: adUnitData.campaignName,
                                                    cmpid: adUnitData.campaignId,
                                                    advnm: adUnitData.advertiserName,
                                                    advid: adUnitData.advId,
                                                    max_deal_id: adUnitData.dealId,
                                                },
                                                Constants.EVENT_TYPE.GRAITIFICATION,
                                            );

                                            EventManager.sendEvent(
                                                Constants.EVENT.AD_FAILED,
                                                {
                                                    error: getErrorText(),
                                                    ad_unit_id: adConfigData.adUnitId,
                                                    cat: 'sponsored_marketplace',
                                                    ervn: adUnitData.bid,
                                                    rv_type: adUnitData.revType,
                                                    crid: adUnitData.creativeId,
                                                    cmpnm: adUnitData.campaignName,
                                                    cmpid: adUnitData.campaignId,
                                                    advnm: adUnitData.advertiserName,
                                                    advid: adUnitData.advId,
                                                    max_deal_id: adUnitData.dealId,
                                                },
                                                Constants.EVENT_TYPE.GRAITIFICATION,
                                            );
                                        }
                                    }
                                } catch (error) {
                                    console.error(error);
                                }
                            }
                        } catch (error) {
                            EventManager.sendEvent(
                                Constants.EVENT.AD_FAILED,

                                {
                                    error: error.error,
                                    ad_unit_id: adConfigData.adUnitId,
                                    cat: 'sponsored_marketplace',
                                },
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                        }
                    } else {
                        // if no type defined in config
                        console.error(
                            '[Err]: No nature of adunit is defined in for Sponsored Marketplace',
                        );
                    }
                }),
            );
        }
    } catch (error) {
        console.error('[Err] Sponsored Marketplace: ', error);
    }
};

// Trigger ad_imp event
export const dispatchAdImpEvents = (jobsData, spot) => {
    const job = jobsData.job;

    const shortDesc = replaceMacro(job.creativeDescription1);

    const urlPhone = Utils.filterInvalidUrlInput(
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE_NUMBER) || '',
    );

    let eventData = {
        adt: getJobTitle(jobsData),
        adst: jobsData.type === 'Dynamic' ? job.jobresult.company : shortDesc,
        adst2: jobsData.type === 'Dynamic' ? shortDesc : '',
        advid: jobsData.type === 'Dynamic' ? job.jobresult.advid : job.advId,
        advnm: jobsData.type === 'Dynamic' ? job.jobresult.advnm : job.advertiserName,
        cmpid: job.campaignId,
        cmpnm: job.campaignName,
        crid: job.creativeId,
        ervn: job.cpa || job.bid,
        offerid: job.offerid,
        max_deal_id: job.dealId,
        spotno: spot,
        rv_type: job.revType,
        cpa: job.cpa,
        cpc: job.cpc || job.bid,
        abt_exp: job.experiment,
        ext5: job.experiment,
        max_filters: job.max_filters,
        phone_number: urlPhone,
        cat: 'sponsored_marketplace',
        ad_unit_id: jobsData.adUnitId,
    };

    // Sending ad_imp event
    EventManager.sendEvent(Constants.EVENT.ADS_IMP, eventData, Constants.EVENT_TYPE.GRAITIFICATION);

    // Sending slot_imp_v Event
    EventManager.sendEvent(
        Constants.EVENT.AD_IMP_V,
        eventData,
        Constants.EVENT_TYPE.GRAITIFICATION,
    );
};

const replaceMacro = str => {
    const uuid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UUID);

    return MacrosHelper.replaceMacroParamsStringMemoized(str, uuid);
};

const getRedirectionUrl = ad => {
    if (ad.type === 'Static') return replaceMacro(ad.job.customAdInfo.link);

    if (ad.type === 'Dynamic') return replaceMacro(ad.job.jobresult.url);

    return '';
};

const getJobTitle = ad => {
    if (ad.type === 'Static') return replaceMacro(ad.job.creativeTitle);

    if (ad.type === 'Dynamic') return ad.job.jobresult.title;

    return '';
};

// Trigger ad_clk event
export const dispatchAdClkEvent = (jobsData, spot) => {
    const job = jobsData.job;
    const shortDesc = replaceMacro(job.creativeDescription1);

    const urlPhone = Utils.filterInvalidUrlInput(
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE_NUMBER) || '',
    );

    // Set cookie with expiry - 60min * 24hour * 7days = 10080 minutes

    if (jobsData.type === 'Dynamic') {
        Utils.setCookie(
            `${job.jobresult.title}-${job.jobresult.company}-${job.jobresult.advnm}`,
            '1',
            1440,
        );
    }

    if (jobsData.type === 'Static') {
        Utils.setCookie(
            `${replaceMacro(job.creativeTitle)}-${replaceMacro(job.dealId)}-${job.advertiserName}`,
            '1',
            1440,
        );
    }

    let eventData = {
        adt: getJobTitle(jobsData),
        adst: jobsData.type === 'Dynamic' ? job.jobresult.company : shortDesc,
        adst2: jobsData.type === 'Dynamic' ? shortDesc : '',
        advid: jobsData.type === 'Dynamic' ? job.jobresult.advid : job.advId,
        advnm: jobsData.type === 'Dynamic' ? job.jobresult.advnm : job.advertiserName,
        cmpid: job.campaignId,
        cmpnm: job.campaignName,
        crid: job.creativeId,
        offerid: job.offerid,
        max_deal_id: job.dealId,
        spotno: spot,
        job_redirect_url: getRedirectionUrl(jobsData),
        rv_type: job.revType,
        ervn: job.cpa || job.bid,
        cpa: job.cpa,
        cpc: job.cpc || job.bid,
        abt_exp: job.experiment,
        ext5: job.experiment,
        max_filters: job.max_filters,
        phone_number: urlPhone,
        cat: 'sponsored_marketplace',
        ad_unit_id: jobsData.adUnitId,
    };

    // Sending ad_clk event
    EventManager.sendEvent(
        Constants.EVENT.AD_CLICK,
        eventData,
        Constants.EVENT_TYPE.GRAITIFICATION,
    );

    if (job.revType === 'cpc') {
        EventManager.sendEvent(
            Constants.EVENT.REVENUE,
            eventData,
            Constants.EVENT_TYPE.GRAITIFICATION,
        );
    }

    // Sending ad_prog event
    EventManager.sendEventWhenVisible(
        Constants.EVENT.AD_PROG,
        eventData,
        Constants.EVENT_TYPE.GRAITIFICATION,
    );
    RecaptchaHelper.genRecaptcha();
};
