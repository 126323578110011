import React from 'react';
import { Link, Avatar, Typography, Button } from '@material-ui/core';
import './jobSearchView.css';
import RouterPrefixHandler from 'common/routePrefixHandler';
import Utils from 'common/utils';
import Constants from 'common/constants';
import eventManager from 'eventManager/eventManager';
import { ConfigProvider, FeatureMap } from 'providers';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronRightTwoTone } from '@material-ui/icons';
import { RecaptchaHelper } from 'helpers';
import { AbTestHelper, CommonHelper } from 'helpers/index';
import memoryStore from 'dataStore/memoryStore';
import memoryStoreKeys from 'common/memoryStoreKeys';
import UserDataStore from 'components/landing/userDataStore';

const images = [
    {
        link: '/images/placeholders/placeholder-img1.svg',
    },
    {
        link: '/images/placeholders/placeholder-img2.svg',
    },
    {
        link: '/images/placeholders/placeholder-img3.svg',
    },
    {
        link: '/images/placeholders/placeholder-img4.svg',
    },
    {
        link: '/images/placeholders/placeholder-img5.svg',
    },
];
const handleClick = ele => {
    console.log('clicked!!!!!!!');
    console.log(ele);
    window.l5_trk(ele);
};
const highlightProps = {
    0: {
        icon: 'rect-yellow',
        text: 'Urgently Hiring',
        styleCls: 'highlightImg1',
        textStyleCls: 'highlighttext1',
    },
    1: {
        icon: 'match',
        text: 'Match!',
        styleCls: 'highlightImg2',
        textStyleCls: 'highlighttext2',
        styleContainer: { display: 'inline-flex', alignItems: 'center' },
    },
    2: {
        icon: 'rect-green',
        text: 'Immediate Start',
        styleCls: 'highlightImg3',
        textStyleCls: 'highlighttext3',
    },
};

const renderHighlight = (index, showHighlight) => {
    if (showHighlight && index < 3) {
        let custom = highlightProps[index];

        return (
            <div className="job-highlightv2" style={custom.styleContainer}>
                <picture className="job-highlight-picture">
                    <source
                        srcSet={RouterPrefixHandler.getImagePath(`images/${custom.icon}.webp`)}
                        type="image/webp"
                    />
                    <img
                        // style={custom.styleImg}
                        alt={`img`}
                        className={`customimg ${custom.styleCls}`}
                        src={RouterPrefixHandler.getImagePath(`images/${custom.icon}.png`)}
                    />
                </picture>
                <span className={`job-highlight-text ${custom.textStyleCls}`}>{custom.text}</span>
            </div>
        );
    }

    return null;
};

const displayJobsList = props => {
    let {
        showLogo,
        showHighlight,
        jobsList,
        isJobDedup,
        newFulfillmentPage,
        staticListings = [],
        nativeAdListings = [],
        isFulfillmentDesignPhaseTwo,
    } = props;
    const fulfillmentPageDesignTest = ConfigProvider.Features.getFeature(
        FeatureMap.FulfillmentPageDesignTest,
    );
    const fulfillmentDesignEnable = fulfillmentPageDesignTest
        ? fulfillmentPageDesignTest.value === 'fulfillment-page-design'
        : false;
    const filteredJobsList = isJobDedup
        ? jobsList.filter(
              ({ title, company, advnm = '' }) => !Utils.getCookie(`${title}-${company}-${advnm}`),
          )
        : jobsList;

    let classes = useStyles();

    let userDetails = UserDataStore.fetchUserDetailsFromStore() || {};

    let phone = null;

    if (userDetails.phone_number) {
        phone = userDetails.phone_number;
    } else {
        phone = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE_NUMBER) || '',
        );
    }

    const getLinkBody = ({ logo, title, location, applyUrl, index, showHighlight, company }) => {
        if (newFulfillmentPage) {
            return (
                <div className={`job-element-card`} style={{ flexDirection: 'column' }}>
                    <div className={classes.cardDetailContainer}>
                        <div className="job-content-wrapper">
                            {showLogo && (
                                <div className={`job-logo-wrapper`}>
                                    <Avatar src={logo} variant={'circle'} />
                                </div>
                            )}
                            <div className={`job-description-wrapper`}>
                                <Typography className={classes.jobTitle} variant="h1">
                                    {title}
                                </Typography>
                                <Typography
                                    className={classes.jobLocation}
                                    style={{ color: '#8e8e8e' }}
                                    variant="h2">
                                    {company}
                                </Typography>
                            </div>
                        </div>
                        <div className={`job-apply-wrapper`}>
                            <Link
                                className={classes.arrowIcon}
                                color="primary"
                                href={applyUrl}
                                target="_blank">
                                <ChevronRightTwoTone />
                            </Link>
                        </div>
                    </div>
                    <span className={classes.underLine}></span>
                    <div className={classes.buttonContainer}>
                        <Link className={classes.newJobDescription} href={applyUrl} target="_blank">
                            View Description
                        </Link>
                        <Link
                            className={`${classes.newJobDescription}`}
                            href={applyUrl}
                            target="_blank">
                            View Salary
                        </Link>
                    </div>
                </div>
            );
        } else if (isFulfillmentDesignPhaseTwo) {
            return (
                <div className={`job-element-card`} style={{ flexDirection: 'column', padding: 0 }}>
                    <span className={classes.greyBoxHeader}>{title}</span>
                    <div className={classes.cardDetailContainer} style={{ paddingLeft: 10 }}>
                        <div className="job-content-wrapper">
                            {showLogo && (
                                <div className={`job-logo-wrapper`}>
                                    <Avatar src={logo} variant={'circle'} />
                                </div>
                            )}
                            <div className={`job-description-wrapper`}>
                                <Typography className={classes.jobTitle} variant="h1">
                                    {location}
                                </Typography>
                                <Typography
                                    className={classes.jobLocation}
                                    style={{ color: '#8e8e8e' }}
                                    variant="h2">
                                    {location}
                                </Typography>
                            </div>
                        </div>
                        <div className={`job-apply-wrapper`}>
                            <Link
                                className={classes.arrowIcon}
                                color="primary"
                                href={applyUrl}
                                target="_blank">
                                <ChevronRightTwoTone />
                            </Link>
                        </div>
                    </div>
                    <span className={classes.underLine}></span>
                    <div className={classes.buttonContainer}>
                        <Link className={classes.newJobDescription} href={applyUrl} target="_blank">
                            View Description
                        </Link>
                        <Link
                            className={`${classes.newJobDescription}`}
                            href={applyUrl}
                            target="_blank">
                            View Salary
                        </Link>
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className={`job-element-card ${
                        fulfillmentDesignEnable && 'job-element-card-fulfillment-design'
                    }`}>
                    <div className="job-content-wrapper">
                        {showLogo && (
                            <div
                                className={`job-logo-wrapper ${
                                    fulfillmentDesignEnable && 'job-logo-wrapper-fulfillment-design'
                                }`}>
                                <Avatar
                                    src={logo}
                                    variant={fulfillmentDesignEnable ? 'rounded' : 'circle'}
                                />
                            </div>
                        )}
                        <div
                            className={`job-description-wrapper ${
                                fulfillmentDesignEnable &&
                                'job-description-wrapper-fulfillment-design'
                            }`}>
                            <Typography className={classes.jobTitle} variant="h1">
                                {title}
                            </Typography>
                            {renderHighlight(index, showHighlight)}
                            <Typography className={classes.jobLocation} variant="h2">
                                {location}
                            </Typography>
                            <Link
                                className={`${classes.jobDescription} ${
                                    fulfillmentDesignEnable === true &&
                                    classes.fulfillmentDesignJobDescription
                                }`}
                                href={applyUrl}
                                target="_blank"
                                underline="always">
                                {fulfillmentDesignEnable
                                    ? 'View Salary & Description'
                                    : 'View Description'}
                            </Link>
                        </div>
                    </div>
                    <div
                        className={`job-apply-wrapper ${
                            fulfillmentDesignEnable && 'job-apply-wrapper-fulfillment-design'
                        }`}>
                        {!fulfillmentDesignEnable && (
                            <Button href={applyUrl} target="_blank">
                                View Salary
                            </Button>
                        )}
                        {fulfillmentDesignEnable && (
                            <Button
                                className="job-apply-button-fulfillment-design"
                                color="primary"
                                href={applyUrl}
                                target="_blank">
                                <picture>
                                    <source
                                        srcSet={RouterPrefixHandler.getImagePath(
                                            'images/left-arrow.webp',
                                        )}
                                        type="image/webp"
                                    />
                                    <img
                                        alt={`icon`}
                                        src={RouterPrefixHandler.getImagePath(
                                            'images/left-arrow.png',
                                        )}
                                    />
                                </picture>
                            </Button>
                        )}
                    </div>
                </div>
            );
        }
    };

    const listings = filteredJobsList.map((jobsData, index) => {
        let {
            location = '',
            url: applyUrl = '',
            title = '',
            logo = '',
            bid = undefined,
            company = '',
            advnm = '',
            advid,
            cat,
            description = '',
            experiment = '',
            cpa,
            cpc,
            max_filters,
            in_uuid,
        } = jobsData;

        if (logo === 'https://cdn.upward.net/company_logos/company_icon.png') {
            logo = RouterPrefixHandler.getImagePath(
                `${images[Math.floor(Math.random() * 4)].link}`,
            );
        }

        let shortDesc = '';

        if (description && !Utils.isNull(description)) {
            shortDesc = description.substring(0, 200);
        }

        return (
            <Link
                className={'link-container'}
                href={applyUrl}
                key={index}
                onClick={e => {
                    console.log('sending grat avail', jobsData);
                    CommonHelper.updateEncodedSource(jobsData.encodedSource);
                    // Set cookie with expiry - 60min * 24hour * 7days = 10080 minutes
                    Utils.setCookie(`${title}-${company}-${advnm}`, '1', 60 * 24 * 7);

                    if (props.mergedJobFeed) {
                        eventManager.sendEvent(
                            Constants.EVENT.AD_CLICK,
                            {
                                adt: title,
                                adst: company,
                                adst2: shortDesc,
                                advid,
                                advnm,
                                spotno: `${index}`,
                                job_redirect_url: applyUrl,
                                rv_type: jobsData.revType,
                                cpa,
                                cpc,
                                abt_exp: experiment,
                                ext5: experiment,
                                max_filters,
                                phone_number: phone,
                            },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                        eventManager.sendEventWhenVisible(
                            Constants.EVENT.AD_PROG,
                            {
                                adt: title,
                                adst: company,
                                adst2: shortDesc,
                                advid,
                                advnm,
                                spotno: `${index}`,
                                job_redirect_url: applyUrl,
                                rv_type: jobsData.revType,
                                cpa,
                                cpc,
                                abt_exp: experiment,
                                ext5: experiment,
                                max_filters,
                                phone_number: phone,
                            },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                        eventManager.sendEvent(
                            Constants.EVENT.GRATIFICATION_AVAIL,
                            {
                                adt: title,
                                adst: company,
                                adst2: shortDesc,
                                advid,
                                advnm,
                                spotno: `${index}`,
                                ervn: bid,
                                job_redirect_url: applyUrl,
                                rv_type: jobsData.revType,
                                cpa,
                                cpc,
                                abt_exp: experiment,
                                ext5: experiment,
                                max_filters,
                                phone_number: phone,
                            },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                        eventManager.sendEventWhenVisible(
                            Constants.EVENT.GRAT_PROG,
                            {
                                adt: title,
                                adst: company,
                                adst2: shortDesc,
                                advid,
                                advnm,
                                spotno: `${index}`,
                                ervn: bid,
                                job_redirect_url: applyUrl,
                                rv_type: jobsData.revType,
                                cpa,
                                cpc,
                                abt_exp: experiment,
                                ext5: experiment,
                                max_filters,
                                phone_number: phone,
                            },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                        RecaptchaHelper.genRecaptcha();
                    } else {
                        const { advid, encodedSource, in_uuid } = jobsData;
                        CommonHelper.updateEncodedSource(encodedSource);
                        const adUnitMap = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU);
                        const adSourceMeta =
                            memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA) || [];
                        const auMeta = adSourceMeta.find(i => i.uuid === in_uuid);

                        eventManager.sendEvent(
                            Constants.EVENT.GRATIFICATION_AVAIL,
                            {
                                adt: title,
                                adst: company,
                                adst2: shortDesc,
                                advid: advid,
                                cat,
                                advnm: Utils.getObjVal(adUnitMap, [in_uuid, 'advertiserName']),
                                cmpid: Utils.getObjVal(adUnitMap, [in_uuid, 'campaignId']),
                                cmpnm: Utils.getObjVal(adUnitMap, [in_uuid, 'campaignName']),
                                rv_type: Utils.getObjVal(adUnitMap, [in_uuid, 'revType']),
                                max_deal_id: Utils.getObjVal(adUnitMap, [in_uuid, 'dealId']),
                                uuid: in_uuid,
                                spotno: `${index}`,
                                ervn: bid,
                                job_redirect_url: applyUrl,
                                cpa,
                                cpc,
                                abt_exp: experiment,
                                ext5: experiment,
                                max_filters,
                                phone_number: phone,
                                ad_unit_id: auMeta && auMeta.adUnitId,
                            },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                        eventManager.sendEventWhenVisible(
                            Constants.EVENT.GRAT_PROG,
                            {
                                adt: title,
                                adst: company,
                                adst2: shortDesc,
                                advid: advid,
                                cat,
                                advnm: Utils.getObjVal(adUnitMap, [in_uuid, 'advertiserName']),
                                cmpid: Utils.getObjVal(adUnitMap, [in_uuid, 'campaignId']),
                                cmpnm: Utils.getObjVal(adUnitMap, [in_uuid, 'campaignName']),
                                rv_type: Utils.getObjVal(adUnitMap, [in_uuid, 'revType']),
                                max_deal_id: Utils.getObjVal(adUnitMap, [in_uuid, 'dealId']),
                                uuid: in_uuid,
                                spotno: `${index}`,
                                ervn: bid,
                                job_redirect_url: applyUrl,
                                cpa,
                                cpc,
                                abt_exp: experiment,
                                ext5: experiment,
                                max_filters,
                                phone_number: phone,
                                ad_unit_id: auMeta && auMeta.adUnitId,
                            },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                        RecaptchaHelper.genRecaptcha();
                    }

                    const adUnitMap = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU);
                    const adSourceMeta =
                        memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA) || [];
                    const auMeta = adSourceMeta.find(i => i.uuid === in_uuid);

                    if (bid) {
                        eventManager.sendEvent(
                            Constants.EVENT.RVN_EVENT_NAME,
                            {
                                ervn: bid,
                                advid: advid,
                                advnm: Utils.getObjVal(adUnitMap, [in_uuid, 'advertiserName']),
                                cmpnm: Utils.getObjVal(adUnitMap, [in_uuid, 'campaignName']),
                                max_deal_id: Utils.getObjVal(adUnitMap, [in_uuid, 'dealId']),
                                uuid: in_uuid,
                                spotno: `${index}`,
                                cpa,
                                cpc,
                                abt_exp: experiment,
                                ext5: experiment,
                                max_filters,
                                phone_number: phone,
                                ad_unit_id: auMeta && auMeta.adUnitId,
                            },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                    }

                    if (Utils.getObjVal(adUnitMap, [in_uuid, 'customAdInfo', 'triggerErvn'])) {
                        eventManager.sendEvent(
                            Constants.EVENT.REVENUE,
                            {
                                ervn: bid || 0,
                                cat,
                                advid: Utils.getObjVal(adUnitMap, [in_uuid, 'advId']),
                                advnm: Utils.getObjVal(adUnitMap, [in_uuid, 'advertiserName']),
                                cmpnm: Utils.getObjVal(adUnitMap, [in_uuid, 'campaignName']),
                                cmpid: Utils.getObjVal(adUnitMap, [in_uuid, 'campaignId']),
                                max_deal_id: Utils.getObjVal(adUnitMap, [in_uuid, 'dealId']),
                                spotno: `${index}`,
                                uuid: in_uuid,
                                cpa,
                                cpc,
                                abt_exp: experiment,
                                ext5: experiment,
                                max_filters,
                                phone_number: phone,
                                ad_unit_id: auMeta && auMeta.adUnitId,
                            },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                    }

                    handleClick(e.currentTarget);
                    console.log(e.target.href);
                }}
                target="_blank">
                {getLinkBody({
                    logo,
                    title,
                    location,
                    applyUrl,
                    shortDesc,
                    index,
                    showHighlight,
                    company,
                })}
            </Link>
        );
    });

    return Utils.mergePriorArray(nativeAdListings, Utils.mergePriorArray(staticListings, listings));
};

const JobListingsV2 = props => {
    const ShowTipBelowSERP = AbTestHelper.ShowTipBelowSERP();

    return (
        <div className="jobs-list-wrapper">
            {props.newFulfillmentPage ||
            props.isFulfillmentDesignPhaseTwo ||
            ShowTipBelowSERP ? null : (
                <Typography className="job-list-header">
                    <span className="bold">Tip:</span> Choose all jobs that you’re interested in,
                    we’ll send you alerts for more jobs like these.
                </Typography>
            )}
            {displayJobsList(props)}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    inputContainer: {
        display: 'flex',
        width: '100%',
    },
    jobTitle: {
        lineHeight: 1,
        letterSpacing: 0,
    },
    jobDescription: {
        color: '#1d5e97',
    },
    fulfillmentDesignJobDescription: {
        color: '#53ae02 !important',
    },
    jobLocation: {
        margin: '2px 0px',
    },
    textField: {
        boxShadow: 'none',
        position: 'relative',
        border: '1px solid #88a1ad',
        fontSize: 18,
        padding: '10px 15px',
        color: '#a8a8a8',
        height: 48,
        margin: '5px 0 15px',
        width: '82%',
        borderRadius: '2px',
        boxSizing: 'border-box',
        backgroundColor: '#fff',
    },
    searchBox: {
        width: '175%',
        marginRight: 8,
    },
    v2Button: {
        height: 'auto',
        fontSize: 24,
        overflow: 'hidden',
        whiteSpace: 'break-word',
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            lineHight: '20px',
            height: 'auto',
        },
    },
    button: {
        height: 'auto',
        fontSize: 24,
        overflow: 'hidden',
        whiteSpace: 'break-word',
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            lineHight: '20px',
            height: 'auto',
        },
    },
    btnIcon: {
        width: 20,
        height: 20,
        marginTop: '10px',
    },
    cardDetailContainer: {
        display: 'flex',
    },
    arrowIcon: {
        height: 25,
        width: 25,
        backgroundColor: '#5CB910',
        color: '#fff',
        borderRadius: '50%',
        margin: 'auto 20px',
        padding: 2,
    },
    underLine: {
        width: 'auto',
        height: 1,
        margin: '0px 10px 10px',
        borderBottom: 'solid 0.5px #d9d9d9',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 10px 10px',
    },
    newJobDescription: {
        color: '#007aff',
        textDecoration: 'none',
        fontSize: 14,
        fontWeight: 500,
    },
    boxShadowEffect: {
        boxShadow: '0 0 0 0 rgb(0 122 255)',
        transform: 'scale(1)',
        animation: 'pulse 2s infinite',
    },
    greyBoxHeader: {
        borderRadius: '4px 0px 0px',
        backgroundColor: '#e8e8e8',
        padding: '6px 8px',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.36,
        color: '#262831',
        marginBottom: 12,
    },
    '@global': {
        '.MuiInputBase-root.Mui-disabled': {
            '&:before': {
                borderBottomStyle: 'none',
            },
        },
        '.MuiInputBase-input.Mui-disabled': {
            opacity: 0.4,
        },
        '.MuiButton-endIcon': {
            margin: 0,
        },
        '.MuiButton-root': {
            minWidth: '50px',
        },
        '.MuiAvatar-root.MuiAvatar-circle': {
            width: '48px',
            height: '48px',
        },
        '.MuiLink-underlineHover': {
            '&:hover': {
                textDecoration: 'none',
            },
        },
        '.MuiAvatar-img': {
            objectFit: 'contain',
        },
    },
}));

export default JobListingsV2;
