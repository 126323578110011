import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Avatar, Divider } from '@material-ui/core';
import { useStyles } from './styles';
import RouterPrefixHandler from 'common/routePrefixHandler';
import { IdStore } from 'dataStore';
import Constants from 'common/constants';
import eventManager from 'eventManager/eventManager';
import { getOverlayJobProviderAuId } from 'helpers/featureValueHelpers/featureValueHelpers';
import Utils from 'common/utils';

const defaultKws = ['Delivery Driver', 'Warehouse', 'Customer Service', 'Stocker', 'Receptionist'];

const getKwList = () =>
    defaultKws.filter(kw => {
        if (!IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM)) return true;

        if (
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM) &&
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM)
                .toLowerCase()
                .includes(kw.toLowerCase())
        )
            return false;

        return true;
    });

export const SmsOverlay = props => {
    const classes = useStyles();

    const { data, handleClose, handleKwClick, createJid, ad, experiment } = props;

    let shortDesc = '';

    if (data.description && !Utils.isNull(data.description)) {
        shortDesc = data.description.substring(0, 200);
    }

    const urlPhone = Utils.filterInvalidUrlInput(
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE_NUMBER) || '',
    );

    const updateCookiesAndClose = () => {
        const jobVisitedId = createJid(data.company, data.title, data.location);
        updateVisitedJobsCookie(jobVisitedId);
        handleClose();
    };

    const adUnitId = getOverlayJobProviderAuId();

    async function triggerOnMount() {
        try {
            if (ad) {
                const eventData = {
                    advid: ad.advId,
                    advnm: ad.advertiserName,
                    cmpid: ad.campaignId,
                    cmpnm: ad.campaignName,
                    adt: data.title,
                    adst: data.company,
                    adst2: shortDesc,
                    rv_type: ad.revType,
                    cat: '2click',
                    uuid: data.in_uuid,
                    cpa: data.cpa,
                    cpc: data && data.bid ? data.bid : ad.bid,
                    ervn: data && data.bid ? data.bid : ad.bid,
                    abt_exp: experiment,
                    ext5: experiment,
                    max_filters: data.max_filters,
                    phone_number: urlPhone,
                    ad_unit_id: adUnitId,
                    max_deal_id: ad.dealId,
                };
                eventManager.sendEvent(
                    Constants.EVENT.ADS_IMP,
                    eventData,
                    Constants.EVENT_TYPE.GRAITIFICATION,
                );
                eventManager.sendEvent(
                    Constants.EVENT.AD_IMP_V,
                    {
                        ...eventData,
                    },
                    Constants.EVENT_TYPE.GRAITIFICATION,
                );
                eventManager.sendEvent(
                    Constants.EVENT.INTERSTITIAL_IMP,
                    {
                        ...eventData,
                        adt: data.title,
                        adst: data.company,
                        adst2: shortDesc,
                    },
                    Constants.EVENT_TYPE.GRAITIFICATION,
                );
            }
        } catch (e) {
            console.error('Failed to process ad for two click overlay', e);
            eventManager.sendEvent(
                Constants.EVENT.AD_FAILED,
                null,
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
            handleClose();
        }
    }

    useEffect(() => {
        triggerOnMount();
    }, []);

    const formLayout = {
        xs: 12,
    };

    const gridItemProps = {
        item: true,
        style: {
            width: '100%',
        },
        ...formLayout,
    };

    const updateVisitedJobsCookie = jobVisitedId => {
        const expiryTime = 5 * 24 * 60;
        Utils.setCookie(jobVisitedId, jobVisitedId, expiryTime);
    };

    const handleClick = e => {
        e.preventDefault();
        const url = data.url;
        window.open(url, '_blank');

        const eventData = {
            adt: data.title,
            adst: data.company,
            adst2: shortDesc,
            advid: ad.advId,
            advnm: ad.advertiserName,
            cmpid: ad.campaignId,
            cmpnm: ad.campaignName,
            rv_type: ad.revType,
            cat: '2click',
            uuid: data.in_uuid,
            cpa: data.cpa,
            cpc: data && data.bid ? data.bid : ad.bid,
            ervn: data && data.bid ? data.bid : ad.bid,
            abt_exp: experiment,
            ext5: experiment,
            max_filters: data.max_filters,
            phone_number: urlPhone,
            ad_unit_id: adUnitId,
            max_deal_id: ad.dealId,
        };

        eventManager.sendEvent(
            Constants.EVENT.AD_CLICK,
            {
                ...eventData,
                adt: data.title,
                adst: data.company,
                adst2: shortDesc,
                advid: ad.advId,
                advnm: ad.advertiserName,
                cmpid: ad.campaignId,
                cmpnm: ad.campaignName,
                rv_type: ad.revType,
                job_redirect_url: url,
                cat: '2click',
                uuid: data.in_uuid,
                phone_number: urlPhone,
                abt_exp: experiment,
                ext5: experiment,
            },
            Constants.EVENT_TYPE.GRAITIFICATION,
        );

        eventManager.sendEventWhenVisible(
            Constants.EVENT.AD_PROG,
            {
                ...eventData,
                adt: data.title,
                adst: data.company,
                adst2: shortDesc,
                advid: ad.advId,
                advnm: ad.advertiserName,
                cmpid: ad.campaignId,
                cmpnm: ad.campaignName,
                rv_type: ad.revType,
                job_redirect_url: url,
                cat: '2click',
                uuid: data.in_uuid,
                phone_number: urlPhone,
                abt_exp: experiment,
                ext5: experiment,
            },
            Constants.EVENT_TYPE.GRAITIFICATION,
        );

        if (ad.revType.toLowerCase() === 'cpc') {
            eventManager.sendEvent(
                Constants.EVENT.REVENUE,
                {
                    ervn: ad.bid,
                    ...eventData,
                },
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
        } else if (
            ad.revType.toLowerCase() === 'cpa' &&
            ad.customAdInfo &&
            ad.customAdInfo.useBid === '1' &&
            data.bid
        ) {
            eventManager.sendEvent(
                Constants.EVENT.REVENUE,
                {
                    ervn: data.bid,
                    ...eventData,
                },
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
        }

        updateCookiesAndClose();
    };

    const handleChipClick = event => {
        event.preventDefault();
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UTM_TERM, event.currentTarget.innerText);
        handleKwClick(event.currentTarget.innerText);
        eventManager.sendEvent(
            Constants.EVENT.INTERSTITIAL_CLOSE,
            {
                adt: data.title,
                adst: data.company,
                adst2: shortDesc,
                advid: ad.advId,
                advnm: ad.advertiserName,
                cmpid: ad.campaignId,
                cmpnm: ad.campaignName,
                cat: '2click',
                uuid: data.in_uuid,
                cpa: data.cpa,
                cpc: data && data.bid ? data.bid : ad.bid,
                ervn: data && data.bid ? data.bid : ad.bid,
                abt_exp: experiment,
                ext5: experiment,
                max_filters: data.max_filters,
                phone_number: urlPhone,
                max_deal_id: ad.dealId,
                ad_unit_id: adUnitId,
            },
            Constants.EVENT_TYPE.GRAITIFICATION,
        );
        updateCookiesAndClose();
    };

    const handleCrossClick = () => {
        eventManager.sendEvent(
            Constants.EVENT.INTERSTITIAL_CLOSE,
            {
                adt: data.title,
                adst: data.company,
                adst2: shortDesc,
                advid: ad.advId,
                advnm: ad.advertiserName,
                cmpid: ad.campaignId,
                cmpnm: ad.campaignName,
                cat: '2click',
                uuid: data.in_uuid,
                cpa: data.cpa,
                cpc: data && data.bid ? data.bid : ad.bid,
                ervn: data && data.bid ? data.bid : ad.bid,
                abt_exp: experiment,
                ext5: experiment,
                max_filters: data.max_filters,
                phone_number: urlPhone,
                ad_unit_id: adUnitId,
                max_deal_id: ad.dealId,
            },
            Constants.EVENT_TYPE.GRAITIFICATION,
        );
        updateCookiesAndClose();
    };

    const [isHidden, setIsHidden] = useState(true);

    return (
        <Grid>
            <div className={classes.headingTitle}>
                <Typography className={classes.headerStyle}>We found a job for you!</Typography>
                <div className={classes.closeIcon} onClick={handleCrossClick}>
                    <svg height="8" viewBox="0 0 8 8" width="8" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fillRule="evenodd">
                            <g fill="#fff" fillRule="nonzero">
                                <g>
                                    <g>
                                        <path
                                            d="M.411 7.976c-.105 0-.21-.04-.29-.12-.16-.16-.16-.42 0-.58L7.277.12c.16-.16.42-.16.58 0 .16.16.16.42 0 .58L.702 7.857c-.08.08-.186.12-.29.12z"
                                            transform="translate(-287 -140) translate(15 130) translate(272 10)"
                                        />
                                        <path
                                            d="M7.567 7.976c-.105 0-.21-.04-.29-.12L.121.701c-.16-.16-.16-.42 0-.58.16-.161.42-.161.58 0l7.156 7.155c.16.16.16.42 0 .58-.08.08-.185.12-.29.12z"
                                            transform="translate(-287 -140) translate(15 130) translate(272 10)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
            <Grid className={classes.root} container direction="column">
                <Grid {...gridItemProps}>
                    {data.logo ? (
                        <img alt="icon" className={classes.myImg} src={data.logo} />
                    ) : (
                        <Avatar className={classes.myImg} variant={'rounded'} />
                    )}
                    <Typography className={classes.header}>{data.title}</Typography>
                    <div onClick={() => setIsHidden(!isHidden)}>
                        <div className={classes.hidden}>
                            {' '}
                            <Typography className={classes.descText}>
                                {data.description}
                            </Typography>{' '}
                        </div>{' '}
                        <Button className={classes.toggleButton} onClick={handleClick} size="small">
                            {' '}
                            {<span className={classes.descTextSub}>Read more</span>}{' '}
                        </Button>{' '}
                    </div>
                    <a
                        href={data.url}
                        onClick={handleClick}
                        rel="noopener noreferrer"
                        target="_blank">
                        <Typography className={classes.linkText}>View Salary</Typography>
                    </a>
                    <Button
                        className={classes.v2button}
                        color="primary"
                        id="submitBtn"
                        name="confirm"
                        onClick={handleClick}
                        style={{
                            fontSize: 22,
                        }}
                        type="submit">
                        {
                            <span className={classes.viewJob}>
                                View Job{' '}
                                <img
                                    alt="icon"
                                    className={classes.arrow}
                                    src={RouterPrefixHandler.getImagePath('images/arrow2.png')}
                                />
                            </span>
                        }
                    </Button>
                </Grid>
            </Grid>
            <Grid>
                <Divider />
            </Grid>
            <Grid className={`${classes.root} ${classes.kwContainer}`} container direction="column">
                <Grid {...gridItemProps}>
                    <Typography className={classes.sub1}>
                        Didn't find what you were looking for?
                    </Typography>
                </Grid>
                <Grid {...gridItemProps}>
                    <Typography className={classes.sub2}>
                        Checkout these other popular searches:
                    </Typography>
                </Grid>
                <Grid className={classes.chip}>
                    {getKwList()
                        .splice(0, 4)
                        .map(kw => (
                            <Typography className={classes.mySpan} key={kw}>
                                <span onClick={handleChipClick}>{kw}</span>
                            </Typography>
                        ))}
                </Grid>
            </Grid>
        </Grid>
    );
};
