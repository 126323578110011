import Constants from 'common/constants';
import EventManager from 'eventManager/eventManager';
import Utils from 'common/utils';
import OfferManager from 'components/ads/offerManager';
import { IdStore } from 'dataStore/index';
import Network from 'common/network';
import UrlManager from 'common/urlManager';

// ------   Helpers ------- //
const _getBaseAdEventInfo = ad => {
    return {
        advid: ad.advId,
        advnm: ad.advertiserName,
        cmpid: ad.campaignId,
        cmpnm: ad.campaignName,
        ...OfferManager.getAdsData(ad),
    };
};

const _getJobInfoEventInfo = job => {
    return {
        adt: job.jobtitle || job.title || '',
        adst: job.company || '',
        adst2: (job.description ? job.description : '').substring(0, 200),
    };
};

// ------ Exports ------ //
export const fireAdImpressionEvent = (ad, eventType, extraData = {}, eventQueueKey, once) => {
    EventManager.sendQueuedEvent(
        Constants.EVENT.ADS_IMP,
        {
            ..._getBaseAdEventInfo(ad),
            ...extraData,
        },
        eventType,
        eventQueueKey,
        once,
    );
};

export const fireSlotImpVEvent = (ad, job, eventType, extraData = {}, eventQueueKey, once) => {
    const jobEventInfo = job ? _getJobInfoEventInfo(job, ad.advertiserName) : {};
    EventManager.sendQueuedEvent(
        Constants.EVENT.AD_IMP_V,
        {
            ..._getBaseAdEventInfo(ad),
            ...jobEventInfo,
            ...extraData,
        },
        eventType,
        eventQueueKey,
        once,
    );
};

export const fireSlotFailedEvent = (
    ad,
    errorMessage,
    eventType,
    extraData = {},
    eventQueueKey,
    once,
) => {
    const eventData = {
        ..._getBaseAdEventInfo(ad),
        error: errorMessage,
        ...extraData,
    };

    if (ad.customAdInfo && ad.customAdInfo.cpa) {
        eventData.cpa = ad.customAdInfo.cpa;
    }

    EventManager.sendQueuedEvent(
        Constants.EVENT.AD_FAILED,
        eventData,
        eventType,
        eventQueueKey,
        once,
    );
};

export const fireAdClickEvent = (ad, job = {}, eventType, extraData = {}, url) => {
    const jobEventInfo = job ? _getJobInfoEventInfo(job, ad.advertiserName) : {};
    const useBid = Utils.getObjVal(ad, ['customAdInfo', 'useBid']);
    const eventData = {
        ..._getBaseAdEventInfo(ad),
        ...jobEventInfo,
        ...extraData,
    };

    if (ad.customAdInfo.cpa) {
        eventData.cpa = ad.customAdInfo.cpa;
    }
    EventManager.sendEvent(
        Constants.EVENT.AD_CLICK,
        { ...eventData, job_redirect_url: url },
        eventType,
    );
    EventManager.sendEvent(
        Constants.EVENT.LANDING_AD_CLICK,
        eventData,
        Constants.EVENT_TYPE.LANDING,
    );

    // Use customAdInfo data to Add Bid Variation below
    if (
        ad[Constants.ADS.RESP_KEYS.REVENUE_TYPE] === Constants.ADS.REVENUE_TYPE.CPC ||
        useBid === '1'
    ) {
        EventManager.sendEvent(
            Constants.EVENT.REVENUE,
            {
                ervn: job ? job.bid : ad.bid,
                ...eventData,
            },
            eventType,
        );
    }

    if (ad.onClickTrackingPixels && ad.onClickTrackingPixels.length) {
        const adsData = OfferManager.getAdsData(ad);
        const eventUrl = EventManager.createAndGetEventUrl(
            Constants.EVENT.MAX_CLICK,
            adsData,
            Constants.EVENT_TYPE.ADS,
        );
        const clickParams = {
            url: encodeURIComponent(ad.onClickTrackingPixels[0]),
            eventUrl: encodeURIComponent(eventUrl),
        };
        Network.get(
            `${UrlManager.getAdClickUrl()}?${Utils.encodeObjectToUrlArgs(clickParams)}`,
        ).catch(err => {
            console.log(err);
        });
    }
};

export const fireAdClickEventV1 = (ad, job = {}, eventType, extraData = {}, url, uuid) => {
    const jobEventInfo = job ? _getJobInfoEventInfo(job, ad.advertiserName) : {};
    const useBid = Utils.getObjVal(ad, ['customAdInfo', 'useBid']);
    const eventData = {
        ..._getBaseAdEventInfo(ad),
        ...jobEventInfo,
        ...extraData,
    };

    if (eventData.rvn) {
        eventData['ervn'] = eventData.rvn;
        delete eventData.rvn;
    }

    if (uuid) {
        eventData['uuid'] = uuid;
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UUID, uuid);
    }

    if (ad.customAdInfo.cpa) {
        eventData.cpa = ad.customAdInfo.cpa;
    }
    EventManager.sendEvent(
        Constants.EVENT.AD_CLICK,
        { ...eventData, job_redirect_url: url },
        eventType,
    );
    EventManager.sendEvent(
        Constants.EVENT.LANDING_AD_CLICK,
        { ...eventData, job_redirect_url: url },
        Constants.EVENT_TYPE.LANDING,
    );

    // Use customAdInfo data to Add Bid Variation below
    if (
        ad[Constants.ADS.RESP_KEYS.REVENUE_TYPE] === Constants.ADS.REVENUE_TYPE.CPC ||
        useBid === '1'
    ) {
        EventManager.sendEvent(
            Constants.EVENT.REVENUE,
            {
                ervn: job ? job.bid : ad.bid,
                ...eventData,
                job_redirect_url: url,
            },
            eventType,
        );
    }

    if (ad.onClickTrackingPixels && ad.onClickTrackingPixels.length) {
        const adsData = OfferManager.getAdsData(ad);
        const eventUrl = EventManager.createAndGetEventUrl(
            Constants.EVENT.MAX_CLICK,
            adsData,
            Constants.EVENT_TYPE.ADS,
        );
        const clickParams = {
            url: encodeURIComponent(ad.onClickTrackingPixels[0]),
            eventUrl: encodeURIComponent(eventUrl),
        };
        Network.get(
            `${UrlManager.getAdClickUrl()}?${Utils.encodeObjectToUrlArgs(clickParams)}`,
        ).catch(err => {
            console.log(err);
        });
    }
};

export const fireAdProgEvent = (ad, job, eventType, extraData = {}) => {
    const eventData = {
        ..._getBaseAdEventInfo(ad),
        ...(job ? _getJobInfoEventInfo(job, ad.advertiserName) : {}),
        ...extraData,
    };

    EventManager.sendEventWhenVisible(Constants.EVENT.AD_PROG, eventData, eventType);
};

export const fireAdProgEventV1 = (ad, job, eventType, uuid, extraData = {}) => {
    const eventData = {
        ..._getBaseAdEventInfo(ad),
        ...(job ? _getJobInfoEventInfo(job, ad.advertiserName) : {}),
        ...extraData,
    };

    if (eventData.rvn) {
        eventData['ervn'] = eventData.rvn;
        delete eventData.rvn;
    }

    if (uuid) {
        eventData['uuid'] = uuid;
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UUID, uuid);
    }

    EventManager.sendEvent(Constants.EVENT.AD_PROG, eventData, eventType);
};

export const fireAdProgEventV2 = (ad, job, eventType, uuid, extraData = {}) => {
    const eventData = {
        ..._getBaseAdEventInfo(ad),
        ...(job ? _getJobInfoEventInfo(job, ad.advertiserName) : {}),
        ...extraData,
    };

    if (eventData.rvn) {
        eventData['ervn'] = eventData.rvn;
        delete eventData.rvn;
    }

    if (uuid) {
        eventData['uuid'] = uuid;
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UUID, uuid);
    }

    EventManager.sendEventWhenVisible(Constants.EVENT.AD_PROG, eventData, eventType);
};

export const fireAdSkipEvent = (ad, job, eventType, extraData = {}) => {
    const eventData = {
        ..._getBaseAdEventInfo(ad),
        ...(job ? _getJobInfoEventInfo(job, ad.advertiserName) : {}),
        ...extraData,
    };
    EventManager.sendEvent(Constants.EVENT.AD_SKIP, eventData, eventType);
};
