import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'styles/common.css';
import Utils from 'common/utils';

class ErrorView extends Component {
    render() {
        let errText = 'Encountered an error, Please try again later';

        if (false === Utils.isEmptyStr(this.props.errText)) {
            errText = this.props.errText;
        }
        let errHeading = 'Something went wrong!';

        if (false === Utils.isEmptyStr(this.props.errHeading)) {
            errHeading = this.props.errHeading;
        }

        return (
            <div className="err-wrapper">
                <div className="err-heading">{errHeading}</div>
                <div className="err-text">{errText}</div>
            </div>
        );
    }
}

ErrorView.propTypes = {
    errHeading: PropTypes.string,
    errText: PropTypes.string,
};

const styles = theme => ({});
export default withStyles(styles)(ErrorView);
