import React from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { getUserFromCache } from 'eventManager/eventHelpers/devEvents';

export const useFormSubmitHandler = (onSubmit, timeout = 5000) => {
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [timeoutRef, setTimeOutRef] = React.useState(null);
    const handleSubmitCb = e => {
        if (!isSubmitting) {
            const probablePromise = onSubmit(e);

            if (probablePromise && probablePromise.finally) {
                probablePromise.finally(() => {
                    timeoutRef && clearTimeout(timeoutRef);
                    console.log('Form Handler: Form Complete');
                    setIsSubmitting(false);
                    setTimeOutRef(null);
                });
            }

            if (timeout) {
                setTimeOutRef(
                    setTimeout(() => {
                        setIsSubmitting(false);
                        console.log('Form Handler: Form Timeout');
                    }, timeout),
                );
            }
            setIsSubmitting(true);
            getUserFromCache();

            return probablePromise;
        }
    };

    return [handleSubmitCb, isSubmitting];
};

const ValidatorFormHOCInternal = (props, ref) => {
    const { onSubmit, timeout, ...rest } = props;
    const [handleSubmitCb] = useFormSubmitHandler(onSubmit, timeout);

    return <ValidatorForm {...rest} onSubmit={handleSubmitCb} ref={ref} />;
};

export const ValidatorFormHOC = React.forwardRef(ValidatorFormHOCInternal);
