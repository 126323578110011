import React from 'react';
import UserDetails from 'models/userDetails';
import UserDetailsDataSource from 'datasource/userDetailsDataSource';
import Utils from 'common/utils';
import Constants from 'common/constants';
import LandingView from './landingView';
import { IdStore } from 'dataStore';
import EventManager from 'eventManager/eventManager';
import { ErrLogger } from 'errLogger';
import UserDataStore from './userDataStore';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import RoutePrefixHandler from 'common/routePrefixHandler';
import { AbTestHelper } from 'helpers';
import { getConcatAddressField } from 'eventManager/eventHelpers/eventDataHelpers';
import {
    evaluateUserStatusOnAPIResponse,
    evaluateUserStatusOnUserRegCompletion,
    isAPIRepeatUser,
    setUserId,
} from 'helpers/session/sessionHelpers';
import { fireNetSpendLead } from 'eventManager/eventHelpers/hnpEvents';
import * as moment from 'moment';
import { shouldFireNocSms } from 'helpers/featureValueHelpers/featureValueHelpers';

class LandingContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isRegisteringUser: false,
        };
        this.userId = null;
        this.userDetails = null;
        this.surveyId =
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.SURVEY_ID) |
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SURVEY_ID);
    }

    componentDidMount() {
        this.props.fireQueueOnMount();
    }

    render() {
        const { classes } = this.props;
        let progressBar = <span></span>;

        if (this.state.isRegisteringUser) {
            progressBar = (
                <div className={classes.root}>
                    <LinearProgress />
                </div>
            );
        }
        const showProgress = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.DYNAMIC_XML_FEED);

        return (
            <React.Fragment>
                {!showProgress && !AbTestHelper.BrandedLanderTest() && progressBar}
                <LandingView
                    dynFeedDetails={this.props.dynFeedDetails}
                    history={this.props.history}
                    pageCompleteCb={this.pageCompleteCb.bind(this)}
                    registerUser={this.registerUser}
                    skipUserDetailsCb={this.skipUserRegistration.bind(this)}
                />
            </React.Fragment>
        );
    }

    skipUserRegistration(userDetailsObj) {
        /*
         * Send the user-data silently
         * Store the original user-details data from the client
         */
        UserDataStore.storeUserDataInCache(userDetailsObj);
        this.userDetails = new UserDetails(userDetailsObj);

        if (isAPIRepeatUser() && !Utils.getObjVal(this.userDetails, ['address', 'line1'])) {
            this.userDetails.address = null;
        }
        // Not handling the response
        UserDetailsDataSource.updateUserDetails(this.userDetails, true).catch(e => {
            console.log(
                'Not able to pass any data into the user-details when registration is skipped',
            );
        });

        // Send an event for skip-here
        if (
            !AbTestHelper.ConfirmationPageTest() ||
            !AbTestHelper.ConfirmationPageTestV2() ||
            !AbTestHelper.ConfirmationPageTestV3()
        ) {
            EventManager.sendEvent(
                Constants.EVENT.USER_REGISTRATION_SKIP,
                this.userDetails.getFormattedObjForEvent(),
                Constants.EVENT_TYPE.REGISTRATION,
            );
        }

        this.pageCompleteCb(
            userDetailsObj,
            err => {
                if (!Utils.isNull(err)) {
                    console.log('ERR: Got error when sending user-details - ', err);
                    this.setState({
                        reqErr: err,
                    });
                }
            },
            true,
        );
    }

    registerUser = (userDetailsObj, skip, userRegComplete, options = {}) => {
        console.log('USER_DETAILS: ', userDetailsObj);
        const evs = userDetailsObj.evs;
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.EMAIL_VERIFICATION, evs);
        const isIntermediate = Utils.getObjVal(options, ['isIntermediate']);

        userDetailsObj.contact_consent = skip ? '0' : '1';

        if (isIntermediate) {
            delete userDetailsObj.contact_consent;
        }

        // Store the original user-details data from the client
        UserDataStore.storeUserDataInCache(userDetailsObj);
        this.userDetails = new UserDetails(userDetailsObj);

        if (!Utils.getObjVal(this.userDetails, ['address', 'line1'])) {
            this.userDetails.address = null;
        }

        if (isIntermediate) {
            delete this.userDetails.contactConsent;
        }

        return UserDetailsDataSource.updateUserDetails(this.userDetails).then(userRespData => {
            const respErr = this.processUserRegistrationResponse(
                userRespData,
                userRegComplete,
                options,
            );

            // Send user-reg event with data
            if (Utils.isNull(this.userDetails)) {
                return new Error('cannot send user-reg-event since userDetails is empty!');
            }

            const userStatusCurrent = IdStore.fetchIdForKey(
                Constants.ID_STORE_KEYS.USER_STATUS_CURRENT,
            );
            const userStatus = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS);

            const isNewUser =
                (userStatusCurrent === 'reg_same_device' && userStatus === 'new') ||
                (userStatusCurrent === 'reg_same_device' && userStatus === 'non_reg') ||
                (userStatusCurrent === 'reg_same_device' && userStatus === 'subscribed');

            if (!isIntermediate && !AbTestHelper.skipUserRegEvents()) {
                EventManager.sendEvent(
                    Constants.EVENT.USER_REGISTRATION_COMPLETE,
                    this.userDetails.getFormattedObjForEvent(),
                    Constants.EVENT_TYPE.REGISTRATION,
                );
            }

            if (
                !isIntermediate &&
                userDetailsObj.contact_consent === '1' &&
                shouldFireNocSms() === '1' &&
                isNewUser
            ) {
                EventManager.sendEvent(
                    Constants.EVENT.HNP_LEAD,
                    { ...this.userDetails.getFormattedObjForEvent(), cat: 'noc_sms' },
                    Constants.EVENT_TYPE.DATA,
                );
            }
            // updates if user is registered successfully
            UserDataStore.updateRegistrationStatus(true);

            return [userRespData, respErr];
        });
    };

    /**
     * This method does the following -
     * - Validate the userDetailsObj
     * - If invalid, send the error in callback.
     * - If valid, send the data to remote and create a new user-id.
     * - If sending the user-data has issues, then error object is sent in callback.
     * @param {Object} userDetailsObj
     * @param {func(Error)} processFinishCb
     */
    pageCompleteCb(userDetailsObj, processFinishCb, skip) {
        console.log('USER_DETAILS: ', userDetailsObj);

        // Show the loader
        this.setState({
            isRegisteringUser: true,
        });
        this.registerUser(userDetailsObj, skip, true)
            .then(([_userDetails, respErr]) => {
                processFinishCb(respErr);
                this.onPageFinish();
            })
            .catch(err => {
                processFinishCb(err);
                this.onPageFinish();
            })
            .finally(() => {
                this.setState({
                    isRegisteringUser: false,
                });
            });

        const showReg3Screen = AbTestHelper.regThreeTest() || AbTestHelper.listingBgRegThreeTest();
        const isSkipClicked = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SKIP_TCPA_CLICKED);

        if (!showReg3Screen || AbTestHelper.prePopFlowV3()) {
            let jt =
                IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL) ||
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL) ||
                Constants.DEFAULT_KW;
            let leadId =
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REG2_LEADID) ||
                (document.getElementById('leadid_token') &&
                    document.getElementById('leadid_token').value);
            let tfCertUrl =
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REG2_TRUSTED_FORM_CERT_URL) ||
                (document.getElementById('xxTrustedFormToken_0') &&
                    document.getElementById('xxTrustedFormToken_0').value);
            const sid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SURVEY_ID);
            const eventData = {
                cat: 'user',
                first_name: this.userDetails.firstName,
                last_name: this.userDetails.lastName,
                email: this.userDetails.emailId,
                gender: this.userDetails.gender,
                address: getConcatAddressField(this.userDetails.address),
                phone_number: this.userDetails.phoneNumber,
                optxt:
                    AbTestHelper.ConfirmationPageTest() ||
                    AbTestHelper.ConfirmationPageTestV2() ||
                    AbTestHelper.ConfirmationPageTestV3()
                        ? ''
                        : sid === Constants.SID_SMS_COMPLIANCE
                        ? Constants.TCPA_OPTIN_TEXT_SMS
                        : `${Constants.TCPA_OPTIN_TEXT + Constants.TCPA_OPTIN_TEXT_CONT}`,
                evs: this.userDetails.evs,
                job_type: jt,
                leadid: leadId,
                tf_curl: tfCertUrl,
            };

            if (!skip && AbTestHelper.fireHnpCatUserLead() && !AbTestHelper.skipUserRegEvents()) {
                EventManager.sendEvent(
                    Constants.EVENT.HNP_LEAD,
                    eventData,
                    Constants.EVENT_TYPE.DATA,
                );
            }

            if (!IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UPWARDRGFLOW)) {
                const reg2EventData = {
                    ...eventData,
                    cat: 'reg2',
                };

                if (AbTestHelper.zipAndEmailLandingTest() && !AbTestHelper.repeatUserv3())
                    EventManager.sendEvent(
                        Constants.EVENT.HNP_LEAD,
                        { ...eventData, cat: Constants.EVENT.MJH_REG2_ALL },
                        Constants.EVENT_TYPE.DATA,
                    );
                else {
                    if (!AbTestHelper.skipUserRegEvents()) {
                        if (
                            !AbTestHelper.employmentStatusTest() &&
                            !AbTestHelper.prePopFlowTest() &&
                            !AbTestHelper.repeatUserv3()
                        ) {
                            fireNetSpendLead();
                        }
                    }

                    if (
                        !AbTestHelper.skipUserRegEvents() &&
                        !isSkipClicked &&
                        !AbTestHelper.repeatUserv3()
                    ) {
                        EventManager.sendEvent(
                            Constants.EVENT.HNP_LEAD,
                            reg2EventData,
                            Constants.EVENT_TYPE.DATA,
                        );
                    }
                }
            }
        }

        this.fireAppcastTrackingPixel();
    }

    fireAppcastTrackingPixel = () => {
        this.source = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE);

        if (this.source === 'xapp') {
            Utils.addScript({
                type: 'text/javascript',
                src: 'https://click.appcast.io/pixels/generic3-11639.js?ent=196',
            });
        }
    };

    processUserRegistrationResponse(respData, userRegComplete, options) {
        if (Utils.isNull(respData)) {
            return new Error('user response data is empty!');
        }

        const isIntermediate = Utils.getObjVal(options, ['isIntermediate']);

        let respObj = respData['resp'];

        if (false === Utils.isNull(respObj) && false === Utils.isNull(respObj.user)) {
            this.userId = respObj.user.id;
        }
        console.log('USER_DETAILS: Got user-data in response - ', respData);
        console.log('USER_DETAILS: User id -', this.userId);

        if (Utils.isNull(this.userId)) {
            return new Error('could not fetch user-id');
        }

        const meta = Utils.getObjVal(respObj, ['meta']);
        UserDataStore.storeUserDataInCache({ meta });

        if (isIntermediate) {
            evaluateUserStatusOnAPIResponse(meta.registrationStatus);
        }

        if (isIntermediate && meta && meta.registrationStatus !== 'New') {
            const currentUserCache = UserDataStore.fetchUserDetailsFromStore();

            const filteredUserCache = Object.keys(currentUserCache).reduce((acc, key) => {
                if (currentUserCache[key]) {
                    acc[key] = currentUserCache[key];
                }

                return acc;
            }, {});

            if (!Utils.getObjVal(filteredUserCache, ['address', 'line1'])) {
                delete filteredUserCache.address;
            }

            const respUserDetail = Utils.snakeCaseKeysShallow(respObj.user || {});
            delete respUserDetail.email_id;

            if (
                respUserDetail.dob &&
                !Utils.isEmptyStr(respUserDetail.dob) &&
                moment(respUserDetail.dob).isValid()
            ) {
                respUserDetail.dob = moment(respUserDetail.dob).format('YYYY-MM-DD');
            } else {
                console.error('[User][Critical] Invalid DOB received from use API');
            }

            const newUserCache = { ...filteredUserCache, ...respUserDetail };
            const newLocation = Utils.getObjVal(newUserCache, ['location']);

            if (newLocation && newLocation.zip) {
                newLocation.zip =
                    Utils.getObjVal(filteredUserCache, ['location', 'zip']) || newLocation.zip;
                newUserCache.zip = newLocation.zip;
            }
            newUserCache.zip = Utils.getObjVal(newUserCache, ['location', 'zip']) || '';

            UserDataStore.storeUserDataInCache(newUserCache);
            this.userDetails = new UserDetails(UserDataStore.fetchUserDetailsFromStore());
        }

        if (userRegComplete && !isIntermediate) {
            // Add uid to the store
            this.storeNewUserId(this.userId);
            evaluateUserStatusOnUserRegCompletion();

            // Store the user-resp
            try {
                let rawResp = JSON.stringify(respObj);
                let userRespHash = btoa(rawResp);
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.USER_RESP_HASH_ID, userRespHash);
            } catch (e) {
                console.log('ERR: Cannot store the raw-resp as hash', e);
                ErrLogger.log(e);
            }
        }
    }

    storeNewUserId(newUserId) {
        if (Utils.isNull(newUserId)) {
            console.log("Error storing the user-id since it's empty!");

            return;
        }

        // Check for presence of an old user-id
        let oldUserId = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_ID);

        // Update the new user-id
        setUserId(newUserId);

        // Call the replace-user event if the old-user-id is present
        if (false === Utils.isNull(oldUserId) && oldUserId !== newUserId) {
            let eventData = {
                oldUserId: oldUserId,
                newUserId: newUserId,
            };
            console.log('Updating user-details');
            EventManager.sendEvent(
                Constants.EVENT.REPLACE_USER,
                eventData,
                Constants.EVENT_TYPE.REGISTRATION,
            );
        }
    }

    // PRAGMA-MARK :- Page finish

    /**
     *  This is called finally when the page is ready to move on.
     */
    onPageFinish() {
        // Validate user-id and survey-id before sending to next page
        if (Utils.isEmptyStr(this.userId) || Utils.isEmptyStr(this.surveyId)) {
            let errMsg = 'ERR: Cannot finish processing page';

            if (Utils.isEmptyStr(this.userId)) {
                errMsg = 'ERR: Cannot finish processing page since user-id is empty!';
            } else if (Utils.isEmptyStr(this.surveyId)) {
                errMsg = 'ERR: Cannot finish processing page since survey-id is empty!';
            }
            console.log(errMsg);
            ErrLogger.logStr(errMsg);

            return;
        }

        const isIdAbsent =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REG2_LEADID) &&
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REG2_TRUSTED_FORM_CERT_URL);

        if (AbTestHelper.setS2oFlagTest()) {
            const surveyPath = {
                pathname: RoutePrefixHandler.generateActualPathname(
                    Constants.PAGE_ENDPOINTS.SURVEY,
                ),
                search: Utils.addParamsToUrl({ s2o: 1 }),
            };

            this.props.history.push(surveyPath);
        }

        if (Utils.isNull(isIdAbsent)) {
            this.props.history.push({
                pathname: RoutePrefixHandler.generateActualPathname(Constants.PAGE_ENDPOINTS.ADS),
                search: window.location.search,
            });
        } else if (
            !AbTestHelper.oldUserJobcaseOffer() &&
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LB_AU_FAIL) !== 1
        ) {
            this.props.history.push({
                pathname: RoutePrefixHandler.generateActualPathname(
                    Constants.PAGE_ENDPOINTS.SURVEY,
                ),
                search: window.location.search,
            });
        } else {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.LB_AU_FAIL, 0);
        }
    }
}

const styles = theme => ({});

export default withStyles(styles)(LandingContainer);
