const DEFAULT_LOGO_MAPPING = {
    'auto hauler': 'auto',
    'auto mechanic': 'auto',
    'auto parts': 'auto',
    'auto technician': 'auto',
    'automotive center manager': 'auto',
    'automotive mechanic': 'auto',
    'automotive service': 'auto',
    'automotive technician': 'auto',
    'body shop technician': 'auto',
    'car detailer': 'auto',
    'collision estimator': 'auto',
    'diesel mechanic': 'auto',
    'diesel technician': 'auto',
    'fleet manager': 'auto',
    'fleet mechanic': 'auto',
    'lube tech': 'auto',
    mechanic: 'auto',
    midas: 'auto',
    'nissan technician': 'auto',
    'parts advisor': 'auto',
    'parts clerk': 'auto',
    'parts coordinator': 'auto',
    'parts counter': 'auto',
    'parts department': 'auto',
    'parts manager': 'auto',
    'truck technician': 'auto',
    'banking entry level': 'banking entry level',
    'bank manager': 'banking entry level',
    banker: 'banking entry level',
    'banking specialist': 'banking entry level',
    'branch service leader': 'banking entry level',
    'cash services representative': 'banking entry level',
    'financial advisor': 'banking entry level',
    'financial services representative': 'banking entry level',
    'loan consultant': 'banking entry level',
    'loan officer': 'banking entry level',
    'mortgage banker': 'banking entry level',
    'mortgage consultant': 'banking entry level',
    'mortgage coordinator': 'banking entry level',
    'mortgage lender': 'banking entry level',
    'mortgage loan originator': 'banking entry level',
    'mortgage processor': 'banking entry level',
    'mortgage trainee': 'banking entry level',
    'personal banker': 'banking entry level',
    'private client advisor': 'banking entry level',
    'relationship banker': 'banking entry level',
    'store manager': 'retail hourly',
    teller: 'banking entry level',
    underwriter: 'banking entry level',
    'universal associate': 'banking entry level',
    'universal banker': 'banking entry level',
    beautician: 'beauty',
    beauty: 'beauty',
    cosmetic: 'beauty',
    cosmetologist: 'beauty',
    cosmetology: 'beauty',
    esthetician: 'beauty',
    'hair and makeup': 'beauty',
    'hair styling': 'beauty',
    hairdresser: 'beauty',
    hairstylist: 'beauty',
    jewelry: 'beauty',
    makeup: 'beauty',
    'nail technician': 'beauty',
    'salon designer': 'beauty',
    'salon leader': 'beauty',
    'salon manager': 'beauty',
    'spa supervisor': 'beauty',
    stylist: 'beauty',
    'wax specialist': 'beauty',
    'account associate': 'business sales',
    'account development': 'business sales',
    'account director': 'business sales',
    'account executive': 'business sales',
    'account manager': 'business sales',
    'account representative': 'business sales',
    'account sales': 'business sales',
    'assistant director': 'business sales',
    'auto sales': 'business sales',
    'automotive sales': 'business sales',
    'bilingual call center': 'business sales',
    'business development': 'business sales',
    'business sales': 'business sales',
    'business to business': 'business sales',
    'call center': 'sales support',
    'call center agent': 'sales support',
    'call center associate': 'sales support',
    'call center manager': 'sales support',
    'call center representative': 'sales support',
    'call center specialist': 'sales support',
    'call center team lead': 'sales support',
    'category leader': 'business sales',
    'category manager': 'business sales',
    'client development': 'business sales',
    'client executive': 'business sales',
    'client solutions': 'business sales',
    'client success': 'business sales',
    'counter sales': 'business sales',
    'direct sales': 'business sales',
    'director sales': 'business sales',
    'entry level sales': 'business sales',
    'field sales': 'business sales',
    'inside sales': 'business sales',
    'insurance financial sales': 'business sales',
    'leasing consultant': 'business sales',
    'medical sales': 'business sales',
    'outbound sales': 'business sales',
    'outside sales': 'business sales',
    'pharmaceutical sales': 'business sales',
    'regional manager': 'business sales',
    sales: 'business sales',
    'sales agent': 'business sales',
    'sales analyst': 'business sales',
    'sales consultant': 'retail hourly',
    'sales development': 'business sales',
    'sales executive': 'business sales',
    'sales intern': 'business sales',
    'sales lead': 'business sales',
    'sales manager': 'retail hourly',
    'sales marketing': 'business sales',
    'sales officer': 'business sales',
    'sales plan': 'business sales',
    // "sales representative": "travel hospitality",
    'sales support': 'business sales',
    salesperson: 'business sales',
    telemarketer: 'business sales',
    telesales: 'business sales',
    'territory representative': 'business sales',
    'territory sales': 'business sales',
    'timeshare sales': 'business sales',
    'vendor sales': 'business sales',
    'building inspector': 'construction',
    'building maintenance': 'construction',
    'building manager': 'construction',
    carpenter: 'construction',
    carpentry: 'construction',
    concrete: 'construction',
    construction: 'construction',
    'construction crew': 'construction',
    'construction management': 'construction',
    electrician: 'construction',
    flooring: 'construction',
    foreman: 'construction',
    foreperson: 'construction',
    'general contract': 'construction',
    'general contractor': 'construction',
    'home remodel': 'construction',
    installer: 'construction',
    landscape: 'construction',
    plumber: 'construction',
    'roof installation': 'construction',
    'roof repair': 'construction',
    roofer: 'construction',
    surveyor: 'construction',
    'academic instructor': 'education',
    'adjunct faculty': 'education',
    'adjunct instructor': 'education',
    admission: 'education',
    'campus representative': 'education',
    'dance instructor': 'education',
    'education coordinator': 'education',
    faculty: 'education',
    'golf instructor': 'education',
    'higher education': 'education',
    'instructional design': 'education',
    'library assistant': 'education',
    'mcat instructor': 'education',
    paraeducator: 'education',
    professor: 'education',
    'sign language': 'education',
    'spanish instructor': 'education',
    'special education': 'education',
    'student records': 'education',
    teacher: 'education',
    tutor: 'education',
    'varsity baseball': 'education',
    'varsity soccer': 'education',
    'varsity softball': 'education',
    'varsity track': 'education',
    'voice instructor': 'education',
    'automation engineer': 'engineering',
    'chemical engineer': 'engineering',
    'civil engineer': 'engineering',
    'electrical engineer': 'engineering',
    'electronics technician': 'engineering',
    'engineer technician': 'engineering',
    'engineering specialist': 'engineering',
    'facilities engineer': 'engineering',
    'field engineer': 'engineering',
    geotechnical: 'engineering',
    'industrial engineer': 'engineering',
    'manufacturing engineer': 'engineering',
    'mechanical engineer': 'engineering',
    'metallurgical engineer': 'engineering',
    'noise engineer': 'engineering',
    'optical engineer': 'engineering',
    'package engineer': 'engineering',
    'packaging engineer': 'engineering',
    'process engineer': 'engineering',
    'product engineer': 'engineering',
    'project engineer': 'engineering',
    'standards engineer': 'engineering',
    'audio record': 'events',
    'cake decorator': 'events',
    caterer: 'events',
    catering: 'events',
    dj: 'events',
    'event coordinator': 'events',
    'event planner': 'events',
    'event specialist': 'events',
    'face paint': 'events',
    'fashion design': 'events',
    'fence installation': 'events',
    floral: 'events',
    florist: 'events',
    'home stager': 'events',
    'interior design': 'events',
    'junk removal': 'events',
    magician: 'events',
    musician: 'events',
    'party rental': 'events',
    'photo booth rental': 'events',
    photographer: 'events',
    photography: 'events',
    'rental supplies': 'events',
    videography: 'events',
    'wedding coordinator': 'events',
    'wedding officiant': 'events',
    'wedding plan': 'events',
    'a/p clerk': 'finance',
    'a/p coordinator': 'finance',
    'a/p specialist': 'finance',
    'a/r clerk': 'finance',
    accountant: 'finance',
    'accounting clerk': 'finance',
    'accounting specialist': 'finance',
    'accounts payable': 'finance',
    'accounts receivable': 'finance',
    'ap/ar clerk': 'finance',
    'ap/ar manager': 'finance',
    'ap/ar specialist': 'finance',
    'ar representative': 'finance',
    'asset management': 'finance',
    'asset protection': 'finance',
    'audit associate': 'finance',
    'audit coordinator': 'finance',
    'audit manager': 'finance',
    auditor: 'finance',
    'billing analyst': 'finance',
    'billing assistant': 'finance',
    'billing associate': 'finance',
    'billing clerk': 'finance',
    'billing coordinator': 'finance',
    'billing lead': 'finance',
    'billing manager': 'finance',
    'billing specialist': 'finance',
    'book keeper': 'finance',
    'budget analyst': 'finance',
    'budget coordinator': 'finance',
    'budget manager': 'finance',
    'budget specialist': 'finance',
    'cash applications': 'finance',
    'cash management': 'finance',
    'cash reconciliation': 'finance',
    'claims adjuster': 'finance',
    'collections agent': 'finance',
    'collections analyst': 'finance',
    'collections associate': 'finance',
    'collections representative': 'finance',
    'collections specialist': 'finance',
    controller: 'finance',
    'cost estimator': 'finance',
    'credit analyst': 'finance',
    'credit risk': 'finance',
    escrow: 'finance',
    finance: 'finance',
    'financial analyst': 'finance',
    'financial assistant': 'finance',
    'financial associate': 'finance',
    'financial auditor': 'finance',
    'financial business': 'finance',
    'financial manager': 'finance',
    'financial service': 'finance',
    'financial solution': 'finance',
    'financial specialist': 'finance',
    'insurance agent': 'finance',
    'insurance inspector': 'finance',
    'internal audit': 'finance',
    'lease purchase': 'finance',
    'mortgage loan': 'finance',
    'payroll accountant': 'finance',
    'payroll administrator': 'finance',
    'payroll assistant': 'finance',
    'payroll clerk': 'finance',
    'payroll coordinator': 'finance',
    'payroll manager': 'finance',
    'payroll specialist': 'finance',
    'payroll supervisor': 'finance',
    'tax accountant': 'finance',
    'tax analyst': 'finance',
    'tax manager': 'finance',
    'tax preparation': 'finance',
    'tax preparer': 'finance',
    'tax professional': 'finance',
    'treasury analyst': 'finance',
    'wealth manager': 'finance',
    dietician: 'fitness and health',
    fitness: 'fitness and health',
    lifeguard: 'fitness and health',
    nutritionist: 'fitness and health',
    'personal train': 'fitness and health',
    'personal trainer': 'fitness and health',
    'self defense': 'fitness and health',
    umpire: 'fitness and health',
    yoga: 'fitness and health',
    zumba: 'fitness and health',
    'activities assistant': 'healthcare',
    'activity coordinator': 'healthcare',
    'activity director': 'healthcare',
    'activity therapist': 'healthcare',
    'admitting clerk': 'healthcare',
    anesthesia: 'healthcare',
    anesthesiologist: 'healthcare',
    'animal tech': 'healthcare',
    'athletic trainer': 'healthcare',
    audiologist: 'healthcare',
    'behavioral technician': 'healthcare',
    'behavioral therapist': 'healthcare',
    cardiology: 'healthcare',
    'cardiopulmonary rehabilitation specialist': 'healthcare',
    cardiovascular: 'healthcare',
    'care associate': 'healthcare',
    'care coordinator': 'healthcare',
    'care giver': 'healthcare',
    'care partner': 'healthcare',
    'care provider': 'healthcare',
    'cath lab tech': 'healthcare',
    chiropractor: 'healthcare',
    'clinical coordinator': 'healthcare',
    'clinical manager': 'healthcare',
    'clinical research': 'healthcare',
    'clinical services': 'healthcare',
    'clinical specialist': 'healthcare',
    'clinical trial': 'healthcare',
    clinician: 'healthcare',
    'companion care': 'healthcare',
    cpr: 'healthcare',
    'dental assistant': 'healthcare',
    'dental biller': 'healthcare',
    'dental hygienist': 'healthcare',
    'dental technician': 'healthcare',
    dentist: 'healthcare',
    dermatologist: 'healthcare',
    'diagnostic technician': 'healthcare',
    'diet clerk': 'healthcare',
    'dietary aide': 'healthcare',
    dietitian: 'healthcare',
    echocardiograph: 'healthcare',
    'emergency medical technician': 'healthcare',
    emt: 'healthcare',
    endodontist: 'healthcare',
    'family practitioner': 'healthcare',
    'first aid': 'healthcare',
    gastroenterologist: 'healthcare',
    'hand therapist': 'healthcare',
    'hands on care': 'healthcare',
    'health care': 'healthcare',
    healthcare: 'healthcare',
    histology: 'healthcare',
    'home health': 'healthcare',
    hospice: 'healthcare',
    hospital: 'healthcare',
    'lab assistant': 'healthcare',
    'labor delivery': 'healthcare',
    'lactation assistant': 'healthcare',
    'mammography tech': 'healthcare',
    'mammography technician': 'healthcare',
    'mammography technologist': 'healthcare',
    'massage therapist': 'healthcare',
    'medical administrator': 'healthcare',
    'medical assistant': 'healthcare',
    'medical biller': 'healthcare',
    'medical claims': 'healthcare',
    'medical coder': 'healthcare',
    'medical data entry clerk': 'healthcare',
    'medical director': 'healthcare',
    'medical imaging': 'healthcare',
    'medical interpreter': 'healthcare',
    'medical lab technician': 'healthcare',
    'medical receptionist': 'healthcare',
    'medical secretary': 'healthcare',
    'medical technologist': 'healthcare',
    'medical transcriptionist': 'healthcare',
    'microbiology technician': 'healthcare',
    'microbiology technologist': 'healthcare',
    'mri technologist': 'healthcare',
    neurologist: 'healthcare',
    'nuclear medicine': 'healthcare',
    'nurse assistant': 'healthcare',
    nutrition: 'healthcare',
    'ob technician': 'healthcare',
    obgyn: 'healthcare',
    'obstetrical technician': 'healthcare',
    obstetrician: 'healthcare',
    'occupational therapy': 'healthcare',
    oncologist: 'healthcare',
    oncology: 'healthcare',
    'operating room technician': 'healthcare',
    ophthalmology: 'healthcare',
    optician: 'healthcare',
    optometrist: 'healthcare',
    orthodontist: 'healthcare',
    orthopedic: 'healthcare',
    outpatient: 'healthcare',
    'palliative care': 'healthcare',
    paramedic: 'healthcare',
    pathologist: 'healthcare',
    'patient access': 'healthcare',
    'patient advocate': 'healthcare',
    'patient care assistant': 'healthcare',
    'patient care coordinator': 'healthcare',
    'patient care support': 'healthcare',
    'patient care technician': 'healthcare',
    'patient navigator': 'healthcare',
    'patient registration': 'healthcare',
    'patient service representative': 'healthcare',
    'patient services coordinator': 'healthcare',
    pediatrician: 'healthcare',
    pharmacist: 'healthcare',
    phlebotomist: 'healthcare',
    'physical therapist': 'healthcare',
    'physical therapy assistant': 'healthcare',
    physician: 'healthcare',
    podiatrist: 'healthcare',
    polysomnographic: 'healthcare',
    'prenatal coordinator': 'healthcare',
    pulmonologist: 'healthcare',
    'radiation therapist': 'healthcare',
    'radiologic technician': 'healthcare',
    radiologist: 'healthcare',
    'recreation therapist': 'healthcare',
    rehab: 'healthcare',
    'respiratory care practitioner': 'healthcare',
    'respiratory therapist': 'healthcare',
    scientist: 'healthcare',
    'sleep technician': 'healthcare',
    sonographer: 'healthcare',
    'speech language pathologist': 'healthcare',
    'speech path': 'healthcare',
    'speech therapy': 'healthcare',
    'sterile processing technician': 'healthcare',
    surgeon: 'healthcare',
    'surgical technician': 'healthcare',
    'technician ct': 'healthcare',
    'technician eeg': 'healthcare',
    'therapeutic care': 'healthcare',
    ultrasound: 'healthcare',
    'urgent care': 'healthcare',
    'vet tech': 'healthcare',
    veterinarian: 'healthcare',
    'xray tech': 'healthcare',
    'benefits administrator': 'human resources',
    'benefits analyst': 'human resources',
    'benefits coordinator': 'human resources',
    'benefits manager': 'human resources',
    'benefits representative': 'human resources',
    'benefits specialist': 'human resources',
    'campus recruiter': 'human resources',
    'college recruiter': 'human resources',
    'college recruiting': 'human resources',
    'corporate recruiter': 'human resources',
    'employee benefit': 'human resources',
    'financial recruiter': 'human resources',
    'hr generalist': 'human resources',
    'hr manager': 'human resources',
    'human resources': 'human resources',
    'open enrollment': 'human resources',
    'organization development': 'human resources',
    'personnel administrator': 'human resources',
    recruiter: 'human resources',
    'recruiting coordinator': 'human resources',
    'recruiting manager': 'human resources',
    'staffing manager': 'human resources',
    'talent acquisition': 'human resources',
    'talent recruiter': 'human resources',
    'active directory admin': 'it jobs',
    'adobe analytics': 'it jobs',
    'broadband technician': 'it jobs',
    'cisco admin': 'it jobs',
    'cisco network': 'it jobs',
    citrix: 'it jobs',
    'computer repair': 'it jobs',
    'computer technician': 'it jobs',
    'data security administrator': 'it jobs',
    'data warehouse': 'it jobs',
    'database administrator': 'it jobs',
    'database analyst': 'it jobs',
    'desktop support': 'it jobs',
    'help desk': 'it jobs',
    helpdesk: 'it jobs',
    informatica: 'it jobs',
    'information systems': 'it jobs',
    'information technology': 'it jobs',
    it: 'it jobs',
    'network administrator': 'it jobs',
    'network analyst': 'it jobs',
    'network communications': 'it jobs',
    'network security': 'it jobs',
    'network support': 'it jobs',
    'network systems': 'it jobs',
    'network technician': 'it jobs',
    'noc analyst': 'it jobs',
    'noc engineer': 'it jobs',
    'noc technician': 'it jobs',
    'office365 administrator': 'it jobs',
    'open source': 'it jobs',
    'open systems': 'it jobs',
    oracle: 'it jobs',
    'tech support': 'it jobs',
    attorney: 'legal',
    'bankruptcy specialist': 'legal',
    'corporate counsel': 'legal',
    'court interpreter': 'legal',
    'general counsel': 'legal',
    lawyer: 'legal',
    'legal assistant': 'legal',
    'legal secretary': 'legal',
    litigation: 'legal',
    paralegal: 'legal',
    transcriptionist: 'legal',
    advertising: 'marketing',
    'brand ambassador': 'marketing',
    'brand coordinator': 'marketing',
    'brand experience': 'marketing',
    'brand manager': 'marketing',
    'brand specialist': 'marketing',
    'business manager': 'marketing',
    'campaign manager': 'marketing',
    'communication specialist': 'marketing',
    'content management manager': 'marketing',
    'convention assistant': 'marketing',
    copywriter: 'marketing',
    'digital marketing': 'marketing',
    'direct mail manager': 'marketing',
    'email marketing': 'marketing',
    journalist: 'marketing',
    'market strategist': 'marketing',
    'marketing analyst': 'marketing',
    'marketing assistant': 'marketing',
    'marketing associate': 'marketing',
    'marketing coordinator': 'marketing',
    'marketing director': 'marketing',
    'marketing intern': 'marketing',
    'marketing manager': 'marketing',
    'marketing professional': 'marketing',
    'marketing representative': 'marketing',
    'marketing specialist': 'marketing',
    'online marketing': 'marketing',
    'organic search': 'marketing',
    'packaging design': 'marketing',
    'paid search': 'marketing',
    'partner manager': 'marketing',
    'public relations': 'marketing',
    reporter: 'marketing',
    /*
     * "avon": "miscellaneous",
     * "franchise": "miscellaneous",
     * "part time": "miscellaneous",
     * "work home": "miscellaneous",
     * "your own boss": "miscellaneous",
     */
    arnp: 'nursing',
    cna: 'nursing',
    crna: 'nursing',
    lpn: 'nursing',
    nicu: 'nursing',
    nurse: 'nursing',
    nursing: 'nursing',
    rn: 'nursing',
    'actuarial analyst': 'office jobs',
    'actuarial associate': 'office jobs',
    'admin office': 'office jobs',
    // administration: 'office jobs',
    'administrative assistant': 'office jobs',
    'administrative associate': 'office jobs',
    'administrative clerk': 'office jobs',
    'administrative coordinator': 'office jobs',
    'administrative office': 'office jobs',
    'administrative specialist': 'office jobs',
    'administrative support': 'office jobs',
    administrator: 'office jobs',
    analyst: 'office jobs',
    bookkeeper: 'office jobs',
    'branch office administrator': 'office jobs',
    'claim representative': 'office jobs',
    clerical: 'office jobs',
    'clerical support': 'office jobs',
    clerk: 'office jobs',
    collector: 'office jobs',
    'compliance analyst': 'office jobs',
    'contact center': 'office jobs',
    'contract administrator': 'office jobs',
    coordinator: 'office jobs',
    'data entry': 'office jobs',
    'data entry associate': 'office jobs',
    'data entry clerk': 'office jobs',
    'data entry operator': 'office jobs',
    'data entry specialist': 'office jobs',
    'data processing': 'office jobs',
    'document administrator': 'office jobs',
    'executive assistant': 'office jobs',
    'file clerk': 'office jobs',
    'front office': 'office jobs',
    'general office': 'office jobs',
    mail: 'office jobs',
    mailroom: 'office jobs',
    'non profit': 'office jobs',
    'non-profit': 'office jobs',
    nonprofit: 'office jobs',
    office: 'office jobs',
    'office administrative': 'office jobs',
    'office administrator': 'office jobs',
    'office and administrative': 'office jobs',
    'office assistant': 'office jobs',
    'office associate': 'office jobs',
    'office clerk': 'office jobs',
    'office coordinator': 'office jobs',
    'office help': 'office jobs',
    'office manager': 'office jobs',
    'office professional': 'office jobs',
    'office specialist': 'office jobs',
    'office worker': 'office jobs',
    'order entry': 'office jobs',
    'order management': 'office jobs',
    organizer: 'office jobs',
    'project administrator': 'office jobs',
    'real estate': 'office jobs',
    receptionist: 'office jobs',
    secretary: 'office jobs',
    'staffing support': 'office jobs',
    typists: 'office jobs',
    'certified technician': 'operations',
    cnc: 'operations',
    'distribution manager': 'operations',
    driller: 'operations',
    'electrical technician': 'operations',
    'equipment technician': 'operations',
    'facilities manager': 'operations',
    'field service technician': 'operations',
    'field specialist': 'operations',
    'home security': 'operations',
    hvac: 'operations',
    inspector: 'operations',
    'installation technician': 'operations',
    locksmith: 'operations',
    logistics: 'operations',
    machinist: 'operations',
    maintenance: 'operations',
    manufacturing: 'operations',
    'material processor': 'operations',
    'mechanical technician': 'operations',
    'oil field technician': 'operations',
    'oil pumper': 'operations',
    operations: 'operations',
    'operations manager': 'operations',
    'production associate': 'operations',
    'production coordinator': 'operations',
    'production designer': 'operations',
    'production laborer': 'operations',
    'production lead': 'operations',
    'production manager': 'operations',
    'production operator': 'operations',
    'production planner': 'operations',
    'production scheduler': 'operations',
    'production supervisor': 'operations',
    'production worker': 'operations',
    'property manager': 'operations',
    'quality control': 'operations',
    'service technician': 'operations',
    'telecommunications technician': 'operations',
    toolpusher: 'operations',
    welder: 'operations',
    'app systems analyst': 'product',
    'application analyst': 'product',
    'assurance manager': 'product',
    'assurance senior': 'product',
    'business analyst': 'product',
    'business analytics': 'product',
    crm: 'product',
    'data analyst': 'product',
    'product analyst': 'product',
    'product manager': 'product',
    'program director': 'product',
    'program manager': 'product',
    'project coordinator': 'product',
    'quality analyst': 'product',
    salesforce: 'product',
    'addictions counselor': 'psychology social work',
    'addictions treatment': 'psychology social work',
    'behavioral health': 'psychology social work',
    'case worker': 'psychology social work',
    'chemical dependency': 'psychology social work',
    'child support': 'psychology social work',
    'child youth behavior': 'psychology social work',
    'community outreach': 'psychology social work',
    counselor: 'psychology social work',
    'mental health': 'psychology social work',
    'probation officer': 'psychology social work',
    psychiatric: 'psychology social work',
    psychiatrist: 'psychology social work',
    psychologist: 'psychology social work',
    psychology: 'psychology social work',
    'residential counselor': 'psychology social work',
    'social worker': 'psychology social work',
    'substance abuse': 'psychology social work',
    'assistant general manager': 'restaurant hourly',
    baker: 'restaurant hourly',
    bakery: 'restaurant hourly',
    banquet: 'restaurant hourly',
    barista: 'travel hospitality',
    'beverage cart': 'restaurant hourly',
    'breakfast attendant': 'restaurant hourly',
    butcher: 'restaurant hourly',
    'cafe associate': 'restaurant hourly',
    'cafe attendant': 'restaurant hourly',
    'cafe manager': 'restaurant hourly',
    cafeteria: 'restaurant hourly',
    'cafeteria assistant': 'restaurant hourly',
    'cafeteria associate': 'restaurant hourly',
    'cafeteria worker': 'restaurant hourly',
    'casual dining': 'restaurant hourly',
    chipotle: 'restaurant hourly',
    'crew any availability': 'restaurant hourly',
    'crew member': 'restaurant hourly',
    delivery: 'trucking jobs',
    'delivery driver': 'trucking jobs',
    'dish machine operator': 'restaurant hourly',
    'dish prep': 'restaurant hourly',
    'district manager': 'retail hourly',
    'food and beverage': 'restaurant hourly',
    'food beverage manager': 'restaurant hourly',
    'food prep': 'restaurant hourly',
    'food prep supervisor': 'restaurant hourly',
    'food prep worker': 'restaurant hourly',
    'food production manager': 'restaurant hourly',
    'food runner': 'restaurant hourly',
    'food safety inspector': 'restaurant hourly',
    'food service': 'restaurant hourly',
    'food service associate': 'restaurant hourly',
    'food service clerk': 'restaurant hourly',
    'food service director': 'restaurant hourly',
    'food service manager': 'restaurant hourly',
    'food service worker': 'restaurant hourly',
    'general manager': 'retail hourly',
    kitchen: 'restaurant hourly',
    'kitchen manager': 'restaurant hourly',
    'kitchen staff': 'restaurant hourly',
    pantry: 'restaurant hourly',
    'part time crew': 'restaurant hourly',
    pastry: 'restaurant hourly',
    pizza: 'restaurant hourly',
    restaurant: 'restaurant hourly',
    'restaurant crew': 'restaurant hourly',
    'restaurant manager': 'restaurant hourly',
    'shift coordinator': 'restaurant hourly',
    'shift manager': 'restaurant hourly',
    shopper: 'retail hourly',
    'snack bar attendant': 'restaurant hourly',
    'team member': 'retail hourly',
    waiter: 'restaurant hourly',
    waitress: 'restaurant hourly',
    'assistant manager': 'retail hourly',
    'backroom associate': 'retail hourly',
    bagger: 'retail hourly',
    buyer: 'retail hourly',
    checker: 'retail hourly',
    'commission sales': 'retail hourly',
    'customer assistant': 'retail hourly',
    'customer representative': 'retail hourly',
    'department manager': 'retail hourly',
    'department supervisor': 'retail hourly',
    grocer: 'retail hourly',
    'grocery clerk': 'retail hourly',
    'in store promoter': 'retail hourly',
    'manager trainee': 'retail hourly',
    merchandise: 'retail hourly',
    retail: 'retail hourly',
    'retail associate': 'retail hourly',
    'retail manager': 'retail hourly',
    'retail sales': 'retail hourly',
    'retail supervisor': 'retail hourly',
    'retail support': 'retail hourly',
    'sales assistant': 'retail hourly',
    'service manager': 'retail hourly',
    'shop manager': 'retail hourly',
    stocker: 'retail hourly',
    stocking: 'retail hourly',
    'store associate': 'retail hourly',
    'store clerk': 'retail hourly',
    'store lead': 'retail hourly',
    'store sales': 'retail hourly',
    'store supervisor': 'retail hourly',
    'support specialist': 'retail hourly',
    'team leader': 'retail hourly',
    'appointment setter': 'sales support',
    'call center assistant': 'sales support',
    'client care': 'sales support',
    'client experience': 'sales support',
    'client relation': 'sales support',
    'client service': 'sales support',
    'client support': 'sales support',
    'customer advocate': 'sales support',
    'customer care': 'sales support',
    'customer experience': 'sales support',
    'customer relations': 'sales support',
    'customer sales': 'sales support',
    'customer satisfaction': 'sales support',
    'customer service associate': 'sales support',
    'customer service manager': 'sales support',
    'customer service representative': 'sales support',
    'customer service specialist': 'sales support',
    'customer service supervisor': 'sales support',
    'customer support': 'sales support',
    'direct support': 'sales support',
    'sales operations': 'sales support',
    'telecommunications agent': 'sales support',
    'armed security': 'security jobs',
    'background investigator': 'security jobs',
    'campus safety': 'security jobs',
    'campus security': 'security jobs',
    'case manager': 'security jobs',
    'commercial security': 'security jobs',
    'correctional officer': 'security jobs',
    'crime lab': 'security jobs',
    dispatcher: 'security jobs',
    'event security': 'security jobs',
    'facility security': 'security jobs',
    firefighter: 'security jobs',
    investigation: 'security jobs',
    'mall security': 'security jobs',
    'onsite security': 'security jobs',
    'police officer': 'security jobs',
    'public safety officer': 'security jobs',
    'retail security': 'security jobs',
    'security agent': 'security jobs',
    'security coordinator': 'security jobs',
    'security flex officer': 'security jobs',
    'security patrol': 'security jobs',
    attendant: 'service jobs',
    babysitter: 'service jobs',
    bookseller: 'service jobs',
    'carpet clean': 'service jobs',
    'child care': 'service jobs',
    childcare: 'service jobs',
    cleaner: 'service jobs',
    cleaning: 'service jobs',
    'cleaning support': 'service jobs',
    custodian: 'service jobs',
    daycare: 'service jobs',
    dishwasher: 'service jobs',
    'dog train': 'service jobs',
    'dog trainer': 'service jobs',
    'dog whisperer': 'service jobs',
    'exterior paint': 'service jobs',
    'furniture assembler': 'service jobs',
    'guitar lesson': 'service jobs',
    handyman: 'service jobs',
    'home appliance': 'service jobs',
    'home care': 'service jobs',
    'horseback ride': 'service jobs',
    'house cleaning': 'service jobs',
    housekeeper: 'service jobs',
    houseperson: 'service jobs',
    'interior paint': 'service jobs',
    janitor: 'service jobs',
    janitorial: 'service jobs',
    'laundry attendant': 'service jobs',
    lawncare: 'service jobs',
    maid: 'service jobs',
    mover: 'service jobs',
    nanny: 'service jobs',
    'paint associate': 'service jobs',
    'paint maintanence': 'service jobs',
    'paint prep': 'service jobs',
    'paint technician': 'service jobs',
    painter: 'service jobs',
    painting: 'service jobs',
    'pest control': 'service jobs',
    'pet sitting': 'service jobs',
    'piano lesson': 'service jobs',
    plumbing: 'service jobs',
    'power wash': 'service jobs',
    psychic: 'service jobs',
    'room attendant': 'service jobs',
    'satellite technician': 'service jobs',
    'sing lesson': 'service jobs',
    sitter: 'service jobs',
    'zoo keeper': 'service jobs',
    '.net architect': 'software engineer',
    '.net developer': 'software engineer',
    '.net engineer': 'software engineer',
    'agile coach': 'software engineer',
    'android developer': 'software engineer',
    'application architect': 'software engineer',
    'application developer': 'software engineer',
    'application engineer': 'software engineer',
    'associate engineer': 'software engineer',
    'atg developer': 'software engineer',
    'backend developer': 'software engineer',
    'backend engineer': 'software engineer',
    'bi developer': 'software engineer',
    'big data architect': 'software engineer',
    'big data developer': 'software engineer',
    'big data engineer': 'software engineer',
    'c# developer': 'software engineer',
    'cloud architect': 'software engineer',
    'cloud developer': 'software engineer',
    'cloud engineer': 'software engineer',
    coding: 'software engineer',
    'computer system': 'software engineer',
    'data architect': 'software engineer',
    'database developer': 'software engineer',
    'database engineer': 'software engineer',
    'design engineer': 'software engineer',
    'engineer ii': 'software engineer',
    'front end developer': 'software engineer',
    'frontend developer': 'software engineer',
    'frontend engineer': 'software engineer',
    hadoop: 'software engineer',
    java: 'software engineer',
    'jquery developer': 'software engineer',
    linux: 'software engineer',
    middleware: 'software engineer',
    'network engineer': 'software engineer',
    'performance test engineer': 'software engineer',
    'php developer': 'software engineer',
    'platform architect': 'software engineer',
    'platform engineer': 'software engineer',
    programmer: 'software engineer',
    'quality assurance': 'software engineer',
    'quality engineer': 'software engineer',
    'ruby on rails': 'software engineer',
    'sales engineer': 'software engineer',
    'scrum master': 'software engineer',
    'security engineer': 'software engineer',
    'software architect': 'software engineer',
    'software developer': 'software engineer',
    'software engineer': 'software engineer',
    'solution architect': 'software engineer',
    'systems administrator': 'software engineer',
    'systems engineer': 'software engineer',
    'test engineer': 'software engineer',
    'user experience': 'software engineer',
    'web developer': 'software engineer',
    'web programmer': 'software engineer',
    'bus driver': 'transportation jobs',
    cdl: 'transportation jobs',
    chauffer: 'transportation jobs',
    'city driver': 'transportation jobs',
    'class a driver': 'transportation jobs',
    'class b driver': 'transportation jobs',
    'class c driver': 'transportation jobs',
    'company drivers': 'transportation jobs',
    conductor: 'transportation jobs',
    courier: 'transportation jobs',
    'customer delivery': 'transportation jobs',
    'freight regional': 'transportation jobs',
    'freight specialist': 'transportation jobs',
    'ocean export': 'transportation jobs',
    'ocean import': 'transportation jobs',
    otr: 'transportation jobs',
    'over the road': 'transportation jobs',
    'owner operator': 'transportation jobs',
    'rail freight': 'transportation jobs',
    transportation: 'transportation jobs',
    'truck driver': 'trucking jobs',
    'truck driver trainee': 'transportation jobs',
    trucking: 'trucking jobs',
    'trucking driver': 'trucking jobs',
    'trucking jobs': 'trucking jobs',
    'van driving': 'transportation jobs',
    'banquet manager': 'travel hospitality',
    barback: 'travel hospitality',
    'bell attendant': 'travel hospitality',
    'bell person': 'travel hospitality',
    bellperson: 'travel hospitality',
    'branch manager': 'travel hospitality',
    busperson: 'travel hospitality',
    concierge: 'travel hospitality',
    dealer: 'travel hospitality',
    drive: 'travel hospitality',
    'flight attendant': 'travel hospitality',
    'front desk': 'travel hospitality',
    'guest service': 'travel hospitality',
    hotel: 'travel hospitality',
    'parking attendant': 'travel hospitality',
    pilot: 'travel hospitality',
    'ramp agent': 'travel hospitality',
    'reservations agent': 'travel hospitality',
    'return agent': 'travel hospitality',
    'sales administrative': 'travel hospitality',
    'service attendant': 'travel hospitality',
    'service captain': 'travel hospitality',
    'shift leader': 'travel hospitality',
    'spa coordinator': 'travel hospitality',
    steward: 'travel hospitality',
    valet: 'travel hospitality',
    'assembly worker': 'warehouse jobs',
    'dock supervisor': 'warehouse jobs',
    'dock worker': 'warehouse jobs',
    'fork lift': 'warehouse jobs',
    forklift: 'warehouse jobs',
    'forklift driver': 'warehouse jobs',
    'forklift operator': 'warehouse jobs',
    'freight handler': 'warehouse jobs',
    'freight team associate': 'warehouse jobs',
    inventory: 'warehouse jobs',
    'inventory clerk': 'warehouse jobs',
    'inventory control': 'warehouse jobs',
    'inventory taker': 'warehouse jobs',
    laborer: 'warehouse jobs',
    'lift truck': 'warehouse jobs',
    'line worker': 'warehouse jobs',
    'load puller': 'warehouse jobs',
    loader: 'warehouse jobs',
    'material handler': 'warehouse jobs',
    'materials clerk': 'warehouse jobs',
    'materials control': 'warehouse jobs',
    'order fulfillment': 'warehouse jobs',
    'order picker': 'warehouse jobs',
    'order processing': 'warehouse jobs',
    'order pull': 'warehouse jobs',
    'order selector': 'warehouse jobs',
    packager: 'warehouse jobs',
    'pick pack': 'warehouse jobs',
    receiver: 'warehouse jobs',
    'receiving clerk': 'warehouse jobs',
    'replenishment associate': 'warehouse jobs',
    'safety manager': 'warehouse jobs',
    'shipping receiving': 'warehouse jobs',
    'stockroom associate': 'warehouse jobs',
    'store receiving specialist': 'warehouse jobs',
    'assistant designer': 'web design graphics jobs',
    autocad: 'web design graphics jobs',
    cad: 'web design graphics jobs',
    'creative director': 'web design graphics jobs',
    'graphic artist': 'web design graphics jobs',
    'graphic design': 'web design graphics jobs',
    'graphics producer': 'web design graphics jobs',
    'graphics specialist': 'web design graphics jobs',
    'ui developer': 'web design graphics jobs',
    'visual design': 'web design graphics jobs',
    'web design': 'web design graphics jobs',
    'website design': 'web design graphics jobs',
    'project manager': 'construction',
    'sales representative': 'retail hourly',
    'sales trainee': 'business sales',
    caregiver: 'healthcare',
    bartender: 'restaurant hourly',
    busser: 'restaurant hourly',
    cashier: 'retail hourly',
    chef: 'restaurant hourly',
    cook: 'restaurant hourly',
    host: 'restaurant hourly',
    'sales associate': 'retail hourly',
    'sales coordinator': 'sales support',
    'sales professional': 'business sales',
    // "sales representative": "business sales",
    'security officer': 'security jobs',
    server: 'restaurant hourly',
    'service representative': 'retail hourly',
    'shift supervisor': 'retail hourly',
    seasonal: 'seasonal',
    'seasonal jobs': 'seasonal',
    'work from home': 'wfh',
    'baskin robbins': 'baskin robbins',
    'baskin robbin': 'baskin robbin',
    appleone: 'appleone',
    apple: 'apple',
    doordash: 'doordash',
    ecs: 'ecs',
    microsoft: 'microsoft',
    'panera bread': 'panera bread',
    postmates: 'postmates',
    tjmaxx: 'tjmaxx',
    smiths: 'smiths',
    jbhunt: 'jbhunt',
    scholastic: 'scholastic',
    'package delivery': 'trucking jobs',
    'package delivery driver': 'trucking jobs',
    'package delivery jobs with own car': 'trucking jobs',
    'home delivery driver': 'trucking jobs',
    'parts delivery': 'trucking jobs',
    // government: 'government',
};

export default window.CNXBucket &&
window.CNXBucket.DEFAULT_LOGO_MAPPING &&
Object.keys(window.CNXBucket.DEFAULT_LOGO_MAPPING).length > 0
    ? { ...DEFAULT_LOGO_MAPPING, ...window.CNXBucket.DEFAULT_LOGO_MAPPING }
    : DEFAULT_LOGO_MAPPING;
