import UrlManager from 'common/urlManager';
import Network from 'common/network';
import CommonDataSource from './commonDataSource';
import { IdStore } from 'dataStore';
import Constants from 'common/constants';
import Utils from 'common/utils';

export default () => {
    console.log('SESSION: Fetching session');

    return new Promise((resolve, reject) => {
        let url = UrlManager.getSessionUrl();

        /*
         * Fetch the required params
         * NOTE: These are being set pro-actively even though the api handles these from
         * the cookies, since third-party cookies can be disabled.
         */
        const body = {};

        let uid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_ID);

        if (false === Utils.isNull(uid) && uid !== 'null') {
            body.uid = uid;
        }

        let sid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SESSION_ID);

        if (false === Utils.isNull(sid) && sid !== 'null') {
            body.sid = sid;
        }

        let did = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.DEVICE_ID);

        if (false === Utils.isNull(did) && did !== 'null') {
            body.did = did;
        }

        let csid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);

        // Create and store a cs-id if doesn't exist already
        if (!csid) {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.CS_ID, `csid_${Utils.generateUUID()}`);
            csid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);
        }

        if (false === Utils.isNull(csid) && csid !== 'null') {
            body.csid = csid;
        }

        let sessionData = getSessionData();

        if (!Utils.isNull(sessionData)) {
            body.sessionData = sessionData;
        }

        const headers = {
            'Content-Type': 'application/json',
        };

        // Session
        Network.post(url, headers, body)
            .then(response => {
                if (false === response.ok) {
                    throw new Error(`response status invalid - ${response}`);
                }

                return response.json();
            })
            .then(responseJson => {
                return CommonDataSource.processJSONResponse(responseJson);
            })
            .then(processedResp => {
                console.log('SESSION: Got session data - ', processedResp);
                resolve(processedResp);
            })
            .catch(sessionErr => {
                console.log('ERR: SESSION: error - ', sessionErr);
                reject(sessionErr);
            });
    });
};

const getSessionData = () => {
    const sessionKeys = [
        'LANDER_ID',
        'FLAVOR_NAME',
        'DOMAIN',
        'SURVEY_ID',
        'SURVEY_PATH_ID',
        'UTM_SOURCE',
        'UTM_MEDIUM',
        'UTM_CAMPAIGN',
        'UTM_TERM',
        'UTM_CONTENT',
        'P_DOMAIN',
        'IS_RDR',
    ];
    const sessionData = {};
    const sessionIds = IdStore.fetchAllSessionIds();

    if (!Utils.isNull(sessionIds)) {
        const idStoreKeys = Constants.ID_STORE_KEYS;

        for (const sessionKey of sessionKeys) {
            const sessionKeyVal = idStoreKeys[sessionKey];

            if (sessionIds[sessionKeyVal]) {
                sessionData[sessionKeyVal] = sessionIds[sessionKeyVal];
            }
        }
    }

    return sessionData;
};
