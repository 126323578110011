module.exports = {
    COMPONENT_INCLUSIONS: {
        EXPLORE_JOBS_FORM_JOB_TYPE: true,
        EXPLORE_JOBS_FORM_LOGO: false,
        USER_INFO_FORM_LOGO: true,
        USER_AGE_FORM_LOGO: true,
        USER_AGE_FORM_DOB_FORMAT: true,
        FORM_JOB_EMPLOYER_DEFAULT_VALUE: '',
    },
    TEXT_CONTENTS: {
        FOOTER_DISCLOSURE_LINK: 'myjobscorner.com',
        FOOTER_DISCLOSURE: 'MyJobsCorner.com, EveryJobForMe.com, NewJobConnections',
        FOOTER_DISCLOSURE_2: 'MyJobsCorner.com',

        /*
         * [
         *     "Citizens Disability",
         *     "National Disability",
         *     "United States Disability",
         *     "American Disability",
         *     "American Disability Helpline",
         *     "Disability Advisor",
         *     "Heard and Smith",
         *     "Advocator Group",
         *     "Premier Disability"
         *   ],
         */

        // EXPLORE_JOBS_PAY: '$17 - $35 /hr',
        OTHER_EMPLOYER_TEXT: 'Other potential employers in your area',
        EXPLORE_JOBS_FORM_SUBMIT_BUTTON: 'VIEW',
        EXPLORE_JOBS_FORM_CONTINUE_BUTTON: 'CONFIRM',
        EXPLORE_JOBS_INFO:
            ' is a job search engine. We are not an agent or representative of any employer. All company names are the registered trademarks of their original owners. The use of any trade name or trademark does not imply any association with the third-party company.',
        EXPLORE_JOBS_INFO_JT_MAPPING:
            ' is a job search engine. We are not an agent or representative of any employer. ',
        EXPLORE_JOBS_FORM_TITLE: 'Jobs',
        EXPLORE_JOBS_FORM_TITLE_PART1: 'Explore',
        EXPLORE_JOBS_FORM_TITLE_PART2: 'in your area',
        DOB_USER_INPUT_DATE_FORMAT: 'YYYY-MM-DD',
        MIN_YEAR: '1900',
        EXPLORE_JOBS_CONTAINER: 'See other popular jobs',
        USER_AGE_FORM_SUBMIT_BUTTON: {
            IS_SUBMITTING: 'Submitting..',
            DEFAULT: 'SUBMIT',
            CONTINUE: 'CONTINUE',
        },
        HEADING: 'Almost there!',
        SUB_HEADING: 'How can you be contacted',
        VIEW_JOBS: 'VIEW JOBS',
    },
    FIELD_ANIMATIONS: {
        TRANSITION_APPEAR: false,
        TRANSITION_APPEAR_TIMEOUT: 1000,
        TRANSITION_LEAVE: false,
        TRANSITION_NAME: 'slide-down',
        TRANSITION_ENTER_TIMEOUT: 1000,
        TRANSITION_LEAVE_TIMEOUT: 1000,
    },

    EVENTNAME: {
        CONTACTUS: 'contactus',
        UNSUBSCRIBE: 'unsubscribe',
        SUBSCRIBE: 'subscribe',
    },
};
