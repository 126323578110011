import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let DefaultTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#5CB910', // Theme color
        },

        secondary: {
            main: '#f8fafc', // background color
            light: '#a8a8a8', // Text field placeholder color
            dark: '#363636', // Text field entry color
            gery: '#848484',
            lightGrey: '#686868',
            lightBlack: '#575757',
            white: '#fff',
        },
    },
    typography: {
        useNextVariants: true,
        // TODO need to update font family according to design

        // Use this variant for texts in footer
        h3: {
            fontSize: '10px',
            color: '#8d9ca3',
            fontFamily: 'Roboto',
            fontWeight: 400, // Normal
            lineHeight: 1,
        },
        h6: {
            fontSize: '11px',
            color: '#637c87',
            fontFamily: 'Roboto',
            fontWeight: 400, // Normal
            lineHeight: 'normal',
            letterSpacing: 'normal',
        },

        // Use this variant for checkbox text
        h2: {
            fontSize: '1.25rem',
            color: '#848484',
            fontFamily: 'Roboto',
            fontWeight: 400, // Normal
            letterSpacing: 0.3,
            lineHeight: 1.6,
        },

        // Use this variant for sub heading of page
        subtitle1: {
            color: '#696969',
            fontWeight: 400, // Normal
            fontFamily: 'Roboto',
            lineHeight: 18,
        },

        // Use this variant for headings of page
        h1: {
            fontSize: 2,
            color: '#363636',
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            lineHeight: 1.38,
        },
        subtitle2: {
            fontSize: 1.5,
            color: '#363636',
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            lineHeight: 1.32,
        },
    },

    overrides: {
        // Put Button specific themes here
        MuiButton: {
            textPrimary: {
                color: '#fff',
                textTransform: 'uppercase',
                padding: '6px 12px',
                letterSpacing: 1.28,
                fontSize: 22,
                fontFamily: 'Roboto',
                fontWeight: 700, // Bold,
                height: '66px',
                borderRadius: '2px',
                backgroundColor: '#0942c2',
                '@media (hover: none)': {
                    '&:hover': {
                        backgroundColor: `#0942c2 !important`,
                    },
                },
                '&:hover': {
                    backgroundColor: '#0942c2',
                },
                '&:disabled': {
                    color: 'rgba(0, 0, 0, 0.26)',
                    boxShadow: 'none',
                    backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
                    border: 'rgba(0, 0, 0, 0.12)',
                    cursor: 'default',
                    pointerEvents: 'none',
                },
            },
        },
        // Override for checkbox label props
        MuiFormControlLabel: {
            label: {
                fontSize: 12,
                color: '#41434d',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
            },
        },
        MuiTextField: {
            root: {
                position: 'relative',
                border: '1px solid #88a1ad',
                fontSize: 18,
                padding: '15px 20px',
                color: '#a8a8a8',
                height: 58,
                margin: '5px 0',
                width: '100%',
                borderRadius: '2px',
                boxSizing: 'border-box',
                backgroundColor: '#fff',
            },
        },
        MuiInputBase: {
            input: {
                fontSize: '22px',
                color: '#41434d',
                padding: 0,
            },
        },
    },
});

let GreenTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#5CB910', // Theme color
            greenShade1: '#5CB910',
            greenShade2: '#55ae00',
        },

        secondary: {
            main: '#f8fafc', // background color
            light: '#a8a8a8', // Text field placeholder color
            dark: '#363636', // Text field entry color
            gery: '#848484',
            lightGrey: '#686868',
            lightBlack: '#575757',
            white: '#fff',
        },
    },
    typography: {
        useNextVariants: true,
        // TODO need to update font family according to design

        // Use this variant for texts in footer
        h3: {
            fontSize: '10px',
            color: '#8d9ca3',
            fontFamily: 'Roboto',
            fontWeight: 400, // Normal
            lineHeight: 1,
        },
        h6: {
            fontSize: '11px',
            color: '#637c87',
            fontFamily: 'Roboto',
            fontWeight: 400, // Normal
            lineHeight: 'normal',
            letterSpacing: 'normal',
        },

        // Use this variant for checkbox text
        h2: {
            fontSize: '1.25rem',
            color: '#848484',
            fontFamily: 'Roboto',
            fontWeight: 400, // Normal
            letterSpacing: 0.3,
            lineHeight: 1.6,
        },

        // Use this variant for sub heading of page
        subtitle1: {
            color: '#696969',
            fontWeight: 400, // Normal
            fontFamily: 'Roboto',
            lineHeight: 18,
        },

        // Use this variant for headings of page
        h1: {
            fontSize: 2,
            color: '#363636',
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            lineHeight: 1.38,
        },
        subtitle2: {
            fontSize: 1.5,
            color: '#363636',
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            lineHeight: 1.32,
        },
    },

    overrides: {
        // Put Button specific themes here
        MuiButton: {
            textPrimary: {
                color: '#fff',
                textTransform: 'uppercase',
                padding: '6px 12px',
                letterSpacing: 1.28,
                fontSize: 22,
                fontFamily: 'Roboto',
                fontWeight: 700, // Bold,
                height: '66px',
                borderRadius: '2px',
                backgroundColor: '#5cb910',
                backgroundImage: 'linear-gradient(to bottom, #55ae00, #4d9e00)',
                '&:hover': {
                    backgroundColor: '#5cb910',
                    backgroundImage: 'linear-gradient(to bottom, #55ae00, #4d9e00)',
                    '@media (hover:none)': {
                        backgroundColor: '#5cb910',
                        backgroundImage: 'linear-gradient(to bottom, #55ae00, #4d9e00)',
                    },
                },
                '&:disabled': {
                    color: 'rgba(0, 0, 0, 0.26)',
                    boxShadow: 'none',
                    backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
                    border: 'rgba(0, 0, 0, 0.12)',
                    cursor: 'default',
                    pointerEvents: 'none',
                },
            },
            textSecondary: {
                color: '#10b910',
            },
        },
        // Override for checkbox label props
        MuiFormControlLabel: {
            label: {
                fontSize: 12,
                color: '#41434d',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
            },
        },
        MuiTextField: {
            root: {
                position: 'relative',
                border: '1px solid #88a1ad',
                fontSize: 18,
                padding: '15px 20px',
                color: '#a8a8a8',
                height: 58,
                margin: '5px 0',
                width: '100%',
                borderRadius: '2px',
                boxSizing: 'border-box',
                backgroundColor: '#fff',
            },
        },
        MuiInputBase: {
            input: {
                fontSize: '22px',
                color: '#41434d',
                padding: 0,
            },
        },
    },
});

DefaultTheme = responsiveFontSizes(DefaultTheme);
GreenTheme = responsiveFontSizes(GreenTheme);

export { DefaultTheme, GreenTheme };
