import Constants from 'common/constants';
import { IdStore } from 'dataStore';
import Utils from 'common/utils';
import md5 from 'md5';
import { LABELS } from 'strings/labels';
import memoize from 'lodash.memoize';
import { getJTFallbackValue } from 'helpers/featureValueHelpers/featureValueHelpers';
import { AbTestHelper } from 'helpers';
import * as moment from 'moment';

const uuidv4 = require('uuid/v4');

export function replaceMacrosWithValues(uuid) {
    const replaceParams = {};

    if (!uuid) {
        uuid = uuidv4().replace(/-/g, '');
    }

    const { jobType, isJobTypeAllowed } = Utils.getJobType();

    const jobTypeEvalFb = isJobTypeAllowed
        ? jobType
        : IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM) || Constants.DEFAULT_KW;

    const jtOrFallback = isJobTypeAllowed
        ? Utils.cleanKwDisplay(Utils.getCapitalizeStr(jobType))
        : getJTFallbackValue();
    const showHPText = AbTestHelper.showHPText();
    const hourlyPay =
        Utils.getHourlyPay(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.HOURLY_PAY) || '',
            LABELS.HP_DURATION,
            showHPText,
        ) || Constants.DEFAULT_PAY_RANGE;

    const maxHourlyPay = Utils.getMaxHourlyPay(hourlyPay);
    replaceParams[Constants.CREATIVE_URL_PARAMS.ZIP_CODE] =
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE) ||
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.ZIP);
    replaceParams[Constants.CREATIVE_URL_PARAMS.PRODUCT] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.PRODUCT,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.SID] = uuid;
    replaceParams[Constants.CREATIVE_URL_PARAMS.KEYWORD] = Utils.cleanKwDisplay(
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM),
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.JOB_TYPE] = Utils.cleanKwDisplay(
        IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL) ||
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL),
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.JOB_TYPE_EVAL] = Utils.getCapitalizeStr(
        Utils.cleanKwDisplay(
            IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL) || jobTypeEvalFb,
        ),
    );

    replaceParams[Constants.CREATIVE_URL_PARAMS.UTM_CONTENT] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.UTM_CONTENT,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.UTM_CAMPAIGN] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.UTM_CAMPAIGN,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.UTM_SOURCE] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.UTM_SOURCE,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.GEO_IP] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.PUBLIC_IP,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.GEO_CITY] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.GEO_CITY,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.GEO_STATE] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.GEO_REGION,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.THEME_COLOR] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.THEME_COLOR,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.THEME_GRADIENT] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.THEME_GRADIENT,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.THEME_TEXT_COLOR] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.THEME_TEXT_COLOR,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.THEME_BORDER] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.THEME_BORDER,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.THEME_BORDER_RADIUS] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.THEME_BORDER_RADIUS,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.THEME_FONT_SIZE] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.THEME_FONT_SIZE,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.THEME_FONT_WEIGHT] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.THEME_FONT_WEIGHT,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.THEME_HEIGHT] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.THEME_HEIGHT,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.THEME_WIDTH] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.THEME_WIDTH,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.SUBID] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.PUBLISHER_SUBID,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.SUB_SOURCE_ID] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.PUBLISHER_SUB_SOURCE,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.ENCODED_SOURCE] =
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.BCK_ENCODED_SOURCE) ||
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.NEW_ENCODED_SUBID);
    replaceParams[Constants.CREATIVE_URL_PARAMS.SHORT_ENCODED_SOURCE] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.NEW_ENCODED_SUBID,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.UTM_CAMPAIGN_BASE64] = Utils.encodeStrToBase64(
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_CAMPAIGN),
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.CLID] = IdStore.fetchIdForKey(
        Constants.URL_ARGS.CLID,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.UTM_TERM] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.UTM_TERM,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.LANDING_JT] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.LANDING_JT,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.LANDING_KW] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.LANDING_KEYWORD,
    );

    const keyword = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM) || '';

    const evaluatedJobType =
        !Utils.isEmptyStr(keyword) &&
        keyword !== Constants.DEFAULT_KW &&
        Utils.cleanKwDisplay(keyword.toLowerCase()) !== 'full time'
            ? keyword
            : jobType;

    replaceParams[Constants.CREATIVE_URL_PARAMS.EVALUATED_JOB_TYPE] = evaluatedJobType;

    replaceParams[Constants.CREATIVE_URL_PARAMS.HOURLY_PAY] = hourlyPay;
    replaceParams[Constants.CREATIVE_URL_PARAMS.MAX_HOURLY_PAY] = maxHourlyPay;
    replaceParams[Constants.CREATIVE_URL_PARAMS.JT_OR_FALLBACK] = jtOrFallback;

    const userDetails = JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS));

    const pubSourceTypeConfig =
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUB_SOURCE_TYPE_CONFIG) &&
        JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUB_SOURCE_TYPE_CONFIG));

    if (pubSourceTypeConfig) {
        let rawEncodedSource = '';

        if (pubSourceTypeConfig.utmSource)
            rawEncodedSource = rawEncodedSource + pubSourceTypeConfig.utmSource;

        if (pubSourceTypeConfig.pubSourceType)
            rawEncodedSource = rawEncodedSource + pubSourceTypeConfig.pubSourceType;

        const base64Encoded = btoa(`${rawEncodedSource.trim()}`);
        replaceParams[Constants.CREATIVE_URL_PARAMS.V2_ENCODED_SOURCE] = base64Encoded;
    }

    if (userDetails) {
        let addressLine1 = '';
        let addressLine2 = '';
        const address = userDetails[Constants.USER_DETAILS_KEY.ADDRESS];

        if (address) {
            addressLine1 = address.line1;
            addressLine2 = address.line2 || '';
        }
        replaceParams[Constants.CREATIVE_URL_PARAMS.FIRST_NAME] =
            userDetails[Constants.USER_DETAILS_KEY.FIRST_NAME];
        replaceParams[Constants.CREATIVE_URL_PARAMS.LAST_NAME] =
            userDetails[Constants.USER_DETAILS_KEY.LAST_NAME];
        replaceParams[Constants.CREATIVE_URL_PARAMS.EMAIL] =
            userDetails[Constants.USER_DETAILS_KEY.EMAIL];
        replaceParams[Constants.CREATIVE_URL_PARAMS.PHONE] =
            userDetails[Constants.USER_DETAILS_KEY.PHONE];
        replaceParams[Constants.CREATIVE_URL_PARAMS.MD5EMAIL] = md5(
            userDetails[Constants.USER_DETAILS_KEY.EMAIL] || '',
        );
        replaceParams[Constants.CREATIVE_URL_PARAMS.GENDER] =
            userDetails[Constants.USER_DETAILS_KEY.GENDER];
        replaceParams[Constants.CREATIVE_URL_PARAMS.USER_STATUS] = IdStore.fetchIdForKey(
            Constants.ID_STORE_KEYS.USER_STATUS,
        );

        try {
            let dob = userDetails[Constants.USER_DETAILS_KEY.DOB];

            if (dob) {
                const dobArray = dob.split('-');

                if (dobArray.length === 3) {
                    dob = `${dobArray[1]}-${dobArray[2]}-${dobArray[0]}`;
                }
            }
            replaceParams[Constants.CREATIVE_URL_PARAMS.DOB] = dob;
        } catch (dobArray) {
            console.error(dobArray);
        }

        if (replaceParams[Constants.CREATIVE_URL_PARAMS.DOB]) {
            replaceParams[Constants.CREATIVE_URL_PARAMS.DOB_MONTH] = moment(
                replaceParams[Constants.CREATIVE_URL_PARAMS.DOB],
                'MM-DD-YYYY',
            ).format('MM');
            replaceParams[Constants.CREATIVE_URL_PARAMS.DOB_DAY] = moment(
                replaceParams[Constants.CREATIVE_URL_PARAMS.DOB],
                'MM-DD-YYYY',
            ).format('DD');
            replaceParams[Constants.CREATIVE_URL_PARAMS.DOB_YEAR] = moment(
                replaceParams[Constants.CREATIVE_URL_PARAMS.DOB],
                'MM-DD-YYYY',
            ).format('YYYY');
            replaceParams[Constants.CREATIVE_URL_PARAMS.AGE] = moment().diff(
                replaceParams[Constants.CREATIVE_URL_PARAMS.DOB],
                'years',
                false,
            );
        }

        replaceParams[Constants.CREATIVE_URL_PARAMS.ADDRESS] = addressLine1;
        replaceParams[Constants.CREATIVE_URL_PARAMS.ADDRESS2] = addressLine2;
        const zip = userDetails[Constants.USER_DETAILS_KEY.ZIP_CODE];

        if (!Utils.isEmptyStr(zip)) {
            replaceParams[Constants.CREATIVE_URL_PARAMS.ZIP_CODE] = zip;
        }

        replaceParams[Constants.CREATIVE_URL_PARAMS.JT_OVER_KW] =
            replaceParams[Constants.CREATIVE_URL_PARAMS.JOB_TYPE] ||
            replaceParams[Constants.CREATIVE_URL_PARAMS.KEYWORD];
    }

    return replaceParams;
}

export function initializeMaxMacros(adData) {
    const maxMacros = {};

    function iterateAndAssign(object) {
        Object.keys(object).forEach(key => {
            if (typeof object[key] === 'object') {
                iterateAndAssign(object[key]);
            } else if (Constants.MAX_REPLACEMENT_MACROS.hasOwnProperty(key)) {
                maxMacros[Constants.MAX_REPLACEMENT_MACROS[key]] = object[key];
            }
        });
    }

    iterateAndAssign(adData);

    return maxMacros;
}

export const replaceMacroParamsString = (str, uuid, ad = {}) => {
    const replaceParams = replaceMacrosWithValues(uuid);
    const maxMacros = initializeMaxMacros(ad);
    const replaceParamsCombined = {
        ...replaceParams,
        ...maxMacros,
    };

    return Utils.matchAndReplaceParams(str, replaceParamsCombined, Constants.CR_MAX_UNIFIED_REGEX);
};

export const replaceMacroParamsStringMemoized = memoize(replaceMacroParamsString);

export function replaceAdParmas(ad, uuid, spotno, opId) {
    let replaceParams = replaceMacrosWithValues(uuid);
    // replace CR macros in ad request
    ad = Utils.replaceMacros(ad, replaceParams, Constants.CREATIVE_URL_PARAMS_REGEX);

    if (ad.creativeImage) {
        ad.creativeImage = ad.creativeImage.url;
    }

    if (ad.creativeIcon) {
        ad.creativeIcon = ad.creativeIcon.url;
    }
    // initialize macro replacement parameters for max
    const maxMacros = initializeMaxMacros(ad);
    // unify CR and max replacement parameters
    replaceParams = {
        ...replaceParams,
        ...maxMacros,
    };

    ad.code = Utils.matchAndReplaceParams(ad.code, replaceParams, Constants.CR_MAX_UNIFIED_REGEX);
    ad.uuid = uuid;
    ad.spotno = spotno || 1;

    if (opId) {
        ad.opid = opId;
    }

    return ad;
}
