import Utils from 'common/utils';
import Constants from 'common/constants';
import { IdStore } from 'dataStore';
import { AbTestHelper, FeatureValueHelper } from 'helpers/index';
import UserDataStore from 'components/landing/userDataStore';
import PhoneValidator from 'common/PhoneValidator';
import eventManager from 'eventManager/eventManager';
import UserDetails from 'models/userDetails';
import { isAPIRepeatUser } from 'helpers/session/sessionHelpers';
import { getEkw } from 'common/scopeUtils';
import JobSearchDataSource from 'datasource/jobsSearchDataSource';
import { getDefaultJobSourcePayload } from 'helpers/jobs/jobSourceHelpers';
import UserDataValidator from 'common/userDataValidator';
import { MemoryStore } from 'dataStore/index';
import memoryStoreKeys from 'common/memoryStoreKeys';

const _getUserFromCache = () => {
    const userDetailsCache =
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS) &&
        JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS));

    return new UserDetails(userDetailsCache);
};

const _getConfigurableH1CTATexts = index => {
    const configuredValue = FeatureValueHelper.getH1CtaText();

    if (
        configuredValue &&
        !Utils.isEmptyObj(configuredValue) &&
        Object.keys(configuredValue).includes(index) &&
        !Utils.isEmptyObj(configuredValue[index])
    ) {
        return configuredValue[index];
    }

    return {};
};

const _formatH1 = (h1, zipCode, hourlyPay) => {
    const ljt = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LANDING_JT) || '';
    const lkw = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LANDING_KEYWORD) || '';
    const jobType =
        IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL) ||
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL) ||
        Constants.DEFAULT_KW;
    const keyword = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM) || '';
    const evaluatedJobType =
        !Utils.isEmptyStr(keyword) &&
        keyword !== Constants.DEFAULT_KW &&
        Utils.cleanKwDisplay(keyword.toLowerCase()) !== 'full time'
            ? keyword
            : jobType;

    return h1
        .replaceAll('%%ZIP_CODE%%', zipCode)
        .replaceAll(
            '%%HOURLY_PAY%%',
            !Utils.isEmptyStr(hourlyPay) ? hourlyPay : Constants.DEFAULT_PAY_RANGE,
        )
        .replaceAll('%%LANDING_JT%%', Utils.getCapitalizeStr(Utils.cleanKwDisplay(ljt)))
        .replaceAll('%%LANDING_KW%%', Utils.getCapitalizeStr(Utils.cleanKwDisplay(lkw)))
        .replaceAll('%%JOB_TYPE%%', Utils.getCapitalizeStr(Utils.cleanKwDisplay(jobType)))
        .replaceAll('%%UTM_TERM%%', Utils.getCapitalizeStr(Utils.cleanKwDisplay(keyword)))
        .replaceAll(
            '%%EVALUATED_JOB_TYPE%%',
            Utils.getCapitalizeStr(Utils.cleanKwDisplay(evaluatedJobType)),
        )
        .replaceAll(
            '%%TOTAL_JOBS_COUNT%%',
            !Utils.isNull(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.TOTAL_JOBS))
                ? IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.TOTAL_JOBS).toString()
                : '',
        );
};

export const checkAndRemoveUTMTerm = () => {
    const kw = (IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM) || '').toLowerCase();
    const isKwBrand = Utils.isJobTypeAllowed(kw);

    if (AbTestHelper.upwardJtAsUtmTerm() || AbTestHelper.kwJtConfigIntegration()) return false;

    if ((AbTestHelper.listingsInBgPrelanderKw() || AbTestHelper.rgKeywordTest()) && !isKwBrand)
        return false;

    return true;
};

export const updateEmployerSelection = selectedEmployer => {
    if (!Utils.isEmptyStr(selectedEmployer)) {
        IdStore.storeIdForKey(Constants.USER.EMPLOYER_TYPE_VAL, selectedEmployer);
    } else {
        IdStore.removeIdForKey(Constants.USER.EMPLOYER_TYPE_VAL);
    }
};

const handleKwAsBrand = jtOrKeyWordLower => {
    const storeKw = IdStore.fetchIdForKey(Constants.UTM_TERM);
    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL, jtOrKeyWordLower);

    if (storeKw && storeKw.toLowerCase && Utils.isJobTypeAllowed(storeKw.toLowerCase())) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JT_KW_MISMATCH, '1');
        checkAndRemoveUTMTerm();
    }
};

export const storeJobsInfoForForm = (jtOrKeyWord, selectedEmployer) => {
    const lkw = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LANDING_KEYWORD);
    const jobType = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);

    const jtOrKeyWordLower = jtOrKeyWord && jtOrKeyWord.toLowerCase && jtOrKeyWord.toLowerCase();
    const lkwLower = lkw && lkw.toLowerCase();
    const jobTypeLower = jobType && jobType.toLowerCase();
    const utmTermChanged =
        lkwLower !== jtOrKeyWordLower &&
        jtOrKeyWordLower !== Constants.DEFAULT_KW.toLowerCase() &&
        jtOrKeyWordLower !== jobTypeLower;

    if (Utils.isJobTypeAllowed(jtOrKeyWordLower)) {
        handleKwAsBrand(jtOrKeyWordLower);
    } else if (!Utils.isEmptyStr(jtOrKeyWordLower) && utmTermChanged) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UTM_TERM, jtOrKeyWordLower);
    }

    updateEmployerSelection(selectedEmployer);
};

export const walkFormElements = formRef => {
    try {
        const formWalker = Utils.getObjVal(formRef, ['current', 'walk']) || Utils.noop();
        const formChilds = Utils.getObjVal(formRef, ['current', 'childs']) || [];
        formWalker(formChilds);
    } catch (e) {
        console.error('Form: Error walking form elements');
    }
};

export const skipReg2PageAPIRepeatUser = () => {
    return (
        (AbTestHelper.regThreeFlowV1() && isAPIRepeatUser(true)) ||
        (AbTestHelper.listingBgRegThreeTest() && isAPIRepeatUser(true))
    );
};

export const skipReg2Page = () => {
    if (AbTestHelper.skipReg2Page()) {
        return true;
    } else if (isAPIRepeatUser()) {
        return skipReg2PageAPIRepeatUser();
    }

    return false;
};

export const getUserPhoneNumber = (dynFeed = false) => {
    const oldUser = UserDataStore.fetchUserDetailsFromStore();
    const urlPhone = Utils.filterInvalidUrlInput(
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE) || '',
    );
    const userPhone = Utils.getObjVal(oldUser, ['phone_number']) || '';
    const maskedPhone = Utils.getObjVal(oldUser, ['meta', 'maskedPhoneNumber']);
    const phoneVerificationStatus = Utils.getObjVal(oldUser, ['phone_verification_status']);
    const showRepeatUsersZipPage =
        AbTestHelper.oldUsersSkipToOffer() &&
        !AbTestHelper.listingsInBgWithZip() &&
        !AbTestHelper.listingsInBgWithZipInEmail();
    const channel = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM);
    const upwardSemiDynamicLander =
        dynFeed &&
        !AbTestHelper.dxmlNewLander() &&
        ('dxml_static' === channel || AbTestHelper.isDXMLMedium());
    const repeatLander =
        showRepeatUsersZipPage && !upwardSemiDynamicLander && !AbTestHelper.dxmlExtFlow();

    if (!Utils.isEmptyStr(urlPhone)) {
        return PhoneValidator.formatPhoneNumber(urlPhone);
    }

    if (AbTestHelper.prePopFlowV3() || AbTestHelper.repeatUserv3() || repeatLander) {
        return PhoneValidator.formatPhoneNumber(userPhone);
    }

    if (AbTestHelper.repeatUserAPIFlowV2() || !phoneVerificationStatus) {
        return '';
    }

    if (isAPIRepeatUser() && !Utils.isEmptyStr(maskedPhone)) {
        return PhoneValidator.formatPhoneNumber(maskedPhone, true);
    }

    return PhoneValidator.formatPhoneNumber(userPhone);
};

export const checkListingsReg2Skipped = formIndex => {
    if (formIndex === 2)
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.LISTING_BG_REG2_SKIPPED, 'skipped');
};

export const checkJobAlertSurveyPathRegSkipped = index => {
    const regPagesSkipSupport = FeatureValueHelper.getJobAlertLandingSkipPages();

    if (
        regPagesSkipSupport &&
        !Utils.isEmptyObj(regPagesSkipSupport) &&
        regPagesSkipSupport.screens &&
        regPagesSkipSupport.screens.length > 0
    ) {
        const indexesSupported = regPagesSkipSupport.screens;

        if (indexesSupported.includes(index)) {
            IdStore.storeIdForKey(
                Constants.ID_STORE_KEYS.JOB_ALERT_SURVEY_PATH_LANDING_SKIP,
                'skipped',
            );
        }
    }
};

export const isLeadBlackListMail = (email = '') => {
    const leadBlackListEmailSuffixes = FeatureValueHelper.getLeadBlackListMailSuffixes();

    return !!leadBlackListEmailSuffixes.find(suffix => email.endsWith(suffix));
};

export const filterInvalidEvsPvs = evs => {
    const isEmailBlackListed = IdStore.storeIdForKey(Constants.ID_STORE_KEYS.IS_EMAIL_BLACK_LISTED);

    if (isEmailBlackListed) return undefined;

    return evs;
};

export const checkAndFireEmailLead = (email, eventData, type) => {
    if (isLeadBlackListMail(email)) return;
    eventManager.sendEvent(Constants.EVENT.HNP_LEAD, eventData, type);
};

export const fireHnpEmailOnlyHnpLead = (data = {}, type = Constants.EVENT_TYPE.DATA) => {
    const userDetails = _getUserFromCache();
    const eventData = {
        cat: 'email',
        email: userDetails.emailId,
        ...data,
        ekw: getEkw(),
    };
    checkAndFireEmailLead(userDetails.emailId, eventData, type);
};

export const updateFormDataWithJbDetails = (details, formData) => {
    if (details) {
        if (details.trafficCategory) {
            formData['trafficCategory'] = details.trafficCategory;
        }

        if (details.val) {
            formData['val'] = details.val;
        }

        if (details.usePubVal) {
            formData['usePubVal'] = details.usePubVal;
        }
    }

    return formData;
};

export const isGenericTerm = term => {
    const genericTermFeature = FeatureValueHelper.fvGetGenericTerms();
    const genericTerms =
        genericTermFeature && genericTermFeature.length
            ? genericTermFeature
            : [
                  'full time jobs',
                  'online jobs',
                  'immediately hiring',
                  'senior jobs',
                  'local jobs',
                  'work from home',
              ];

    return genericTerms.includes((term || '').toLowerCase());
};

export const getH1TextConfig = (index, zipCode, hourlyPay) => {
    const h1CTATextObj = _getConfigurableH1CTATexts(index);

    if (!Utils.isNull(h1CTATextObj) && !Utils.isEmptyObj(h1CTATextObj)) {
        if (h1CTATextObj.h1 && !Utils.isEmptyStr(h1CTATextObj.h1)) {
            h1CTATextObj.h1 = _formatH1(h1CTATextObj.h1, zipCode, hourlyPay);
        }

        if (h1CTATextObj.h2 && !Utils.isEmptyStr(h1CTATextObj.h2)) {
            h1CTATextObj.h2 = _formatH1(h1CTATextObj.h2, zipCode, hourlyPay);
        }

        return h1CTATextObj;
    }

    return { h1: undefined, h2: undefined, logoBeforeH1: '0', ctaText: '' };
};

export const fetchJobs = async () => {
    const jobsType = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);

    if (!Utils.isNull(jobsType)) {
        // Fetch # of jobs based on zip entered by user or device zip or city
        const userData = UserDataStore.fetchUserDetailsFromStore();
        const location =
            (userData && userData['zip']) ||
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE) ||
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_CITY) ||
            null;
        const query = JobSearchDataSource.createJobsQueryForTerm({
            term: jobsType,
            location,
        });

        const jobsData = await JobSearchDataSource.fetchJobsDataForQueryMultiSource(
            getDefaultJobSourcePayload(query, {}),
        );

        if (!Utils.isEmptyObj(jobsData)) {
            return jobsData;
        }

        return {};
    }

    return {};
};

export const storeTotalJobs = totalJobs => {
    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.TOTAL_JOBS, totalJobs);
};

export const hasProfanity = text => {
    const profanityExclusionWords = FeatureValueHelper.getProfanityExcludedTerms();
    const filteredTextFromExclusionList = profanityExclusionWords.filter(
        word => word.toLowerCase() === text.toLowerCase(),
    );

    if (filteredTextFromExclusionList && filteredTextFromExclusionList.length > 0) return null;

    return UserDataValidator.hasProfanity(text);
};

export const isSkipToNewTabV4 = () => {
    const featureData = FeatureValueHelper.getSkipToNewTabV4Data() || {};
    const adUnitData = MemoryStore.getItem(memoryStoreKeys.SKIP_TO_TAB_AD_UNIT_DATA);

    return (
        AbTestHelper.skipToNewTabV4() &&
        IdStore.fetchIdForKey(featureData.abtChannel, 'control') !== 'control' &&
        adUnitData &&
        adUnitData.ad
    );
};

export const isSkipToNewTabStaticDynamic = () => {
    const featureData = FeatureValueHelper.getStaticDynamicTaboverData() || {};
    const adUnitData = MemoryStore.getItem(memoryStoreKeys.SKIP_TO_TAB_AD_UNIT_DATA);

    return (
        AbTestHelper.staticDynamicTaboverLander() &&
        IdStore.fetchIdForKey(featureData.abtChannel, 'control') !== 'control' &&
        adUnitData &&
        adUnitData.ad
    );
};
