export const INVALID_KEYWORDS = [
    'null',
    'city',
    'other',
    '{keyword}',
    'jj',
    'undefined',
    'all',
    '{job type}',
    '{Job Type}',
];
