import Utils from 'common/utils';

const uuidv4 = require('uuid/v4');

export const formatAdUnit = (au = {}) => {
    const adUnit = JSON.parse(JSON.stringify(au));

    if (adUnit.ad.revType && adUnit.ad.revType.toLowerCase() === 'cpa') {
        if (adUnit.ad.customAdInfo && adUnit.ad.customAdInfo.cpa) {
            adUnit.ad['cpa'] = adUnit.ad.customAdInfo.cpa;
        } else if (adUnit.ad.bid) {
            adUnit.ad['cpa'] = adUnit.ad.bid;
        }
    }

    if (adUnit.ad.revType && adUnit.ad.revType.toLowerCase() === 'cpc') {
        // eslint-disable-next-line max-depth
        if (adUnit.ad.customAdInfo && adUnit.ad.customAdInfo.cpc) {
            adUnit.ad['cpc'] = adUnit.ad.customAdInfo.cpc;
        } else if (adUnit.ad.bid) {
            adUnit.ad['cpc'] = adUnit.ad.bid;
        }
    }

    if (adUnit.ad && adUnit.ad.dealId) {
        adUnit.ad['max_deal_id'] = adUnit.ad.dealId;
    }

    if (adUnit.ad && !adUnit.ad.uuid) {
        adUnit.ad.uuid = uuidv4().replace(/-/g, '');
    }

    if (adUnit.ad) {
        // Job Result Meta deta
        const bid = Utils.getObjVal(adUnit, ['ad', 'bid']);
        const { forwardAdMetaData, ...rest } = Utils.getObjVal(adUnit, ['ad', 'customAdInfo']);

        adUnit.meta = {
            ...(bid ? { bid: bid } : {}),
            uuid: adUnit.ad.uuid,
            advid: adUnit.ad.advId,
            ...(forwardAdMetaData ? rest : {}),
        };
    }

    return adUnit;
};
