export default class PhoneValidator {
    static skipDeepValidation = false;

    /**
     * @param {string} phoneNumber
     * @returns {string|null}
     */
    static isValidPhoneNumber(phoneNumber) {
        const errorMessage = 'Invalid Phone Number';

        if (phoneNumber.length !== 14) {
            return errorMessage;
        }

        return null;
    }

    static formatPhoneNumber(rawPhoneNumber, allowPartial) {
        if (!rawPhoneNumber) return '';

        const phoneNumber = rawPhoneNumber.replace(/\D/g, '');

        const partA = phoneNumber.substring(0, 3);
        const partB = phoneNumber.substring(3, 6);
        const partC = phoneNumber.substring(6, 11);

        // 2345678  =>  234-567-8
        if (partC || (allowPartial && partB)) {
            return `(${partA}) ${partB}-${partC}`;
        }

        // 2345 => 234-5
        if (partB) {
            return `${partA}-${partB}`;
        }

        // if partA
        return phoneNumber;
    }
}
