import Constants from 'common/constants';
import Utils from 'common/utils';

export const delayedPromise = duration => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, duration);
    });
};

export const fetchWithTimeout = (url, options, timeout = Constants.DEFAULT_NETWORK_TIMEOUT_MS) => {
    return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-var
        var interval = setTimeout(function () {
            clearTimeout(interval);
            reject({ status: 504, message: 'timeout - client' });
        }, timeout);

        fetch(url, options)
            .then(response => {
                clearTimeout(interval);
                resolve(response);
            })
            .catch(error => {
                clearTimeout(interval);
                reject(error);
            });
    });
};

/**
 * Fetch calls
 * @param {*} url
 * @param {*} options
 * @param {*} retries
 * @param {*} backoff
 */
export const fetchRetry = async (url, options = {}, retries = 2, backoff = 300) => {
    /* 1 */
    const retryCodes = [408, 500, 502, 503, 504, 522, 524];

    for (let attempt = 1; attempt <= retries + 1; attempt++) {
        try {
            console.log(`Fetch Config Attempt: ${attempt}`);
            const res = await fetchWithTimeout(url, options);

            if (res.ok) return res.json();

            if (retryCodes.includes(res.status)) {
                await delayedPromise(backoff * attempt);
            } else {
                throw new Error(res);
            }
        } catch (err) {
            console.error(`ERR in fetchRetry: ${JSON.stringify(err)}`);

            if (!(err && err.status && retryCodes.includes(err.status))) {
                throw new Error(err);
            }
        }
    }
};

export const addValueToObject = (object, key, value) => {
    if (Utils.isNull(key)) return;

    if (Utils.isNull(value)) {
        object[key] = '';
    } else {
        object[key] = value;
    }
};
