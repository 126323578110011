import React from 'react';
import { Grid, Typography, Button, FormLabel, InputAdornment } from '@material-ui/core';
import RoutePrefixHandler from 'common/routePrefixHandler';
import CloseIcon from '@material-ui/icons/Close';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { ValidatorFormHOC } from 'hocs/validatorFormHOC';
import Constants from 'common/constants';
import { TextValidator } from 'react-material-ui-form-validator';
import { useStyles } from './preferencePopup.styles';

function PreferencePopup(props) {
    const formLayout = {
        xs: 12,
    };

    const gridItemProps = {
        item: true,
        style: {
            width: '100%',
        },
        ...formLayout,
    };

    const {
        handleSubmit,
        handleClick,
        selectedEmployer,
        handleCrossClick,
        success,
        jobType,
        zipCode,
        handleZipCodeFieldFocused,
        handleInputChange,
    } = props;

    const classes = useStyles();

    return (
        <div className={`app-wrapper`}>
            <Grid className={classes.container} container direction="column">
                <Grid {...gridItemProps}>
                    <Typography className={classes.header}>
                        IMPROVE YOUR JOB SEARCH.
                        <br /> UPDATE YOUR PROFILE
                    </Typography>
                </Grid>
                <Grid
                    className={classes.root}
                    container
                    direction="column"
                    id="landing"
                    justify="space-between">
                    <div>
                        <ValidatorFormHOC
                            className={`form-warpper ${classes.inputContainer}`}
                            onSubmit={handleSubmit}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%',
                                }}>
                                <div
                                    className={`white-bg-input align-input-side ${classes.zipJobTitleContainer}`}>
                                    <Grid {...gridItemProps} className="mobile-align-input">
                                        <FormLabel className={`input-label ${classes.labelV2}`}>
                                            {Constants.LABELS.LANDING.ZIP}
                                        </FormLabel>
                                        <TextValidator
                                            InputProps={{
                                                spellCheck: false,
                                                type: 'tel',
                                                endAdornment: success.zipCode && zipCode && (
                                                    <InputAdornment position="end">
                                                        <LazyLoadComponent threshold="50">
                                                            <picture>
                                                                <source
                                                                    srcSet={RoutePrefixHandler.getImagePath(
                                                                        'images/checked.webp',
                                                                    )}
                                                                    type="image/webp"
                                                                />
                                                                <img
                                                                    alt={`icon`}
                                                                    className={classes.checkedImg}
                                                                    src={RoutePrefixHandler.getImagePath(
                                                                        'images/checked.png',
                                                                    )}
                                                                />
                                                            </picture>
                                                        </LazyLoadComponent>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            className={
                                                success.zipCode
                                                    ? `input-with-icon ${classes.inputBox}`
                                                    : `input-with-icon ${classes.errorInputBox}`
                                            }
                                            errorMessages={['Required', 'Invalid zip code']}
                                            id="landing-form-zip-code"
                                            label="Zip Code"
                                            name="zipCode"
                                            onChange={handleInputChange}
                                            onFocus={handleZipCodeFieldFocused}
                                            validators={['required', 'isValidZipCode']}
                                            value={zipCode || ''}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        {...gridItemProps}
                                        className={`desktop-margin mobile-align-input `}>
                                        <FormLabel className={`input-label  ${classes.labelV2}`}>
                                            Job Title
                                        </FormLabel>
                                        <TextValidator
                                            InputProps={{
                                                spellCheck: false,
                                                endAdornment: success.jobType && jobType && (
                                                    <InputAdornment position="end">
                                                        <LazyLoadComponent threshold="50">
                                                            <picture>
                                                                <source
                                                                    srcSet={RoutePrefixHandler.getImagePath(
                                                                        'images/checked.webp',
                                                                    )}
                                                                    type="image/webp"
                                                                />
                                                                <img
                                                                    alt={`icon`}
                                                                    className={classes.checkedImg}
                                                                    src={RoutePrefixHandler.getImagePath(
                                                                        'images/checked.png',
                                                                    )}
                                                                />
                                                            </picture>
                                                        </LazyLoadComponent>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            className={
                                                success.jobType
                                                    ? `input-with-icon ${classes.inputBox}`
                                                    : `input-with-icon ${classes.errorInputBox}`
                                            }
                                            errorMessages={['Required', 'Invalid job type']}
                                            id="landing-form-job-type"
                                            label="Job Title"
                                            name="jobType"
                                            onChange={handleInputChange}
                                            placeholder="Job Title"
                                            validators={['required', 'isValidJobType']}
                                            value={jobType || ''}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </div>

                                <Grid {...gridItemProps}>
                                    <div className={classes.btnContainer}>
                                        <Button
                                            className={classes.submitBtn}
                                            color="primary"
                                            endIcon={
                                                <LazyLoadComponent threshold="50">
                                                    <picture>
                                                        <source
                                                            srcSet={RoutePrefixHandler.getImagePath(
                                                                'images/left-arrow.webp',
                                                            )}
                                                            type="image/webp"
                                                        />
                                                        <img
                                                            alt={`icon`}
                                                            className={classes.btnIcon}
                                                            height="18"
                                                            src={RoutePrefixHandler.getImagePath(
                                                                'images/left-arrow.png',
                                                            )}
                                                            width="11"
                                                        />
                                                    </picture>
                                                </LazyLoadComponent>
                                            }
                                            onClick={handleClick}
                                            type="submit"
                                            value={selectedEmployer}>
                                            SAVE CHANGES
                                        </Button>
                                    </div>
                                </Grid>
                            </div>
                        </ValidatorFormHOC>
                    </div>
                </Grid>
            </Grid>

            <div className={`${classes.crossIconContainer}`} onClick={handleCrossClick}>
                <CloseIcon className={classes.crossIcon} height="15" width="15" />
            </div>
            <div className={classes.userIconContainer}>
                <picture>
                    <source
                        srcSet={RoutePrefixHandler.getImagePath('images/userIcon2.png')}
                        type="image/png"
                    />
                    <img
                        alt={`icon`}
                        className={classes.userIcon}
                        src={RoutePrefixHandler.getImagePath('images/userIcon2.png')}
                    />
                </picture>
            </div>
        </div>
    );
}

export default PreferencePopup;
