import AbManager from 'abManager/abTest';
import Utils from 'common/utils';
import Network from 'common/network';
import UrlManager from 'common/urlManager';
import OfferManager from 'components/ads/offerManager';

import Constants from 'common/constants';
import { IdStore } from 'dataStore';
import { StringHelper } from 'helpers';
import memoryStore from 'dataStore/memoryStore';
import memoryStoreKeys from 'common/memoryStoreKeys';
import { mapDataToEventObj } from 'eventManager/eventManager';

const blackListJobOnTitle = ['work', 'home'];

const advertiserSourceMap = {
    upward: 'upward',
    talroo: 'talroo',
    ziprecruiter: 'zip_recruiter',
    appcast: 'appcast',
    neuvoo: 'neuvoo',
    myjobhelper: 'myjobhelper',
};

const placementIdMap = {
    '/landing': 'lp',
    '/survey': 'sv',
    '/offers': 'op',
    '/listings': 'fp',
};

const excludeJobResultApiCachingEndpoints = [Constants.PAGE_ENDPOINTS.GRATIFICATION];

const advertisers = Object.keys(advertiserSourceMap);

export const isValidAdvertiser = (rawAdvertiser, advnms = advertisers) => {
    const advertiserNameStr = rawAdvertiser.toLowerCase();
    const advnm = advnms.find(e => advertiserNameStr.includes(e));

    return advnm ? advertiserSourceMap[advnm] : null;
};

export const addJobResultRequestData = () => {
    let additionalJobArgs = {};
    additionalJobArgs[Constants.ID_STORE_KEYS.DEVICE_WIDTH] = window.screen.width;
    additionalJobArgs[Constants.ID_STORE_KEYS.DEVICE_HEIGHT] = window.screen.height;
    additionalJobArgs[Constants.ID_STORE_KEYS.DEVICE_PIXEL_RATIO] = window.devicePixelRatio;
    additionalJobArgs[Constants.ID_STORE_KEYS.PAGE_URL] = window.location.href;
    let status = 0;

    try {
        if (Notification) {
            // granted=1, denied=2, default=0, not supported=4
            switch (Notification.permission) {
                case 'granted':
                    status = 1;
                    break;
                case 'denied':
                    status = 2;
                    break;
                default:
                    status = 0;
                    break;
            }
        }
    } catch {
        status = 4;
    }

    additionalJobArgs[Constants.NOTIFICATIONS.PUSH_ACCESS] = status;

    if (process.env.REACT_APP_CLIENT_VERSION) {
        additionalJobArgs['code_version'] = process.env.REACT_APP_CLIENT_VERSION;
    }

    try {
        const pubSourceTypeConfig =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUB_SOURCE_TYPE_CONFIG) &&
            JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUB_SOURCE_TYPE_CONFIG));

        if (pubSourceTypeConfig && pubSourceTypeConfig.pubSourceType)
            additionalJobArgs[Constants.ID_STORE_KEYS.PUB_SOURCE_TYPE] =
                pubSourceTypeConfig.pubSourceType;

        if (pubSourceTypeConfig) {
            let rawEncodedSource = '';

            if (pubSourceTypeConfig.utmSource)
                rawEncodedSource = rawEncodedSource + pubSourceTypeConfig.utmSource;

            if (pubSourceTypeConfig.pubSourceType)
                rawEncodedSource = rawEncodedSource + pubSourceTypeConfig.pubSourceType;

            const base64EncodedEncodedSource = btoa(`${rawEncodedSource.trim()}`);
            additionalJobArgs['encodedSourceV2'] = base64EncodedEncodedSource;
        }
    } catch (error) {
        console.log('Event Data: Pub Source Type Error - ', error);
    }
    const ps = IdStore.fetchIdForKey(Constants.URL_ARGS.PUBLISHER_SUBID);
    const utmSource = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE);
    const locale = Utils.getValForKeyFromCurrentUrl('lng');

    if (locale) {
        additionalJobArgs['locale'] = locale;
    }

    if (utmSource && ps) {
        additionalJobArgs['encodedSource'] = StringHelper.encodeURLSafeBase64(
            `${IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE)}-${IdStore.fetchIdForKey(
                Constants.ID_STORE_KEYS.UTM_CAMPAIGN,
            )}-${IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUBLISHER_SUBID)}`,
        );
    }

    const idObj = !Utils.isNull(IdStore) && IdStore.fetchAllIds && IdStore.fetchAllIds();

    if (false === Utils.isNull(idObj)) {
        // Only add entries from the list into the event object
        const mappedData = mapDataToEventObj(idObj, additionalJobArgs);
        additionalJobArgs = {
            ...additionalJobArgs,
            ...mappedData,
        };
    }

    return additionalJobArgs;
};

export default class JobSearchDataSource {
    static createJobsQueryForTerm({
        term,
        pageNum,
        pageSize,
        location,
        source,
        target,
        cpcfloor,
        advid,
    }) {
        if (Utils.isEmptyStr(term)) {
            term = ''; // Constants.JOBS_SEARCH.DEFAULT_JOB_SEARCH_TERM
        }

        if (Utils.isEmptyStr(pageNum)) {
            pageNum = Constants.JOBS_SEARCH.DEFAULT_JOB_PAGE_NUMBER;
        }

        if (Utils.isNull(pageSize)) {
            pageSize = Constants.JOBS_SEARCH.DEFAULT_JOB_RESULTS_PER_PAGE;
        }

        let args = {
            query: term,
            start: (pageNum - 1) * pageSize,
            limit: pageSize,
        };

        if (Utils.isNull(location)) {
            location = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE);
        }

        if (false === Utils.isNull(location)) {
            args['zipCode'] = location;
        }
        let ip = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUBLIC_IP);

        if (Utils.isNull(ip)) {
            ip = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_IP) || '23.249.172.175';
        }

        if (!Utils.isEmptyStr(source)) {
            args.source = source;
        }

        if (!Utils.isEmptyStr(advid)) {
            args.advid = advid;
        }

        if (!Utils.isNull(cpcfloor) && Utils.isValidNumber(cpcfloor)) {
            args.cpcfloor = cpcfloor;
        }

        if (!Utils.isEmptyStr(target)) {
            args.target = target;
        }
        args['ip'] = ip;

        return args;
    }

    /**
     * Fetches jobs data.
     * @param {Object} jobArgs
     * @returns {Promise}
     */
    static fetchJobsDataForQueryMultiSource(jobArgs, version = 'v5') {
        return new Promise((resolve, reject) => {
            if (Utils.isNull(jobArgs)) {
                console.log('Cannot fetch when the survey-args are empty!');
                reject(new Error('Survey args cannot be empty!'));

                return;
            }

            console.log(('[Jobs] Fetch Jobs V5', jobArgs));

            if (!jobArgs.target) {
                jobArgs.target = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM);

                if (
                    window.location.href &&
                    window.location.href.includes('lrdr') &&
                    jobArgs['source'] === Constants.ADVERTISERS.UPWARD
                ) {
                    jobArgs.target = 'back';
                }
            }

            jobArgs['sid'] = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UUID);
            jobArgs['cs_id'] = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);
            jobArgs.advertisers =
                jobArgs.advertisers && jobArgs.advertisers.length
                    ? jobArgs.advertisers
                    : memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA) || [];
            const additionalJobArgs = addJobResultRequestData();

            let jobsUrl = UrlManager.getJobsUrlV5();

            Network.post(
                jobsUrl,
                { 'content-type': 'application/json' },
                { ...jobArgs, ...additionalJobArgs },
                null,
                false,
            )
                .then(response => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }

                    return response.json();
                })
                .then(respJson => {
                    let { data } = respJson;
                    let { jobs = [] } = data;
                    const isSms =
                        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM) === 'sms';

                    if (jobs.length > 0 && isSms) {
                        let filteredJobs = [];
                        filteredJobs = jobs.filter(jb => {
                            let { title = '' } = jb;
                            title = title.toLowerCase().replace(/[^a-zA-Z\d ]/g, '');

                            if (title.length <= 0) return false;

                            return !blackListJobOnTitle.every(text => title.indexOf(text) !== -1);
                        });

                        data['jobs'] = filteredJobs;
                    }
                    // IdStore.storeIdForKey(Utils.hashCode(jobsUrlForCaching), JSON.stringify(data));
                    resolve(data);
                })
                .catch(err => {
                    console.log('Error -', err);
                    reject(err);
                });
        });
    }

    /**
     * Fetches jobs data.
     * @param {Object} jobArgs
     * @returns {Promise}
     */
    static fetchJobsDataForQuery(jobArgs, version = 'v2') {
        // Todo Remove log before merging
        console.trace('[Jobs]: Firing JobResults V2/V3');

        return new Promise((resolve, reject) => {
            if (Utils.isNull(jobArgs)) {
                console.log('Cannot fetch when the survey-args are empty!');
                reject(new Error('Survey args cannot be empty!'));

                return;
            }

            if (!jobArgs.source) {
                const mergedJobFeed = AbManager.getAbt7() === 'merged-job-feed';

                if (mergedJobFeed) {
                    jobArgs['source'] = 'talroo,appcast,upward';
                } else {
                    jobArgs['source'] = IdStore.fetchIdForKey(
                        Constants.ID_STORE_KEYS.JOB_ADS_SOURCE,
                    )
                        ? IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_ADS_SOURCE)
                        : Constants.ADVERTISERS.UPWARD;
                }
            }

            if (!jobArgs.target) {
                jobArgs.target = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM);

                if (
                    window.location.href &&
                    window.location.href.includes('lrdr') &&
                    jobArgs['source'] === Constants.ADVERTISERS.UPWARD
                ) {
                    jobArgs.target = 'back';
                }
            }
            let jobArgsForCaching = { ...jobArgs };

            jobArgs['sid'] = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UUID);
            let argsStr = Utils.encodeObjectToUrlArgs(jobArgs);

            let jobsUrl = `${
                version === 'v3' ? UrlManager.getJobsUrlV3() : UrlManager.getJobsUrl()
            }?${argsStr}`;

            let jobsUrlForCaching = `${
                version === 'v3' ? UrlManager.getJobsUrlV3() : UrlManager.getJobsUrl()
            }?${Utils.encodeObjectToUrlArgs(jobArgsForCaching)}`;

            let headers = null;
            console.log('JOBS: ', jobsUrl);

            let resp = IdStore.fetchIdForKey(Utils.hashCode(jobsUrlForCaching));

            const cachedJson = Utils.jsonSafeParse(resp);

            if (
                !Utils.isEmptyObj(cachedJson) &&
                excludeJobResultApiCachingEndpoints.indexOf(window.location.pathname) === -1
            ) {
                resolve(cachedJson);

                return;
            }

            Network.get(jobsUrl, headers, null, false)
                .then(response => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }

                    return response.json();
                })
                .then(respJson => {
                    let { data } = respJson;
                    let { jobs = [] } = data;
                    const isSms =
                        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM) === 'sms';

                    if (jobs.length > 0 && isSms) {
                        let filteredJobs = [];
                        filteredJobs = jobs.filter(jb => {
                            let { title = '' } = jb;
                            title = title.toLowerCase().replace(/[^a-zA-Z\d ]/g, '');

                            if (title.length <= 0) return false;

                            return !blackListJobOnTitle.every(text => title.indexOf(text) !== -1);
                        });

                        data['jobs'] = filteredJobs;
                    }
                    IdStore.storeIdForKey(Utils.hashCode(jobsUrlForCaching), JSON.stringify(data));
                    resolve(data);
                })
                .catch(err => {
                    console.log('Error -', err);
                    reject(err);
                });
        });
    }

    static isPrePopulated() {
        const userDetails =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS) &&
            JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS));

        return (userDetails &&
            userDetails[Constants.USER_DETAILS_KEY.FIRST_NAME] &&
            userDetails[Constants.USER_DETAILS_KEY.LAST_NAME] &&
            userDetails[Constants.USER_DETAILS_KEY.EMAIL] &&
            userDetails[Constants.USER_DETAILS_KEY.PHONE] &&
            userDetails[Constants.USER_DETAILS_KEY.DOB] &&
            userDetails[Constants.USER_DETAILS_KEY.ADDRESS]) ||
            Utils.hasNonEmptyPIIFieldsFromUrl()
            ? 1
            : 0;
    }

    static getKWTerm(initTerm) {
        return initTerm === 'Jobs near me' || initTerm === '' ? Constants.DEFAULT_KW : initTerm;
    }

    static getJTVal() {
        // Resolved from URL PARAMS
        let { jobType } = Utils.getJobType();

        if (!Utils.isEmptyStr(jobType)) return jobType;

        jobType = IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL);

        if (!Utils.isEmptyStr(jobType)) return jobType;

        jobType = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);

        if (!Utils.isEmptyStr(jobType)) return jobType;

        return Constants.JOBS_NEAR_ME;
    }

    static async fetchJobDataForAdUnitSourceV3(term, zip, adsUnitData) {
        try {
            console.log('Debug: Firing V3');

            if (adsUnitData && adsUnitData.ad && !Utils.isEmptyStr(adsUnitData.ad.advertiserName)) {
                const { revType, trackingPixels, campaignId, campaignName } = adsUnitData.ad;
                OfferManager.fireTrackingPixelsList(trackingPixels);

                const advertiser = {
                    id: adsUnitData.ad.advId,
                    name: adsUnitData.ad.advertiserName,
                };
                const cpcfloor =
                    Utils.getObjVal(adsUnitData, ['ad', 'customAdInfo', 'cpcfloor']) ||
                    Utils.getObjVal(adsUnitData, ['ad', 'customAdInfo', 'cpcFloor']);

                let campaign = null;

                if (!Utils.isEmptyStr(campaignId) && !Utils.isEmptyStr(campaignName)) {
                    campaign = {
                        id: campaignId,
                        name: campaignName,
                    };
                }

                const jobsQuery = JobSearchDataSource.createJobsQueryForTerm({
                    term,
                    pageNum: 1,
                    pageSize: 10,
                    location: zip,
                    cpcfloor,
                    advid: advertiser.id,
                    source: advertiser.name,
                });

                const jobsData = await JobSearchDataSource.fetchJobsDataForQuery(jobsQuery, 'v3');

                if (
                    advertiser &&
                    advertiser.name &&
                    advertiser.name.toLowerCase().includes('recruitics') &&
                    jobsData &&
                    jobsData.jobs &&
                    jobsData.jobs.length > 0
                ) {
                    const subIdValue = `subid=${IdStore.fetchIdForKey(
                        Constants.ID_STORE_KEYS.UTM_SOURCE,
                    )}-${IdStore.fetchIdForKey(
                        Constants.ID_STORE_KEYS.PUBLISHER_SUBID,
                    )}&clid=${IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UUID)}&placementid=${
                        placementIdMap[window.location.pathname]
                    }`;
                    const paramToAdd = {
                        subid: StringHelper.encodeURLSafeBase64(subIdValue),
                        rx_p: IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UUID),
                    };
                    jobsData.jobs.forEach(job => {
                        const url = new URL(job.url);
                        Object.keys(paramToAdd).forEach(param => {
                            url.searchParams.append(param, paramToAdd[param]);
                        });
                        job.url = url.href;
                    });
                }

                return {
                    advertiser,
                    jobsData,
                    revType,
                    campaign,
                    term,
                    zip,
                    source: advertiser.name,
                };
            }
        } catch (err) {
            throw new Error(`ERR in fetchJobDataForAdUnitSource: ${err}`);
        }
    }

    static async fetchJobDataForAdUnitSourceV5(term, zip, adsUnitData) {
        try {
            if (adsUnitData && adsUnitData.ad && !Utils.isEmptyStr(adsUnitData.ad.advertiserName)) {
                const { revType, trackingPixels, campaignId, campaignName } = adsUnitData.ad;
                OfferManager.fireTrackingPixelsList(trackingPixels);
                let advertiser;
                advertiser = {
                    id: adsUnitData.ad.advId,
                    name: adsUnitData.ad.advertiserName,
                };

                let campaign = null;

                if (!Utils.isEmptyStr(campaignId) && !Utils.isEmptyStr(campaignName)) {
                    campaign = {
                        id: campaignId,
                        name: campaignName,
                    };
                }

                const jobsQuery = JobSearchDataSource.createJobsQueryForTerm({
                    term,
                    pageNum: 1,
                    pageSize: 10,
                    location: zip,
                    advertisers: [{ ...adsUnitData.meta }],
                });

                const jobsData = await JobSearchDataSource.fetchJobsDataForQueryMultiSource({
                    ...jobsQuery,
                    advertisers: [{ ...adsUnitData.meta }],
                });

                return {
                    advertiser,
                    jobsData,
                    revType,
                    campaign,
                    adsUnitData,
                    term,
                    zip,
                    advertisers: [{ ...adsUnitData.meta }],
                };
            }
        } catch (err) {
            throw new Error(`ERR in fetchJobDataForAdUnitSource: ${err}`);
        }
    }

    static async fetchJobDataForSponsoredMarketplace(term, zip, adsUnitData) {
        try {
            if (adsUnitData && adsUnitData.ad && !Utils.isEmptyStr(adsUnitData.ad.advertiserName)) {
                const { revType, trackingPixels, campaignId, campaignName } = adsUnitData.ad;
                OfferManager.fireTrackingPixelsList(trackingPixels);
                let advertiser;
                advertiser = {
                    id: adsUnitData.ad.advId,
                    name: adsUnitData.ad.advertiserName,
                };

                let campaign = null;

                if (!Utils.isEmptyStr(campaignId) && !Utils.isEmptyStr(campaignName)) {
                    campaign = {
                        id: campaignId,
                        name: campaignName,
                    };
                }

                const jobsQuery = JobSearchDataSource.createJobsQueryForTerm({
                    term,
                    pageNum: 1,
                    pageSize: 1,
                    location: zip,
                    advertisers: [{ ...adsUnitData.meta }],
                });

                const jobsData = await JobSearchDataSource.fetchJobsDataForQueryMultiSource({
                    ...jobsQuery,
                    advertisers: [{ ...adsUnitData.meta }],
                });

                return {
                    advertiser,
                    jobsData,
                    revType,
                    campaign,
                    adsUnitData,
                    term,
                    zip,
                    advertisers: [{ ...adsUnitData.meta }],
                };
            }
        } catch (err) {
            throw new Error(`ERR in fetchJobDataForSponsoredMarketplace: ${err}`);
        }
    }
}
