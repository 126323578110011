import React from 'react';
import { Link, Typography } from '@material-ui/core';
import '../jobSearchView.css';
import RouterPrefixHandler from 'common/routePrefixHandler';
import Utils from 'common/utils';
import Constants from 'common/constants';
import OfferManager from 'components/ads/offerManager';
import EventManager from 'eventManager/eventManager';
import UrlManager from 'common/urlManager';
import Network from 'common/network';
import { makeStyles } from '@material-ui/core/styles';
import { IdStore } from 'dataStore/index';

const CreditScoreListings = props => {
    const {
        ad,
        week,
        job,
        creativeTitle,
        URL,
        newFulfillmentPage,
        isFulfillmentDesignPhaseTwo,
    } = props;
    const [title1, title2] = creativeTitle.split('-');
    const userDetails =
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS) &&
        JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS));
    const userFirstName =
        Utils.filterInvalidUrlInput(Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.FNAME)) ||
        (userDetails && userDetails['first_name']) ||
        false;
    const classes = styles();

    return (
        <React.Fragment>
            {newFulfillmentPage || isFulfillmentDesignPhaseTwo ? (
                <div className={`${classes.titleBody} title`}>
                    <span className="bold">
                        <span className={`${classes.titleHead} focus`}>
                            {userFirstName ? `${userFirstName}!` : ''}
                        </span>{' '}
                    </span>
                    We have located a job which is hiring immediately.
                </div>
            ) : (
                <div className="title">
                    <span className="bold">
                        <span className="focus">Wait!</span>{' '}
                    </span>
                    We’ve located a job hiring immediately!
                </div>
            )}
            <div className={`jobs-list-wrapper static-list ${week}`}>
                <Link
                    className={'link-container'}
                    href={URL}
                    onClick={e => {
                        const eventData = {
                            advid: ad.advId,
                            advnm: ad.advertiserName,
                            cmpid: ad.campaignId,
                            cmpnm: ad.campaignName,
                            cpa: job.cpa,
                            cpc: job.cpc,
                            max_filters: job.max_filters,
                        };

                        if (ad.customAdInfo.cpa) {
                            eventData.cpa = ad.customAdInfo.cpa;
                        }
                        EventManager.sendEvent(
                            Constants.EVENT.AD_CLICK,
                            { ...eventData, job_redirect_url: URL },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );

                        EventManager.sendEventWhenVisible(
                            Constants.EVENT.AD_PROG,
                            { ...eventData, job_redirect_url: URL },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );

                        if (ad.revType.toLowerCase() === 'cpc') {
                            EventManager.sendEvent(
                                Constants.EVENT.REVENUE,
                                {
                                    ervn: ad.bid,
                                    ...eventData,
                                },
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                        } else if (
                            ad.revType.toLowerCase() === 'cpa' &&
                            ad.customAdInfo &&
                            ad.customAdInfo.useBid === '1' &&
                            job.bid
                        ) {
                            EventManager.sendEvent(
                                Constants.EVENT.RVN_EVENT_NAME,
                                {
                                    ervn: job.bid,
                                    ...eventData,
                                },
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                        }

                        if (ad.onClickTrackingPixels && ad.onClickTrackingPixels.length) {
                            const adsData = OfferManager.getAdsData(ad);
                            const eventUrl = EventManager.createAndGetEventUrl(
                                Constants.EVENT.MAX_CLICK,
                                adsData,
                                Constants.EVENT_TYPE.ADS,
                            );
                            const clickParams = {
                                url: encodeURIComponent(ad.onClickTrackingPixels[0]),
                                eventUrl: encodeURIComponent(eventUrl),
                            };
                            Network.get(
                                `${UrlManager.getAdClickUrl()}?${Utils.encodeObjectToUrlArgs(
                                    clickParams,
                                )}`,
                            ).catch(err => {
                                console.log(err);
                            });
                        }
                        Utils.setCookie(`${ad.advertiserName}-click`, 1, 60);
                    }}
                    target="_blank">
                    <span className="static-branding-txt">
                        <picture>
                            <source
                                srcSet={RouterPrefixHandler.getImagePath('images/bolt.webp')}
                                type="image/webp"
                            />
                            <img
                                alt={`icon`}
                                className={'bolt-icon'}
                                src={RouterPrefixHandler.getImagePath('images/bolt.png')}
                            />
                        </picture>
                        <span>LIMITED OFFER</span>
                    </span>

                    <div className="job-element-card static-feed">
                        <div
                            className={`job-content-wrapper job-content-wrapper-creditAd
                            }`}>
                            <div className="job-content-title-info">
                                <div className="job-description-wrapper static-desc">
                                    <Typography variant="h1">{title1}</Typography>
                                    <div className={'job-description-creditAd-description'}>
                                        {ad.creativeDescription1 && (
                                            <Typography variant="h1">
                                                {ad.creativeDescription1}
                                            </Typography>
                                        )}
                                        {ad.creativeDescription2 && (
                                            <Typography variant="h1">
                                                {ad.creativeDescription2}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <Link className="static-anc" href={URL} target="_blank">
                                <span>{title2}</span>
                            </Link>
                        </div>
                    </div>
                </Link>
            </div>
        </React.Fragment>
    );
};

const styles = makeStyles({
    titleHead: {
        color: '#57b101 !important',
    },
    titleBody: {
        color: '#245475 !important',
        fontWeight: 'normal !important',
        lineHeight: '1.29 !important',
        marginBottom: 14,
    },
});

export default CreditScoreListings;
