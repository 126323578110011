import React from 'react';
import { Link, Avatar, Typography, Button, Grid, Chip } from '@material-ui/core';
import './jobSearchView.css';
import RouterPrefixHandler from 'common/routePrefixHandler';
import Utils from 'common/utils';
import Constants from 'common/constants';
import eventManager from 'eventManager/eventManager';
import { makeStyles } from '@material-ui/core/styles';
import { ConfigProvider, FeatureMap } from 'providers';
import { ChevronRightTwoTone } from '@material-ui/icons';
import { RecaptchaHelper, AbTestHelper } from 'helpers';
import memoryStore from 'dataStore/memoryStore';
import memoryStoreKeys from 'common/memoryStoreKeys';
import classNames from 'classnames';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import UserDataStore from 'components/landing/userDataStore';
import { CommonHelper, FeatureValueHelper } from 'helpers/index';
import { brandedFlowValues } from 'helpers/featureValueHelpers/featureValueHelpers';

const images = [
    {
        link: '/images/placeholders/placeholder-img1.svg',
    },
    {
        link: '/images/placeholders/placeholder-img2.svg',
    },
    {
        link: '/images/placeholders/placeholder-img3.svg',
    },
    {
        link: '/images/placeholders/placeholder-img4.svg',
    },
    {
        link: '/images/placeholders/placeholder-img5.svg',
    },
];

const ctaArrowBtn = () => {
    return (
        <div className="static-feed-right static-feed-rightv">
            <picture>
                <source
                    srcSet={RouterPrefixHandler.getImagePath('images/arrow2.webp')}
                    type="image/webp"
                    width={9}
                />
                <img
                    alt={`icon`}
                    className="arrow-icon-img"
                    src={RouterPrefixHandler.getImagePath('images/arrow2.png')}
                    width={9}
                />
            </picture>
        </div>
    );
};

const newSERPBtn = () => {
    let classes = useStyles();

    return (
        <div>
            <Button className={classes.serpBtn}>
                View Salary&ensp; <ArrowForwardIcon />
            </Button>
        </div>
    );
};
const staticLocationMncJobs = (company, location) => {
    return (
        <div
            className={classNames('loc-lead-wrapper', {
                'loc-lead-wrap-v3': AbTestHelper.serpRevampV2(),
                'loc-lead-wrap-v1': AbTestHelper.serpRevampV1(),
            })}>
            <div className="cont">
                <img
                    alt={`location`}
                    className={`box-img`}
                    src={RouterPrefixHandler.getImagePath(`images/upward-rg/company.png`)}
                    width={12}
                />
                <span className="ContText">{company}</span>
            </div>
            <div className="cont">
                <img
                    alt={`location`}
                    className={`box-img`}
                    src={RouterPrefixHandler.getImagePath(`images/upward-rg/locationsmall.png`)}
                    width={12}
                />
                <span className="ContText">{location}</span>
            </div>
        </div>
    );
};

const renderHighlight = (index, showHighlight) => {
    const fulfillmentPageDesignTest = ConfigProvider.Features.getFeature(
        FeatureMap.FulfillmentPageDesignTest,
    );
    const fulfillmentDesignEnable = fulfillmentPageDesignTest
        ? fulfillmentPageDesignTest.value === 'fulfillment-page-design'
        : false;

    if (showHighlight && index < 2) {
        const custom =
            index === 0
                ? {
                      icon: 'flame',
                      text: fulfillmentDesignEnable ? 'IMMEDIATE HIRE' : 'HOT',
                      styleImg: { width: '10px', height: '14px', display: 'inline-flex' },
                      styleContainer: fulfillmentDesignEnable
                          ? { backgroundColor: '#ff4c4c' }
                          : { backgroundColor: '#f9001a' },
                  }
                : {
                      icon: 'star-2',
                      text: fulfillmentDesignEnable ? 'JUST POSTED' : 'NEW',
                      styleImg: { width: '11px', height: '10px', display: 'inline-flex' },
                      styleContainer: fulfillmentDesignEnable
                          ? { backgroundColor: '#0097fb' }
                          : AbTestHelper.metaTagTest()
                          ? { backgroundColor: '#27b100' }
                          : { backgroundColor: '#f9001a' },
                  };

        return (
            <div
                className={`job-highlight ${
                    fulfillmentDesignEnable === true && 'job-highlight-fulfillment-design'
                }`}
                style={custom.styleContainer}>
                <picture className="job-highlight-picture">
                    <source
                        srcSet={RouterPrefixHandler.getLogoPath(`logos/${custom.icon}.webp`)}
                        type="image/webp"
                    />
                    <img
                        alt={`img`}
                        src={RouterPrefixHandler.getLogoPath(`logos/${custom.icon}.png`)}
                        style={custom.styleImg}
                    />
                </picture>
                <span
                    className={`job-highlight-text ${
                        fulfillmentDesignEnable === true && 'job-highlight-text-fulfillment-design'
                    }`}>
                    {custom.text}
                </span>
            </div>
        );
    }

    return null;
};
const renderHighlightABtest = (index, showHighlight) => {
    const fulfillmentPageDesignTest = ConfigProvider.Features.getFeature(
        FeatureMap.FulfillmentPageDesignTest,
    );
    const fulfillmentDesignEnable = fulfillmentPageDesignTest
        ? fulfillmentPageDesignTest.value === 'fulfillment-page-design'
        : false;

    if (showHighlight && (index === 0 || index === 1 || index === 6 || index === 9)) {
        let custom = {};

        if (index === 0 || index === 9) {
            custom = {
                icon: 'urgent',
                text: fulfillmentDesignEnable ? 'IMMEDIATE HIRE' : 'Urgent Hiring',
                styleImg: {
                    width: '10px',
                    height: '14px',
                    display: 'inline-flex',
                    paddingTop: '3px',
                },
                styleContainer: fulfillmentDesignEnable
                    ? { backgroundColor: '#ff4c4c' }
                    : {
                          backgroundImage: 'linear-gradient(to left, #f90, #ffc656)',
                          fontWeight: '600',
                      },
            };
        } else if (index === 1) {
            custom = {
                icon: 'Dollar',
                text: fulfillmentDesignEnable ? 'IMMEDIATE HIRE' : 'Trending',
                styleImg: {
                    width: '10px',
                    height: '14px',
                    display: 'inline-flex',
                    paddingTop: '3px',
                },
                styleContainer: fulfillmentDesignEnable
                    ? { backgroundColor: '#ff4c4c' }
                    : {
                          backgroundImage: 'linear-gradient(to left, #5bb109, #92de45)',
                          fontWeight: '600',
                      },
            };
        } else if (index === 6) {
            custom = {
                icon: 'Immediate',
                text: fulfillmentDesignEnable ? 'IMMEDIATE HIRE' : 'Immediate Start',
                styleImg: {
                    width: '10px',
                    height: '14px',
                    display: 'inline-flex',
                    paddingTop: '3px',
                },
                styleContainer: fulfillmentDesignEnable
                    ? { backgroundColor: '#ff4c4c' }
                    : {
                          backgroundImage: 'linear-gradient(to left, #5183fe, #9cb9ff)',
                          fontWeight: '600',
                      },
            };
        }

        return (
            <div
                className={`job-highlight job-highlightv ${
                    fulfillmentDesignEnable === true && 'job-highlight-fulfillment-design'
                }`}
                style={custom.styleContainer}>
                <picture className="job-highlight-picture">
                    <source
                        srcSet={RouterPrefixHandler.getImagePath(`images/logos/${custom.icon}.svg`)}
                    />
                    <img
                        alt={`img`}
                        src={RouterPrefixHandler.getImagePath(`images/logos/${custom.icon}.svg`)}
                        style={custom.styleImg}
                    />
                </picture>
                <span
                    className={`job-highlight-text job-highlight-textv ${
                        fulfillmentDesignEnable === true && 'job-highlight-text-fulfillment-design'
                    }`}>
                    {custom.text}
                </span>
            </div>
        );
    }

    return null;
};

const handleClick = ele => {
    console.log('clicked!!!!!!!');
    console.log(ele);
    window.l5_trk(ele);
};

const displayJobsList = props => {
    const fulfillmentPageDesignTest = ConfigProvider.Features.getFeature(
        FeatureMap.FulfillmentPageDesignTest,
    );
    const fulfillmentDesignEnable = fulfillmentPageDesignTest
        ? fulfillmentPageDesignTest.value === 'fulfillment-page-design'
        : false;

    let userDetails = UserDataStore.fetchUserDetailsFromStore() || {};

    let phone = null;

    if (userDetails.phone_number) {
        phone = userDetails.phone_number;
    } else {
        phone = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE_NUMBER) || '',
        );
    }

    let {
        showLogo,
        showHighlight,
        jobsList,
        staticListings = [],
        nativeAdListings = [],
        updateAdvertiser,
        showFbGoogleInterstitial,
        newFulfillmentPage,
        isFulfillmentDesignPhaseTwo,
    } = props;
    const handleFbGoogleInterstitialClick = applyUrl => {
        props.handleFbGoogleInterstitialClick(applyUrl);
    };
    let classes = useStyles();

    const getLinkBody = ({
        logo,
        title,
        location,
        applyUrl,
        styles,
        index,
        showHighlight,
        company,
        shortDesc,
        workTypeList,
        salaryList,
        benefitList,
    }) => {
        const jobDesc = shortDesc.length > 100 ? `${shortDesc.substring(0, 100)}...` : shortDesc;

        if (newFulfillmentPage) {
            return (
                <div className={`job-element-card`} style={{ flexDirection: 'column' }}>
                    <div className={classes.cardDetailContainer}>
                        <div className="job-content-wrapper">
                            {showLogo && (
                                <div className={`job-logo-wrapper`}>
                                    <Avatar src={logo} variant={'circle'} />
                                </div>
                            )}
                            <div className={`job-description-wrapper`}>
                                <Typography className={classes.jobTitle} variant="h1">
                                    {title}
                                </Typography>
                                <Typography className={classes.jobLocationNewDesign} variant="h2">
                                    {location}
                                </Typography>
                            </div>
                        </div>
                        <div className={`job-apply-wrapper`}>
                            <Link
                                className={classes.arrowIcon}
                                color="primary"
                                href={applyUrl}
                                target="_blank">
                                <ChevronRightTwoTone />
                            </Link>
                        </div>
                    </div>
                    <span className={classes.underLine}></span>
                    <div className={classes.buttonContainer}>
                        <Link className={classes.newJobDescription} href={applyUrl} target="_blank">
                            View Description
                        </Link>
                        <Link
                            className={`${classes.newJobDescription}`}
                            href={applyUrl}
                            target="_blank">
                            View Salary
                        </Link>
                    </div>
                </div>
            );
        } else if (isFulfillmentDesignPhaseTwo) {
            return (
                <div className={`job-element-card`} style={{ flexDirection: 'column', padding: 0 }}>
                    <span className={classes.greyBoxHeader}>{title}</span>
                    <div className={classes.cardDetailContainer} style={{ paddingLeft: 10 }}>
                        <div className="job-content-wrapper">
                            {showLogo && (
                                <div className={`job-logo-wrapper`}>
                                    <Avatar src={logo} variant={'circle'} />
                                </div>
                            )}
                            <div className={`job-description-wrapper`}>
                                <Typography className={classes.jobTitle} variant="h1">
                                    {location}
                                </Typography>
                                <Typography className={classes.jobLocationNewDesign} variant="h2">
                                    {company}
                                </Typography>
                            </div>
                        </div>
                        <div className={`job-apply-wrapper`}>
                            <Link
                                className={classes.arrowIcon}
                                color="primary"
                                href={applyUrl}
                                target="_blank">
                                <ChevronRightTwoTone />
                            </Link>
                        </div>
                    </div>
                    <span className={classes.underLine}></span>
                    <div className={classes.buttonContainer}>
                        <Link className={classes.newJobDescription} href={applyUrl} target="_blank">
                            View Description
                        </Link>
                        <Link
                            className={`${classes.newJobDescription}`}
                            href={applyUrl}
                            target="_blank">
                            View Salary
                        </Link>
                    </div>
                </div>
            );
        } else if (AbTestHelper.newSERPTest() || AbTestHelper.darkSERPTest()) {
            return (
                <Grid className={`${classes.mainContainer}`}>
                    <div className={classes.newSERPRoot}>
                        <Grid className={classes.contentContainer}>
                            {showLogo && (
                                <div
                                    className={`job-logo-wrapper ${classes.jobBadge} ${
                                        fulfillmentDesignEnable &&
                                        'job-logo-wrapper-fulfillment-design'
                                    }`}>
                                    <div className={classes.indexingDiv}>
                                        <p>{index + 1}</p>
                                    </div>
                                </div>
                            )}
                            <Grid className={classes.jobInfoContainer}>
                                <Typography className={classNames(classes.jobTitleV2)}>
                                    {title}
                                </Typography>
                                <Grid className={classes.locationDiv}>
                                    <Typography className={classes.company}>
                                        <picture>
                                            <source
                                                srcSet={RouterPrefixHandler.getImagePath(
                                                    'images/solid-bag.webp',
                                                )}
                                                type="image/webp"
                                            />
                                            <img
                                                alt={`icon`}
                                                className={classes.companyIcon}
                                                src={RouterPrefixHandler.getImagePath(
                                                    'images/solid-bag.png',
                                                )}
                                            />
                                        </picture>{' '}
                                        {company}
                                    </Typography>
                                    <Typography className={classes.location}>
                                        <LocationOnIcon className={classes.locationIcon} />
                                        {location}
                                    </Typography>
                                </Grid>
                                {AbTestHelper.darkSERPTestV1() && (
                                    <Grid className={classes.shortDescV1}>
                                        {jobDesc}
                                        <Button className={classes.learnMore}>Learn More</Button>
                                    </Grid>
                                )}
                                {!AbTestHelper.darkSERPTestV1() && (
                                    <Grid className={classes.shortDesc}>{shortDesc}</Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid className={classes.newBtnContainer}>{newSERPBtn()}</Grid>
                    </div>
                    {AbTestHelper.darkSERPTestV2() && (
                        <Link className={classes.viewDescContainer} href={URL} target="_blank">
                            View Description
                        </Link>
                    )}
                </Grid>
            );
        } else if (AbTestHelper.metaTagTest()) {
            return (
                <div
                    className={`job-element-card ${
                        fulfillmentDesignEnable && 'job-element-card-fulfillment-design'
                    } ${AbTestHelper.serpRevamp() && 'job-element-cardv borderSpecific'} `}
                    style={styles}>
                    <div className={classes.metaTagWrapper}>
                        <div
                            className={classNames('job-content-wrapper', {
                                'job-content-wrapperv': AbTestHelper.serpRevamp(),
                            })}>
                            {showLogo && (
                                <div
                                    className={`job-logo-wrapper ${
                                        fulfillmentDesignEnable &&
                                        'job-logo-wrapper-fulfillment-design'
                                    }`}>
                                    <Avatar
                                        src={logo}
                                        variant={fulfillmentDesignEnable ? 'rounded' : 'circle'}
                                    />
                                </div>
                            )}
                            <div
                                className={classNames('job-description-wrapper', {
                                    'job-description-wrapper-fulfillment-design': fulfillmentDesignEnable,
                                    'job-despv': AbTestHelper.serpRevamp(),
                                    'job-desp-v1': AbTestHelper.serpRevampV1(),
                                })}>
                                <Typography
                                    className={classNames(classes.jobTitle, {
                                        [classes.jobTitlev1]: AbTestHelper.serpRevampV1(),
                                    })}
                                    variant="h1">
                                    {title}
                                </Typography>
                                {AbTestHelper.serpRevamp() ? (
                                    staticLocationMncJobs(company, location)
                                ) : (
                                    <Typography className={classes.jobLocation} variant="h2">
                                        {location}
                                    </Typography>
                                )}
                                {AbTestHelper.serpRevampV1() ? (
                                    <Link className="view-salary-btn" href={URL} target="_blank">
                                        <span>View Salary</span>
                                    </Link>
                                ) : (
                                    <Link
                                        className={classNames('classes.jobDescription', {
                                            'classes.fulfillmentDesignJobDescription': fulfillmentDesignEnable,
                                            view_text: AbTestHelper.serpRevampV2(),
                                        })}
                                        href={applyUrl}
                                        target="_blank"
                                        underline="always">
                                        {AbTestHelper.serpRevampV2()
                                            ? 'View Description & Salary Info'
                                            : fulfillmentDesignEnable
                                            ? 'View Salary & Description'
                                            : 'View Description'}
                                    </Link>
                                )}
                            </div>
                        </div>
                        <div
                            className={`job-apply-wrapper ${
                                fulfillmentDesignEnable && 'job-apply-wrapper-fulfillment-design'
                            } ${AbTestHelper.serpRevamp() && 'job-apply-wrappervab'}`}>
                            {!fulfillmentDesignEnable && (
                                <Button
                                    className={`${AbTestHelper.serpRevamp() && 'view_sal_btn'} ${
                                        AbTestHelper.BrandedLanderListingsTest() &&
                                        classes.salaryButtonBranded
                                    }`}
                                    href={applyUrl}
                                    target="_blank">
                                    {FeatureValueHelper.getListingCTAText()
                                        ? FeatureValueHelper.getListingCTAText()
                                        : 'View Salary'}
                                </Button>
                            )}
                            {AbTestHelper.serpRevampV1() && (
                                <div className="link-btn-wrapper">
                                    <Link
                                        className={`view-salary-btn-desk ${
                                            AbTestHelper.BrandedLanderListingsTest() &&
                                            classes.salaryButtonBranded
                                        }`}
                                        href={URL}
                                        target="_blank">
                                        <span>View Salary</span>
                                    </Link>
                                </div>
                            )}
                            {AbTestHelper.serpRevampV2() && ctaArrowBtn()}
                            {fulfillmentDesignEnable && (
                                <Button
                                    className="job-apply-button-fulfillment-design"
                                    color="primary"
                                    href={applyUrl}
                                    target="_blank">
                                    <picture>
                                        <source
                                            srcSet={RouterPrefixHandler.getImagePath(
                                                'images/left-arrow.webp',
                                            )}
                                            type="image/webp"
                                        />
                                        <img
                                            alt={`icon`}
                                            src={RouterPrefixHandler.getImagePath(
                                                'images/left-arrow.png',
                                            )}
                                        />
                                    </picture>
                                </Button>
                            )}
                            {AbTestHelper.serpRevamp()
                                ? renderHighlightABtest(index, showHighlight)
                                : renderHighlight(index, showHighlight)}
                        </div>
                    </div>
                    {workTypeList || salaryList || benefitList ? (
                        <div className={classes.jobTagWrapper}>
                            <div className={classes.chipContainer}>
                                {workTypeList
                                    ? workTypeList.map(labelText => (
                                          <Chip className={classes.metaTag} label={labelText} />
                                      ))
                                    : null}
                                {salaryList
                                    ? salaryList.map(labelText => (
                                          <Chip className={classes.metaTag} label={labelText} />
                                      ))
                                    : null}
                                {benefitList
                                    ? benefitList.map(labelText => (
                                          <Chip className={classes.metaTag} label={labelText} />
                                      ))
                                    : null}
                            </div>
                        </div>
                    ) : null}
                </div>
            );
        } else {
            return (
                <div
                    className={`job-element-card ${
                        fulfillmentDesignEnable && 'job-element-card-fulfillment-design'
                    } ${AbTestHelper.serpRevamp() && 'job-element-cardv borderSpecific'} `}
                    style={styles}>
                    <div
                        className={classNames('job-content-wrapper', {
                            'job-content-wrapperv': AbTestHelper.serpRevamp(),
                        })}>
                        {showLogo && (
                            <div
                                className={`job-logo-wrapper ${
                                    fulfillmentDesignEnable && 'job-logo-wrapper-fulfillment-design'
                                }`}>
                                <Avatar
                                    src={logo}
                                    variant={fulfillmentDesignEnable ? 'rounded' : 'circle'}
                                />
                            </div>
                        )}
                        <div
                            className={classNames('job-description-wrapper', {
                                'job-description-wrapper-fulfillment-design': fulfillmentDesignEnable,
                                'job-despv': AbTestHelper.serpRevamp(),
                                'job-desp-v1': AbTestHelper.serpRevampV1(),
                            })}>
                            <Typography
                                className={classNames(classes.jobTitle, {
                                    [classes.jobTitlev1]: AbTestHelper.serpRevampV1(),
                                })}
                                variant="h1">
                                {title}
                            </Typography>
                            {AbTestHelper.serpRevamp() ? (
                                staticLocationMncJobs(company, location)
                            ) : (
                                <Typography className={classes.jobLocation} variant="h2">
                                    {location}
                                </Typography>
                            )}
                            {AbTestHelper.serpRevampV1() ? (
                                <Link className="view-salary-btn" href={URL} target="_blank">
                                    <span>View Salary</span>
                                </Link>
                            ) : (
                                <Link
                                    className={classNames('classes.jobDescription', {
                                        'classes.fulfillmentDesignJobDescription': fulfillmentDesignEnable,
                                        view_text: AbTestHelper.serpRevampV2(),
                                    })}
                                    href={applyUrl}
                                    target="_blank"
                                    underline="always">
                                    {AbTestHelper.serpRevampV2()
                                        ? 'View Description & Salary Info'
                                        : fulfillmentDesignEnable
                                        ? 'View Salary & Description'
                                        : 'View Description'}
                                </Link>
                            )}
                        </div>
                    </div>
                    <div
                        className={`job-apply-wrapper ${
                            fulfillmentDesignEnable && 'job-apply-wrapper-fulfillment-design'
                        } ${AbTestHelper.serpRevamp() && 'job-apply-wrappervab'}`}>
                        {!fulfillmentDesignEnable && (
                            <Button
                                className={`${AbTestHelper.serpRevamp() && 'view_sal_btn'} ${
                                    AbTestHelper.BrandedLanderListingsTest() &&
                                    classes.salaryButtonBranded
                                }`}
                                href={applyUrl}
                                target="_blank">
                                {FeatureValueHelper.getListingCTAText()
                                    ? FeatureValueHelper.getListingCTAText()
                                    : 'View Salary'}
                            </Button>
                        )}
                        {AbTestHelper.serpRevampV1() && (
                            <div className="link-btn-wrapper">
                                <Link
                                    className={`view-salary-btn-desk ${
                                        AbTestHelper.BrandedLanderListingsTest() &&
                                        classes.salaryButtonBranded
                                    }`}
                                    href={URL}
                                    target="_blank">
                                    <span>View Salary</span>
                                </Link>
                            </div>
                        )}
                        {AbTestHelper.serpRevampV2() && ctaArrowBtn()}
                        {fulfillmentDesignEnable && (
                            <Button
                                className="job-apply-button-fulfillment-design"
                                color="primary"
                                href={applyUrl}
                                target="_blank">
                                <picture>
                                    <source
                                        srcSet={RouterPrefixHandler.getImagePath(
                                            'images/left-arrow.webp',
                                        )}
                                        type="image/webp"
                                    />
                                    <img
                                        alt={`icon`}
                                        src={RouterPrefixHandler.getImagePath(
                                            'images/left-arrow.png',
                                        )}
                                    />
                                </picture>
                            </Button>
                        )}
                        {AbTestHelper.serpRevamp()
                            ? renderHighlightABtest(index, showHighlight)
                            : renderHighlight(index, showHighlight)}
                    </div>
                </div>
            );
        }
    };
    const [firstClick, setAdClick] = React.useState(false);

    const listings = jobsList.map((jobsData, index) => {
        let {
            location = '',
            url: applyUrl = '',
            title = '',
            logo = '',
            bid = undefined,
            company = '',
            description = '',
            experiment = '',
        } = jobsData;

        const workTypeList = jobsData.worktype_details ? jobsData.worktype_details : undefined;
        const salaryList = jobsData.salary_details ? jobsData.salary_details : undefined;
        const benefitList = jobsData.benefit_details ? jobsData.benefit_details : undefined;

        if (logo === 'https://cdn.upward.net/company_logos/company_icon.png') {
            logo = RouterPrefixHandler.getImagePath(
                `${images[Math.floor(Math.random() * 4)].link}`,
            );
        }

        let styles = {};

        if (showHighlight && index < 2) {
            styles =
                index === 0 ? { border: 'solid 1px #f9001a' } : { border: 'solid 1px #244767' };
        }

        if (AbTestHelper.serpRevampV1()) {
            if (showHighlight && index < 2) {
                styles =
                    index === 0 ? { border: 'solid 1px #005897' } : { border: 'solid 1px #244767' };
            }
        } else if (AbTestHelper.serpRevampV2()) {
            if (showHighlight && index < 2) {
                styles =
                    index === 0 || index === 1
                        ? { border: 'solid 1px #d8d8d8' }
                        : { border: 'solid 1px #244767' };
            }
        }

        if (AbTestHelper.metaTagTest()) {
            styles = { flexDirection: 'column' };

            if (showHighlight && index < 2) {
                styles =
                    index === 0
                        ? { border: 'solid 1px #f9001a', flexDirection: 'column' }
                        : { border: 'solid 1px #27b100', flexDirection: 'column' };
            }
        }

        let shortDesc = '';

        if (description && !Utils.isNull(description)) {
            shortDesc = description.substring(0, 200);
        }

        return (
            <Link
                className={'link-container'}
                href={applyUrl}
                key={index}
                onClick={e => {
                    console.log('[JOBS] sending grat avail', jobsData, applyUrl);

                    if (props.mergedJobFeed) {
                        const { advid, advnm, cpa, cpc, max_filters, encodedSource } = jobsData;
                        CommonHelper.updateEncodedSource(encodedSource);
                        eventManager.sendEvent(
                            Constants.EVENT.AD_CLICK,
                            {
                                adt: title,
                                adst: company,
                                adst2: shortDesc,
                                advid,
                                advnm,
                                spotno: `${index}`,
                                ervn: bid,
                                job_redirect_url: applyUrl,
                                rv_type: jobsData.revType,
                                cpa,
                                cpc,
                                abt_exp: experiment,
                                ext5: experiment,
                                max_filters,

                                phone_number: phone,
                            },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                        eventManager.sendEventWhenVisible(
                            Constants.EVENT.AD_PROG,
                            {
                                adt: title,
                                adst: company,
                                adst2: shortDesc,
                                advid,
                                advnm,
                                spotno: `${index}`,
                                ervn: bid,
                                job_redirect_url: applyUrl,
                                rv_type: jobsData.revType,
                                cpa,
                                cpc,
                                abt_exp: experiment,
                                ext5: experiment,
                                max_filters,

                                phone_number: phone,
                            },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                        eventManager.sendEvent(
                            Constants.EVENT.GRATIFICATION_AVAIL,
                            {
                                adt: title,
                                adst: company,
                                adst2: shortDesc,
                                advid,
                                advnm,
                                spotno: `${index}`,
                                ervn: bid,
                                job_redirect_url: applyUrl,
                                rv_type: jobsData.revType,
                                cpa,
                                cpc,
                                abt_exp: experiment,
                                ext5: experiment,
                                max_filters,

                                phone_number: phone,
                            },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                        eventManager.sendEventWhenVisible(
                            Constants.EVENT.GRAT_PROG,
                            {
                                adt: title,
                                adst: company,
                                adst2: shortDesc,
                                advid,
                                advnm,
                                spotno: `${index}`,
                                ervn: bid,
                                job_redirect_url: applyUrl,
                                rv_type: jobsData.revType,
                                cpa,
                                cpc,
                                abt_exp: experiment,
                                ext5: experiment,
                                max_filters,

                                phone_number: phone,
                            },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                        RecaptchaHelper.genRecaptcha();
                    } else {
                        const {
                            advid,
                            cat,
                            cpa,
                            cpc,
                            max_filters,
                            encodedSource,
                            in_uuid,
                        } = jobsData;
                        CommonHelper.updateEncodedSource(encodedSource);
                        const adUnitMap = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU);
                        const adSourceMeta =
                            memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA) || [];
                        const auMeta = adSourceMeta.find(i => i.uuid === in_uuid);

                        const eventData = {
                            adt: title,
                            adst: company,
                            adst2: shortDesc,
                            advid: advid,
                            cat,
                            advnm: Utils.getObjVal(adUnitMap, [in_uuid, 'advertiserName']),
                            cmpnm: Utils.getObjVal(adUnitMap, [in_uuid, 'campaignName']),
                            cmpid: Utils.getObjVal(adUnitMap, [in_uuid, 'campaignId']),
                            rv_type: Utils.getObjVal(adUnitMap, [in_uuid, 'revType']),
                            spotno: `${index}`,
                            uuid: in_uuid,
                            ervn: bid,
                            job_redirect_url: applyUrl,
                            cpa,
                            cpc,
                            max_filters,
                            abt_exp: experiment,
                            ext5: experiment,
                            phone_number: phone,
                            ad_unit_id: auMeta && auMeta.adUnitId,
                            max_deal_id: Utils.getObjVal(adUnitMap, [in_uuid, 'dealId']),
                        };
                        eventManager.sendEvent(
                            Constants.EVENT.GRATIFICATION_AVAIL,
                            eventData,
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                        eventManager.sendEventWhenVisible(
                            Constants.EVENT.GRAT_PROG,
                            eventData,
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                        RecaptchaHelper.genRecaptcha();
                    }

                    const { advid, cat, cpa, cpc, max_filters, encodedSource, in_uuid } = jobsData;
                    CommonHelper.updateEncodedSource(encodedSource);
                    const adUnitMap = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU);
                    const adSourceMeta =
                        memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA) || [];
                    const auMeta = adSourceMeta.find(i => i.uuid === in_uuid);

                    if (bid) {
                        eventManager.sendEvent(
                            Constants.EVENT.RVN_EVENT_NAME,
                            {
                                ervn: bid,
                                advid: advid,
                                advnm: Utils.getObjVal(adUnitMap, [in_uuid, 'advertiserName']),
                                uuid: in_uuid,
                                phone_number: phone,
                                spotno: `${index}`,
                                cpa,
                                cpc,
                                abt_exp: experiment,
                                ext5: experiment,
                                ad_unit_id: auMeta && auMeta.adUnitId,
                                max_deal_id: Utils.getObjVal(adUnitMap, [in_uuid, 'dealId']),
                                max_filters,
                            },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                    }

                    if (Utils.getObjVal(adUnitMap, [in_uuid, 'customAdInfo', 'triggerErvn'])) {
                        eventManager.sendEvent(
                            Constants.EVENT.REVENUE,
                            {
                                ervn: bid || 0,
                                cat,
                                advid: Utils.getObjVal(adUnitMap, [in_uuid, 'advId']),
                                advnm: Utils.getObjVal(adUnitMap, [in_uuid, 'advertiserName']),
                                cmpnm: Utils.getObjVal(adUnitMap, [in_uuid, 'campaignName']),
                                cmpid: Utils.getObjVal(adUnitMap, [in_uuid, 'campaignId']),
                                spotno: `${index}`,
                                phone_number: phone,
                                uuid: in_uuid,
                                cpa,
                                cpc,
                                abt_exp: experiment,
                                ext5: experiment,
                                ad_unit_id: auMeta && auMeta.adUnitId,
                                max_deal_id: Utils.getObjVal(adUnitMap, [advid, 'dealId']),
                                max_filters,
                            },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                    }

                    if (showFbGoogleInterstitial && !firstClick) {
                        e.preventDefault();
                        handleFbGoogleInterstitialClick(applyUrl);
                        setAdClick(true);
                    } else {
                        handleClick(e.currentTarget);
                        updateAdvertiser();
                    }
                    console.log(e.target.href);
                }}
                style={{ cursor: 'pointer' }}
                target={'_blank'}>
                {getLinkBody({
                    logo,
                    title,
                    location,
                    applyUrl,
                    styles,
                    shortDesc,
                    index,
                    showHighlight,
                    company,
                    workTypeList,
                    salaryList,
                    benefitList,
                })}
            </Link>
        );
    });

    return Utils.mergePriorArray(nativeAdListings, Utils.mergePriorArray(staticListings, listings));
};

const JobListingsV1 = props => {
    return <div className="jobs-list-wrapper">{displayJobsList(props)}</div>;
};

const useStyles = makeStyles(theme => ({
    inputContainer: {
        display: 'flex',
        width: '100%',
    },
    newSERPRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        borderTop: 'none !important',
        borderRight: 'none !important',
        borderLeft: 'none !important',
        marginTop: '25px',
        paddingBottom: AbTestHelper.darkSERPTestV2() ? '10px' : '25px',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: '10px',
            marginTop: '20px',
        },
    },
    mainContainer: {
        borderBottom: AbTestHelper.darkSERPTest()
            ? '1px solid #22282C !important'
            : '1px solid #D9E0E7 !important',
        width: '100%',
    },
    viewDescContainer: {
        display: 'flex',
        fontFamily: "'Roboto'",
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '12px',
        lineHeight: '14px',
        letterSpacing: '0.4px',
        textDecorationLine: 'underline',
        textTransform: 'capitalize',
        color: '#FFFFFF !important',
        opacity: 0.7,
        justifyContent: 'center',
        marginBottom: '20px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '59px',
            justifyContent: 'flex-start',
        },
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 70%',
        minHeight: '80px',
    },
    jobInfoContainer: {
        paddingRight: '40px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '0px',
        },
    },
    newBtnContainer: {
        padding: '0',
        margin: 'auto',
        flex: '1 1 20%',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '13px',
        },
    },
    locationIcon: {
        width: '24px',
        height: '17px',
        marginRight: '5px',
    },
    companyIcon: {
        width: '14px',
        height: '14px',
        marginRight: '5px',
        position: 'relative',
        top: '2px',
    },
    jobTitle: {
        lineHeight: 1,
        letterSpacing: 0,
    },
    jobTitleV2: {
        fontFamily: "'Roboto'",
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px !important',
        lineHeight: '18px',
        letterSpacing: '0.2px',
        color: AbTestHelper.darkSERPTest() ? '#8E8E8E !important' : '#989898 !important',
        marginBottom: '8px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '16px !important',
            fontWeight: 600,
        },
    },
    jobTitlev1: {
        textDecoration: 'underline !important',
        color: '#0065db !important',
        fontSize: '14px !important',
        fontWeight: '600 !important',
        lineHeight: '1.5 !important',
    },
    jobDescription: {
        color: '#1d5e97',
    },
    fulfillmentDesignJobDescription: {
        color: '#53ae02 !important',
    },
    serpBtn: {
        boxSizing: 'border-box',
        background: 'linear-gradient(180deg, #1EA800 0%, #1D9D00 100%)',
        border: '0.778481px solid #458E00',
        borderRadius: '60.7215px',
        marginBottom: '10px',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '16px',
        color: '#FFFFFF !important',
        textTransform: 'none',
        padding: '16px 20px',
        minWidth: '316px !important',
        [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
            padding: '13px 20px',
            minWidth: '300px !important',
        },
    },
    jobLocation: {
        margin: '2px 0px',
    },
    jobLocationNewDesign: {
        margin: '2px 0px',
        color: '#8e8e8e',
    },
    textField: {
        boxShadow: 'none',
        position: 'relative',
        border: '1px solid #88a1ad',
        fontSize: 18,
        padding: '10px 15px',
        color: '#a8a8a8',
        height: 48,
        margin: '5px 0 15px',
        width: '82%',
        borderRadius: '2px',
        boxSizing: 'border-box',
        backgroundColor: '#fff',
    },
    searchBox: {
        width: '175%',
        marginRight: 8,
    },
    v2Button: {
        height: 'auto',
        fontSize: 24,
        overflow: 'hidden',
        backgroundColor: '#0942c2',
        backgroundImage: 'linear-gradient(to bottom, #0942c2, #0942c2)',
        whiteSpace: 'break-word',
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            lineHight: '20px',
            height: 'auto',
        },
    },
    button: {
        height: 'auto',
        fontSize: 24,
        overflow: 'hidden',
        whiteSpace: 'break-word',
        border: 'solid 1px #458e00',
        backgroundImage: 'linear-gradient(to bottom, #55ae00, #4d9e00)',
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            lineHight: '20px',
            height: 'auto',
        },
        '&:hover': {
            borderColor: '#10b910',
            backgroundColor: '#5cb910',
        },
        '&:hover $btnIcon': {
            transform: 'translateX(4px)',
        },
    },
    cardDetailContainer: {
        display: 'flex',
    },
    btnIcon: {
        width: 20,
        height: 20,
        marginTop: '10px',
    },
    arrowIcon: {
        height: 25,
        width: 25,
        backgroundColor: '#5CB910',
        color: '#fff',
        borderRadius: '50%',
        margin: 'auto 20px',
        padding: 2,
    },
    underLine: {
        width: 'auto',
        height: 1,
        margin: '0px 10px 10px',
        borderBottom: 'solid 0.5px #d9d9d9',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 10px 10px',
    },
    newJobDescription: {
        color: '#007aff',
        textDecoration: 'none',
        fontSize: 14,
        fontWeight: 500,
    },
    boxShadowEffect: {
        boxShadow: '0 0 0 0 rgb(0 122 255)',
        transform: 'scale(1)',
        animation: 'pulse 2s infinite',
    },
    greyBoxHeader: {
        borderRadius: '4px 0px 0px',
        backgroundColor: '#e8e8e8',
        padding: '6px 8px',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.36,
        color: '#262831',
        marginBottom: 12,
    },
    testJobTitle: {
        color: '#989898 !important',
    },
    jobBadge: {
        marginRight: '16px',
    },
    locationDiv: {
        display: 'flex',
        flexDirection: 'row',
    },
    location: {
        color: AbTestHelper.darkSERPTest() ? '#989898 !important' : '#B2B4C3 !important',
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '5px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '10px',
        },
    },
    company: {
        fontSize: '12px',
        color: AbTestHelper.darkSERPTest() ? '#989898 !important' : '#B2B4C3 !important',
        alignItems: 'center',
        width: '110px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [theme.breakpoints.up('sm')]: {
            fontSize: '10px',
        },
    },
    descContainer: {
        display: 'flex',
    },
    shortDesc: {
        marginTop: '12px',
        letterSpacing: '-0.1px',
        marginBottom: '20px',
        fontFamily: "'Roboto'",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        color: AbTestHelper.darkSERPTest() ? '#858585' : '#A3A3A3',
        width: 'auto',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'initial',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '10px',
            WebkitLineClamp: '1',
            width: '500px',
        },
        [theme.breakpoints.up('md')]: {
            width: '700px',
        },
    },
    shortDescV1: {
        letterSpacing: '-0.1px',
        marginTop: '12px',
        marginBottom: '20px',
        fontFamily: "'Roboto'",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        color: AbTestHelper.darkSERPTest() ? '#858585' : '#A3A3A3',
        width: 'auto',
        /*
         * paddingRight: '35px',
         * [theme.breakpoints.up('sm')]: {
         *     padding: 0,
         * },
         */
    },
    learnMore: {
        fontFamily: "'Roboto'",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        color: AbTestHelper.darkSERPTest() ? '#858585' : '#A3A3A3',
        padding: 0,
        textTransform: 'none',
        textDecoration: 'underline',
    },
    indexingDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '34px',
        height: '34px',
        background: AbTestHelper.darkSERPTest() ? '#272628' : '#E6E6E6',
        borderRadius: '4px',
        fontFamily: "'Roboto'",
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '21px',
        textAlign: 'center',
        letterSpacing: '1.5px',
        color: '#8C8C8C',
        [theme.breakpoints.up('sm')]: {
            width: '44px',
            height: '44px',
        },
    },
    '@global': {
        '.MuiInputBase-root.Mui-disabled': {
            '&:before': {
                borderBottomStyle: 'none',
            },
        },
        '.MuiInputBase-input.Mui-disabled': {
            opacity: 0.4,
        },
        '.MuiButton-endIcon': {
            margin: 0,
        },
        '.MuiButton-root': {
            minWidth: '50px',
        },
        '.MuiAvatar-root.MuiAvatar-circle': {
            width: '48px',
            height: '48px',
        },
        '.MuiLink-underlineHover': {
            '&:hover': {
                textDecoration: 'none',
            },
        },
        '.MuiAvatar-img': {
            objectFit: 'contain',
        },
    },
    metaTagContainer: {
        display: 'flex !important',
        flexDirection: 'column !important',
    },
    metaTag: {
        height: '22px',
        maxWidth: '200px',
        marginRight: '4px',
        marginBottom: '4px',
        borderRadius: AbTestHelper.metaTagTestv1() ? '4px' : '44px',
        background: AbTestHelper.metaTagTestv1() ? '#f2f3f5' : '#DAE3F3',
        fontWeight: '500',
        fontSize: '10px',
        letterSpacing: '0.4px',
        textTransform: 'uppercase',
        cursor: 'pointer',
        color: AbTestHelper.metaTagTestv1() ? '#235375' : '#000000',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '350px',
        },
    },
    chipContainer: {
        display: 'flex !important',
        width: '100%',
        flexWrap: 'wrap',
    },
    metaTagWrapper: { display: 'flex' },
    jobTagWrapper: {
        paddingLeft: '58px',
        marginBottom: '10px',
    },
    salaryButtonBranded: {
        color: !Utils.isEmptyStr(brandedFlowValues().listingsBtnColor)
            ? `${brandedFlowValues().listingsBtnColor} !important`
            : '#007ECA',
    },
}));

export default JobListingsV1;
