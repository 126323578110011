import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    iframeWrapper: {
        width: '100%',
        maxWidth: '1140px',
        height: '130px',
        marginBottom: '5px',
    },
    loadingWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100px',
    },
}));
