import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: '20px !important',
    },

    // V1 CSS Starts
    wrapperV1: {
        padding: '10px',
        border: '1px solid #d8e0e7',
        borderRadius: '4px',
        display: 'block',
        boxSizing: 'border-box',
        backgroundColor: '#ffffff',
        marginBottom: '12px',
        '&:last-child:not(:only-child)': {
            marginBottom: '0',
        },
    },

    titleV1: {
        fontSize: '16px',
        fontWeight: '500',
        color: '#6070ff',
        lineHeight: '1.14',
        paddingBottom: '5px',
    },

    companyV1: {
        fontSize: '14px',
        color: '#294964',
        fontWeight: '500',
        paddingBottom: '5px',
    },

    descriptionV1: {
        fontSize: '12px',
        color: '#41434d',
    },

    // V2 CSS Starts
    wrapperV2: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #f2f2f2',
        padding: '15px 0px',
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    contentWrapperV2: {
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        border: '2px solid #f2f2f2',
        padding: '0px 20px',
        boxSizing: 'border-box',
    },
    titleV2: {
        fontSize: '16px',
        fontWeight: '600',
        color: '#294964',
    },
    ctaButtonV2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: '#ff5b01',
        height: '30px',
        width: '30px',
        padding: '5px',
        fontSize: '20px',
        color: '#ffffff',
        minWidth: 'unset',
        '& svg': {
            fontSize: '20px',
        },
        '&:hover': {
            backgroundColor: '#ff5b01',
        },
        [theme.breakpoints.up('sm')]: {
            height: '30px',
            width: '30px',
        },
    },
}));
