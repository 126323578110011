import * as moment from 'moment';

export default class DateUtils {
    /**
     * @param {string, string} dob
     * @returns {string|null}
     */
    static isAboveTeenage(dob) {
        if (moment().diff(dob, 'years') < 18) {
            return 'Age cannot be less than 18';
        }

        return null;
    }

    static isValidDateStringForFormat(dateStr, format) {
        return moment(dateStr, format, true).isValid();
    }

    static convertDateBetweenFormats(dateStr, fromFormat, toFormat) {
        let m = moment(dateStr, fromFormat, true);

        if (false === m.isValid()) {
            return dateStr;
        } else {
            return m.format(toFormat);
        }
    }
}
