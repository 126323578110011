import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import { getScope } from 'common/scopeUtils';
import { ConfigProvider } from '../index';

/**
 * Feature provider methods to get, set values in client application
 */
export default class Features {
    constructor(data) {
        this.Id = null;
        this.Name = null;
        this.initialize(data);
    }

    /**
     * Initialize Features in the application, leverage on caching
     */
    async initialize(data) {
        if (Utils.isNull(data)) {
            await ConfigProvider.initialize();
            data = ConfigProvider.data;
        }

        return Utils.isNull(data.lander) ? false : this.setLander(data.lander);
    }

    /**
     * Set Value for Landers Features on client side
     * @param {*} key UID Feature key string
     * @param {*} value Value for feature
     * @param {*} type Value Type
     * @param {*} scope Scope for feature
     */
    setLanderFeature(key, value, type, scope) {
        if (Utils.isStringNullOrWhiteSpace(key)) return false;

        if (Utils.isStringNullOrWhiteSpace(`${scope}`)) return false;

        IdStore.storeIdForKey(
            `lander_feature_${key}_${scope}`,
            JSON.stringify({ type: type, value: value }),
        );

        return true;
    }

    /**
     *
     * @param {*} features Array of feature objects containing Key, Value, Value Type, Feature Scope.
     */
    setFeatures(features) {
        if (Utils.isNull(features)) return false;

        features.forEach(feature => {
            this.setLanderFeature(
                feature.key,
                feature.value,
                feature.type,
                getScope(feature.scope),
            );
        });

        return true;
    }

    /**
     * Returns Feature object, which contains value and its type
     * @param {*} key Feature UID string
     * @param {*} scope Feature Scope, either Global or current Section of the Application
     */
    getLanderFeature(key, scope) {
        // parameter validation
        if (Utils.isStringNullOrWhiteSpace(key)) return null;

        try {
            return JSON.parse(IdStore.fetchIdForKey(`lander_feature_${key}_${getScope(scope)}`));
        } catch (exception) {
            console.log(`PARSING FAILED: lander_feature_${key}_${scope} - ${exception}`);
        }
    }

    /**
     * Returns Lander object, which contains id and its tynamepe
     */
    getLander() {
        try {
            return JSON.parse(IdStore.fetchIdForKey('lander'));
        } catch (exception) {
            console.log(`PARSING FAILED: lander - ${exception}`);

            return null;
        }
    }

    /**
     * Set Lander configuration
     * @param {*} data Lander Data
     */
    setLander(data) {
        if (Utils.isNull(data)) return false;

        // set object
        this.Id = data.id;
        this.Name = data.name;
        this.setFeatures(data.featureMap);
        this.getFeature = this.getLanderFeature;

        // cache in memory
        IdStore.storeIdForKey(`lander`, JSON.stringify({ id: this.Id, name: this.Name }));

        return true;
    }
}
