import 'react-app-polyfill/stable';
import { ErrBoundaryComponent, ErrLogger } from 'errLogger';
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorPage } from 'components/error';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import Analytics from './analytics';
import Utils from 'common/utils';
import '../src/styles/index.css';
import { IdStore } from 'dataStore/index';
import Constants from 'common/constants';
import { Container } from 'typedi';
import Filter from 'bad-words';
import abManager from 'abManager/abTest';
import { ConfigProvider } from 'providers';
import { SessionHelper, StringHelper, AbTestHelper, FeatureValueHelper } from 'helpers';
import { Processors } from 'helpers/index';
import UserDataSource from 'datasource/userDetailsDataSource';
import memoryStore from 'dataStore/memoryStore';
import memoryStoreKeys from 'common/memoryStoreKeys';
import { setUserId, trackUserStatus } from 'helpers/session/sessionHelpers';
import crossFrameComUtils from 'common/crossFrameComUtils';
import userDataStore from 'components/landing/userDataStore';
import * as moment from 'moment';
import eventManager from 'eventManager/eventManager';
import DateUtils from 'common/DateUtils';

const ReactPlaceholder = 'react-survey-app';

function generateCsId() {
    return `${Utils.generateUUID()}`.replace(/-/g, '');
}

const setEmailInfo = async () => {
    const email = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EMAIL);
    const encryptedEmail = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.ENCRYPTED_EMAIL);

    if (!Utils.isEmptyStr(email)) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.EMAIL_FED, email);
    } else if (encryptedEmail) {
        const source = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE);
        const intialVector =
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.INITIAL_VECTOR) || '';

        await UserDataSource.decryptPublisher(encryptedEmail, source, intialVector)
            .then(decryptedEmail => {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.EMAIL_FED, decryptedEmail);
            })
            .catch(err => console.error(`ERR in email decryption: ${err}`));
    }
};

async function initCrossFrameUserData() {
    const getTimeStamp = await crossFrameComUtils.pingIframe('ping', {}, 'ping_response');
    console.log(
        '[IFrameCom] Ping response',
        getTimeStamp && getTimeStamp[0] && getTimeStamp[0].payload,
    );
    const response = await crossFrameComUtils.sendMessageAwaitResponse(
        'request_cookie',
        {},
        'dispatch_cookie',
    );
    const cookieString = (response && response[0] && response[0].payload.cookie) || '';

    const userObjString = Utils.getCookieFromString(
        userDataStore.getStoreKeyForUser(),
        cookieString,
    );

    const cVc = Utils.safeParseInt(
        Utils.getCookieFromString(
            Utils.getFlavoredKey(Constants.ID_STORE_KEYS.VISIT_COUNT),
            cookieString,
            true,
        ),
        null,
    );
    const rVc = Utils.safeParseInt(
        Utils.getCookieFromString(
            Utils.getFlavoredKey(Constants.ID_STORE_KEYS.VISIT_COUNT),
            cookieString,
            true,
        ),
        null,
    );
    const fvTs = Utils.getCookieFromString(
        Utils.getFlavoredKey(Constants.ID_STORE_KEYS.FIRST_VISIT),
        cookieString,
        true,
    );
    const userId = Utils.getCookieFromString(
        Utils.getFlavoredKey(Constants.ID_STORE_KEYS.USER_ID),
        cookieString,
        true,
    );

    const userStatus = Utils.getCookieFromString(
        Utils.getFlavoredKey(Constants.ID_STORE_KEYS.USER_STATUS),
        cookieString,
        true,
    );

    const currentUserStatus = Utils.getCookieFromString(
        Utils.getFlavoredKey(Constants.ID_STORE_KEYS.USER_STATUS_CURRENT),
        cookieString,
        true,
    );

    if (fvTs) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.FIRST_VISIT, fvTs);
    }

    if (userId && userId !== 'null' && userId !== 'undefined') {
        setUserId(userId);
    }

    if (userStatus) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.USER_STATUS, userStatus);
    }

    if (currentUserStatus) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.USER_STATUS_CURRENT, currentUserStatus);
    }

    if (cVc || cVc === 0) IdStore.storeIdForKey(Constants.ID_STORE_KEYS.VISIT_COUNT, cVc);

    if (rVc || rVc === 0)
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT, rVc);

    const userKey = userDataStore.getStoreKeyForUser();
    const currentUserDetails = Utils.jsonSafeParse(IdStore.fetchIdForKey(userKey)) || {};
    const cookieUserDetails = Utils.jsonSafeParse(userObjString) || {};
    const newUserDetails = { ...currentUserDetails, ...cookieUserDetails };
    const strUserDetails = Utils.jsonSafeStringify(newUserDetails);
    strUserDetails &&
        strUserDetails.length &&
        IdStore.storeIdForKey(userDataStore.getStoreKeyForUser(), strUserDetails);

    const userRegStatusKey = userDataStore.getStoreKeyForRegisteredUser();
    const userRegStatusCrossFrame = Utils.getCookieFromString(userRegStatusKey, cookieString, true);

    // Set User Data
    if (userRegStatusCrossFrame === true || userRegStatusCrossFrame === 'true') {
        userDataStore.updateRegistrationStatus(true);
    }
}

async function initialiseApp() {
    // Initializing logger
    ErrLogger.initialise();
    Analytics.initialise();
    Processors.preAppInitProcessor();
    crossFrameComUtils.init(document.getElementById('il-frame'));

    await initCrossFrameUserData();

    const path = window.location.pathname;

    IdStore.removeIdForKey(Constants.ID_STORE_KEYS.ASA_PIXEL);
    IdStore.removeIdForKey(Constants.ID_STORE_KEYS.BCK_ENCODED_SOURCE);
    let restrictIdStoreEvals = window.location.search.includes('s2nt');
    const isSkippedToNewTab = window.location.search.includes('s2nt');
    const allowEmailSet = path && path.includes('/listings');

    // Disabling logs throughout the app if not in development
    if (false === Utils.isTestEnv()) {
        console.log = function () {};
        console.debug = function () {};
    }

    let surveyId = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.SURVEY_ID);

    if (surveyId) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.SURVEY_ID, surveyId);
    } else {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.SURVEY_ID, '47');
    }

    // Store the UTM entries if available
    let getNonEmptyVal = (val, defaultVal) => {
        if (Utils.isEmptyStr(val)) {
            return defaultVal;
        }

        return val;
    };
    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.PRODUCT, 'MJC');

    // Remove pubSourceTypeConfig from session store if utm_source or utm_campaign is changed
    const utmSourceFromUrl = getNonEmptyVal(
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_SOURCE_G),
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_SOURCE),
        Constants.UTM_DEFAULT.SOURCE,
    );

    const utmCampaignFromUrl = getNonEmptyVal(
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_CAMPAIGN),
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_CAMPAIGN_G),
        Constants.UTM_DEFAULT.MISC,
    );

    if (
        !Utils.isNull(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUB_SOURCE_TYPE_CONFIG)) &&
        ((!Utils.isNull(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE)) &&
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE) !== utmSourceFromUrl) ||
            (!Utils.isNull(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_CAMPAIGN)) &&
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_CAMPAIGN) !== utmCampaignFromUrl))
    ) {
        IdStore.removeIdForKey(Constants.ID_STORE_KEYS.PUB_SOURCE_TYPE_CONFIG);
    }

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.UTM_SOURCE,
        getNonEmptyVal(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_SOURCE_G),
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_SOURCE),
            Constants.UTM_DEFAULT.SOURCE,
        ),
    );

    if (
        !Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PUBLISHER_SUBID) &&
        !Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PUBLISHER_SUB_SOURCE) &&
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_SOURCE)
    ) {
        IdStore.storeIdForKey(
            Constants.ID_STORE_KEYS.PUBLISHER_SUBID,
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_SOURCE),
        );
    } else {
        if (
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PUBLISHER_SUBID) &&
            !Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PUBLISHER_SUB_SOURCE) &&
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_SOURCE)
        ) {
            IdStore.storeIdForKey(
                Constants.ID_STORE_KEYS.PUBLISHER_SUB_SOURCE,
                Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_SOURCE),
            );
        }

        if (
            !Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PUBLISHER_SUBID) &&
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PUBLISHER_SUB_SOURCE) &&
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_SOURCE)
        ) {
            IdStore.storeIdForKey(
                Constants.ID_STORE_KEYS.PUBLISHER_SUBID,
                Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_SOURCE),
            );
        }
    }

    let hourlyPay = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.HOURLY_PAY) || '';

    try {
        hourlyPay = window.atob(hourlyPay);
    } catch (e) {
        hourlyPay = '';
        console.log(`[debugging]: ${e} error decoding hourly pay `);
    }

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.PCT1,
        Utils.getValForKeyFromCurrentUrl(Constants.ID_STORE_KEYS.PCT1),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.PCT2,
        Utils.getValForKeyFromCurrentUrl(Constants.ID_STORE_KEYS.PCT2),
    );

    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.HOURLY_PAY, hourlyPay);

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.EXT1,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EXT1),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.EXT2,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EXT2),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.EXT3,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EXT3),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.EXT4,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EXT4),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.EXT5,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EXT5),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.UTM_CAMPAIGN,
        getNonEmptyVal(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_CAMPAIGN),
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_CAMPAIGN_G),
            Constants.UTM_DEFAULT.MISC,
        ),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.UTM_MEDIUM,
        getNonEmptyVal(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_MEDIUM),
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_MEDIUM_G),
            Constants.UTM_DEFAULT.MISC,
        ),
    );
    const ljt = getNonEmptyVal(Utils.getValForKeyFromCurrentUrl('jt'), '');
    const rawKeyword = getNonEmptyVal(
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_TERM),
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_TERM_G),
        '',
    );
    let keyword = Utils.filterInvalidKw(rawKeyword);

    // if invalid kw and ljt present then update kw to job_type
    if (
        keyword &&
        keyword.toLowerCase() === Constants.DEFAULT_KW.toLowerCase() &&
        !Utils.isEmptyStr(ljt)
    ) {
        keyword = ljt;
    }

    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.LANDING_JT, StringHelper.cleanTerm(ljt));
    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.LANDING_KEYWORD,
        StringHelper.cleanTerm(rawKeyword),
    );

    allowEmailSet && (await setEmailInfo());

    let { jobType } = Utils.getJobType();
    const brandFromKeyword = Utils.brandFromKeyword(keyword);

    const restrictJTSet =
        brandFromKeyword === jobType && jobType !== ljt && !path.includes('/landing');
    const channel = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM);

    if (brandFromKeyword && path && path.includes('/landing')) {
        if (
            ljt &&
            Utils.isString(ljt) &&
            Utils.isJobTypeAllowed(ljt) &&
            Utils.isEmptyStr(keyword) &&
            !('dxml_static' === channel || AbTestHelper.isDXMLMedium())
        ) {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UTM_TERM, ljt);
        } else {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JT_KW_MISMATCH, '1');
        }
    }

    if (!restrictIdStoreEvals) {
        if (keyword && !keyword.startsWith('{')) {
            IdStore.storeIdForKey(
                Constants.ID_STORE_KEYS.UTM_TERM,
                Utils.cleanKwDisplay(keyword ? keyword.split('+').join(' ') : ''),
            );
        }

        if (
            (Utils.isNull(jobType) || Utils.isEmptyStr(jobType)) &&
            (Utils.isNull(keyword) || Utils.isEmptyStr(keyword))
        ) {
            IdStore.storeIdForKey(
                Constants.ID_STORE_KEYS.UTM_TERM,
                Utils.cleanKwDisplay(Constants.DEFAULT_KW),
            );
        }

        if (
            !Utils.isNull(jobType) &&
            !Utils.isEmptyStr(jobType) &&
            (Utils.isNull(keyword) || Utils.isEmptyStr(keyword))
        ) {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UTM_TERM, jobType);
        }

        if (
            Utils.isEmptyStr(jobType) ||
            Utils.isNull(jobType) ||
            Utils.isInvalidKw(jobType) ||
            restrictJTSet
        ) {
            if (!Utils.isNull(keyword) && !Utils.isEmptyStr(keyword)) {
                IdStore.storeIdForKey(
                    Constants.ID_STORE_KEYS.JOB_TYPE_VAL,
                    Utils.cleanKwDisplay(keyword),
                );
            } else {
                IdStore.storeIdForKey(
                    Constants.ID_STORE_KEYS.JOB_TYPE_VAL,
                    Utils.cleanKwDisplay(Constants.DEFAULT_KW),
                );
            }
        } else {
            if (Utils.isJobTypeAllowed(ljt)) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL, jobType);
            } else if (brandFromKeyword) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UTM_TERM, brandFromKeyword);
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL, brandFromKeyword);
            } else {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL, jobType);
            }
        }
    }

    IdStore.storeIdForKey(
        Constants.URL_ARGS.ADGROUP_ID,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.ADGROUP_ID),
    );

    IdStore.storeIdForKey(
        Constants.URL_ARGS.PLACEMENT_ID,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PLACEMENT_ID),
    );
    IdStore.storeIdForKey(
        Constants.URL_ARGS.CREATIVE_ID,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.CREATIVE_ID),
    );

    IdStore.storeIdForKey(
        Constants.URL_ARGS.TARGET_ID,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.TARGET_ID),
    );

    IdStore.storeIdForKey(
        Constants.URL_ARGS.FBCLID,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.FBCLID),
    );

    IdStore.storeIdForKey(
        Constants.URL_ARGS.CLID,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.CLID),
    );

    IdStore.storeIdForKey(
        Constants.URL_ARGS.PUBLISHER_SUBID,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PUBLISHER_SUBID),
    );

    IdStore.storeIdForKey(
        Constants.URL_ARGS.PUBLISHER_SUB_SOURCE,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PUBLISHER_SUB_SOURCE),
    );

    // Read ongage params from url and add check for invalid date
    const otsDate = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.O_TS);

    if (otsDate && moment(otsDate).format('YYYY-MM-DD HH:mm:ss.SSSSSS') !== 'Invalid date') {
        IdStore.storeIdForKey(
            Constants.URL_ARGS.O_TS,
            `${moment(otsDate).format('YYYY-MM-DD HH:mm:ss.SSSSSS')}`,
        );
    }

    IdStore.storeIdForKey(
        Constants.URL_ARGS.O_KEYWORD,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.O_KEYWORD),
    );

    IdStore.storeIdForKey(
        Constants.URL_ARGS.O_MID,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.O_MID),
    );

    IdStore.storeIdForKey(
        Constants.URL_ARGS.O_SOURCE,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.O_SOURCE),
    );

    // https://app.clickup.com/t/qx2agg
    if ('5' === IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE)) {
        try {
            const ps = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PUBLISHER_SUBID);

            if (!Utils.isNull(ps) && !Utils.isEmptyStr(ps)) {
                IdStore.storeIdForKey(Constants.URL_ARGS.PUBLISHER_SUBID, ps.slice(0, -6));
                IdStore.storeIdForKey(
                    Constants.URL_ARGS.PUBLISHER_SUB_SOURCE,
                    ps.slice(ps.length - 6),
                );
            }
        } catch (e) {
            console.log(e);
        }
    }

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.AD_SOURCE,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.SOURCE),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.UTM_CONTENT,
        getNonEmptyVal(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_CONTENT),
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_CONTENT_G),
            Constants.UTM_DEFAULT.MISC,
        ),
    );

    // Send the http details
    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.REFERRER, document.referrer);
    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.DOMAIN, window.location.host);

    const pDomainValueFromUrl = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.P_DOMAIN);

    if (!Utils.isEmptyStr(pDomainValueFromUrl)) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.P_DOMAIN, pDomainValueFromUrl);
    }

    const isSameDomain =
        Utils.isNull(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.P_DOMAIN)) ||
        (IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.P_DOMAIN) &&
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.DOMAIN) &&
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.P_DOMAIN) ===
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.DOMAIN));

    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.IS_RDR, isSameDomain ? 0 : 1);

    const oLurl = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LANDING_URL);
    let newFlow = false;

    if (path && path.includes('/landing')) {
        const lurl = window.location.href.split('#')[0];

        if (lurl !== oLurl) {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.LANDING_URL, lurl);
            newFlow = true;
        }
    }
    // Create and store a cs-id if doesn't exist already
    const CS_ID = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);

    if (!CS_ID || newFlow) {
        if (IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.IS_FORWARDED) === '1') {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.IS_FORWARDED, 0);
        } else {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.CS_ID, generateCsId());
        }
    }

    const logoArr = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.MULTI_BRAND_LOGO);

    if (!Utils.isEmptyStr(logoArr)) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.MULTI_BRAND_LOGO, logoArr);
    }

    // Set luck orange tags
    const tags = {
        source: IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE),
        campaign: IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_CAMPAIGN),
        keyword: IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM),
        jobType: IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL),
        medium: IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM),
    };
    Utils.setLuckyOrangeTags(tags);
    /* Prepping dependency Injection using typedi */
    let profanityFilter = new Filter();
    profanityFilter.removeWords(...['hell', 'hells']);
    Container.set('profanityFilter', profanityFilter);

    SessionHelper.trackVisitCount();

    if (!isSkippedToNewTab) trackUserStatus();

    // GTM Registered targeting
    !restrictIdStoreEvals &&
        IdStore.storeIdForKey(
            Constants.ID_STORE_KEYS.GTM_IS_REGISTERED,
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_ID) ? '1' : '0',
        );

    // Fire lp_imp_0 with all data
    const performanceTime = Utils.getPagePerformanceTiming();

    let uDob = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.DOB) || '';
    uDob = Utils.filterInvalidUrlInput(uDob);
    let dob = null;

    if (uDob) {
        const dobMoment = Utils.parseDateFromUrl();
        dob =
            dobMoment &&
            dobMoment.isValid &&
            dobMoment.isValid() &&
            !DateUtils.isAboveTeenage(dobMoment)
                ? dobMoment
                : '';
    }

    dob = dob ? dob.format('YYYY-MM-DD') : '';

    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.LANDING_DOB, dob);

    eventManager.sendEvent(
        Constants.EVENT.INITIAL_PAGE_IMP,
        {
            container_load_time: performanceTime,
            dob: dob,
        },
        Constants.EVENT_TYPE.LANDING,
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.NEW_ENCODED_SUBID,
        await Utils.getEncodedSubId(
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE),
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_CAMPAIGN),
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUBLISHER_SUBID),
        ),
    );
    // initialize configuration
    await ConfigProvider.initialize();

    IdStore.storeIdForKey(
        Constants.URL_ARGS.CLID,
        AbTestHelper.fbClidReplacement()
            ? Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.FBCLID)
            : Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.CLID),
    );

    if (AbTestHelper.talrooAPIKwTest()) {
        const talrooKwArray = await Utils.getTalrooKwlist(
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM),
        );

        if (talrooKwArray === 'failed') {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.TALROO_API_KWS, 'failed');
            const updatableAbtChannel = FeatureValueHelper.getUpdatableABTChannelForTalrooAPIKwTest();

            IdStore.storeIdForKey(updatableAbtChannel, 'TalrooAPIKwTest_Control');
        } else {
            const slicedTalrooKwArray = talrooKwArray.slice(0, 4);

            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.TALROO_API_KWS, slicedTalrooKwArray);
        }
    }

    abManager.genTest();

    if (Processors.postConfigInitRedirection()) {
        return;
    }

    await Processors.postConfigInitProcessor();
    memoryStore.setItem(memoryStoreKeys.INITIAL_VIEW_PORT_HEIGHT, window.innerHeight);
    // Load the react component
    ReactDOM.render(
        /*
         * Create a more elaborate error-view based on the below example.
         * - https://github.com/bugsnag/bugsnag-react/blob/master/example/app.js#L105-L107
         */
        <ErrBoundaryComponent FallbackComponent={ErrorPage}>
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        </ErrBoundaryComponent>,
        document.getElementById(ReactPlaceholder),
    );
}

initialiseApp();
