import Utils from 'common/utils';
import MemoryStoreKeys from 'common/memoryStoreKeys';

const MemoryStoreKeysInv = Utils.objectFlip(MemoryStoreKeys);

class MemoryStore {
    constructor() {
        this.cache = {};
    }

    validateKey(key) {
        if (!MemoryStoreKeysInv[key]) {
            console.error('MemStore: Error! Empty key');

            return false;
        }

        return true;
    }

    getItem(key, ignoreKeyValidation) {
        if (ignoreKeyValidation) {
            return this.cache[key] || null;
        }

        if (this.validateKey(key)) {
            return this.cache[key];
        }

        return null;
    }

    setItem(key, val, ignoreKeyValidation) {
        if (ignoreKeyValidation) {
            console.log('MemStore: Storing key val', key, val);
            this.cache[key] = val;

            return true;
        }

        if (this.validateKey(key)) {
            console.log('MemStore: Storing key val', key, val);
            this.cache[key] = val;

            return true;
        }

        return false;
    }

    removeItem(key) {
        if (this.validateKey(key)) {
            return delete this.cache[key];
        }

        return false;
    }

    clear() {
        this.cache = {};

        return true;
    }
}

export default new MemoryStore();
