import { makeStyles } from '@material-ui/core/styles';
import flavors from 'flavors.macro';

flavors();

export const useStyles = makeStyles(theme => ({
    container: {
        padding: 10,
    },
    headerStyle: {
        color: '#fff',
        fontSize: '16px',
        fontWeight: '500',
        textAlign: 'center',
    },
    root: {
        display: 'flex',
        width: '100%',
        overflowX: 'hidden',
        alignItems: 'center',
        padding: '12px',
    },
    header: {
        color: '#0e4574',
        fontSize: '20px',
        fontWeight: '500',
        textAlign: 'center',
        lineHeight: 'normal',
        marginBottom: '12px',
    },
    sub1: {
        textAlign: 'left',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '1.14',
        color: '#535353',
        marginBottom: '6px',
    },
    sub2: {
        textAlign: 'left',
        fontSize: '12px',
        fontWeight: '200',
        marginBottom: '8px',
        color: '#565656',
        lineHeight: '1.33',
    },
    chip: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        '& > *': {
            margin: '6px 12px 4px 0',
        },
    },
    myImg: {
        width: '60px',
        height: '60px',
        display: 'flex',
        border: '2px solid #e7e7e7',
        borderRadius: '10px',
        margin: '0 auto 14px',
    },
    descText: {
        textAlign: 'center',
        fontSize: '12px',
        color: '#565656',
        lineHeight: '1.33',
        margin: '0 auto',
    },
    linkText: {
        textAlign: 'center',
        marginTop: '12px',
        fontSize: '14px',
        color: '#0085d7 !important',
        margin: '24px auto 16px',
    },
    v2button: {
        width: '100%',
        fontSize: 22,
        borderRadius: '4px',
        height: 48,
        backgroundImage: ' linear-gradient(to bottom, #ffc700, #ff7100)',
        '&:hover': {
            backgroundImage: ' linear-gradient(to bottom, #ffc700, #ff7100)',
        },
    },
    mySpan: {
        background: '#ecf7fc',
        color: '#0085d7',
        padding: '5px 8px',
        borderRadius: '4px',
        objectFit: 'contain',
        fontSize: '10px',
        lineHeight: ' 1.6',
        cursor: 'pointer',
    },
    hrs: {
        color: '#dcdcdc',
        marginBottom: '0px',
        marginTop: '0px',
    },
    // color: '#147fbb !important',

    hidden: {
        display: '-webkit-box',
        WebkitLineClamp: props => (props.noOfLines ? props.noOfLines : 2),
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
    },
    toggleButton: {
        minWidth: '30px',
        background: '#fff',
        color: '#0064ff',
        padding: '0',
        position: 'relative',
        textTransform: 'capitalize',
        left: 'calc(100% - 53px)',
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
    descTextSub: {
        fontSize: '10px',
        color: '#0085d7',
    },
    viewJob: {
        fontSize: '16px',
        textAlign: 'center',
    },
    arrow: {
        width: '12px',
        height: '12px',
        objectFit: 'contain',
    },
    headingTitle: {
        backgroundColor: theme.palette.primary.main,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        position: 'relative',
        padding: '6px 0',
    },
    closeIcon: {
        display: 'inline-block',
        position: 'absolute',
        right: '22px',
        top: '8px',
    },
    kwContainer: {
        paddingTop: 15,
        paddingBottom: 12,
    },
    loadingWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
