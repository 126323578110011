import Utils from 'common/utils';
import LoaderView from 'components/loader/index';
import React, { useEffect } from 'react';
import Constants from 'common/constants';

const Redirecting = () => {
    useEffect(() => {
        const rdrUrl = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.REDIRECT_URL);

        if (rdrUrl && rdrUrl.length) {
            window.open(decodeURIComponent(rdrUrl), '_self');
        }
    }, []);

    return <LoaderView />;
};

export default Redirecting;
