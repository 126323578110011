import { ConfigProvider } from 'providers';
import Utils from 'common/utils';
import { APP_SCOPES } from 'common/scopeUtils';
import { parseValueToArray, getCurrentRoute } from '../common';
import { getSkipText } from 'helpers/featureValueHelpers/featureValueHelpers';
import { AbTestHelper } from 'helpers';
import { LABELS } from 'strings/labels';

export const getFeatureValues = (featureKey, scope) => {
    if (ConfigProvider && ConfigProvider.Features && ConfigProvider.Features.getFeature) {
        const feature = ConfigProvider.Features.getFeature(featureKey, scope);

        if (feature && !Utils.isEmptyStr(feature.value)) {
            return parseValueToArray(feature.value).map(val => decodeURIComponent(val));
        }
    }

    return [];
};

export const getFeatureMapValue = (featureKey, scope) => {
    if (ConfigProvider && ConfigProvider.Features && ConfigProvider.Features.getFeature) {
        const feature = ConfigProvider.Features.getFeature(featureKey, scope);

        if (feature && !Utils.isEmptyStr(feature.value)) {
            return feature.value;
        }
    }

    return '';
};

export const getFeatureValuesByCurrentScope = (
    featureKey,
    scope = getCurrentRoute(),
    fbScope = APP_SCOPES.GLOBAL,
) => {
    const scopeValue = getFeatureValues(featureKey, scope);
    const fbValue = fbScope !== null ? getFeatureValues(featureKey, fbScope) : [];

    if (scopeValue && scopeValue.length) {
        return scopeValue;
    } else return fbValue;
};

export const getFeatureMapValuesByCurrentScope = (
    featureKey,
    scope = getCurrentRoute(),
    fbScope = APP_SCOPES.GLOBAL,
) => {
    return (
        getFeatureMapValue(featureKey, scope) ||
        (fbScope && getFeatureMapValue(featureKey, fbScope))
    );
};

export const getFeatureValueJson = (featureKey, scope) =>
    getFeatureValues(featureKey, scope).map(Utils.jsonSafeParse);

export const getFeatureValueJsonV2 = (featureKey, scope) => {
    const feature = ConfigProvider.Features.getConfigAPIFeature(featureKey, scope);
    let configValue;

    if (feature && feature.value && feature.value.length > 0) {
        configValue = feature.value.map(config => Utils.jsonSafeParse(config));
    }

    return configValue;
};

export const getFeatureValueJsonByCurrentScope = (featureKey, scope, fbScope) =>
    getFeatureValuesByCurrentScope(featureKey, scope, fbScope).map(Utils.jsonSafeParse);

export const getSkipTextValue = () => {
    let text = getSkipText();

    if (!Utils.isEmptyStr(text)) {
        if (AbTestHelper.skipTextChange()) return text;
    }

    return LABELS.SKIP_TEXT;
};
