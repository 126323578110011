import React from 'react';
import JobsSearchView from './jobsSearchView';
import JobSearchDataSource from 'datasource/jobsSearchDataSource';
import Utils from 'common/utils';
import Constants from 'common/constants';
import RoutePrefixHandler from 'common/routePrefixHandler';
import { IdStore } from 'dataStore';
import UserDataStore from 'components/landing/userDataStore';
import Pagination from 'react-paginating';
import { withRouter } from 'react-router-dom';
import InterstitialAds from '../../common/interstititalAdsCarousel';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import { UserInfoInterstitial } from './userInfoInterstitial';
import AbManager from 'abManager/abTest';
import eventManager from 'eventManager/eventManager';
import { ConfigProvider, FeatureMap } from 'providers';
import { AbTestHelper, FormHelpers } from 'helpers';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import FbGoogleFbGoogleEmailRegistration from './fbGoogleInterstitialEmail';
import EmailPopupToggleContainer from 'components/common/EmailPopupToggle';
import TargetingManager from 'targetingManager';
import AdsDataSource from 'datasource/adsDataSource';
import memoryStore from 'dataStore/memoryStore';
import memoryStoreKeys from 'common/memoryStoreKeys';
import { withStyles } from '@material-ui/core/styles';
import { CommonHelper, FeatureValueHelper } from 'helpers/index';
import ReactHtmlParser from 'react-html-parser';
import { SmsOverlay } from './smsTwoClickOverlay';
import PreferencePopup from '../../common/preferencePopup/index';
import { UserDetailsDataSource } from 'datasource/index';
import { ValidatorForm } from 'react-material-ui-form-validator';
import UserDataValidator from 'common/userDataValidator';
import PhoneValidator from 'common/PhoneValidator';
import DateUtils from 'common/DateUtils';
import { getConcatAddressField } from 'eventManager/eventHelpers/eventDataHelpers';
import LoaderView from 'components/loader';
import {
    getFallBackPageListingsAuId,
    brandedFlowValues,
} from 'helpers/featureValueHelpers/featureValueHelpers';
import { Grid } from '@material-ui/core';

import {
    fetchJTJobs,
    pickAdvertisersDataForJobResults,
    setupAdSourceForSingleSource,
    setupAdSourcesForJTJobsAdvertisers,
    setupOverlayJobProvider,
    wrapAdvertisersWithExtraData,
    selectJobSourceAdvertiser,
} from 'helpers/jobs/jobSourceHelpers';
import OfferManager from 'components/ads/offerManager';
import { isGenericTerm } from 'helpers/form/formHelpers';
import { fetchAdvertiser } from '../../../helpers/jobs/jobSourceHelpers';
import SponsoredMarketplace from '../sponsoredMarketplace/index';
import { getAdsForSponsoredMarketplace } from '../sponsoredMarketplace/sponsoredMarketplaceHandler';

class JobsSearchContainer extends React.Component {
    constructor(props) {
        super(props);

        this.aggregatedFormResp = {};
        this.isListingsInBg = AbTestHelper.listingsInBgFlow();
        const GoogleDisplayKwTest = ConfigProvider.Features.getFeature(FeatureMap.GoogleDisplayKw);
        this.isGoogleDisplayKwTest =
            GoogleDisplayKwTest && GoogleDisplayKwTest.value === 'google_kw_flow';
        this.mergedJobFeed = AbManager.getAbt7() === 'merged-job-feed';
        this.isListingsInBgWithZip = AbTestHelper.listingsInBgWithZip();
        const [term, queryTerm, location] = this.findParams();
        const selectedAdvertiser = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_ADS_SOURCE);
        const medium = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM);
        this.isEmailOrSMSMedium =
            !Utils.isEmptyStr(medium) &&
            (medium.toLowerCase().includes('sms') || medium.toLowerCase().includes('email'));
        const listingsJobDedup = ConfigProvider.Features.getFeature(FeatureMap.ListingsJobDedup);
        this.isJobImpDedup = listingsJobDedup ? listingsJobDedup.value === 'job-imp-dedup' : false;
        this.isJobClickDedup = listingsJobDedup
            ? listingsJobDedup.value === 'job-click-dedup'
            : false;
        const initPageNum = this.getInitPageNum(
            this.isEmailOrSMSMedium,
            this.isJobImpDedup,
            term,
            selectedAdvertiser,
        );
        this.eventData = this.getEventData();
        this.initPageNum = initPageNum;
        let { jobType, isJobTypeAllowed } = Utils.getJobType();
        const initialTerm = this.getInitialSearchTerm();
        this.internalZipCode = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE);
        this.state = {
            isSettingUp: true,
            searchTerm: term,
            queryTerm: queryTerm,
            isLoadingJobs: true,
            jobsList: [],
            experiment: '',
            jtJobsList: [],
            resultTitle: '',
            currentPageNum: initPageNum,
            jobLoc: '',
            totalJobs: 0,
            pageSize: Constants.JOBS_SEARCH.DEFAULT_JOB_RESULTS_PER_PAGE,
            location: location,
            shouldSendEvents: this.props.sendEvents,
            shouldScroll: false,
            showInterstitialAds: true,
            selectedAdvertiser: selectedAdvertiser,
            defaultJobBrand: this.getDefaultJobBrand(),
            showDialog: true,
            showFbGoogleInterstitial: false,
            backButtonClick: 0,
            showXmlPopup: false,
            showNeuvooWidget: false,
            nuevooWidgetLoading: true,
            overlayJobsState: {
                isLoading: true,
                jobsList: [],
                uuid: Utils.generateUUID(),
                experiment: '',
            },
            jobType: initialTerm,
            zipCode: this.internalZipCode || '',
            selectedEmployer: isJobTypeAllowed ? jobType : '',
            success: {
                jobType: true,
                zipCode: Utils.isEmptyStr(this.internalZipCode)
                    ? true
                    : !UserDataValidator.isValidZipCode(this.internalZipCode),
            },
            initialPreferences: '',
            redirectToOffers: false,
        };
        !AbTestHelper.adWorksExternalNotificationPushEnabled() &&
            !AbTestHelper.disableBackButtonBehaviour() &&
            !AbTestHelper.disableBackButtonListings() &&
            this.props.history.push({
                pathname: window.location.pathname,
                search: window.location.search,
                state: {
                    index: initPageNum,
                },
                hash: window.location.hash,
            });

        // this.showInHouseAds = !isMediumSMS
        this.isZipCodeFieldFocused = false;
        this.escFunction = this.escFunction.bind(this);
        this.onPopState = this.onPopState.bind(this);
        const FbGoogleEmailInterstitial = AbTestHelper.fbGoogleEmailInterstitial();
        this.showFbGoogleInterstitial =
            FbGoogleEmailInterstitial &&
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.EMAIL_SUB) !== 'true';
        this.showFbGoogleInterstitialUrl = null;
        this.newFulfillmentPage = AbTestHelper.isFulfillmentPageNewDesign();
        this.isFulfillmentDesignPhaseTwo = AbTestHelper.isFulfillmentDesignPhaseTwo();
        const lrdrFromUrl = Utils.getValForKeyFromCurrentUrl('lrdr');
        this.isbackButtonClicked = !Utils.isNull(lrdrFromUrl) && !Utils.isEmptyStr(lrdrFromUrl);
        this.isNeuvooWidgetTestActive = AbTestHelper.neuvooWidgetTest();
        this.neuvooWidgetAdData = null;
        this.jtAdSourcePromise = setupAdSourcesForJTJobsAdvertisers();

        const adSourceIds = memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS) || [];

        if (!adSourceIds.length) {
            setupAdSourceForSingleSource(Constants.ADVERTISERS.UPWARD);
        }
    }

    selectAdvertiser = async () => {
        await selectJobSourceAdvertiser();

        this.setState({
            isSettingUp: false,
        });
    };

    shouldDisplaySmsOverlay = () => {
        return AbTestHelper.enableSms2Click();
    };

    updatedKw = keyword => {
        this.setState(
            {
                searchTerm: keyword,
                queryTerm: keyword,
            },
            () => {
                const searchContent = {
                    searchTerm: this.state.searchTerm,
                    location: this.state.location,
                };
                this.searchHandler(searchContent);
            },
        );
    };

    updatedZip = zip => {
        this.setState(
            {
                zipCode: zip,
            },
            () => {
                const searchContent = {
                    searchTerm: this.state.searchTerm,
                    location: zip,
                };
                this.searchHandler(searchContent);
            },
        );
    };

    getInitPageNum(isEmailOrSMSMedium, isJobDedup, searchTerm, selectedAdvertiser) {
        let initPageNum = 1;

        if (isEmailOrSMSMedium) {
            if (isJobDedup) {
                const cookieData = Utils.getCookie('listingJobImpDedup');

                if (!Utils.isEmptyStr(cookieData)) {
                    const parsedCookieData = JSON.parse(cookieData);

                    if (parsedCookieData && parsedCookieData.length === 3) {
                        const [term, advertiser, pageNo] = parsedCookieData;

                        if (
                            (term && term.toLowerCase()) ===
                                (searchTerm && searchTerm.toLowerCase()) &&
                            advertiser === selectedAdvertiser &&
                            pageNo === 1
                        ) {
                            initPageNum = 2;
                        }
                    }
                }
            }

            // Set cookie with expiry - 60min * 24hour * 7days = 10080 minutes
            Utils.setCookie(
                'listingJobImpDedup',
                JSON.stringify([searchTerm, selectedAdvertiser, initPageNum]),
                60 * 24 * 7,
            );
        }

        return initPageNum;
    }

    handleOrganicAdClick = () => {
        this.setState({
            showXmlPopup: true,
        });
        const eventData = {
            cat: 'email',
        };
        eventManager.sendEvent(Constants.EVENT.HNP_IMP, eventData, Constants.EVENT_TYPE.DATA);
    };

    handleCloseSubmit = () => {
        this.setState({
            showXmlPopup: false,
        });
    };

    handleCloseIcon = () => {
        this.setState({
            showXmlPopup: false,
        });
        eventManager.sendEvent(
            Constants.EVENT.INTERSTITIAL_CLOSE_EMAIL,
            null,
            Constants.EVENT_TYPE.GRAITIFICATION,
        );
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.XML_VISITED, '1');
    };

    getDefaultJobBrand() {
        return Constants.DEFAULT_JOB_TYPE_ICONS[
            Math.floor(Math.random() * Constants.DEFAULT_JOB_TYPE_ICONS.length)
        ];
    }

    checkAndScrollTop() {
        // Scrolls to top if top bar is not completely in view port
        if (
            this.isListingsInBg &&
            !this.props.location.pathname.includes(
                RoutePrefixHandler.getRouteTemplateForPath(Constants.PAGE_ENDPOINTS.GRATIFICATION),
            )
        ) {
            const mjcTopBar = document.querySelector('.non-fixed-content-wrapper .MuiGrid-root');

            if (mjcTopBar) {
                const rect = mjcTopBar.getBoundingClientRect();

                if (rect && rect.y < 0) {
                    mjcTopBar.scrollIntoView();
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.isListingsInBg &&
            !this.props.location.pathname.includes(
                RoutePrefixHandler.getRouteTemplateForPath(Constants.PAGE_ENDPOINTS.GRATIFICATION),
            )
        ) {
            return;
        }

        if (prevProps.location.hash !== this.props.location.hash) {
            if (this.props.location.state) {
                this.setState({
                    currPageNumber: this.props.location.state.index,
                });
            } else {
                this.setState({
                    currPageNumber: 1,
                });
                this.props.history.push({
                    pathname: window.location.pathname,
                    search: this.props.history.location.search,
                    state: {
                        index: 1,
                    },
                    hash: `${1}`,
                });
            }
        }
    }

    async componentDidMount() {
        this.checkAndScrollTop();
        window.addEventListener(
            Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED,
            this.sessionInitializedEventHandler,
        );

        AbTestHelper.preferencePopupTest() &&
            window.addEventListener(
                Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED,
                this.firePrefPageImpEvent.bind(this),
                { once: true },
            );

        document.addEventListener('keydown', this.escFunction, false);
        !AbTestHelper.disableBackButtonListings() &&
            window.addEventListener('popstate', this.onPopState);

        ValidatorForm.addValidationRule('isValidZipCode', value => {
            const success =
                UserDataValidator.isValidZipCode(value) && !FormHelpers.hasProfanity(value)
                    ? false
                    : true;
            const successObj = this.state.success;
            this.setState({
                success: {
                    ...successObj,
                    zipCode: success,
                },
            });

            return success;
        });

        ValidatorForm.addValidationRule('isValidJobType', value => {
            const success =
                UserDataValidator.isValidJobType(value) && !FormHelpers.hasProfanity(value)
                    ? false
                    : true;

            return success;
        });

        if (this.isbackButtonClicked && this.isNeuvooWidgetTestActive) {
            const neuvooWidgetAdUnitFeature = ConfigProvider.Features.getFeature(
                FeatureMap.NeuvooWidgetAdUnit,
            );
            const neuvooWidgetAdUnit =
                neuvooWidgetAdUnitFeature && neuvooWidgetAdUnitFeature.value
                    ? neuvooWidgetAdUnitFeature.value
                    : '';

            if (neuvooWidgetAdUnit) {
                try {
                    const targetingParams = TargetingManager.fetchTargetingParams();
                    const adsUnitData = await AdsDataSource.fetchAdsForAdUnit(
                        targetingParams,
                        neuvooWidgetAdUnit,
                    );
                    let adClickEventData;

                    if (adsUnitData && adsUnitData.ad) {
                        this.setState(
                            {
                                nuevooWidgetLoading: false,
                                showNeuvooWidget: true,
                            },
                            () => {
                                adClickEventData = {
                                    advid: adsUnitData.ad.advId,
                                    advnm: adsUnitData.ad.advertiserName,
                                    cmpid: adsUnitData.ad.campaignId,
                                    cmpnm: adsUnitData.ad.campaignName,
                                    ad_unit_id: neuvooWidgetAdUnit,
                                    max_deal_id: adsUnitData.ad.dealId,
                                };
                                this.neuvooWidgetAdData = adClickEventData;
                                document.getElementById('jobsense1') &&
                                    document
                                        .getElementById('jobsense1')
                                        .addEventListener('click', this.jobSenseEventListener);
                                this.pushNeuvooWidgetScriptDynamically();
                            },
                        );
                    } else {
                        this.setState({
                            nuevooWidgetLoading: false,
                        });
                    }
                } catch (e) {
                    console.error('Error fetching Neuvoo Widget AdUnit');
                    this.setState({
                        nuevooWidgetLoading: false,
                    });
                }
            } else {
                this.setState({
                    nuevooWidgetLoading: false,
                });
            }
        }

        if (AbTestHelper.talrooAdRelatedTest() || AbTestHelper.talrooWidgetTest()) {
            Utils.addScript(
                {
                    type: 'text/javascript',
                    id: 'talrooAds',
                },
                `
                (TalrooAds = window.TalrooAds || []).push({});
            `,
            );
            const showPopup = IdStore.fetchIdForKey('showPopup') === 'true';

            AbTestHelper.talrooWidgetTest() &&
                window.location.pathname === Constants.PAGE_ENDPOINTS.GRATIFICATION &&
                eventManager.sendEvent(
                    Constants.EVENT.GRATIFICATION_PAGE_IMP,
                    {
                        val: showPopup ? 1 : 0,
                        advid: Utils.isTestEnv() ? '2235' : '2250',
                        advnm: Utils.isTestEnv() ? 'Talroo Staging' : 'talroo',
                        cmpnm: 'Talroo Widget',
                    },
                    Constants.EVENT_TYPE.GRAITIFICATION,
                );
        }

        if (window.location.pathname === Constants.PAGE_ENDPOINTS.GRATIFICATION) {
            await getAdsForSponsoredMarketplace();
        }
    }

    getPathDetailsForJobResultApi = () => {
        const medium = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM);

        if (medium && medium.toLowerCase() === 'sms2click') {
            return 'sms';
        }

        if (medium && medium.toLowerCase() === 'email') {
            return 'email';
        }

        return 'serp';
    };

    jobSenseEventListener = e => {
        const parentElement =
            e.srcElement && e.srcElement.parentElement ? e.srcElement.parentElement : null;

        if (parentElement) {
            let title = parentElement.getElementsByClassName('t-alternate-titles-label');

            if (title && title.length > 0) {
                title = title[0].innerText;
            }

            let employerName = parentElement.getElementsByClassName(
                't-alternate-titles-salary-empname',
            );

            if (employerName && employerName.length > 0) {
                employerName = employerName[0].innerText;
            }

            eventManager.sendEvent(
                Constants.EVENT.AD_CLICK,
                {
                    ...this.neuvooWidgetAdData,
                    adt: title,
                    adst: employerName,
                    job_redirect_url: 'inaccessible',
                    cat: '2click',
                },
                Constants.EVENT_TYPE.GRAITIFICATION,
            );

            eventManager.sendEventWhenVisible(
                Constants.EVENT.AD_PROG,
                {
                    ...this.neuvooWidgetAdData,
                    adt: title,
                    adst: employerName,
                    job_redirect_url: 'inaccessible',
                    cat: '2click',
                },
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
        }
    };

    pushNeuvooWidgetScriptDynamically = () => {
        const sid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SESSION_ID);
        const city = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_CITY);
        const kw =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM) ||
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);
        const country = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_COUNTRY);
        const NeuvooWidgetCPCFloorFeature = ConfigProvider.Features.getFeature(
            FeatureMap.NeuvooWidgetCPCFloor,
        );
        const cpcFloor =
            NeuvooWidgetCPCFloorFeature && NeuvooWidgetCPCFloorFeature.value
                ? NeuvooWidgetCPCFloorFeature.value
                : '';
        Utils.addScript(
            {
                type: 'text/javascript',
                src: `https://www.talent.com/jobsense/assets/jobsense.js.php?k=${kw}&l=${city}&country=${country}&salary=0&direct=1&contenttype=all&size=15&publisher=cd12f831&chnl1=Widget&chnl2=${sid}&chnl3=default&cpcfloor=${
                    cpcFloor || '0'
                }`,
            },
            '',
            () => {
                eventManager.sendEvent(
                    Constants.EVENT.BACK_BTN_IMPRESSION,
                    {
                        ...this.neuvooWidgetAdData,
                    },
                    Constants.EVENT_TYPE.GRAITIFICATION,
                );
            },
        );
    };

    escFunction(event) {
        if (event.keyCode === 27) {
            this.setState({ showDialog: false });
        }
    }

    async onPopState(e) {
        this.setState({ showDialog: false });
        this.setState(
            {
                backButtonClick: this.state.backButtonClick + 1,
            },
            async () => {
                let lrdrFromUrl = Utils.getValForKeyFromCurrentUrl('lrdr');

                const listing2OffersCount = IdStore.fetchIdForKey(
                    Constants.ID_STORE_KEYS.LISTINGS_TO_OFFERS_COUNT,
                );

                const pathName = window.location.pathname;

                if (
                    AbTestHelper.backBtn2Offer() &&
                    (!pathName.includes('landing') ||
                        !pathName.includes('survey') ||
                        !pathName.includes('offers'))
                ) {
                    if (listing2OffersCount < 1 && this.state.redirectToOffers) {
                        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.LISTINGS_TO_OFFERS_COUNT, 1);

                        if (!Utils.isEmptyStr(lrdrFromUrl)) {
                            Utils.replaceUrlParam('lrdr', 'listings');
                        } else {
                            Utils.insertUrlParam('lrdr', 'listings');
                        }

                        this.props.history.push({
                            pathname: RoutePrefixHandler.generateActualPathname(
                                Constants.PAGE_ENDPOINTS.ADS,
                            ),
                            search: window.location.search,
                        });
                    } else {
                        this.props.history.push({
                            pathname: window.location.pathname,
                            search: this.props.history.location.search,
                            hash: window.location.hash,
                        });
                    }
                } else if (AbTestHelper.enableBack2ListingsMidPathOffer()) {
                    if (
                        !Utils.isNull(lrdrFromUrl) &&
                        !Utils.isEmptyStr(lrdrFromUrl) &&
                        ((lrdrFromUrl.includes('landing') && this.state.backButtonClick === 2) ||
                            (lrdrFromUrl.includes('survey') && this.state.backButtonClick === 1))
                    ) {
                        const enableListingsToMidPathOffer = ConfigProvider.Features.getFeature(
                            FeatureMap.Back2ListingsMidPathRedirection,
                        );
                        IdStore.storeIdForKey(
                            Constants.ID_STORE_KEYS.AB_TEST_5,
                            enableListingsToMidPathOffer.value,
                        );
                        eventManager.sendEvent(
                            Constants.EVENT.PAGE_BACK,
                            { responsedata: 'back2ListingsMidPathOffer' },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );

                        if (
                            !Utils.isNull(lrdrFromUrl) &&
                            !Utils.isEmptyStr(lrdrFromUrl) &&
                            (lrdrFromUrl.includes('landing') || lrdrFromUrl.includes('survey'))
                        ) {
                            this.props.history.push({
                                pathname: RoutePrefixHandler.generateActualPathname(
                                    Constants.PAGE_ENDPOINTS.ADS,
                                ),
                                search: window.location.search,
                            });
                        }
                    }
                } else if (
                    AbTestHelper.motiveInteractiveTest() &&
                    !Utils.isNull(lrdrFromUrl) &&
                    !Utils.isEmptyStr(lrdrFromUrl) &&
                    this.state.backButtonClick === 1
                ) {
                    const clId = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UUID);
                    let url;
                    const adUnitId = Utils.isTestEnv()
                        ? Constants.AD_UNIT_ID.MOTIVE_INTERACTIVE_TEST.STAG
                        : Constants.AD_UNIT_ID.MOTIVE_INTERACTIVE_TEST.PROD;

                    try {
                        const targetingParams = TargetingManager.fetchTargetingParams();
                        const adsUnitData = await AdsDataSource.fetchAdsForAdUnit(
                            targetingParams,
                            adUnitId,
                        );
                        let adClickEventData;

                        if (adsUnitData && adsUnitData.ad) {
                            adClickEventData = {
                                advid: adsUnitData.ad.advId,
                                advnm: adsUnitData.ad.advertiserName,
                                cmpid: adsUnitData.ad.campaignId,
                                cmpnm: adsUnitData.ad.campaignName,
                            };
                        }

                        if (
                            adsUnitData &&
                            adsUnitData.ad &&
                            AbTestHelper.motiveInteractiveTestAndroid()
                        ) {
                            url = `https://dailystash.com/6365/how-to-boost-your-income-with-these-money-making-apps-14/?affid=70030&s2=${clId}`;
                            await eventManager.sendEvent(
                                Constants.EVENT.MOTIVE_BACK_LINK,
                                null,
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                            await eventManager.sendEvent(
                                Constants.EVENT.AD_CLICK,
                                { ...adClickEventData, job_redirect_url: url, cat: '2click' },
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                            await eventManager.sendEventWhenVisible(
                                Constants.EVENT.AD_PROG,
                                { ...adClickEventData, job_redirect_url: url, cat: '2click' },
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                            window.setTimeout(() => {
                                window.open(url, '_self');
                            }, 500);
                        } else if (
                            adsUnitData &&
                            adsUnitData.ad &&
                            AbTestHelper.motiveInteractiveTestIos()
                        ) {
                            url = `https://dailystash.com/6368/how-to-boost-your-income-with-these-money-making-apps-15/?affid=70030&s2=${clId}`;
                            await eventManager.sendEvent(
                                Constants.EVENT.MOTIVE_BACK_LINK,
                                null,
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                            await eventManager.sendEvent(
                                Constants.EVENT.AD_CLICK,
                                { ...adClickEventData, job_redirect_url: url, cat: '2click' },
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                            await eventManager.sendEventWhenVisible(
                                Constants.EVENT.AD_PROG,
                                { ...adClickEventData, job_redirect_url: url, cat: '2click' },
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                            window.setTimeout(() => {
                                window.open(url, '_self');
                            }, 500);
                        } else {
                            eventManager.sendEvent(
                                Constants.EVENT.AD_FAILED,
                                null,
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                            this.props.history.push({
                                pathname: window.location.pathname,
                                search: this.props.history.location.search,
                                state: {
                                    index: this.initPageNum,
                                },
                                hash: window.location.hash,
                            });
                        }
                    } catch (err) {
                        console.error(
                            `Error while fetching AdUnit for Motive Interactive Test - `,
                            err,
                        );
                        eventManager.sendEvent(
                            Constants.EVENT.AD_FAILED,
                            null,
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                        this.props.history.push({
                            pathname: window.location.pathname,
                            search: this.props.history.location.search,
                            state: {
                                index: this.initPageNum,
                            },
                            hash: window.location.hash,
                        });
                    }
                } else if (
                    window.location.pathname.includes(Constants.PAGE_ENDPOINTS.GRATIFICATION) &&
                    !AbTestHelper.adWorksExternalNotificationPushEnabled()
                ) {
                    this.props.history.push({
                        pathname: window.location.pathname,
                        search: this.props.history.location.search,
                        state: {
                            index: this.initPageNum,
                        },
                        hash: window.location.hash,
                    });
                }
            },
        );
    }

    sessionInitializedEventHandler = async () => {
        await this.selectAdvertiser();
        !AbTestHelper.talrooWidgetTest() && this.loadJobsData();
        // Check if zip-code is available
        this.internalZipCode = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE);

        if (Utils.isEmptyStr(this.internalZipCode)) {
            console.log('ZIP_CODE: There is no zip-code available in the session response');

            return;
        }
        this.populateDefaultZipCode();
    };

    populateDefaultZipCode() {
        /*
         * Update the zipcode into the textfield only if -
         * - internal-zipcode is available
         * - zipcode is not already populated (by user or old-data)
         * - user is not currently focused on the zip-code field
         */
        if (
            false === Utils.isEmptyStr(this.internalZipCode) &&
            Utils.isEmptyStr(this.state.zipCode) &&
            false === this.isZipCodeFieldFocused
        ) {
            this.setState({
                zipCode: this.internalZipCode,
            });
        }

        const initialZip = this.state.zipCode;
        const initialJobType = this.state.jobType;
        const initialPreferences = this.getPreferences(initialZip, initialJobType);

        this.setState({ initialPreferences: initialPreferences });
    }

    getPreferences(zip, jobType) {
        return zip.replace(/\s/g, '').toLowerCase() + jobType.replace(/\s/g, '').toLowerCase();
    }

    isQueryChangeRequiredForAdvertiser = adSourceAusDetails => {
        const list = FeatureValueHelper.getQueryParamChangeAdvertisers();

        if (adSourceAusDetails && adSourceAusDetails.length > 0 && list && list.length > 0) {
            for (
                let adSourceIndex = 0;
                adSourceIndex < adSourceAusDetails.length;
                adSourceIndex++
            ) {
                const advertiser =
                    adSourceAusDetails[adSourceIndex] &&
                    adSourceAusDetails[adSourceIndex].advertiserName
                        ? adSourceAusDetails[adSourceIndex].advertiserName.split(' ')
                        : '';

                for (let index = 0; index < advertiser.length; index++) {
                    if (list.includes(advertiser[index].toLowerCase())) {
                        return true;
                    }
                }
            }
        }

        return false;
    };

    getInitialSearchTerm() {
        // Resolved from URL PARAMS
        let { jobType } = Utils.getJobType();
        let medium = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_MEDIUM);
        let keyword =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PREFERENCE_JOB_TYPE) ||
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM);

        if (!Utils.isEmptyStr(keyword) && isGenericTerm(keyword)) {
            if (!Utils.isEmptyStr(jobType)) return jobType;

            let jobTypeFromStorage =
                IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL) ||
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);

            if (!Utils.isEmptyStr(jobTypeFromStorage)) return jobTypeFromStorage;
        }

        if (medium && medium.toLowerCase() === Constants.REDIRECT_MEDIUM.SMS) {
            return Utils.isNull(keyword) || Utils.isEmptyStr(keyword) ? 'Jobs near me' : keyword;
        } else if (!Utils.isEmptyStr(keyword) && !isGenericTerm(keyword)) return keyword;
        else if (!Utils.isEmptyStr(jobType) && !isGenericTerm(jobType)) {
            return jobType;
        }

        jobType = IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL);

        if (!Utils.isEmptyStr(keyword)) return keyword;

        if (!Utils.isEmptyStr(jobType)) return jobType;

        jobType = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);

        if (!Utils.isEmptyStr(jobType)) return jobType;

        return 'Jobs near me';
    }

    getQueryTerm() {
        // Resolved from URL PARAMS
        let { jobType } = Utils.getJobType();
        let medium = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_MEDIUM);
        const mediumFromStore = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM);
        const mediumLowered =
            mediumFromStore && mediumFromStore.toLowerCase && mediumFromStore.toLowerCase();
        let keyword =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PREFERENCE_JOB_TYPE) ||
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM);

        const adSourceAus = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU);
        const adSourceAusDetails = !Utils.isNull(adSourceAus) && Object.values(adSourceAus);
        const isMessagingChannel = mediumLowered === 'sms2click' || mediumLowered === 'email';

        const isQueryChangeRequired =
            this.isQueryChangeRequiredForAdvertiser(adSourceAusDetails) ||
            mediumLowered === 'sms2click' ||
            mediumLowered === 'email';

        if (isMessagingChannel) {
            let jobTypeFromStorage =
                IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL) ||
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);

            if (Utils.isNull(keyword) || isGenericTerm(keyword)) return jobTypeFromStorage;

            return keyword;
        }

        if (medium && medium.toLowerCase() === Constants.REDIRECT_MEDIUM.SMS) {
            return Utils.isNull(keyword) || Utils.isEmptyStr(keyword) ? 'Jobs near me' : keyword;
        } else {
            if (isQueryChangeRequired) {
                let jobTypeFromStorage =
                    IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL) ||
                    IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);

                if (!Utils.isNull(jobTypeFromStorage)) {
                    if (jobTypeFromStorage === Constants.DEFAULT_KW && !Utils.isEmptyStr(keyword)) {
                        jobTypeFromStorage = '';
                    } else {
                        jobTypeFromStorage = jobTypeFromStorage.toLowerCase();
                    }
                }

                if (!Utils.isNull(keyword)) {
                    keyword = keyword.toLowerCase();
                }

                if (
                    !Utils.isEmptyStr(keyword) &&
                    isGenericTerm(keyword) &&
                    isGenericTerm(jobTypeFromStorage)
                ) {
                    return keyword;
                }

                if (
                    !Utils.isEmptyStr(keyword) &&
                    isGenericTerm(keyword) &&
                    !Utils.isEmptyStr(jobTypeFromStorage)
                ) {
                    return jobTypeFromStorage;
                }

                if (!Utils.isEmptyStr(keyword) && Utils.isEmptyStr(jobTypeFromStorage))
                    return keyword;
                else if (!Utils.isEmptyStr(jobTypeFromStorage) && Utils.isEmptyStr(keyword))
                    return jobTypeFromStorage;
                else if (
                    !Utils.isEmptyStr(keyword) &&
                    !Utils.isEmptyStr(jobTypeFromStorage) &&
                    jobTypeFromStorage === keyword
                )
                    return jobTypeFromStorage;
                else if (
                    !Utils.isEmptyStr(keyword) &&
                    !Utils.isEmptyStr(jobTypeFromStorage) &&
                    jobTypeFromStorage !== keyword
                )
                    return `${jobTypeFromStorage} ${keyword}`;
            } else {
                if (!Utils.isEmptyStr(keyword)) return keyword;
                else if (!Utils.isEmptyStr(jobType)) return jobType;
            }
        }
        jobType = IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL);

        if (!Utils.isEmptyStr(keyword)) return keyword;

        if (!Utils.isEmptyStr(jobType)) return jobType;

        jobType = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);

        if (!Utils.isEmptyStr(jobType)) return jobType;

        return 'Jobs near me';
    }

    findParams() {
        let term = this.getInitialSearchTerm();
        let queryTerm = this.getQueryTerm();
        let location = Utils.getValForKeyFromCurrentUrl('z');
        let user = UserDataStore.fetchUserDetailsFromStore();

        const prefPageLocation = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PREFERENCE_ZIP_CODE);

        if (prefPageLocation) {
            location = prefPageLocation;
        }

        if (!location || Utils.isEmptyStr(location) || isNaN(location) || location.length !== 5) {
            location = user ? user['zip'] : '';
        }

        if (Utils.isEmptyStr(location)) {
            location = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE);
        }

        return [term, queryTerm, location];
    }

    async loadJobsData() {
        const [term, queryTerm, location] = this.findParams();
        this.setState({
            searchTerm: term,
            isLoadingJobs: true,
            location: location,
            queryTerm: queryTerm,
        });

        let jobsQuery = JobSearchDataSource.createJobsQueryForTerm({
            term: queryTerm,
            pageNum: this.state.currentPageNum,
            pageSize: null,
            location,
        });

        // let sponsoredAdsQuery = this.showInHouseAds ? SponsoredAdsDataSource.createSponsoreJobsQueryForTerm(term) : null;
        setTimeout(this.updateJobsDataForQuery(jobsQuery), 0);

        if (this.shouldDisplaySmsOverlay()) {
            await setupOverlayJobProvider();
            this.fetchOverlayJobDetails(jobsQuery);
        }
    }

    updateAdvertiser = () => {
        const currAdvertiser = this.state.selectedAdvertiser;

        if (AbManager.getAbt7() !== 'rotate_advtser') return;
        let adSource = '';
        let r;

        while (true) {
            r = Math.random();

            if (r < 0.2 && currAdvertiser !== Constants.ADVERTISERS.APPCAST) {
                adSource = Constants.ADVERTISERS.APPCAST;
                break;
            } else if (r < 0.5 && currAdvertiser !== Constants.ADVERTISERS.UPWARD) {
                adSource = Constants.ADVERTISERS.UPWARD;
                break;
            } else if (currAdvertiser !== Constants.ADVERTISERS.TALROO) {
                adSource = Constants.ADVERTISERS.TALROO;
                break;
            }
        }

        this.setState({
            selectedAdvertiser: adSource,
        });
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_ADS_SOURCE, adSource);

        let jobsQuery = JobSearchDataSource.createJobsQueryForTerm({
            term: this.state.queryTerm,
            pageNume: this.state.currentPageNum,
            pageSize: null,
            location: this.state.location,
        });
        this.updateJobsDataForQuery(jobsQuery);
    };

    shouldMakeEmptySearchQuery(totalJobs) {
        console.log('Should make empty search query ', totalJobs);

        if (totalJobs === 0) {
            this.updateJobsDataForEmptySearchQuery();
        }
    }

    dispatchAdImpEvents(jobs) {
        jobs.forEach((job, index) => {
            const { advid, advnm, title, description, company } = job;

            let shortDesc = '';

            if (description && !Utils.isNull(description)) {
                shortDesc = description.substring(0, 200);
            }
            eventManager.sendEvent(
                Constants.EVENT.ADS_IMP,
                {
                    adt: title,
                    adst: company,
                    adst2: shortDesc,
                    advid,
                    advnm,
                    spotno: `${index}`,
                },
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
        });
    }

    triggerGratificationEvents = (jobResults, errors, extraData = {}, experiment) => {
        if (window.location.pathname !== Constants.PAGE_ENDPOINTS.GRATIFICATION) return;

        const showPopup = IdStore.fetchIdForKey('showPopup') === 'true';

        let userDetails = UserDataStore.fetchUserDetailsFromStore() || {};

        let phone = null;

        if (userDetails.phone_number) {
            phone = userDetails.phone_number;
        } else {
            phone = Utils.filterInvalidUrlInput(
                Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE_NUMBER) || '',
            );
        }

        console.log(`showing popup? ${showPopup}`);
        const lrdrFromUrl = Utils.getValForKeyFromCurrentUrl('lrdr');
        const isbackButtonClicked = !Utils.isNull(lrdrFromUrl) && !Utils.isEmptyStr(lrdrFromUrl);

        if (isbackButtonClicked && AbTestHelper.neuvooWidgetTest()) return;

        const resultAdSourceIds = jobResults.reduce((acc, curr) => {
            if (curr && curr.in_uuid) {
                acc[curr.in_uuid] = true;
            }

            return acc;
        }, {});

        const adSourceAus = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU);
        const adSourceMeta = memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA) || [];
        const adSourceUUidMap = memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP) || {};

        if (extraData.cat !== 'jt') {
            Object.keys(resultAdSourceIds).forEach(in_uuid => {
                const au = adSourceAus[in_uuid];
                const auMeta = adSourceMeta.find(i => i.uuid === in_uuid);
                CommonHelper.updateEncodedSource(au.encodedSource);
                au &&
                    eventManager.sendEvent(
                        Constants.EVENT.GRATIFICATION_PAGE_IMP,
                        {
                            val: showPopup ? 1 : 0,
                            advid: adSourceUUidMap[in_uuid],
                            advnm: au.advertiserName,
                            cmpnm: au.campaignName,
                            cmpid: au.campaignId,
                            uuid: in_uuid,
                            phone_number: phone,
                            rv_type: au.revType,
                            abt_exp: experiment,
                            ext5: experiment,
                            ad_unit_id: auMeta && auMeta.adUnitId,
                            max_deal_id: au.dealId,
                            ...extraData,
                        },
                        Constants.EVENT_TYPE.GRAITIFICATION,
                    );
            });
        }

        Object.keys(errors).forEach(errUuid => {
            const au = adSourceAus[errUuid];
            const auMeta = adSourceMeta.find(i => i.uuid === errUuid);

            CommonHelper.updateEncodedSource(au.encodedSource);
            au &&
                eventManager.sendEvent(
                    Constants.EVENT.GRATIFICATION_PAGE_FAIL,
                    {
                        val: showPopup ? 1 : 0,
                        advid: auMeta.advid,
                        advnm: au.advertiserName,
                        cmpnm: au.campaignName,
                        cmpid: au.campaignId,
                        uuid: errUuid,
                        error: errors[errUuid],
                        rv_type: au.revType,
                        abt_exp: experiment,
                        ext5: experiment,
                        max_deal_id: au.dealId,
                        ad_unit_id: auMeta && auMeta.adUnitId,
                        ...extraData,
                    },
                    Constants.EVENT_TYPE.GRAITIFICATION,
                );
        });

        !AbTestHelper.talrooWidgetTest() &&
            jobResults.forEach((job, index) => {
                const jobAdvId = job.advid;
                const uuid = job.in_uuid;
                const {
                    title,
                    description,
                    company,
                    bid,
                    cpa,
                    cpc,
                    max_filters,
                    encodedSource,
                } = job;

                CommonHelper.updateEncodedSource(encodedSource);

                let shortDesc = '';

                let userDetails = UserDataStore.fetchUserDetailsFromStore() || {};

                let phone = null;

                if (userDetails.phone_number) {
                    phone = userDetails.phone_number;
                } else {
                    phone = Utils.filterInvalidUrlInput(
                        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE_NUMBER) || '',
                    );
                }

                if (description && !Utils.isNull(description)) {
                    shortDesc = description.substring(0, 200);
                }

                const au = adSourceAus[uuid];
                const auMeta = adSourceMeta.find(i => i.uuid === uuid);

                if (au) {
                    eventManager.sendEvent(
                        Constants.EVENT.SPOT_GRAT_IMP,
                        {
                            ervn: bid,
                            advid: jobAdvId,
                            advnm: au.advertiserName,
                            cmpnm: au.campaignName,
                            cmpid: au.campaignId,
                            uuid: uuid,
                            adt: title,
                            adst: company,
                            adst2: shortDesc,
                            spotno: `${index}`,
                            cpa,
                            cpc,
                            abt_exp: experiment,
                            ext5: experiment,
                            max_filters,
                            phone_number: phone,
                            rv_type: au.revType,
                            ad_unit_id: auMeta && auMeta.adUnitId,
                            max_deal_id: au.dealId,
                            ...extraData,
                        },
                        Constants.EVENT_TYPE.GRAITIFICATION,
                    );
                    OfferManager.fireTrackingPixelsList(au.trackingPixels);
                }
            });
    };

    triggerGratificationCallFailure = e => {
        console.log(
            'JOBS: ERR: could not fetch the data - ',
            e,
            memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS),
        );
        const errorObj = (memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS) || []).reduce(
            (acc, curr) => {
                acc[curr] = `Exception: Failed to fetch Jobs API`;

                return acc;
            },
            {},
        );

        this.triggerGratificationEvents([], errorObj);
    };

    updateJobsDataForEmptySearchQuery() {
        console.log('Making empty search query');

        this.setState({
            isLoadingJobs: true,
        });

        let jobsQuery = JobSearchDataSource.createJobsQueryForTerm({
            term: '',
        });
        delete jobsQuery.search;
        const extraData = {
            placement: this.getPathDetailsForJobResultApi(),
        };

        jobsQuery = {
            ...jobsQuery,

            advertisers: wrapAdvertisersWithExtraData(
                pickAdvertisersDataForJobResults(
                    memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS),
                ),
                extraData,
            ),
        };
        console.log('Jobs arguments ', jobsQuery);

        JobSearchDataSource.fetchJobsDataForQueryMultiSource(jobsQuery)
            .then(async jobsData => {
                console.log('JOBS: got jobs data - ', jobsData);
                const jtJobsArr = await this.getJtJobs();

                let { totalCount: totalJobs, error, experiment } = jobsData;

                if (Utils.isNull(totalJobs)) {
                    totalJobs = 0;
                }
                /*
                 * let { jobType, isJobTypeAllowed } = Utils.getJobType();
                 * var userId = JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_ID))
                 * let key = isJobTypeAllowed ? jobType : userId.job_type
                 */
                let { jobs: jobsList } = jobsData;
                let resultTitle = '';

                if (Utils.isEmptyArr(jobsList)) {
                    jobsList = [];
                }

                if (jobsList.length > 0) {
                    resultTitle =
                        this.newFulfillmentPage || this.isFulfillmentDesignPhaseTwo
                            ? `${totalJobs} Results for ${
                                  this.state.searchTerm !== 'Jobs near me'
                                      ? `${Utils.getCapitalizeStr(this.state.searchTerm)} `
                                      : ''
                              } jobs ${this.state.location ? 'near' : ''} ${this.state.location}`
                            : AbTestHelper.darkSERPTest()
                            ? `<div class="result-title-wrapper test-dark-txt">${totalJobs} Results for ${
                                  this.state.searchTerm !== 'Jobs near me'
                                      ? `${Utils.getCapitalizeStr(this.state.searchTerm)} `
                                      : ''
                              } jobs ${this.state.location ? 'near' : ''} ${
                                  jobsList && jobsList.length && jobsList[0].location
                              }</div>`
                            : AbTestHelper.newSERPTest()
                            ? `<div class="result-title-wrapper test-nrml-txt">${totalJobs} Results for ${
                                  this.state.searchTerm !== 'Jobs near me'
                                      ? `${Utils.getCapitalizeStr(this.state.searchTerm)} `
                                      : ''
                              } jobs ${this.state.location ? 'near' : ''} ${
                                  jobsList && jobsList.length && jobsList[0].location
                              }</div>`
                            : `
                    <div class="result-title-wrapper">
                        <div class="result-title ${AbTestHelper.serpRevamp() && 'jobsearchText'}">
                            <span class="nrml-txt ${
                                AbTestHelper.serpRevamp() && 'jobsearchText'
                            }">Search Results for <span class="highlight">${
                                  this.state.searchTerm !== 'Jobs near me'
                                      ? `${Utils.getCapitalizeStr(
                                            Utils.cleanH1Keyword(this.state.searchTerm),
                                        )} `
                                      : ''
                              }</span> Near <span class="highlight">${
                                  this.state.location
                              }</span></span>
                        </div>
                    </div>`;

                    if (
                        this.mergedJobFeed &&
                        !(
                            AbTestHelper.listingsInBgFlow() &&
                            !this.props.location.pathname.includes(
                                RoutePrefixHandler.getRouteTemplateForPath(
                                    Constants.PAGE_ENDPOINTS.GRATIFICATION,
                                ),
                            )
                        )
                    ) {
                        this.dispatchAdImpEvents(jobsList);
                    }
                }
                this.triggerGratificationEvents(jobsList, error || {}, {}, experiment);

                if (
                    AbTestHelper.listingsInBgFlow() &&
                    !this.props.location.pathname.includes(
                        RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.GRATIFICATION,
                        ),
                    ) &&
                    jobsList.length > 0
                ) {
                    IdStore.storeIdForKey('listings-jb', JSON.stringify(jobsList));
                } else {
                    IdStore.removeIdForKey('listings-jb');
                }

                this.setState({
                    isLoadingJobs: false,
                    jobsList: Utils.mergePriorArray(jtJobsArr, jobsList).filter(item => !!item),
                    experiment,
                    resultTitle,
                    totalJobs,
                    shouldScroll: false,
                });
            })
            .catch(e => {
                this.triggerGratificationCallFailure(e);
            });
    }

    fallBackUpdateJobsDataForEmptySearchQuery = async () => {
        console.log('Making empty search query');

        let jobsQuery = JobSearchDataSource.createJobsQueryForTerm({
            term: '',
        });
        delete jobsQuery.search;
        const extraData = {
            placement: this.getPathDetailsForJobResultApi(),
        };

        jobsQuery = {
            ...jobsQuery,

            advertisers: wrapAdvertisersWithExtraData(
                pickAdvertisersDataForJobResults(
                    getFallBackPageListingsAuId()
                        ? memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS)
                        : memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_FALLBACK),
                ),
                extraData,
            ),
        };

        console.log('Jobs arguments ', jobsQuery);

        try {
            const jobsData = await JobSearchDataSource.fetchJobsDataForQueryMultiSource(jobsQuery);
            let { jobs: jobsList, totalCount: totalJobs } = jobsData;

            if (totalJobs && totalJobs > 0) {
                return { jobsList, totalJobs };
            } else {
                this.triggerGratificationCallFailure();
                jobsQuery = {
                    ...jobsQuery,

                    advertisers: wrapAdvertisersWithExtraData(
                        pickAdvertisersDataForJobResults(
                            memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_FALLBACK),
                        ),
                        extraData,
                    ),
                };

                const jobsData = await JobSearchDataSource.fetchJobsDataForQueryMultiSource(
                    jobsQuery,
                );
                let { jobs: jobsList, totalCount: totalJobs } = jobsData;

                return { jobsList, totalJobs };
            }
        } catch (e) {
            console.log('Fallback fail');
        }
    };

    getJtJobs = async () => {
        await this.jtAdSourcePromise;
        let { jobType } = Utils.getJobType();

        jobType = !Utils.isEmptyStr(jobType)
            ? jobType
            : IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);

        if (Utils.isEmptyStr(jobType)) {
            return [];
        } else {
            const [jtJobsArr = [], jobJobsError = {}, experiment = ''] = await fetchJTJobs(
                jobType,
                this.state.location,
            );

            this.triggerGratificationEvents(jtJobsArr, jobJobsError, { cat: 'jt' }, experiment);

            return jtJobsArr;
        }
    };

    fetchOverlayJobDetails(jobsQuery) {
        const overlayJobAu = memoryStore.getItem(memoryStoreKeys.JOB_OVERLAY_INT_AU);
        const overlayJobAd = overlayJobAu && overlayJobAu.ad;
        console.log('[Jobs] Fetching Jobs for overlay sourced ', overlayJobAd);

        if (!overlayJobAd) {
            console.warn('[Jobs] Failed to get Overlay Job Source');

            this.setState({
                overlayJobsState: {
                    ...this.state.overlayJobsState,
                    isLoading: false,
                    jobsList: [],
                    isError: true,
                },
            });

            return false;
        }

        this.setState({
            overlayJobsState: {
                ...this.state.overlayJobsState,
                isLoading: true,
                jobsList: [],
            },
        });
        const query = {
            ...jobsQuery,
            advertisers: [{ ...overlayJobAu.meta, uuid: this.state.overlayJobsState.uuid }],
        };

        JobSearchDataSource.fetchJobsDataForQueryMultiSource(query)
            .then(jobsData => {
                console.log('[JOBS]: got overlay jobs data - ', jobsData);

                let { jobs = [], experiment } = jobsData;

                this.setState({
                    overlayJobsState: {
                        ...this.state.overlayJobsState,
                        isLoading: false,
                        jobsList: jobs,
                        isError: false,
                        experiment: experiment,
                    },
                });
            })
            .catch(err => {
                console.error('[JOBS]: Error Fetching Overlay Job', err);
                this.setState({
                    overlayJobsState: {
                        ...this.state.overlayJobsState,
                        isLoading: false,
                        jobsList: [],
                        isError: true,
                    },
                });
            });
    }

    getSmsOverlayJobs = () => {
        const overlayJobsState = this.state.overlayJobsState;

        if (Array.isArray(overlayJobsState.jobsList) && overlayJobsState.jobsList[0])
            return overlayJobsState.jobsList;

        if (overlayJobsState.isLoading) return null;

        if (overlayJobsState.isError || !Array.isArray(overlayJobsState.jobsList))
            return Array.isArray(this.state.jobsList) ? this.state.jobsList : null;

        return Array.isArray(this.state.jobsList) ? this.state.jobsList : null;
    };

    getJobSource = selectedJob => {
        const overlayJobAu = memoryStore.getItem(memoryStoreKeys.JOB_OVERLAY_INT_AU);

        const adUnitMap = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU);
        const selectedJobUuid = Utils.getObjVal(selectedJob, ['in_uuid']);

        if (
            !Utils.isNull(overlayJobAu) &&
            !Utils.isEmptyStr(selectedJobUuid) &&
            selectedJobUuid === Utils.getObjVal(overlayJobAu, ['uuid'])
        ) {
            console.log('[Jobs]Selecting Overlay Job Au', overlayJobAu);

            return overlayJobAu.ad;
        }

        console.log('[Jobs]Selecting SERP Job Au', Utils.getObjVal(adUnitMap, [selectedJobUuid]));

        return Utils.getObjVal(adUnitMap, [selectedJobUuid]);
    };

    fallbackSelectJobSourceAdvertiser = async () => {
        const adUnitId = getFallBackPageListingsAuId() || '';
        const urlAdSource = Utils.getValForKeyFromCurrentUrl('source');
        let jobAdsSource = 'upward';

        if (
            [
                Constants.ADVERTISERS.TALROO,
                Constants.ADVERTISERS.UPWARD,
                Constants.ADVERTISERS.APPCAST,
                Constants.ADVERTISERS.ZIPRECRUITER,
            ].includes(urlAdSource)
        ) {
            await Promise.resolve();
            console.log('[Jobs] Got Source from URL', urlAdSource);
            jobAdsSource = urlAdSource;
            setupAdSourceForSingleSource(urlAdSource);
        } else if (adUnitId) {
            const singleAuResp = await fetchAdvertiser(adUnitId);
            // Set Job Ad Source across other JobResults

            if (singleAuResp && singleAuResp.source) {
                jobAdsSource = singleAuResp.source;
                memoryStore.setItem(
                    memoryStoreKeys.SINGLE_JOB_AD_AU,
                    singleAuResp && singleAuResp.ad,
                );
            } else {
                jobAdsSource = Constants.ADVERTISERS.UPWARD;
            }

            // Set Job Ad Sources for job results
            const adSourcesResponses = [singleAuResp];

            const adSourceIds = [];
            const adSourceAus = {};
            const adSourceMeta = [];
            const advUuidMap = {};
            const dupeMap = {};
            adSourcesResponses.forEach(resp => {
                if (resp && resp.meta && resp.meta.uuid && !dupeMap[resp.meta.uuid]) {
                    const { ad, meta, advId } = resp;

                    if (ad && ad.dealId) {
                        meta['dealId'] = ad.dealId;
                    }
                    dupeMap[meta.uuid] = true;
                    adSourceIds.push(meta.uuid);
                    adSourceAus[meta.uuid] = ad;
                    adSourceMeta.push(meta);
                    advUuidMap[meta.uuid] = advId;
                }
            });

            if (adSourceIds.length) {
                memoryStore.setItem(memoryStoreKeys.JOB_AD_SOURCE_IDS, adSourceIds);

                const jobAddAU = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU) || {};
                memoryStore.setItem(memoryStoreKeys.JOB_AD_AU, { ...jobAddAU, ...adSourceAus });

                const jobAdMetaData = memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA) || [];
                const jobAdMetaDataObject = {};
                [...jobAdMetaData, ...adSourceMeta].map(
                    jobData => (jobAdMetaDataObject[jobData.uuid] = jobData),
                );

                memoryStore.setItem(
                    memoryStoreKeys.JOB_AD_META_DATA,
                    Object.values(jobAdMetaDataObject),
                );
                const jobAddUuidMap =
                    memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP) || {};
                memoryStore.setItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP, {
                    ...jobAddUuidMap,
                    ...advUuidMap,
                });
            }
        } else {
            // Fallback ad source
            setupAdSourceForSingleSource(Constants.ADVERTISERS.UPWARD);
        }

        console.log(
            '[Jobs] Payload',
            memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS),
            memoryStore.getItem(memoryStoreKeys.JOB_AD_AU),
            memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA),
            memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP),
        );

        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_ADS_SOURCE, jobAdsSource);
    };

    updateJobsDataForQuery(jobsQuery) {
        this.setState({
            isLoadingJobs: true,
        });

        const extraData = {
            placement: this.getPathDetailsForJobResultApi(),
        };

        const query = {
            ...jobsQuery,
            advertisers: wrapAdvertisersWithExtraData(
                pickAdvertisersDataForJobResults(
                    memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS),
                ),
                extraData,
            ),
        };
        JobSearchDataSource.fetchJobsDataForQueryMultiSource(query)
            .then(async jobsData => {
                console.log('JOBS: got jobs data - ', jobsData);

                const jtJobsArr = await this.getJtJobs();

                let { totalCount: totalJobs, error, experiment } = jobsData;

                if (Utils.isNull(totalJobs)) {
                    totalJobs = 0;
                }
                let { jobs } = jobsData;
                jobs = jobs && jobs.map(job => (job = { ...job, experiment: experiment }));

                let jobsList =
                    jobs &&
                    jobs.filter(
                        job =>
                            job &&
                            job.company &&
                            !job.company.toLowerCase().includes('myjobscorner'),
                    );
                let resultTitle = '';

                if (Utils.isEmptyArr(jobsList)) {
                    this.triggerGratificationCallFailure(error);
                    await this.fallbackSelectJobSourceAdvertiser();
                    jobsData = (await this.fallBackUpdateJobsDataForEmptySearchQuery()) || [];
                    jobsList = jobsData.jobsList;
                    totalJobs = jobsData.totalJobs;
                }

                const replaceSearchMacro = searchText => {
                    if (Utils.isNull(searchText)) return '';

                    let replacedText = searchText;

                    if (replacedText.includes('%%SEARCH_TERM%%')) {
                        const searchTerm =
                            this.state.searchTerm !== 'Jobs near me'
                                ? Utils.getCapitalizeStr(
                                      Utils.cleanH1Keyword(this.state.searchTerm),
                                  )
                                : '';

                        replacedText = replacedText.replace('%%SEARCH_TERM%%', searchTerm);
                    }

                    if (replacedText.includes('%%SEARCH_LOCATION%%')) {
                        const searchLocation = this.state.location;
                        replacedText = replacedText.replace('%%SEARCH_LOCATION%%', searchLocation);
                    }

                    if (replacedText.includes('%%TOTAL_JOBS%%')) {
                        replacedText = replacedText.replace('%%TOTAL_JOBS%%', totalJobs);
                    }

                    return replacedText;
                };

                if (jobsList.length > 0) {
                    resultTitle = FeatureValueHelper.getSearchTerm()
                        ? `<div class="result-title-wrapper">
                        <div class="result-title">
                        <span class="nrml-txt 
                        ">${replaceSearchMacro(FeatureValueHelper.getSearchTerm())}</span>
                    </div>
                </div>`
                        : this.newFulfillmentPage || this.isFulfillmentDesignPhaseTwo
                        ? `${totalJobs} Results for ${
                              this.state.searchTerm !== 'Jobs near me'
                                  ? `${Utils.getCapitalizeStr(
                                        Utils.cleanH1Keyword(this.state.searchTerm),
                                    )} `
                                  : ''
                          } ${this.state.location ? 'near' : ''} ${this.state.location}`
                        : AbTestHelper.darkSERPTest()
                        ? `<div class="result-title-wrapper test-dark-txt">${totalJobs} Results for ${
                              this.state.searchTerm !== 'Jobs near me'
                                  ? `${Utils.getCapitalizeStr(this.state.searchTerm)} `
                                  : ''
                          } jobs ${this.state.location ? 'near' : ''} ${
                              jobsList && jobsList.length && jobsList[0].location
                          }</div>`
                        : AbTestHelper.newSERPTest()
                        ? `<div class="result-title-wrapper test-nrml-txt">${totalJobs} Results for ${
                              this.state.searchTerm !== 'Jobs near me'
                                  ? `${Utils.getCapitalizeStr(this.state.searchTerm)} `
                                  : ''
                          } jobs ${this.state.location ? 'near' : ''} ${
                              jobsList && jobsList.length && jobsList[0].location
                          }</div>`
                        : `<div class="result-title-wrapper">
                            <div class="result-title ${
                                AbTestHelper.serpRevamp() && 'jobsearchText'
                            }">
                            <span class="nrml-txt ${
                                AbTestHelper.serpRevamp() && 'jobsearchText'
                            }">Search Results for <span class="highlight">${
                              this.state.searchTerm !== 'Jobs near me'
                                  ? `${Utils.getCapitalizeStr(
                                        Utils.cleanH1Keyword(this.state.searchTerm),
                                    )} `
                                  : ''
                          }</span> Near <span class="highlight">${this.state.location}</span></span>
                        </div>
                    </div>`;

                    if (
                        this.mergedJobFeed &&
                        !(
                            AbTestHelper.listingsInBgFlow() &&
                            !this.props.location.pathname.includes(
                                RoutePrefixHandler.getRouteTemplateForPath(
                                    Constants.PAGE_ENDPOINTS.GRATIFICATION,
                                ),
                            )
                        )
                    ) {
                        this.dispatchAdImpEvents(jobsList);
                    }
                }

                this.triggerGratificationEvents(jobsList, {}, {}, experiment);

                if (
                    AbTestHelper.listingsInBgFlow() &&
                    !this.props.location.pathname.includes(
                        RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.GRATIFICATION,
                        ),
                    ) &&
                    jobsList.length > 0
                ) {
                    IdStore.storeIdForKey('listings-jb', JSON.stringify(jobsList));
                }

                this.setState(
                    {
                        isLoadingJobs: false,
                        jobsList: Utils.mergePriorArray(jtJobsArr, jobsList).filter(item => !!item),
                        experiment,
                        resultTitle,
                        totalJobs,
                        shouldScroll: false,
                    },
                    () => {
                        this.shouldMakeEmptySearchQuery(totalJobs);
                    },
                );
            })
            .catch(e => {
                this.triggerGratificationCallFailure(e);
            });
    }

    componentWillReceiveProps(nextProps) {
        let jobsList = [];

        try {
            jobsList = JSON.parse(IdStore.fetchIdForKey('listings-jb'));
        } catch (e) {
            console.log("Couldn't parse job listings", e);
        }

        if (
            this.state.jobsList &&
            this.state.jobsList.length <= 0 &&
            jobsList &&
            jobsList.length > 0
        ) {
            this.setState({
                isLoadingJobs: false,
                jobsList: jobsList,
            });

            return;
        }

        if (
            AbTestHelper.listingsInBgFlow() &&
            !this.props.location.pathname.includes(
                RoutePrefixHandler.getRouteTemplateForPath(Constants.PAGE_ENDPOINTS.GRATIFICATION),
            ) &&
            this.state.jobsList &&
            this.state.jobsList.length > 0
        ) {
            return;
        }

        if (
            false === Utils.isNull(nextProps.history) &&
            false === Utils.isNull(nextProps.history.location) &&
            false === Utils.isNull(nextProps.history.location.state) &&
            false === Utils.isNull(nextProps.history.location.state.index) &&
            this.state.currentPageNum !== nextProps.history.location.state.index
        ) {
            console.log('PAGE: Did receive props - ', nextProps.history.location.state.index);
            this.handlePageChange(nextProps.history.location.state.index);
        }

        if (Utils.isNull(nextProps.history.location.state)) {
            this.setState({
                redirectToOffers: true,
            });

            IdStore.storeIdForKey('redirectToOffers', true);
        }
    }

    handlePageChange = pageNumber => {
        console.log(`active page is ${pageNumber}`);
        this.updateJobsDataForQuery(
            JobSearchDataSource.createJobsQueryForTerm({
                term: this.state.queryTerm,
                pageNum: pageNumber,
                pageSize: null,
                location: this.state.location,
            }),
        );
        this.setState(
            {
                shouldScroll: true,
                activePage: pageNumber,
                currentPageNum: pageNumber,
            },
            () => {
                if (
                    this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.state &&
                    this.props.history.location.state.index &&
                    this.props.history.location.state.index !== pageNumber
                ) {
                    this.props.history.push({
                        pathname: window.location.pathname,
                        search: this.props.history.location.search,
                        state: {
                            index: pageNumber,
                        },
                        hash: `${pageNumber}`,
                    });
                }
            },
        );
    };

    handleClose = e => {
        this.setState({
            showInterstitialAds: false,
        });
    };

    handlePopupCloseButton = e => {
        this.handlePopClose();
    };

    handlePopClose = e => {
        this.setState({ showDialog: false });
    };

    searchHandler = term => {
        if (Utils.isEmptyObj(term)) {
            console.log('JOBS: Not searching for an empty string ');

            return;
        }
        const initPageNum = this.getInitPageNum(
            this.isEmailOrSMSMedium,
            this.isJobImpDedup,
            term.searchTerm,
            this.state.selectedAdvertiser,
        );
        this.setState({
            searchTerm: term.searchTerm,
            location: term.location,
            currentPageNum: initPageNum,
            queryTerm: term.searchTerm,
        });

        this.props.history.push({
            pathname: window.location.pathname,
            search: this.props.history.location.search,
            state: {
                index: initPageNum,
            },
            hash: `${initPageNum}`,
        });

        // let sponsoredAdsQuery = this.showInHouseAds ? SponsoredAdsDataSource.createSponsoreJobsQueryForTerm(term.searchTerm) : null;
        this.updateJobsDataForQuery(
            JobSearchDataSource.createJobsQueryForTerm({
                term: term.searchTerm,
                pageNum: 1,
                pageSize: null,
                location: term.location,
            }),
        );
    };

    handleFbGoogleInterstitialClick = applyUrl => {
        this.setState({
            showFbGoogleInterstitial: true,
        });
        this.showFbGoogleInterstitialUrl = applyUrl;
    };

    handleFbGoogleInterstitialCrossClick = (continueClick = false) => {
        this.setState({
            showFbGoogleInterstitial: false,
        });
        window.open(this.showFbGoogleInterstitialUrl, '_blank');
        this.updateAdvertiser();

        if (continueClick) {
            this.showFbGoogleInterstitial = false;
        }
    };

    componentWillUnmount() {
        window.removeEventListener(
            Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED,
            this.sessionInitializedEventHandler,
        );

        window.removeEventListener('popstate', this.onPopState);
        const jobSense = document.getElementById('jobsense1');

        if (jobSense) {
            jobSense.removeEventListener('click', this.jobSenseEventListener);
        }
    }

    getBackgroundImages = () => {
        let listingsBgXmlImages = {
            mobile: '',
            tablet: '',
            web: '',
        };
        const ListingsBgXMLTestMobileImage = ConfigProvider.Features.getFeature(
            FeatureMap.ListingsBgXmlTestMobileImage,
        );
        const listingsBgXMLTestMobileImage =
            ListingsBgXMLTestMobileImage && ListingsBgXMLTestMobileImage.value
                ? decodeURIComponent(ListingsBgXMLTestMobileImage.value)
                : '';

        const ListingsBgXMLTestTabletImage = ConfigProvider.Features.getFeature(
            FeatureMap.ListingsBgXmlTestTabletImage,
        );
        const listingsBgXMLTestTabletImage =
            ListingsBgXMLTestTabletImage && ListingsBgXMLTestTabletImage.value
                ? decodeURIComponent(ListingsBgXMLTestTabletImage.value)
                : '';

        const ListingsBgXMLTestWebImage = ConfigProvider.Features.getFeature(
            FeatureMap.ListingsBgXmlTestWebImage,
        );
        const listingsBgXMLTestWebImage =
            ListingsBgXMLTestWebImage && ListingsBgXMLTestWebImage.value
                ? decodeURIComponent(ListingsBgXMLTestWebImage.value)
                : '';

        listingsBgXmlImages.mobile = listingsBgXMLTestMobileImage;
        listingsBgXmlImages.tablet = listingsBgXMLTestTabletImage;
        listingsBgXmlImages.web = listingsBgXMLTestWebImage;

        return listingsBgXmlImages;
    };

    setTint = () => {
        let imageContainer = document.querySelector('.listings-bg-dialog .MuiDialog-scrollPaper');

        if (imageContainer.classList) {
            imageContainer.classList.add('image-in-bg');
        }
    };

    renderTalrooAdHtml = () => {
        return ReactHtmlParser(`<ins
        class="talrooads"
           data-ad-id=${
               AbTestHelper.talrooWidgetTest()
                   ? '1d7274a2-749d-4020-9148-efa198c80efb'
                   : AbTestHelper.talrooAdRelatedSearchesTest()
                   ? 'b86c96ae-b1f2-46e7-83c1-bb25429ef54c'
                   : 'cb60ad5f-0245-4e92-aaec-c833fd1a522f'
           }
           data-ad-cid="Y3VzdG9tZXJfaWQ6NDA1OQ=="
           data-input-keyword="${this.state.searchTerm}"
           style="display:inline-block;width:100%;height:auto">
    </ins>`);
    };

    createJid = (company, title, location) => {
        return `${company || ''} ${title || ''} ${location || ''}`;
    };

    selectInterstitialJob = () => {
        const overlayJobs = this.getSmsOverlayJobs() || [];
        let jobIndex = 0;

        for (jobIndex = 0; jobIndex < overlayJobs.length; jobIndex++) {
            const jid = this.createJid(
                overlayJobs[jobIndex].company,
                overlayJobs[jobIndex].title,
                overlayJobs[jobIndex].location,
            );

            const isJobVisited =
                !Utils.isEmptyStr(Utils.getCookie(jid)) &&
                Utils.getCookieNamesWithValue(jid) &&
                Utils.getCookieNamesWithValue(jid).length !== 0;

            if (isJobVisited === false) {
                return overlayJobs[jobIndex];
            }
        }

        /**
         * This is for repeating the job interstitial cycle from the start.
         * It deletes all the cookies when all of the jobs are visited.
         */
        for (jobIndex = 0; jobIndex < overlayJobs.length; jobIndex++) {
            const jid = this.createJid(
                overlayJobs[jobIndex].company,
                overlayJobs[jobIndex].title,
                overlayJobs[jobIndex].location,
            );

            Utils.deleteCookie(jid);
        }

        return overlayJobs[0];
    };

    async fetchAndUpdateLocDetails(zip) {
        try {
            let user = await UserDetailsDataSource.fetchLocWithUserZip(zip);

            if (!Utils.isNull(user)) {
                const { city, country, state } = user;
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_CITY, city);
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_COUNTRY, country);
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_REGION, state);
            }
        } catch (e) {
            console.error(e);
        }
    }

    handleSubmitCb = async formData => {
        console.log('[Log] Starting Submit Form');

        if (false === Utils.isEmptyObj(formData)) {
            this.aggregatedFormResp = Object.assign(this.aggregatedFormResp, formData);
        }
        console.log('FORM: Got form data - ', this.aggregatedFormResp);
        // Store the form-data
        UserDataStore.storeUserDataInCache(formData);

        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.PREFERENCE_ZIP_CODE, this.state.zipCode);
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.PREFERENCE_JOB_TYPE, this.state.jobType);

        eventManager.sendEvent(
            Constants.EVENT.PREF_POPUP_CTA_CLICK,
            this.eventData,
            Constants.EVENT_TYPE.GRAITIFICATION,
        );
    };

    handleSubmit = async e => {
        let { jobType: job_type, zipCode: zip, selectedEmployer } = this.state;

        await this.fetchAndUpdateLocDetails(zip);
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE, zip);
        AbManager.genNetSpendTest();

        const formData = {
            job_type,
            zip,
        };
        const jtLower = job_type.toLowerCase();
        FormHelpers.storeJobsInfoForForm(
            job_type,
            Utils.isJobTypeAllowed(jtLower) ? jtLower : selectedEmployer,
        );

        this.updatedKw(job_type);
        this.updatedZip(zip);

        if (false === Utils.isNull(this.handleSubmitCb)) {
            console.log('[Log] Submitting FORM');
            this.handleSubmitCb(formData);
        } else {
            console.log(
                'ERR: form-data cannot be sent since (handleSubmitCb) cbWithData callback is not available',
            );
        }
        this.handlePopClose();
    };

    firePrefPageImpEvent = () => {
        eventManager.sendEvent(
            Constants.EVENT.PREF_POPUP_SHOWN,
            this.eventData,
            Constants.EVENT_TYPE.GRAITIFICATION,
        );
    };

    getEventData() {
        let email = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EMAIL) || '';
        let firstName = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.FNAME) || '';
        let lastName = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.LNAME) || '';
        let gender = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.GENDER) || '';
        let addr = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.ADDRESS);

        const urlPhone = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE) || '',
        );

        let phoneNo = urlPhone;

        if (!Utils.isEmptyStr(urlPhone)) {
            phoneNo = PhoneValidator.formatPhoneNumber(urlPhone);
        }

        let uDob = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.DOB) || '';
        uDob = Utils.filterInvalidUrlInput(uDob);
        let dob = null;

        if (uDob) {
            const dobMoment = Utils.parseDateFromUrl();
            dob =
                dobMoment &&
                dobMoment.isValid &&
                dobMoment.isValid() &&
                !DateUtils.isAboveTeenage(dobMoment)
                    ? dobMoment
                    : '';
        }

        firstName = Utils.filterInvalidUrlInput(firstName);
        lastName = Utils.filterInvalidUrlInput(lastName);
        gender = Utils.filterInvalidUrlInput(gender);
        email = Utils.filterInvalidUrlInput(email);

        if (gender) {
            switch (gender) {
                case 'm':
                case 'male':
                    gender = 'male';
                    break;
                case 'f':
                case 'female':
                    gender = 'female';
                    break;
                default:
                    gender = null;
                    break;
            }
        }

        let address = {
            line1: addr || '',
            line2: '',
        };

        let eventData = {
            first_name: firstName,
            last_name: lastName,
            gender: gender,
            dob: dob ? dob.format('YYYY-MM-DD') : '',
            address: getConcatAddressField(address),
            phone_number: phoneNo,
            email: email,
        };

        return eventData;
    }

    handleClick = e => {
        let { jobType: job_type, zipCode: zip } = this.state;

        const errZip = UserDataValidator.isValidZipCode(zip);
        const errJT = UserDataValidator.isValidJobType(job_type);

        if (errJT || errZip) {
            this.setState({
                success: {
                    zipCode: !errZip,
                    jobType: !errJT,
                },
            });

            return false;
        }

        return true;
    };

    handleCrossClick = e => {
        eventManager.sendEvent(
            Constants.EVENT.PREF_POPUP_CLOSED,
            this.eventData,
            Constants.EVENT_TYPE.GRAITIFICATION,
        );
        this.handlePopClose();
    };

    handleZipCodeFieldFocused = event => {
        this.isZipCodeFieldFocused = true;
    };

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
        let { success } = this.state;

        if (name === 'jobType') {
            const errJT = UserDataValidator.isValidJobType(value);
            this.setState({
                success: {
                    ...success,
                    jobType: !errJT,
                },
            });
        } else if (name === 'zipCode') {
            const errZip = UserDataValidator.isValidZipCode(value);
            this.setState({
                success: {
                    ...success,
                    zipCode: !errZip,
                },
            });
        }
    };

    render() {
        const { classes } = this.props;
        const sid = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.SURVEY_ID);
        const source = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE);
        const MJCHomeSitePath = source === 'mjc' && sid === '57';
        const phoneSubbed = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PHONE_SUB) === 'true';
        const emailSubbed = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.EMAIL_SUB) === 'true';
        const { jobType } = Utils.getJobType();
        const interstitialJob = this.selectInterstitialJob();
        const text = brandedFlowValues();
        const showAmazonJobsImage =
            !AbTestHelper.ShowWarmupQuestion() &&
            AbTestHelper.showListingsBgXMLTestV2() &&
            [
                Constants.PAGE_ENDPOINTS.LANDING,
                Constants.PAGE_ENDPOINTS.SURVEY,
                Constants.PAGE_ENDPOINTS.ADS,
            ].includes(this.props.match.path);
        const showStaticBgImg =
            !AbTestHelper.ShowWarmupQuestion() &&
            AbTestHelper.staticImgListingsBgTest() &&
            [Constants.PAGE_ENDPOINTS.LANDING].includes(this.props.match.path);

        const { mobile = '', tablet = '', web = '' } =
            (showAmazonJobsImage && this.getBackgroundImages()) ||
            (showStaticBgImg && AbTestHelper.getStaticListingsBgImgs());

        let arrowStyle = null;

        if (AbTestHelper.serpRevamp()) {
            arrowStyle = {
                backgroundColor: '#ffffff',
                color: '#1b4b6c',
            };
        }

        if (this.state.isSettingUp) {
            return <LoaderView loaderText="Loading..." />;
        }

        if (
            this.isbackButtonClicked &&
            this.isNeuvooWidgetTestActive &&
            (this.state.nuevooWidgetLoading || this.state.showNeuvooWidget)
        ) {
            if (this.state.nuevooWidgetLoading) {
                return (
                    <div
                        style={{
                            display: 'flex',
                            height: 'calc(100vh - 80px)',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <CircularProgress />
                    </div>
                );
            } else {
                return <div id="jobsense1" style={{ width: '100%' }}></div>;
            }
        } else {
            return showAmazonJobsImage || showStaticBgImg ? (
                <>
                    {AbTestHelper.BrandedLanderTest() && (
                        <Grid className={classes.topBar}>
                            {text && text.TopBarText ? text.TopBarText : 'Hiring Now!'}
                        </Grid>
                    )}
                    <div>
                        <LazyLoadComponent afterLoad={showStaticBgImg ? () => {} : this.setTint}>
                            <picture className={classes.picture}>
                                <source media="(min-width:1280px)" srcSet={web} />
                                <source media="(min-width:600px)" srcSet={tablet} />
                                <img
                                    alt={`${Utils.getCapitalizeStr(jobType)} Jobs`}
                                    className={classes.bgImg}
                                    src={mobile}
                                />
                            </picture>
                        </LazyLoadComponent>
                    </div>
                </>
            ) : (
                <React.Fragment>
                    {this.props.showAds ? (
                        <InterstitialAds
                            handleClose={this.handleClose}
                            jobs={this.state.jobsList}
                            open={
                                this.state.jobsList &&
                                this.state.jobsList.length > 0 &&
                                this.props.showAds &&
                                this.state.showInterstitialAds
                            }
                        />
                    ) : null}
                    {AbTestHelper.talrooWidgetTest() ? (
                        <div className={classes.talrooAdContainer}>{this.renderTalrooAdHtml()}</div>
                    ) : (
                        <>
                            <div>
                                <JobsSearchView
                                    activePage={this.state.activePage}
                                    adsData={this.state.adsData}
                                    currPageNumber={this.state.pageNumber}
                                    defaultJobBrand={this.state.defaultJobBrand}
                                    experiment={this.state.experiment}
                                    handleCloseIcon={this.handleCloseIcon}
                                    handleCloseSubmit={this.handleCloseSubmit}
                                    handleFbGoogleInterstitialClick={
                                        this.handleFbGoogleInterstitialClick
                                    }
                                    isEmailOrSMSMedium={this.isEmailOrSMSMedium}
                                    isFulfillmentDesignPhaseTwo={this.isFulfillmentDesignPhaseTwo}
                                    isJobDedup={this.isJobClickDedup}
                                    isLoadingJobs={this.state.isLoadingJobs}
                                    jobsList={this.state.jobsList}
                                    location={this.state.location}
                                    mergedJobFeed={this.mergedJobFeed}
                                    newFulfillmentPage={this.newFulfillmentPage}
                                    onSubmitClick={this.handleOrganicAdClick}
                                    open={this.state.showXmlPopup}
                                    queryTerm={this.state.queryTerm}
                                    resultTitle={this.state.resultTitle}
                                    scrollPageOnPagination={this.state.shouldScroll}
                                    searchCb={this.searchHandler.bind(this)}
                                    searchParamChange={this.handleSearchParamChange}
                                    searchTerm={this.state.searchTerm}
                                    selectedAdvertiser={this.state.selectedAdvertiser}
                                    shouldSendEvents={this.state.shouldSendEvents}
                                    // showInHouseAds={this.showInHouseAds}
                                    showFbGoogleInterstitial={this.showFbGoogleInterstitial}
                                    updateAdvertiser={this.updateAdvertiser}
                                />
                                {AbTestHelper.talrooAdRelatedTest() && (
                                    <div className={classes.talrooAdContainer}>
                                        {this.renderTalrooAdHtml()}
                                    </div>
                                )}
                                <div className="pagination-wrapper">
                                    <Pagination
                                        currentPage={this.state.currentPageNum}
                                        limit={this.state.pageSize}
                                        pageCount={AbTestHelper.serpRevamp() ? 3 : 5}
                                        total={Number(this.state.totalJobs)}>
                                        {({
                                            pages,
                                            currentPage,
                                            hasNextPage,
                                            hasPreviousPage,
                                            previousPage,
                                            nextPage,
                                            totalPages,
                                            getPageItemProps,
                                        }) => (
                                            <div
                                                className={`pagination-inner-wrapper ${
                                                    AbTestHelper.serpRevamp() &&
                                                    'pagination-inner-wrapper-v'
                                                } ${
                                                    AbTestHelper.newSERPTest() &&
                                                    'pagination-inner-wrapper-newSERP'
                                                }
                                            ${
                                                AbTestHelper.darkSERPTest() &&
                                                'pagination-inner-wrapper-darkSERP'
                                            }`}>
                                                {hasPreviousPage && (
                                                    <button
                                                        {...getPageItemProps({
                                                            pageValue: previousPage,
                                                            onPageChange: this.handlePageChange,
                                                            style: arrowStyle,
                                                        })}>
                                                        {'<'}
                                                    </button>
                                                )}
                                                {pages.map(page => {
                                                    let activePage = null;

                                                    if (currentPage === page) {
                                                        if (AbTestHelper.serpRevamp()) {
                                                            activePage = {
                                                                backgroundColor: '#1b4b6c',
                                                                color: '#ffffff',
                                                            };
                                                        } else if (AbTestHelper.darkSERPTest()) {
                                                            activePage = {
                                                                backgroundColor: '#272628',
                                                                color: '#BCBCBC',
                                                                border: 'none',
                                                            };
                                                        } else if (AbTestHelper.newSERPTest()) {
                                                            activePage = {
                                                                backgroundColor: '#E6E6E6',
                                                                color: '#8C8C8C',
                                                            };
                                                        } else {
                                                            activePage = {
                                                                backgroundColor: '#4a90e2',
                                                                color: '#fff',
                                                            };
                                                        }
                                                    }

                                                    return (
                                                        <button
                                                            {...getPageItemProps({
                                                                pageValue: page,
                                                                key: page,
                                                                style: activePage,
                                                                onPageChange: this.handlePageChange,
                                                            })}>
                                                            {page}
                                                        </button>
                                                    );
                                                })}
                                                {hasNextPage && (
                                                    <button
                                                        {...getPageItemProps({
                                                            pageValue: nextPage,
                                                            onPageChange: this.handlePageChange,
                                                            style: arrowStyle,
                                                        })}>
                                                        {'>'}
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </Pagination>
                                </div>
                                {(FeatureValueHelper.getSponsoredMarketplaceVariant() === 'v1' ||
                                    FeatureValueHelper.getSponsoredMarketplaceVariant() === 'v2') &&
                                    this.state.jobsList &&
                                    this.state.jobsList.length &&
                                    window.location.pathname ===
                                        Constants.PAGE_ENDPOINTS.GRATIFICATION && (
                                        <SponsoredMarketplace
                                            totalJobsListing={this.state.jobsList.length}
                                        />
                                    )}
                            </div>
                            {AbTestHelper.emailAlertsCTA() && <EmailPopupToggleContainer />}
                            {AbTestHelper.preferencePopupTest() && (
                                <Dialog
                                    className="listings-bg-dialog listings-bg-offer-path email-preference-dialog"
                                    open={this.state.showDialog}>
                                    <DialogContent>
                                        <PreferencePopup
                                            handleClick={this.handleClick}
                                            handleCrossClick={this.handleCrossClick}
                                            handleInputChange={this.handleInputChange}
                                            handleSubmit={this.handleSubmit}
                                            handleZipCodeFieldFocused={
                                                this.handleZipCodeFieldFocused
                                            }
                                            jobType={this.state.jobType}
                                            selectedEmployer={this.state.selectedEmployer}
                                            success={this.state.success}
                                            zipCode={this.state.zipCode}
                                        />
                                    </DialogContent>
                                </Dialog>
                            )}
                            {MJCHomeSitePath && (!emailSubbed || !phoneSubbed) && (
                                <Dialog
                                    className="mjc-pop-main-wrapper"
                                    open={this.state.showDialog}>
                                    <DialogContent>
                                        <UserInfoInterstitial
                                            emailSubbed={emailSubbed}
                                            handlePopClose={this.handlePopClose}
                                            phoneSubbed={phoneSubbed}
                                        />
                                    </DialogContent>
                                </Dialog>
                            )}
                            {this.showFbGoogleInterstitial && this.state.showFbGoogleInterstitial && (
                                <Dialog open={this.state.showFbGoogleInterstitial}>
                                    <DialogContent style={{ padding: 0 }}>
                                        <FbGoogleFbGoogleEmailRegistration
                                            handleFbGoogleInterstitialCrossClick={
                                                this.handleFbGoogleInterstitialCrossClick
                                            }
                                        />
                                    </DialogContent>
                                </Dialog>
                            )}
                            {this.shouldDisplaySmsOverlay() && interstitialJob && (
                                <Dialog
                                    className="mjc-pop-main-wrapper"
                                    open={this.state.showDialog && interstitialJob}>
                                    <SmsOverlay
                                        ad={this.getJobSource(interstitialJob)}
                                        createJid={this.createJid}
                                        data={interstitialJob}
                                        experiment={this.state.overlayJobsState.experiment}
                                        handleClose={this.handlePopupCloseButton}
                                        handleKwClick={this.updatedKw}
                                    />
                                </Dialog>
                            )}
                        </>
                    )}
                </React.Fragment>
            );
        }
    }
}
const styles = theme => ({
    bgImageContainer: {
        ...(AbTestHelper.staticImgListingsBgTest() && {
            height: '100%',
        }),
    },
    picture: {
        ...(AbTestHelper.staticImgListingsBgTest() && {
            width: '100%',
            height: AbTestHelper.BrandedLanderTestv2() ? '350px' : '100%',
            display: 'flex',
            [theme.breakpoints.down('md')]: {
                height: AbTestHelper.BrandedLanderTestv2() ? '250px' : '100%',
            },
        }),
    },
    bgImg: {
        ...(AbTestHelper.staticImgListingsBgTest()
            ? { width: '100%', height: 'auto', objectFit: 'cover' }
            : { width: '100%', height: 'auto' }),
    },
    talrooAdContainer: {
        width: 'calc(100% - 30px)',
        [theme.breakpoints.up('md')]: {
            width: '1140px',
            margin: '0 auto',
        },
    },
    topBar: {
        width: '100%',
        height: '60px',
        backgroundColor: !Utils.isEmptyStr(brandedFlowValues().TopBarBgColor)
            ? brandedFlowValues().TopBarBgColor
            : '#007ECA',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 700,
        color: '#fff',
        fontSize: '24px',
        [theme.breakpoints.down('md')]: {
            height: '50px',
        },
    },
});
export default withStyles(styles)(withRouter(JobsSearchContainer));
