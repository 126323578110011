import React from 'react';
import { AnimatedSwitch } from 'react-router-transition';
import { Route, Redirect } from 'react-router-dom';
import Constants from 'common/constants';
import RoutePrefixHandler from 'common/routePrefixHandler';
import { ErrorPage } from 'components/error';
import LandingApp from 'apps/landingApp';
import LoaderView from 'components/loader/index';
import Redirecting from 'components/redirecting/index';
import { getFlavor } from 'flavors.macro';

const flavor = getFlavor('layout-theme');

const SurveyApp = React.lazy(() => import('./apps/surveyApp'));
const GratificationApp = React.lazy(() => import('./apps/gratificationApp'));
const AdsApp = React.lazy(() => import('./apps/adsApp'));
const ContactUs = React.lazy(() => import(`flavors/${flavor}/contactus`));
const Unsubscribe = React.lazy(() => import(`flavors/${flavor}/unsubscribe`));
const EmailOpt = React.lazy(() => import(`flavors/${flavor}/emailopt`));
const EmailPreference = React.lazy(() => import(`./apps/emailPreference`));
const DetailsConfirmation = React.lazy(() => import('./apps/detailsConfirmation'));

export default class Routes extends React.Component {
    render() {
        return (
            <AnimatedSwitch
                atActive={{ opacity: 1 }}
                atEnter={{ opacity: 0 }}
                atLeave={{ opacity: 0 }}
                className="switch-wrapper">
                <React.Suspense fallback={<LoaderView />}>
                    <Route
                        component={LandingApp}
                        exact
                        path={RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.LANDING,
                        )}
                    />
                    <Route
                        component={SurveyApp}
                        exact
                        path={RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.SURVEY,
                        )}
                    />
                    <Route
                        component={AdsApp}
                        exact
                        path={RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.ADS,
                        )}
                    />
                    <Route
                        component={GratificationApp}
                        exact
                        path={RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.GRATIFICATION,
                        )}
                    />
                    <Route
                        component={EmailOpt}
                        exact
                        path={RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.SUBSCRIBE,
                        )}
                    />
                    <Route
                        component={EmailPreference}
                        exact
                        path={RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.EMAIL_PREFERENCE,
                        )}
                    />
                    <Route
                        component={Unsubscribe}
                        exact
                        path={RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.UNSUBSCRIBE,
                        )}
                    />
                    <Route
                        component={ContactUs}
                        exact
                        path={RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.CONTACTUS,
                        )}
                    />
                    <Route
                        component={DetailsConfirmation}
                        exact
                        path={RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.DETAILS_CONFIRMATION,
                        )}
                    />
                    <Route component={Redirecting} exact path={'/redirecting'} />
                    {/* This is just for the dev environment to not raise error for 404*/}
                    <Route component={ErrorPage} exact path="/404" />
                    {/* Defaults to landing if nothing is mentioned */}
                    <Route
                        component={({ location }) => {
                            // Preserving the arguments in the redirect
                            return (
                                <Redirect
                                    to={{
                                        pathname: RoutePrefixHandler.getDefaultRedirectPathForLocation(
                                            location,
                                        ),
                                        search:
                                            location.search ||
                                            Constants.PAGE_ENDPOINTS.DEFAULT_SEARCH_PARAM,
                                        hash: 0,
                                    }}
                                />
                            );
                        }}
                        exact
                        path="/"
                    />
                </React.Suspense>
            </AnimatedSwitch>
        );
    }
}
