import Constants from 'common/constants';
import EventManager from 'eventManager/eventManager';
import { AbTestHelper } from 'helpers';
import { evaluateUserStatusOnEmailSub } from 'helpers/session/sessionHelpers';
import Utils from 'common/utils';
// Private helpers

// Units  //

// Imp //
export const fireEmailImp = () => {
    const eventData = {
        cat: 'email',
    };

    if (AbTestHelper.zipAndEmailLandingTest())
        EventManager.sendEvent(
            Constants.EVENT.HNP_IMP,
            { ...eventData, cat: Constants.EVENT.MJH_EMAIL_REG1ONLY },
            Constants.EVENT_TYPE.DATA,
        );
    else EventManager.sendEvent(Constants.EVENT.HNP_IMP, eventData, Constants.EVENT_TYPE.DATA);
};

// Subs //
export const fireExploreJobsButtonSub = () => {
    EventManager.sendEvent(
        Constants.EVENT.EXPLORE_JOBS_BUTTON_SUB,
        null,
        Constants.EVENT_TYPE.LANDING,
    );
};

export const fireZipSub = () => {
    EventManager.sendEvent(Constants.EVENT.ZIP_AADED, null, Constants.EVENT_TYPE.LANDING);
};

export const fireEmailSub = formData => {
    const eventData = {
        cat: 'email',
        ...formData,
    };
    evaluateUserStatusOnEmailSub();
    EventManager.sendEvent(Constants.EVENT.EMAIL_ADDED, eventData, Constants.EVENT_TYPE.LANDING);
};

export const fireUserImp = () => {
    const eventData = {
        cat: 'user',
    };
    EventManager.sendEvent(Constants.EVENT.HNP_IMP, eventData, Constants.EVENT_TYPE.DATA);
};

export const fireReg2HnpImp = () => {
    EventManager.sendEvent(Constants.EVENT.HNP_IMP, { cat: 'reg2' }, Constants.EVENT_TYPE.DATA);
};

// Compositions //

// Imp //
export const fireLandingImp = () => {
    const performanceTime = Utils.getPagePerformanceTiming();
    EventManager.sendEvent(
        Constants.EVENT.LANDING_PAGE_IMP,
        { container_load_time: performanceTime },
        Constants.EVENT_TYPE.LANDING,
    );
};

export const fireReg1Imp = () => {
    fireEmailImp();
};

// Subs //

export const fireReg0Sub = () => {
    fireExploreJobsButtonSub();
    fireZipSub();
};

export const fireReg2HnpEvents = () => {
    fireUserImp();
    fireReg2HnpImp();
};

export const fireAntiSpamPixelEvent = (route, extraData = {}) => {
    const endPointEventTypeMap = {
        [Constants.PAGE_ENDPOINTS.LANDING]: Constants.EVENT_TYPE.LANDING,
        [Constants.PAGE_ENDPOINTS.SURVEY]: Constants.EVENT_TYPE.SURVEY,
        [Constants.PAGE_ENDPOINTS.ADS]: Constants.EVENT_TYPE.ADS,
        [Constants.PAGE_ENDPOINTS.GRATIFICATION]: Constants.EVENT_TYPE.GRAITIFICATION,
    };
    const eventType = endPointEventTypeMap[route];
    EventManager.sendEventWhenSessionInit(Constants.EVENT.SPAM_PIXEL_FIRED, extraData, eventType);
};
