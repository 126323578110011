import React from 'react';
import RoutePrefixHandler from 'common/routePrefixHandler';
import { InputAdornment, makeStyles } from '@material-ui/core';
import { CheckCircleRounded } from '@material-ui/icons';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const useStyles = makeStyles(theme => ({
    checkedImg: {
        maxWidth: '23px',
        maxHeight: '17px',
        width: 'auto',
        height: 'auto',
    },
}));

const InputEndAdornment = ({ checkVariant, lazyLoad = true }) => {
    const classes = useStyles();
    const icon =
        checkVariant === 'filled' ? (
            <CheckCircleRounded style={{ color: '#47a301' }} />
        ) : (
            <picture>
                <source
                    srcSet={RoutePrefixHandler.getImagePath('images/checked.webp')}
                    type="image/webp"
                />
                <img
                    alt={`icon`}
                    className={classes.checkedImg}
                    src={RoutePrefixHandler.getImagePath('images/checked.png')}
                />
            </picture>
        );

    return (
        <InputAdornment position="end">
            {lazyLoad ? <LazyLoadComponent threshold="50">{icon}</LazyLoadComponent> : icon}
        </InputAdornment>
    );
};

export default InputEndAdornment;
