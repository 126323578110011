import Utils from 'common/utils';
import Constants from 'common/constants';
import { IdStore } from 'dataStore';

class CommonDataSource {
    /**
     * Process the response that fits the following model
     * {
     *  "success": true/false,
     *  "data": {
     *      // ...Actual data
     *      "_sid": "<session_val>" // Optional
     *  }
     * }
     * @param {object} respObj
     * @returns {Promise}
     * @resolve {Object} The value of the "data" key
     * @reject {Error}
     */
    processJSONResponse(respObj) {
        return new Promise((resolve, reject) => {
            if (Utils.isNull(respObj)) {
                reject(new Error('response object cannot be null'));

                return;
            }

            // Check for status
            let isSuccess = respObj.success;

            if (Utils.isNull(isSuccess)) {
                reject(new Error('success field required in response'));

                return;
            } else if (isSuccess !== true) {
                reject(new Error('success is false in response'));

                return;
            }

            // Store the session if present in the response
            this.processSession(respObj);

            /**
             *  NOTE: Purposefully not nil-checking data.
             * There might be cases where only success is sufficient
             */
            resolve(respObj.data);
        });
    }

    /**
     * Checks for session-key and updates store if present.
     * The respObj is the original object (which contains data and success keys).
     * @param {Object} respObj
     */
    processSession(respObj) {
        if (false === Utils.isEmptyObj(respObj) && false === Utils.isEmptyObj(respObj.data)) {
            let sessionId = respObj.data[Constants.URL_ARGS.COMMON_SESSION_KEY];

            if (false === Utils.isEmptyStr(sessionId)) {
                // Store the session data
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.SESSION_ID, sessionId);
            }
        }
    }

    /**
     * Adds common args required for all urls as query-params.
     * If no args, then original url is returned
     * - `_sid` (session-id if-present)
     * @param {string} url
     * @returns {string}
     */
    processUrl(url) {
        if (Utils.isEmptyStr(url)) {
            return url;
        }

        let newArgs = {};

        // Add session-id
        let sessionId = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SESSION_ID);

        if (false === Utils.isEmptyStr(sessionId)) {
            newArgs[Constants.ID_STORE_KEYS.SESSION_ID] = sessionId;
        }

        // Add device-id
        let deviceId = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.DEVICE_ID);

        if (false === Utils.isEmptyObj(deviceId)) {
            newArgs[Constants.ID_STORE_KEYS.DEVICE_ID] = deviceId;
        }

        // Add user-id
        let userId = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_ID);

        if (false === Utils.isEmptyObj(userId)) {
            newArgs[Constants.ID_STORE_KEYS.USER_ID] = userId;
        }

        if (false === Utils.isEmptyObj(newArgs)) {
            // Add session-id to the url
            let paramStr = Utils.encodeObjectToUrlArgs(newArgs);
            let sep = '&';

            if (url.indexOf('?') === -1) {
                sep = '?';
            }
            url = `${url}${sep}${paramStr}`;
        }

        return url;
    }
}

const commonDataSrc = new CommonDataSource();
export default commonDataSrc;
