import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    header: {
        color: '#2f4862',
        fontSize: '28px',
        fontWeight: '600',
        textAlign: 'center',
        lineHeight: 'normal',
        padding: '70px 0px 25px',
        backgroundImage: 'linear-gradient(to bottom, rgba(228, 241, 252, 0.36) 0%, #e4f1fc 100%)',
        borderRadius: '6px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
            padding: '50px 0px 25px',
        },
    },
    container: {
        background: 'white',
        alignItems: 'center',
        position: 'relative',
        marginTop: '50px',
        borderRadius: '6px',
    },
    root: {
        padding: 40,
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
    },
    '@global': {
        '.MuiFormHelperText-root.Mui-error': {
            width: 'auto',
            margin: '0 auto',
            color: '#e10000',
            textAlign: 'center',
            display: 'inline-flex',
            padding: '0 9px',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#f6ddd9',
            marginTop: '4px',
        },
        '.MuiInputBase-input': {
            textTransform: 'capitalize',
        },
    },
    btnIcon: {
        position: 'relative',
        top: '2px',
        marginLeft: '20px',
        height: 18,
        width: 11,
        [theme.breakpoints.down('sm')]: {
            height: 15,
            width: 10,
            top: 0,
        },
    },
    crossIconContainer: {
        position: 'absolute',
        top: '65px',
        right: '15px',
        [theme.breakpoints.down('sm')]: {
            top: '60px',
            right: '11px',
        },
    },
    iconClass: {
        position: 'relative',
        top: '-55px',
        right: '-5px',
    },
    userIconContainer: {
        position: 'absolute',
        top: '0px',
        right: 'calc(50% - 55px)',
        [theme.breakpoints.down('sm')]: {
            top: '15px',
            right: 'calc(50% - 40px)',
        },
    },
    userIcon: {
        width: '110px',
        height: '110px',
        [theme.breakpoints.down('sm')]: {
            height: 75,
            width: 75,
        },
    },
    zipJobTitleContainer: { display: 'block !important', marginBottom: '0px !important' },
    labelV2: {
        color: '#335068',
        fontSize: '14px',
        fontWeight: '800',
    },
    checkedImg: {
        maxWidth: '23px',
        maxHeight: '17px',
        width: 'auto',
        height: 'auto',
    },
    errorInputBox: {
        border: 'solid 1px #e63737',
        borderRadius: '2px',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '16px',
        width: '100%',
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        width: '100%',
        margin: 'auto',
        paddingTop: 10,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    submitBtn: {
        overflow: 'hidden',
        whiteSpace: 'break-spaces',
        height: 64,
        fontSize: '24px',
        fontWeight: 500,
        borderRadius: 4,
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
        },
    },
}));
